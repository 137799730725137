
import OrdersIcon from '@/assets/images/icons/sidebar/OrdersIconLarge.svg?inline';
import PaymentsIcon from '@/assets/images/icons/sidebar/PaymentsIconLarge.svg?inline';
import RightIcon from '@/assets/images/icons/rightIcon.svg?inline';
import InvoicesIcon from '@/assets/images/icons/sidebar/InvoicesIconLarge.svg?inline';
import DisputesIcon from '@/assets/images/icons/sidebar/DisputesIconLarge.svg?inline';
import {
  statementsPageName,
  ordersPageName,
  paymentsPageName,
  transactionsPageName,
  disputesPageName,
  noop
} from '@/router';
import { authenticationStore, userStore } from '@/store/store';
import ActionButton from '../controls/ActionButton.vue';
import DashboardView from '../layouts/DashboardView.vue';
import Vue from 'vue';
export default Vue.extend({
  name: 'SimpleDashboard',
  components: {
    'dashboard-view': DashboardView,
    'action-button': ActionButton,
    'orders-icon': OrdersIcon,
    'payments-icon': PaymentsIcon,
    // eslint-disable-next-line vue/no-unused-components
    'right-icon': RightIcon,
    'invoices-icon': InvoicesIcon,
    'disputes-icon': DisputesIcon,
  },
  computed: {
    userName(): string {
      return authenticationStore.userName;
    },
    canAccessDisputes(): any {
      return userStore.canAccessDisputes;
    },
    canAccessStatements(): any {
      return userStore.canAccessStatements;
    },
    canAccessPayments(): any {
      return userStore.canAccessPayments;
    },
  },
  methods: {
    navigateOrders() {
      this.$router.push({ name: ordersPageName }).catch(noop);
    },
    navigatePayments() {
      this.$router.push({ name: paymentsPageName }).catch(noop);
    },
    navigateTransactions() {
      this.$router.push({ name: transactionsPageName }).catch(noop);
    },
    navigateStatements() {
      this.$router.push({ name: statementsPageName }).catch(noop);
    },
    navigateDisputes() {
      this.$router.push({ name: disputesPageName }).catch(noop);
    },
  },
});
