
import ComboBox from '@/components/controls/ComboBox.vue';
import { InsightsReportingPeriodEnum } from '@/store/insights/insights-models';
import { TranslateResult } from 'vue-i18n';
import Vue from 'vue';

export default Vue.extend({
  name: 'ReportingPeriodFilter',
  components: {
    'combobox': ComboBox,
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  data() {
    return {
      internalState: InsightsReportingPeriodEnum.lastMonth,
    }
  },
  props: {
    value: {
      type: String,
      required: true,
      validator: (v: string) => InsightsReportingPeriodEnum[v] !== undefined,
    },
  },
  async mounted() {
    this.internalState = this.value;
  },
  watch: {
    value: function(newValue: InsightsReportingPeriodEnum){
      this.internalState = newValue;
    }
  },
  computed: {
    filterItems(): any {
      const values: string[] = [];
      for (const n in InsightsReportingPeriodEnum) {
          if (typeof n === 'string') {
            values.push(n);
          }
      }
      return values.map(value => {
        return { value: InsightsReportingPeriodEnum[value], text: this.getItemMessage(InsightsReportingPeriodEnum[value]) }
      });
    },
    labelText(): TranslateResult {
      return this.$t('insights.reporting-period-label');
    },
  },
  methods: {
    onFilterValueChanged(value: InsightsReportingPeriodEnum) {
      if (InsightsReportingPeriodEnum[value] === undefined) {
        this.$emit('change', InsightsReportingPeriodEnum.thisWeek);
        return;
      }
      this.$emit('change', value);
    },
    getItemMessage(filter?: InsightsReportingPeriodEnum): TranslateResult | string {
      if (filter === undefined || InsightsReportingPeriodEnum[filter] === undefined) {
        return '';
      }
      return this.$t(`insights.reporting-period-type-${filter}`);
    }
  }
});
