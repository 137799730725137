
import Vue from "vue";
import MerchantIntegrationInformation from "@/components/common/MerchantIntegrationInformation.vue";

export default Vue.extend({
  name: "BasicIntegration",
  components: {
    "merchant-integration-info": MerchantIntegrationInformation,
  },
  props: {
    "name": {
      type: String,
      default: "fallback",
    },
    "link": {
      type: String,
      default: "https://developers.zip.co/docs/other-platforms",
    },
    "instructions": {
      type: String,
      default: ""
    },
  },
});
