
import VerifyEmail from '@/components/login/VerifyEmail.vue';
import Vue from 'vue';

export default Vue.extend({
  name: 'VerifyEmailPage',
  components: {
    'verify-email': VerifyEmail,
  },
});
