
import { DisputeEvidence, DisputeProductServiceTypeEnum } from '@/store/dispute/dispute-models';
import DisputeDetailsSection from '../DisputeDetailsSection.vue';
import ActionButton from '@/components/controls/ActionButton.vue';
import { DefaultValidatorArray, RequiredFieldValidator } from '@/validators';
import Tooltip from '@/components/controls/Tooltip.vue';
import TextBox from '@/components/controls/TextBox.vue';
import RadioGroup from '@/components/controls/RadioGroup.vue';
import Vue, { PropType } from 'vue';

export default Vue.extend({
  name: 'DisputeProductServiceSection',
  components: {
    'action-button': ActionButton,
    'dispute-details-section': DisputeDetailsSection,
    'tooltip': Tooltip,
    'textbox': TextBox,
    'radio-group': RadioGroup,
  },
  props: {
    evidence: {
      type: Object as PropType<DisputeEvidence>,
      required: true,
    },
    editable: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      requiredTextField: DefaultValidatorArray,
      requiredRadioField: DefaultValidatorArray,
      description: '',
      productServiceType: null as string | null,
    };
  },
  computed: {
    disputeProductServiceTypeItems(): any {
      // Exclude unspecified type
      const values: string[] = [];
      for (const n in DisputeProductServiceTypeEnum) {
        if (typeof DisputeProductServiceTypeEnum[n] === 'number' && n !== DisputeProductServiceTypeEnum[DisputeProductServiceTypeEnum.unspecified]) {
          values.push(n);
        }
      }
      return values.map(value => {
        return { value, label: this.getProductServiceType(DisputeProductServiceTypeEnum[value]) };
      });
    },
  },
  async created() {
    this.requiredTextField = RequiredFieldValidator;
    this.requiredRadioField = RequiredFieldValidator;
  },
  async mounted() {
    this.description = this.evidence.productDescription;
  },
  methods: {
    getProductServiceType(type?: DisputeProductServiceTypeEnum) {
      switch (type) {
        case DisputeProductServiceTypeEnum.digitalProductOrService:
          return this.$t('disputeFormProductServiceSection.product-service-type-digital-product-or-service');
        case DisputeProductServiceTypeEnum.eventBookingOrReservation:
          return this.$t('disputeFormProductServiceSection.product-service-type-event-booking-or-reservation');
        case DisputeProductServiceTypeEnum.offlineService:
          return this.$t('disputeFormProductServiceSection.product-service-type-offline-service');
        case DisputeProductServiceTypeEnum.other:
          return this.$t('disputeFormProductServiceSection.product-service-type-other');
        case DisputeProductServiceTypeEnum.physicalProduct:
          return this.$t('disputeFormProductServiceSection.product-service-type-physical-product');
        default:
          return type;
      }
    },
    next() {
      this.$emit('next', {
        description: this.description,
        productServiceType: DisputeProductServiceTypeEnum[this.productServiceType as string]
      });
    },
  },
});
