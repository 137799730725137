
import MapChart from '../../controls/charts/MapChart.vue';
import {
  AggregatedCustomer,
  ComparableAggregatedResult,
  CustomerDataTypeEnum,
} from '@/store/insights/insights-models';
import InsightsStore from '@/store/insights/insights-store';
import {
  loadMapFeature,
  resolveStateName,
} from '@/components/controls/charts/maps/usa';
import styles from '@/assets/styles/variables/_export.module.scss';
import Vue, { PropType } from 'vue';

const MapName = loadMapFeature();
export default Vue.extend({
  name: 'CustomerLocationChart',
  components: {
    'map-chart': MapChart,
  },
  props: {
    data: {
      type: Object as PropType<ComparableAggregatedResult>,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
    },
    selectedReportedPeriodLabel: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      options: {} as any,
      calculating: false,
    };
  },
  computed: {
    title(): any {
      return this.$t('insights.chart-title-customers-by-location');
    },
  },
  watch: {
    'data': async function(newVal) {
      await this.onDataChange(newVal);
    },
  },
  methods: {
    optionsWithData(
      data: Array<{ name: string; value: number }>,
      valueFormatter
    ) {
      return {
        tooltip: {
          trigger: 'item',
          showDelay: 0,
          transitionDuration: 0.2,
          backgroundColor: this.$vuetify.theme.themes.light.accent?.toString(),
          borderWidth: 0,
          valueFormatter,
          textStyle: {
            color: this.$vuetify.theme.themes.light.info?.toString(),
            fontFamily: styles.fontFamilyBook,
          },
        },
        visualMap: {
          min: 0,
          max: Math.max(...data.map((e) => e.value)),
          show: false,
          inRange: {
            // colors created by
            // taking zip fearless brand color #7A3DCE
            // entering to a color editor, I used paint.net,
            // set saturation too 70%
            // then take color change in 10 steps
            // between saturation 20% to 70%.
            //
            // 10 steps is arbitrary. Can be less or more steps.
            // echarts seems to put values in the matching bracket
            // in the range defined by min and max properties.
            //
            // if the above brand color is different, use
            // the right color, but make sure the saturation
            // range you use is visible at the bottom,
            // (so the color is still visible against white, and
            // differentiates against a data value of 0)
            // not over saturated at the top (so the high values are
            // not hurtful to see.)
            color: [
              styles.purpleTransition01,
              styles.purpleTransition02,
              styles.purpleTransition03,
              styles.purpleTransition04,
              styles.purpleTransition05,
              styles.purpleTransition06,
              styles.purpleTransition07,
              styles.purpleTransition08,
              styles.purpleTransition09,
              styles.purpleTransition10,
              styles.purpleTransition11,
            ],
          },
          calculable: false,
        },
        toolbox: {
          show: false,
        },
        series: [{
          name: this.selectedReportedPeriodLabel,
          type: 'map',
          roam: false,
          map: MapName,
          emphasis: {
            label: {
              show: false, // label may not fit within the state boundaries
            },
            itemStyle: {
              areaColor: this.$vuetify.theme.themes.light.emphasis,
              shadowBlur: 3,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.1)',
            },
            data: data,
          },
          data: data,
          zoom: 1.2,
          itemStyle: {
            areaColor: this.$vuetify.theme.themes.light.grey,
          }
        }],
      };
    },
    async onDataChange(newVal: ComparableAggregatedResult) {
      this.calculating = true;
      const result = newVal?.current;

      if (!result || !result.data) {
        return;
      }
      const { keys, values } = await InsightsStore.aggregateByCategory(
        result.data as AggregatedCustomer[],
        CustomerDataTypeEnum.Location
      );

      const data: Array<{ name: string; value: number }> = [];
      let total = 0;
      for (let index = 0; index < keys.length; index++) {
        // note that name in the response must match the names defined in the features collection.
        data.push({ name: resolveStateName(keys[index]), value: values[index] });
        await InsightsStore.sleep(); // Do not stall the UI!!
        total += values[index];
      }

      const valueFormatter = (value) => {
        if (isNaN(value)) {
          return '-';
        }
        return `${this.formatValue(value)} (${this.displayPercentage(value, total)})`;
      };

      this.options = this.optionsWithData(data, valueFormatter);
      this.calculating = false;
    },
    displayPercentage(value, total: number) {
      return this.formatPercentage((value * 100) / Math.max(1, total));
    },
    formatValue(value: number): string {
      return `${this.$n(Math.round(value))}`;
    },
    formatPercentage(value: number): string {
      return `${isNaN(value) ? 0 : value.toFixed(2)}%`;
    },
  },
});
