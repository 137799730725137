import configuration from '@/configuration';
import { handleResponseErrors } from '@/errors';
import logger from '@/logger';
import {
  User, UserPermissions
} from '@/store/merchant/merchant-models';
import store, { authenticationStore, featureStore } from '@/store/store';
import {
  Action,
  Module,
  Mutation,
  VuexModule,
} from 'vuex-module-decorators';
import { UpdateUserRolesCommand, UserSearchOptions } from './user-models';

// QP Merchant API Url
const merchantApiUrl = `${configuration.links.apiDomain}${configuration.links.apiPath}/`;

const roleSetsEqual = (apiRoles: string[], tokenRoles: string[]) => {
  const lengthEqual = apiRoles.length === tokenRoles.length;
  const apiRolesInToken = apiRoles.map(r => tokenRoles.some(tr => r === tr));
  const tokenRolesInApi = tokenRoles.map(tr => apiRoles.some(r => tr === r));
  const anyApiRolesMissingInToken = apiRolesInToken.some(r => !r);
  const anyTokenRolesMissingInApi = tokenRolesInApi.some(r => !r);

  return lengthEqual && !anyApiRolesMissingInToken && !anyTokenRolesMissingInApi;
}

/**
 * The user store is responsible for managing user data and business logic
 */
@Module({
  name: 'user',
  namespaced: true,
  store,
})
export default class UserStore extends VuexModule {
  users: User[] = [];
  userCount = 0;
  currentUser: User | null = null;
  currentUserRoles: UserPermissions[] = [];
  isUserLoading = false;

  get loggedInUser(): User | null {
    return this.currentUser;
  }

  get canAccessDisputes() {
    return featureStore.shouldAllowDisputePagesAccess && hasAdminOrRole(this.currentUserRoles, UserPermissions.disputes);
  }

  get canAccessStatements() {
    return hasAdminOrRole(this.currentUserRoles, UserPermissions.transactionsRecon);
  }

  get canAccessPayments() {
    return hasAdminOrRole(this.currentUserRoles, UserPermissions.paymentsRecon);
  }

  get canAccessInsights() {
    return featureStore.shouldAllowInsightPagesAccess && hasAdminOrRole(this.currentUserRoles, UserPermissions.transactionsRecon);
  }

  get canAccessUserPages() {
    return hasAdminOrRole(this.currentUserRoles, UserPermissions.admin);
  }

  get canAccessSettings() {
    return hasAdminOrRole(this.currentUserRoles, UserPermissions.settings);
  }

  /**
   * Get Users for merchant
   */
  @Action
  async getMerchantUsers(searchOptions: UserSearchOptions) {
    const { accessToken } = authenticationStore;
    const merchantId = authenticationStore.merchantId;
    let url = `${merchantApiUrl}${merchantId}/search-users`;

    url += `?Page=${(searchOptions.page - 1).toString()}`;
    url += `&PageSize=${searchOptions.pageSize.toString()}`;
    url += `&OrderBy.ColumnName=${searchOptions.sortBy}`;
    url += `&OrderBy.Direction=${searchOptions.sortDesc ? "Desc" : "Asc"}`;

    if (configuration.featureFlags.showZipEmailsInUserManagement) {
      url += '&ShowZipEmails=true';
    }

    if (searchOptions.search) {
      url += `&SearchTerm=${encodeURIComponent(searchOptions.search)}`;
    }
 
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          authorization: `Bearer ${accessToken}`,
          'qp-territory': authenticationStore.currentTerritory,
        },
      });
      logger.debug("Collection response", response);

      if (response.status === 403) {
        // We don't have access to this feature, show a pop-up with a message
        await handleResponseErrors(response, 'Merchant ID', true, false);
        return false;
      } else if (response.status > 226) {
        // There should be a Merchant in the collection, throw error if not
        await handleResponseErrors(response, 'Merchant ID', false, true);
        return false;
      } else {
        const res = await response.json();
        this.setUsers(res.value as User[]);
        this.setUserTotal(res.total as number);
      }
    } catch (e) {
      logger.error(`Error getting merchant users: `, e);
      throw e;
    }
  }
  
  /**
   * Get Users for merchant
   */
  @Action
  async getUserInfo(email: string): Promise<User | null> {
    const { accessToken } = authenticationStore;
    const merchantId = authenticationStore.merchantId;
    const url = `${merchantApiUrl}${merchantId}/get-user/${encodeURIComponent(email)}`;

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          authorization: `Bearer ${accessToken}`,
          'qp-territory': authenticationStore.currentTerritory,
        },
      });
      logger.debug("Collection response", response);
      if (response.status > 226) {
        // There should be a Merchant in the collection, throw error if not
        await handleResponseErrors(response, 'Merchant ID', false, true);
        return null;
      } else {
        const res = await response.json() as User;
        return res;
      }
    } catch (e) {
      logger.error(`Error getting user info: `, e);
      throw e;
    }
  }

  /**
   * Get Users for merchant
   */
    @Action
    async inviteUser(email: string) {
      const { accessToken } = authenticationStore;
      const merchantId = authenticationStore.merchantId;
      const url = `${merchantApiUrl}${merchantId}/invite-user`;
      const nickName = email.split('@')[0];
      const body = {
        userEmail: email,
        nickName: nickName
      }
  
      try {
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: `Bearer ${accessToken}`,
            'qp-territory': authenticationStore.currentTerritory,
          },
          body: JSON.stringify(body)
        });
        logger.debug("Collection response", response);
        if (response.status > 226) {
          // There should be a Merchant in the collection, throw error if not
          await handleResponseErrors(response, 'Merchant ID', false, true);
          return false;
        } else {
          return true;
        }
      } catch (e) {
        logger.error(`Error inviting user: `, e);
        throw e;
      }
    }

  /**
   * Update roles for user
   */
  @Action
  async updateUserRoles(command: UpdateUserRolesCommand) {
    const { accessToken } = authenticationStore;
    const merchantId = authenticationStore.merchantId;
    const url = `${merchantApiUrl}${merchantId}/update-user-roles`;

    try {
      const response = await fetch(url, {
        method: "PUT",
        headers: {
          "content-type": "application/json",
          authorization: `Bearer ${accessToken}`,
          'qp-territory': authenticationStore.currentTerritory,
        },
        body: JSON.stringify(command)
      });
      logger.debug("Collection response", response);
      if (response.status > 226) {
        // There should be a Merchant in the collection, throw error if not
        await handleResponseErrors(response, 'Merchant ID', false, true);
        return false;
      }
    } catch (e) {
      logger.error(`Error updating user roles: `, e);
      throw e;
    }
  }

  @Mutation
  setUsers(users: User[]) {
    this.users = users;
  }

  @Mutation
  setUserTotal(total: number) {
    this.userCount = total;
  }

  @Action
  async removeUser(email: string) {
    const { accessToken } = authenticationStore;
    const merchantId = authenticationStore.merchantId;
    const url = `${merchantApiUrl}${merchantId}/remove-user`;
    const body = {
      userEmail: email
    }

    try {
      const response = await fetch(url, {
        method: "PUT",
        headers: {
          "content-type": "application/json",
          authorization: `Bearer ${accessToken}`,
          'qp-territory': authenticationStore.currentTerritory,
        },
        body: JSON.stringify(body)
      });
      logger.debug("Collection response", response);
      if (response.status > 226) {
        // There should be a Merchant in the collection, throw error if not
        await handleResponseErrors(response, 'Merchant ID', false, true);
        return false;
      } else {
        return true;
      }
    } catch (e) {
      logger.error(`Error inviting user: `, e);
      throw e;
    }
  }

  @Action
  async getCurrentUser() {
    const { accessToken } = authenticationStore;
    const merchantId = authenticationStore.merchantId;
    const url = `${merchantApiUrl}${merchantId}/current-user`;

    if(!merchantId) {
      return;
    }

    this.setIsUserLoading(true);

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          authorization: `Bearer ${accessToken}`,
          'qp-territory': authenticationStore.currentTerritory,
        },
      });
      logger.debug("Collection response", response);
      if (response.status > 226) {
        // There should be a Merchant in the collection, throw error if not
        await handleResponseErrors(response, 'Merchant ID', false, true);
        return false;
      } else {
        const res = await response.json() as User;
        
        this.setCurrentUser({
          email: res.email,
          nickName: res.nickName,
          fullName: res.fullName,
          roles: res.roles
        });

        this.setCurrentUserRoles(res.roles);

        // See whether the roles match between the token and the API response.
        // If they differ it means we need to re-fetch the access token.
        const tokenRoles = authenticationStore.roles || [];
        const apiRoles = res.roles || [];
        const requiresRefresh = !roleSetsEqual(apiRoles, tokenRoles);

        if (requiresRefresh) {
          await authenticationStore.refreshToken();
        }
      }
    } catch (e) {
      logger.error(`Error setting current user: `, e);
      throw e;
    }

    this.setIsUserLoading(false);
  }

  @Action
  onError() {
    this.setIsUserLoading(false);
  }

  @Mutation
  setIsUserLoading(x: boolean) {
    this.isUserLoading = x;
  }

  @Mutation
  setCurrentUser(user: User) {
      this.currentUser = user;
  }

  @Mutation
  setCurrentUserRoles(roles: string[]) {
    this.currentUserRoles = roles.map(r => r as UserPermissions);
  }

  @Mutation
  reset() {
    this.users = [];
    this.userCount = 0;
    this.currentUser = null;
    this.currentUserRoles = [];
  }
}

export const hasAdminOrRole = (roles: UserPermissions[], role: UserPermissions): boolean => {
  return roles.indexOf(UserPermissions.admin) > -1 || roles.indexOf(role) > -1;
}
