import configuration from '@/configuration';
import eventBus from '@/event-bus';
import { applicationStore } from '@/store/store';

/* eslint no-console: 0 */
/**
 * Generate a message with the logging content, the timestamp, and the log level.
 * @param message The original log message
 * @param level The log level
 */
const generateLogMessage = (message: any, level: string): string => {
  const messagePrefix = new Date().toISOString() + ' ' + level;
  if (typeof message === 'string') {
    return messagePrefix + ' ' + message;
  } else {
    return messagePrefix;
  }
};

/**
 * Logger is used for logging debug messages to the JavaScript console.
 */
export default {

  /**
   * Check if we can log to the console and if the log is enabled/overridden
   */
  canLogToConsole(): boolean {
    return !!window.console && (configuration.debug.appLogEnabled || this.override);
  },

  /**
   * Reactive getter that uses the store to see if we should override the log enabled value
   */
  get override(): boolean {
    return applicationStore.debugMode;
  },

  debug(message: any, ...args: any[]) {
    if (this.canLogToConsole()) {
      const level = 'DEBUG';
      const logMessage = generateLogMessage(message, level);
      if (typeof message !== 'string') {
        args.push(message);
      }
      console.debug(logMessage, ...args);
      eventBus.publishTraceEvent(message, ...args, { level });
    }
  },

  info(message: any, ...args: any[]) {
    if (this.canLogToConsole()) {
      const level = 'INFO';
      const logMessage = generateLogMessage(message, level);
      if (typeof message !== 'string') {
        args.push(message);
      }

      console.info(logMessage, ...args);
      eventBus.publishTraceEvent(message, ...args, { level });
    }
  },

  warn(message: any, ...args: any[]) {
    if (this.canLogToConsole()) {
      const level = 'WARN';
      const logMessage = generateLogMessage(message, level);
      if (typeof message !== 'string') {
        args.push(message);
      }

      console.warn(logMessage, ...args);
      eventBus.publishTraceEvent(message, ...args, { level });
    }
  },

  error(message: any, ...args: any[]) {
    if (this.canLogToConsole()) {
      const level = 'ERROR';
      const logMessage = generateLogMessage(message, level);
      if (typeof message !== 'string') {
        args.push(message);
      }

      console.error(logMessage, ...args);
      eventBus.publishTraceEvent(message, ...args, { level });
    }
  },
};
