export function orderBy<T>(input: T[], selector: (a: T) => any): T[] {
  const output = [...input];

  output.sort((a, b) => (selector(a) > selector(b) ? 1 : -1));

  return output;
}

export function orderByDesc<T>(input: T[], selector: (a: T) => any): T[] {
  const output = [...input];

  output.sort((a, b) => (selector(a) < selector(b) ? 1 : -1));

  return output;
}

export interface PagingInfo {
  pageLimit: number;
  total: number;
}