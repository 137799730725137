
import ErrorImage from '@/assets/images/error.svg?inline';
import SimpleCopyrightFooter from '@/components/common/SimpleCopyrightFooter.vue';
import SimpleLogoHeader from '@/components/common/SimpleLogoHeader.vue';
import configuration from '@/configuration';
import { authenticationStore } from '@/store/store';
import Vue from 'vue';

export default Vue.extend({
  name: 'UnauthorizedPage',
  components: {
    'simple-footer': SimpleCopyrightFooter,
    'simple-header': SimpleLogoHeader,
    'error-image': ErrorImage,
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    companyName(): string {
      return configuration.company.name;
    },
  },
  methods: {
    async navigateLogin(event: Event) {
      event.preventDefault();

      // UI idempotency in case a user clicks more than once
      if (this.loading) {
        return;
      }

      this.loading = true;

      try {
        // Logout and redirect to login page
        await authenticationStore.logout(true);
      } catch (e) {
        throw new Error(`Error logging out: ${JSON.stringify(e)}`);
      }
    },
  },
});
