
import ActionButton from '../controls/ActionButton.vue';
import Vue, { PropType } from 'vue';

export default Vue.extend({
  name: 'DialogBox',
  components: {
    'action-button': ActionButton,
  },
  props: {
    id: {
      type: String,
      default: "",
    },
    show: {
      type: Boolean,
      required: true,
    },
    dialogClass: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    message: {
      type: String,
      default: "",
    },
    buttonText: {
      type: String,
      default: "",
    },
    buttonAction: {
      type: Function as PropType<() => void>,
      required: true,
    },
  },
  data() {
    return {
      showState: false,
    };
  },
  watch: {
    'show': function(newVal) {
      this.showState = newVal;
    },
  },
  async mounted() {
    this.showState = this.show;
  },
});
