
import DisputeDetailsSection from '../DisputeDetailsSection.vue';
import Vue from 'vue';

export default Vue.extend({
  name: 'DisputeStartResponseHeaderSection',
  components: {
    'dispute-details-section': DisputeDetailsSection
  },
});
