
import Vue, { PropType } from "vue";

export default Vue.extend({
  name: "ActionButton",
  components: {},
  props: {
    on: { required: false, default: undefined },
    attrs: { required: false, default: undefined },
    extraClasses: { required: false, default: undefined },
    disabled: {
      type: Boolean as PropType<boolean | undefined>,
      required: false,
      default: undefined,
    },
    loading: {
      type: Boolean as PropType<boolean | undefined>,
      required: false,
      default: undefined,
    },
    capitalize: { type: Boolean, required: false, default: true },
    href: {
      required: false,
      default: undefined,
    }
  },
  computed: {
    hasIconSlot(): any {
      return !!this.$slots.icon;
    },
    cssClassesNormalized(): string[] {
      if (Array.isArray(this.extraClasses)) {
        return this.extraClasses;
      }
      return [this.extraClasses]; // Assuming single CSS class string
    },
  },
  methods: {
    emitClick(event) {
      this.$emit("click", event);
    },
  },
});
