
import { DisputeEvidence } from '@/store/dispute/dispute-models';
import DisputeEvidenceFiles from '../components/DisputeEvidenceFiles.vue';
import DisputeSupportingEvidenceGuide from './DisputeSupportingEvidenceGuide.vue';
import DisputeDetailsSection from '../DisputeDetailsSection.vue';
import Tooltip from '@/components/controls/Tooltip.vue';
import TextBox from '@/components/controls/TextBox.vue';
import Vue, { PropType } from 'vue';

export default Vue.extend({
    name: 'DisputeSupportingEvidenceFilesSection',
    components: {
        'dispute-supporting-evidence-guide': DisputeSupportingEvidenceGuide,
        'dispute-evidence-files': DisputeEvidenceFiles,
        'dispute-details-section': DisputeDetailsSection,
        'tooltip': Tooltip,
        'textbox': TextBox,
    },
    props: {
        evidence: {
            type: Object as PropType<DisputeEvidence>,
            required: true,
        },
        editable: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            expandedState: [],
            uncategorizedText: '',
        };
    },
    watch: {
        'uncategorizedText': function (newVal) {
            this.onServiceDateChanged(newVal);
        },
    },
    async created() {
        this.uncategorizedText = this.evidence.uncategorizedText;
    },
    methods: {
        onServiceDateChanged(newValue) {
            this.$emit('evidencePropertyChanged', { propertyName: 'uncategorizedText', propertyValue: newValue });
        },
        onRemoteFileUploaded(evt) {
            this.$emit('remoteFileUploaded', evt);
        },
        onRemoteFileDeleted(evt) {
            this.$emit('remoteFileDeleted', evt);
        },
    },
});
