
import configuration from '@/configuration';
import moment from 'moment-timezone';
import Vue from 'vue';
export default Vue.extend({
  name: 'SimpleCopyrightFooter',
  data() {
    return {
      currentYear: moment().year(),
      companyFormalName: configuration.company.companyFormalName,
    };
  },
  computed: {
    copyrightText() {
      return this.$t('simpleCopyrightFooter.copyright-text', { year: this.currentYear, company: this.companyFormalName });
    },
  },
});
