import { Platform } from "./merchant-models";

// Options for MSS
// All eCommerce Platform options (systemNames correspond to BE Platform enum)
export const bigCommerce: Platform = { displayName: 'Big Commerce', systemName: 'big_commerce' };
export const custom: Platform = { displayName: 'Custom', systemName: 'custom' };
export const magento2: Platform = { displayName: 'Magento 2', systemName: 'magento_2' };
export const oracleAtg: Platform = { displayName: 'Oracle ATG', systemName: 'oracle_atg' };
export const salesforceCommerceCloud: Platform = { displayName: 'Salesforce Commerce Cloud', systemName: 'salesforce_commerce_cloud' };
export const shopify: Platform = { displayName: 'Shopify', systemName: 'shopify' };
export const shopifyCartHook: Platform = { displayName: 'CartHook', systemName: 'shopify_-_carthook' };
export const spree: Platform = { displayName: 'Spree', systemName: 'spree' };
export const sylius: Platform = { displayName: 'Sylius', systemName: 'sylius' };
export const virtualCheckout: Platform = { displayName: 'Virtual Checkout', systemName: 'virtual_checkout' };
export const woocommerce: Platform = { displayName: 'WooCommerce', systemName: 'woocommerce' };

export const allECommercePlatforms: Platform[] = [
  bigCommerce,
  custom,
  magento2,
  oracleAtg,
  salesforceCommerceCloud,
  shopify,
  shopifyCartHook,
  spree,
  sylius,
  virtualCheckout,
  woocommerce,
];

// eCommerce Platform options we currently do not support
export const unsupportedECommercePlatforms: Platform[] = [
  custom,
  oracleAtg,
  salesforceCommerceCloud,
  spree,
  sylius,
  virtualCheckout,
];
export const isPlatformSupported = (platform: string): boolean => {
  platform = platform.toLowerCase();
  return !unsupportedECommercePlatforms.some(x => x.systemName === platform);
}

// eCommerce Platform options compatible with sign-up query params
// must match enums directly (ex Big Commerce -> big_commerce)
export const supportedSignUpPlatforms: Platform[] = [
  bigCommerce,
];
export const isPlatformSupportedOnSignUp = (platform: string): boolean => {
  platform = platform.toLowerCase();
  return supportedSignUpPlatforms.some(x => x.systemName === platform);
}

// Primary and secondary industries
export const industries: Record<string, string[]> = {
  "Home & Furniture": [
      "Appliances",
      "Kitchen",
      "Stationary",
      "Decor",
      "Bedroom & Bathroom",
      "Niche",
      "Garden & Botany",
      "Office",
      "Construction",
  ],
  "Jewelry & Accessories": [
      "Jewelry",
      "Optical",
      "Luggage",
      "Hand Bags",
      "Niche",
      "Watches",
      "Accessories",
      "Hats",
      "Gifts",
  ],
  "Beauty, Health & Wellness": [
      "Optical",
      "Hair",
      "Skincare",
      "Perfume",
      "Cosmetics",
      "Grooming",
      "Supplements",
      "CBD",
      "Adult",
      "Niche",
      "Hair Extensions",
      "Spas/Salons",
      "Eye Wear",
      "Wellness",
      "Consumer Goods"
  ],
  "Clothing": [
      "Plus Size",
      "Activewear",
      "Designer Fashion",
      "Streetwear",
      "Formal",
      "Bridal",
      "Maternity",
      "Swimwear",
      "Intimates",
      "Niche",
      "Accessories",
      "Casual",
      "Denim",
      "Fast Fashion",
  ],
  "Food & Beverage": [
      "Health Food",
      "Niche",
      "Alcohol",
      "Delivery",
      "Grocery",
      "Resturant",
  ],
  "Kids & Babies": [
      "Toys",
      "Strollers & Carriers",
      "Niche",
      "Childcare",
  ],
  "Pets": [
      "Grooming",
      "Accessories",
      "Pets",
  ],
  "Footwear": [
      "Activewear",
      "Designer Fashion",
      "Streetwear",
      "Formal",
      "Shoes",
  ],
  "Travel, Leisuire & Experiences": [
      "Flights",
      "Ticketing",
      "Amusement Parks",
      "Cruises",
      "Hotels",
      "Trains",
  ],
  "Sports, Fitness & Outdoors": [
      "Equipment",
      "Beach",
      "Niche",
      "Fitness / Gyms",
      "Landscaping",
      "Sports team",
      "e-sports",
  ],
  "Education": [
      "Learning Materials",
      "In-Person Learning",
      "E-learning",
  ],
  "Marketplace": [
      "Home & Furniture",
      "Jewelry & Accessories",
      "Beauty & Health",
      "Clothing",
      "Food & Beverage",
      "Kids & Babies",
      "Footwear",
      "Travel & Experiences",
      "Retail",
      "Entertainment",
      "Art & Design",
  ],
  "Financial Services": [
      "Banking",
      "Insurance",
  ],
  "Utilities": [
      "Electric",
      "Gas",
      "Water",
  ],
  "Entertainment": [
      "Cinema",
      "Music venue ",
      "Music equipments",
      "Ticketing",
      "Games & Video Games",
      "Consumer Goods",
  ],
  "Health Care": [
      "Dental",
      "Hospital",
      "Medical Devices",
      "Medical Practice",
      "Medical Services",
      "Pharmacy",
      "Clinic",
  ],
  "Legal Services": [
      "Law Practice",
  ],
  "Technology & Media": [
      "Consumer Electronics",
      "Computer Hardware",
      "Computer Software ",
      "Media",
      "Telecommunication",
  ],
  "Automotive": [
      "Cars",
      "Bikes / Scooters",
      "Car Parts",
      "Tyres",
      "Used Car",
  ],
  "Art & Design": [
      "Museum",
      "Gallery",
      "Art studio",
      "Design Studio",
      "Photography",
  ]
};

// Secondary Industries options we currently do not support
export const unsupportedSecondaryIndustries: string[] = [
  "Adult",
  "CBD",
];

// Annual Sales slider value is an index
// Ticks are for labels/display
// vvv MUST correspond to annualSalesMinsAndMaxes
export const annualSalesTicks: string[] = [
  "0K-1M",
  "1M-5M",
  "5M-20M",
  "20M-50M",
  "50M-100M",
  "100M+",
];

// Mins and Maxes are for use
// ^^^ MUST correspond to annualSalesTicks
export const annualSalesMinsAndMaxes: number[][] = [
  [0, 1000000], // T4
  [1000000, 5000000], // T3
  [5000000, 20000000],
  [20000000, 50000000],
  [50000000, 100000000],
  [100000000, 100000001], // 100000001 used to signify 100000000+
];

export const howYouHeardAboutQuadpay: string[] = [
  "Google",
  "Social Media",
  "Advertising",
  "Merchant Ad",
  "Other",
];