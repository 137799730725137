
import { Notification } from '@/store/content/content-models';
import SidebarNotification from './SidebarNotification.vue';
import Vue, { PropType } from 'vue';
export default Vue.extend({
  name: 'SidebarNotificationList',
  components: {
    'sidebar-notification': SidebarNotification,
  },
  props: {
    notifications: {
      type: Array as PropType<Notification[]>,
      required: true,
    },
  },
  computed: {
    hasNotifications(): any {
      return this.notifications && this.notifications.length > 0;
    },
  },
});
