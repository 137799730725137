import configuration from "@/configuration";
// import logger from "@/logger";
import { ContentfulNotification, MerchantTargetGroup, MerchantTargetGroupContentTypeId, WhatsNewItem } from "@/store/content/content-models";

import * as contentful from 'contentful';

const client = contentful.createClient({
  space: configuration.contentful.spaceId,
  accessToken: configuration.contentful.contentDeliveryToken,
  environment: configuration.contentful.environment
})

export default {
  async getNotifications(): Promise<{items: ContentfulNotification[], merchantTargetGroups: MerchantTargetGroup[]}> {
    const response = await client.getEntries<ContentfulNotification>({
      content_type: 'notification',
    });

    return {
      items: response.items.map(i => ({ ...i.fields })),
      merchantTargetGroups: response.includes?.Entry
        ?.filter(e => e?.sys?.contentType?.sys?.id === MerchantTargetGroupContentTypeId)
        ?.map(e => ({
          id: e.sys.id,
          ...e.fields,
        })) ?? [],
    }
  },
  
  async getWhatsNewItems(): Promise<{items: WhatsNewItem[], merchantTargetGroups: MerchantTargetGroup[]}> {
    const response = await client.getEntries<WhatsNewItem>({
      content_type: 'whats_new',
    });

    return {
      items: response.items.map(i => ({ ...i.fields })),
      merchantTargetGroups: response.includes?.Entry
        ?.filter(e => e?.sys?.contentType?.sys?.id === MerchantTargetGroupContentTypeId)
        ?.map(e => ({
          id: e.sys.id,
          ...e.fields,
        })) ?? [],
    }
  }
}