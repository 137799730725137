
import LineChart, { LineChartStatic } from '../../controls/charts/LineChart.vue';
import ScalarChart from '../../controls/charts/ScalarChart.vue';
import InsightsStore from '@/store/insights/insights-store';
import {
  AggregatedResult,
  ComparableAggregatedResult,
} from '@/store/insights/insights-models';
import AggregatedResultChartDate from './AggregatedResultChartDate';
import ChartTooltipCallback from './ChartTooltipCallback';
import ChartTooltip from './ChartTooltip.vue';
import { applicationStore } from '@/store/store';
import Vue, { PropType } from 'vue';
export default Vue.extend({
  name: 'AggregatedResultChart',
  components: {
    'scalar-chart': ScalarChart,
    'line-chart': LineChart,
    'chart-tooltip': ChartTooltip,
  },
  props: {
    data: {
      type: Object as PropType<ComparableAggregatedResult>,
      required: true,
    },
    calculateAverage: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
    },
    visible: {
      type: Boolean,
      default: true,
    },
    getValueFunc: {
      type: Function as PropType<(entry?: any) => number>,
      required: true,
    },
    getAverageValueFunc: {
      type: Function as PropType<(values: number[], data: AggregatedResult) => number>,
      required: false,
    },
    formatValueFunc: {
      type: Function as PropType<(val?: number) => string>,
      required: true,
    },
    formatVerticalAxisFunc: {
      type: Function as PropType<(val?: number) => string>,
      default: undefined,
    },
    selectedReportedPeriodLabel: {
      type: String,
      required: false,
    },
    comaprisonPeriodLabel: {
      type: String,
      required: false,
    },
    selectedReportedPeriod: {
      type: String,
      required: false,
    },
    comaprisonPeriod: {
      type: String,
      required: false,
    },
    cssClasses: {
      type: Array as PropType<Array<string>>,
      required: false,
    },
    tooltipText: {
      type: String,
      required: false,
    },
    minRatio: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      total: 0,
      datasets: [] as Array<any>,
      labels: [] as Array<string>,
      calculating: false,
      options: LineChartStatic.createOptionsTemplate(),
      chartTooltipOpacity: '0',
      chartTooltipLeft: '',
      chartTooltipTop: '',
      chartTooltipMainTitle: '',
      chartTooltipMainLabel: '',
      chartTooltipMainValue: '',
      chartTooltipAuxTitle: '',
      chartTooltipAuxLabel: '',
      chartTooltipAuxValue: '',
      comparisonPercentage: 0,
      comparisonValue: 0,
    };
  },
  computed: {
    computedComparisonValue(): any {
      return this.comparisonValue == 0
        ? ''
        : this.formatValueFunc(this.comparisonValue);
    },
    currentLocale(): string {
      return applicationStore.currentLocale.locale;
    },
  },
  watch: {
    'data': async function(newVal) {
      await this.onDataChange(newVal);
    },
  },
  methods: {
    getAverageValue(values: number[], data: AggregatedResult) {
      if (this.getAverageValueFunc) {
        return this.getAverageValueFunc(values, data);
      }
      return (
        values.reduce((acc, cur) => (acc += cur), 0) /
        (this.calculateAverage ? Math.max(values.length, 1) : 1)
      );
    },
    async onDataChange(newVal: ComparableAggregatedResult) {
      this.calculating = true;

      const result = newVal?.current;
      const pyresult = newVal?.previous;
      if (!result || !result.data) {
        return;
      }

      const { keys, values, isWeekly } = await InsightsStore.createKeyValueArrays(
        result.data,
        result.startDate,
        result.endDate,
        newVal.reportingPeriod,
        this.getValueFunc
      );
      let pyArr = { values: new Array<number>(), keys: new Array<Date>() };
      if (pyresult) {
        pyArr = await InsightsStore.createKeyValueArrays(
          pyresult.data,
          pyresult.startDate,
          pyresult.endDate,
          newVal.reportingPeriod,
          this.getValueFunc
        );
      }

      this.total = this.getAverageValue(values, result);
      this.comparisonValue =
        (pyArr?.values?.reduce((acc, cur) => (acc += cur), 0) || 0) /
        (this.calculateAverage ? Math.max(pyArr?.values?.length ?? 1, 1) : 1);
      this.comparisonPercentage = this.comparisonValue === 0 ? Infinity : ((this.total - this.comparisonValue) / this.comparisonValue) * 100;

      const mainDataset = {
        ...LineChartStatic.createDatasetTemplate(this.$vuetify, values.length),
        label: this.selectedReportedPeriodLabel,
        data: values,
      };

      const comparisonDataSet = !pyresult ? null : {
        ...LineChartStatic.createDatasetTemplate(this.$vuetify, pyArr.values.length, true),
        label: this.comaprisonPeriodLabel,
        data: pyArr.values,
      };

      const keySets = !comparisonDataSet ? [keys] : [keys, pyArr.keys];
      const datasets = !comparisonDataSet
        ? [mainDataset]
        : [mainDataset, comparisonDataSet];
      this.datasets = datasets;
      this.labels = keys.map((l) => AggregatedResultChartDate.formatDate(l, this.currentLocale, false, newVal.reportingPeriod));

      let minValue = Number.MAX_SAFE_INTEGER;
      values.forEach((n) => {
        minValue = Math.min(n, minValue);
      });
      if (pyresult) {
        pyArr.values.forEach((n) => {
          minValue = Math.min(n, minValue);
        });
      }
      if (minValue === Number.MAX_SAFE_INTEGER) {
        minValue = 0;
      }

      const options = LineChartStatic.createOptionsTemplate();
      if (this.minRatio) {
        options.scales.y.min = Math.round(minValue * this.minRatio);
      }

      const instance = this;
      if (instance.formatVerticalAxisFunc) {
        options.scales.y.ticks = {
          // Include a dollar sign in the ticks
          callback: function(value /*, index, ticks*/) {
            if (instance.formatVerticalAxisFunc) {
              return instance.formatVerticalAxisFunc(value);
            }
          },
        };
      }

      options.plugins.tooltip.enabled = false;
      options.plugins.tooltip.external = (context) => ChartTooltipCallback.defaultCallback(instance, datasets, keySets, context, isWeekly, instance.formatValueFunc);
      this.options = options;

      this.calculating = false;
    },
  },
});
