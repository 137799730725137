
import LoadingSpinner from '@/components/common/LoadingSpinner.vue';
import SimpleCopyrightFooter from '@/components/common/SimpleCopyrightFooter.vue';
import SimpleLogoHeader from '@/components/common/SimpleLogoHeader.vue';
import { merchantStore } from '@/store/store';
import Vue from 'vue';

export default Vue.extend({
  name: 'VerifyEmail',
  components: {
    'loading-spinner': LoadingSpinner,
    'simple-footer': SimpleCopyrightFooter,
    'simple-header': SimpleLogoHeader,
  },
  computed: {
    message(): string {
      return (this.$route.query.message || '') as string;
    },
    success(): boolean {
      return this.$route.query.success && (this.$route.query.success as string).toLowerCase() === 'true' ? true : false;
    },
  },
  mounted() {
    merchantStore.handleEmailVerification(this.success, this.message);
  },
});
