
import CheckImage from '@/assets/images/check.svg?inline';
import SimpleCopyrightFooter from '@/components/common/SimpleCopyrightFooter.vue';
import SimpleLogoHeader from '@/components/common/SimpleLogoHeader.vue';
import { defaultPageName, noop } from '@/router';
import { authenticationStore } from '@/store/store';
import Vue from 'vue';
import ActionButtonVue from '../controls/ActionButton.vue';

export default Vue.extend({
  name: 'Logout', // eslint-disable-line vue/multi-word-component-names
  components: {
    'check-image': CheckImage,
    'simple-footer': SimpleCopyrightFooter,
    'simple-header': SimpleLogoHeader,
    'action-button': ActionButtonVue
  },
  data() {
    return {
      loading: true,
    };
  },
  async mounted() {
    await authenticationStore.logout(true);
    this.loading = false;
  },
  methods: {
    async navigateLogin(event: Event) {
      event.preventDefault();

      // UI idempotency in case a user clicks more than once
      if (this.loading) {
        return;
      }

      // Since user was already logged out on mount, just reroute to default page
      this.$router.push({ name: defaultPageName }).catch(noop);
    },
  },
});
