import LogMethod from '@/decorators/logger-decorator';
import store from '@/store/store';

import {
  config,
  Action,
  Module,
  Mutation,
  VuexModule,
} from 'vuex-module-decorators';
import { getObject } from '../store-requests';

import { featureFlagConfiguration } from "./feature-config";

// Set rawError for all Actions in module to true
config.rawError = true;

/**
 * The feature store is responsible for managing feature flags
 * 
 * To add new feature flag:
 *   1. Add a new Flag in optimisely.  Use whatever name you like, but pay attention to the key that is generated (e.g. 'my_feature_key')
 *   1. Add a new local variable in this store (e.g. 'shouldShowMyNewFeature')
 *   2. Define an entry in the ./feature-configuration.ts file using the new local variable name ('shouldShowMyNewFeature') as name, and the key from optimizely ('my_feature_key') as the key.
 *   3. Optionally define on and off values as necessary (will default to on=>true / off=>false)
 */
@Module({
  name: 'feature',
  namespaced: true,
  store,
})
export default class FeatureStore extends VuexModule {
  shouldDisplayTaxInfo = false;
  shouldAllowInsightPagesAccess = false;
  shouldAllowDisputePagesAccess = false;
  isNewMerchantComplianceFlow = false;
  showComplianceIntegrationTypeDropdown = false;
  skipOnboardingIntegrationForMerchant = false;
  isIntercomEnabled = false;

  @Action
  async initialize() {
    this.reset();

    const { enabledFlags } = await getObject<{enabledFlags: string[]}>({
      url: {
        service: 'featureFlag',
      }
    });

    this.setFeatureFlags(enabledFlags);
  }

  @Mutation
  @LogMethod
  setFeatureFlags(enabledKeys: string[]) {
    for(const flag of featureFlagConfiguration) {
      const isFlagEnabled = enabledKeys.indexOf(flag.key) > -1;
      const flagOnValue = flag.on !== undefined ? flag.on() : true;
      const flagOffValue = flag.off !== undefined ? flag.off() : false;

      this[flag.name] = isFlagEnabled ? flagOnValue : flagOffValue;
    }
  }

  @Mutation
  @LogMethod
  reset() {
    for(const flag of featureFlagConfiguration) {
      this[flag.name] = flag.off !== undefined ? flag.off() : false;
    }
  }
}
