
import Vue, { PropType } from 'vue';

export default Vue.extend({
    name: 'ComboBox',
    components: {},
    model: {
        prop: 'value',
        event: 'change',
    },
    props: {
        value: { required: true },
        hideDetails: { required: false, default: undefined },
        items: { type: Array as PropType<Array<any>>, required: true },
        itemText: { type: String, required: false, default: 'text' },
        itemValue: { type: String, required: false, default: 'value' },
        label: { type: String, required: false },
        inline: { type: Boolean, required: false, default: false },
        clearable: { type: Boolean, required: false, default: false },
        filter: { type: Boolean, required: false, default: false },
        disabled: { type: Boolean, required: false, default: false },
        slim: { type: Boolean, required: false, default: false },
        select: { type: Boolean, required: false, default: false },
        rules: { type: Array as PropType<Array<any>>, required: false, default: () => [] },
    },
    data() {
        return {
            selectedValue: '' as any,
        };
    },
    computed: {
        hasPrepend(): any {
            return this.$slots.default;
        },
        shouldHideDetails() {
            if (this.hideDetails === undefined) {
                if (this.slim || this.filter || this.inline) {
                    return true;
                }
                return false;
            }
            return this.hideDetails;
        },
    },
    watch: {
        value: function(newVal) {
            this.onPropChanged(newVal);
        },
    },
    methods: {
        onSelectValueChanged(value: any) {
            this.$emit('change', value);
        },
        onComboboxValueChanged(filter: { value: any }) {
            this.$emit('change', filter ? filter[this.itemValue] : undefined);
        },
        onPropChanged(newVal) {
            if (this.select) {
                this.selectedValue = newVal;
            } else {
                const item = this.items?.find(i => i[this.itemValue] === newVal);
                this.selectedValue = item ? item[this.itemText] : '';
            }
        },
    },
    async mounted() {
        if (this.value === undefined || this.value === null) {
            this.selectedValue = '';
        } else {
            this.onPropChanged(this.value);
        }
    }
});
