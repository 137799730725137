
import TextBox from '@/components/controls/TextBox.vue';
import { DisputeEvidence } from '@/store/dispute/dispute-models';
import DisputeEvidenceAddress from '../components/DisputeEvidenceAddress.vue';
import DisputeDetailsSection from '../DisputeDetailsSection.vue';
import Vue, { PropType } from 'vue';

export default Vue.extend({
    name: 'DisputeCustomerDetailsSection',
    components: {
        'dispute-evidence-address': DisputeEvidenceAddress,
        'dispute-details-section': DisputeDetailsSection,
        'textbox': TextBox,
    },
    props: {
        evidence: {
            type: Object as PropType<DisputeEvidence>,
            required: true,
        },
        editable: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            customerEmailAddress: '',
            customerName: '',
            billingAddress: '',
        };
    },
    watch: {
        'customerEmailAddress': function (newVal) {
            this.onCustomerEmailAddressChanged(newVal);
        },
        'customerName': function (newVal) {
            this.onCustomerNameChanged(newVal);
        },
        'billingAddress': function (newVal) {
            this.onBillingAddressChanged(newVal);
        },
    },
    async created() {
        this.customerEmailAddress = this.evidence.customerEmailAddress;
        this.customerName = this.evidence.customerName;
        this.billingAddress = this.evidence.billingAddress;
    },
    methods: {
        onCustomerEmailAddressChanged(newValue) {
            this.$emit('evidencePropertyChanged', { propertyName: 'customerEmailAddress', propertyValue: newValue });
        },
        onCustomerNameChanged(newValue) {
            this.$emit('evidencePropertyChanged', { propertyName: 'customerName', propertyValue: newValue });
        },
        onBillingAddressChanged(newValue) {
            this.$emit('evidencePropertyChanged', { propertyName: 'billingAddress', propertyValue: newValue });
        },
    },
});
