
import { mdiClose } from "@mdi/js";
import { userStore } from "@/store/store";
import Vue from 'vue';

export default Vue.extend({
  name: 'RemoveUserDialog',
  props: {
    email: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      submitting: false,
      closeIcon: mdiClose,
      showDialog: false,
    };
  },
  methods: {
    data() {
      return {
        showDialog: false,
      };
    },
    async onRemove() {
      this.submitting = true;
      await userStore.removeUser(this.email);
      this.submitting = false;
      this.close();
    },
    close() {
      if (this.showDialog) {
        this.showDialog = false;
      }
      this.submitting = false;
      this.$emit('user-removed');
    },
  },
});
