
import DisputeDetails from '@/components/disputes/DisputeDetails.vue';
import BasicLayout from '@/components/layouts/BasicLayout.vue';
import { disputesPageName } from '@/router';
import { disputeStore } from '@/store/store';
import Vue from 'vue';

export default Vue.extend({
  name: 'DisputeDetailsPage',
  components: {
    'basic-layout': BasicLayout,
    'dispute-details': DisputeDetails,
  },
  computed: {
    loading(): boolean {
      return disputeStore.loadingDisputeDetails;
    },
  },
  data: () => {
    return {
      disputesLink: { name: disputesPageName },
    };
  },
  async created() {
    // Make sure data are loaded before displaying the page
    if (this.$route.params.disputeId && typeof this.$route.params.disputeId === "string") {
      await disputeStore.loadDisputeDetails(this.$route.params.disputeId);
    }
  },
  beforeRouteLeave(to, from, next) {
    // Relay the call to the child component to determine what to do
    if (this.$refs.details && !(this.$refs.details as any).onBeforeRouteLeave(to, from, next)) {
      return false;
    } else {
      next();
    }
  },
});
