
import Vue, { PropType } from 'vue';
import ActionButton from '@/components/controls/ActionButton.vue';
import { placeStore } from '@/store/store';
import { DefaultValidatorArray, RequiredFieldValidator } from '@/validators';
import CheckBox from '@/components/controls/CheckBox.vue';
import TextBox from '@/components/controls/TextBox.vue';
import ComboBox from '@/components/controls/ComboBox.vue';

interface AddressEntry {
  line1: string;
  line2: string;
  city: string;
  state: string;
  country: string;
  zip: string;
}

const DefaultAddress: AddressEntry = {
  line1: '',
  line2: '',
  city: '',
  state: '',
  zip: '',
  country: '',
};

export default Vue.extend({
  name: 'DisputeEvidenceAddress',
  components: {
    'action-button': ActionButton,
    'checkbox': CheckBox,
    'textbox': TextBox,
    'combobox': ComboBox,
  },
  model: {
    prop: 'model',
    event: 'change',
  },
  props: {
    model: { type: String as PropType<string | undefined>, required: false, default: undefined },
    disabled: { type: Boolean, required: false, default: false },
    copyFromAddressFunc: { type: Function as PropType<(() => string) | undefined>, required: false, default: undefined },
    copyFromAddressType: { type: String as PropType<string | undefined>, required: false, default: undefined },
  },
  data() {
    return {
      editing: false,
      isManualEntryMode: false,
      state: '' as (string | undefined),
      structuredAddress: { ...DefaultAddress },
      requiredField: DefaultValidatorArray,
      valid: true,
    };
  },
  computed: {
    mainTextboxValidators() {
      return this.isManualEntryMode ? this.requiredField : [];
    },
    countries() {
      return placeStore.countries;
    },
    states() {
      return placeStore.states[this.structuredAddress.country] ?? [];
    },
  },
  methods: {
    convertStructuredAddress() {
      let state = this.structuredAddress.state;
      let zip = this.structuredAddress.zip;
      if (this.structuredAddress.state && this.structuredAddress.zip) {
        state = `${this.structuredAddress.state} ${this.structuredAddress.zip}`;
        zip = '';
      }

      if (!this.isManualEntryMode) {
        this.state = [
          this.structuredAddress.line1,
          this.structuredAddress.line2,
          this.structuredAddress.city,
          state,
          zip,
          placeStore.countries.filter(c => c.code === this.structuredAddress.country)[0]?.name,
        ]
        .filter((i) => i && i !== '')
        .join(', ');
      }
    },
    async onCountryChanged(newValue) {
      this.structuredAddress.state = '';
      await placeStore.ensureStates(newValue);
    },
    onStartEditing() {
      this.editing = true;
      this.isManualEntryMode = !!this.state && this.state !== '';
    },
    onSetManualMode() {
      this.convertStructuredAddress();
      this.isManualEntryMode = true;
    },
    onCopyAddress() {
      if (!this.copyFromAddressFunc) {
        return;
      }

      this.state = this.copyFromAddressFunc();
      this.editing = false;
      this.structuredAddress = { ...DefaultAddress };
      this.isManualEntryMode = true;
    },
    validate() {
      this.valid = (this.$refs.addressform as HTMLFormElement).validate();
      return this.valid;
    },
    onSaveAddress() {
      if(!this.validate()) {
        return;
      }

      this.convertStructuredAddress();

      this.editing = false;
      this.structuredAddress = { ...DefaultAddress };
      this.isManualEntryMode = true;
    },
    onReset() {
      this.state = undefined;
      this.structuredAddress = { ...DefaultAddress };
      this.isManualEntryMode = false;
    },
    onStateChanged(newState: any) {
      this.$emit('change', newState);
    },
  },
  watch: {
    'state': function(newVal) {
      this.onStateChanged(newVal);
    }
  },
  async created() {
    this.requiredField = RequiredFieldValidator;
  },
  async mounted() {
    this.state = this.model;
  },
});
