
import configuration from '@/configuration';
import { toLocalDate } from '@/datetime';
import { DisputeDetail, DisputeReconciledStatusEnum, DisputeReasonEnum } from '@/store/dispute/dispute-models';
import DisputeDetailsSection from '../DisputeDetailsSection.vue';
import Vue, { PropType } from 'vue';

export default Vue.extend({
  name: 'DisputeDetailsSummaryHeaderSection',
  components: {
    'dispute-details-section': DisputeDetailsSection
  },
  props: {
    dispute: {
      type: Object as PropType<DisputeDetail>,
      required: true,
    },
    hasSubmittedEvidence: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    headline(): any {
      switch (this.dispute.status) {
        case DisputeReconciledStatusEnum.needsResponse:
          return this.$t('disputeDetailsSummary.headline-needs-response');
        case DisputeReconciledStatusEnum.underReview:
          return this.$t('disputeDetailsSummary.headline-under-review');
        case DisputeReconciledStatusEnum.won:
          return this.$t('disputeDetailsSummary.headline-won');
        case DisputeReconciledStatusEnum.wonPartially:
          return this.$t('disputeDetailsSummary.headline-won-partially');
        case DisputeReconciledStatusEnum.closed:
          return this.$t('disputeDetailsSummary.headline-lost-accepted');
        case DisputeReconciledStatusEnum.lost:
          return this.$t('disputeDetailsSummary.headline-lost');
        default:
          return '';
      }
    },
    subheadline(): any {
      if (this.dispute.status !== DisputeReconciledStatusEnum.needsResponse) {
        return '';
      }

      switch (this.dispute.reason) {
        case DisputeReasonEnum.subscriptionCanceled:
          return this.$t('disputeDetailsSummary.subheadline-subscription-cancelled');
        case DisputeReasonEnum.productUnacceptable:
          return this.$t('disputeDetailsSummary.subheadline-product-unacceptable');
        case DisputeReasonEnum.productNotReceived:
          return this.$t('disputeDetailsSummary.subheadline-product-not-recieved');
        case DisputeReasonEnum.creditNotProcessed:
          return this.$t('disputeDetailsSummary.subheadline-credit-not-processed');
        case DisputeReasonEnum.general:
          return this.$t('disputeDetailsSummary.subheadline-general');
      }

      return '';
    },
    description(): any {
      switch (this.dispute.status) {
        case DisputeReconciledStatusEnum.underReview:
          return this.$t('disputeDetailsSummary.description-under-review', {
            submittedOn: this.submittedOn ? this.$t('disputeDetailsSummary.submitted-on', {
              submissionDate: this.submittedOn
            }) : ''
          });
        case DisputeReconciledStatusEnum.wonPartially:
          return this.$t('disputeDetailsSummary.description-won-partially', {
            amount: this.amount,
            remainingAmount: this.remainingAmount,
            returnedAmount: this.returnedAmount,
            disputeFee: this.disputeFee
          });
        case DisputeReconciledStatusEnum.won:
          return this.$t('disputeDetailsSummary.description-won', { settledOn: this.settledOn, amount: this.amount, disputeFee: this.disputeFee });
        case DisputeReconciledStatusEnum.closed:
          return this.$t('disputeDetailsSummary.description-lost-accepted');
        case DisputeReconciledStatusEnum.lost:
          if (!this.hasSubmittedEvidence) {
            return this.$t('disputeDetailsSummary.description-lost-no-evidence', { amount: this.amount, disputeFee: this.disputeFee });
          }
          return this.$t('disputeDetailsSummary.description-lost-with-evidence', { amount: this.amount, disputeFee: this.disputeFee });
        case DisputeReconciledStatusEnum.needsResponse:
          switch (this.dispute.reason) {
            case DisputeReasonEnum.subscriptionCanceled:
              return this.$t('disputeDetailsSummary.description-needs-response-subscription-cancelled');
            case DisputeReasonEnum.productUnacceptable:
              return this.$t('disputeDetailsSummary.description-needs-response-product-unacceptable');
            case DisputeReasonEnum.productNotReceived:
              return this.$t('disputeDetailsSummary.description-needs-response-product-not-recieved');
            case DisputeReasonEnum.creditNotProcessed:
              return this.$t('disputeDetailsSummary.description-needs-response-credit-not-processed');
            default:
              return '';
          }
      }
      return '';
    },
    descriptionLink(): any {
      switch (this.dispute.status) {
        case DisputeReconciledStatusEnum.needsResponse:
          return {
            text: this.$t("disputeDetailsSummary.link-needs-response"),
            link: configuration.links.respondingToDisputeLink,
          };

        case DisputeReconciledStatusEnum.underReview:
          return {
            text: this.$t("disputeDetailsSummary.link-under-review"),
            link: configuration.links.respondingToDisputeLink,
          };
      }

      return {
        text: this.$t("disputeDetailsSummary.link-other"),
        link: configuration.links.preventingDisputesLink,
      };
    },
    amount(): any {
      return this.$n(this.dispute.disputedAmount, "currency");
    },
    returnedAmount(): any {
      return this.$n(this.dispute.reinstatedAmount ?? 0, "currency");
    },
    remainingAmount(): any {
      return this.$n(this.dispute.disputedAmount - (this.dispute.reinstatedAmount ?? 0), "currency");
    },
    disputeFee(): any {
      return this.$n(this.dispute?.disputeFeeAmount ?? 0, "currency");
    },
    settledOn(): any {
      return toLocalDate(this.dispute?.settledOn);
    },
    submittedOn(): any {
      return toLocalDate(this.dispute?.evidenceSubmittedOn);
    },
  },
});
