
import Vue from 'vue';
export default Vue.extend({
  name: 'ChartTooltip',
  components: {
  },
  props: {
    opacity: {
      type: String,
      default: undefined,
    },
    top: {
      type: String,
      default: undefined,
    },
    left: {
      type: String,
      default: undefined,
    },
    mainLabel: {
      type: String,
      default: undefined,
    },
    mainTitle: {
      type: String,
      default: undefined,
    },
    mainValue: {
      type: String,
      default: undefined,
    },
    auxLabel: {
      type: String,
      default: undefined,
    },
    auxTitle: {
      type: String,
      default: undefined,
    },
    auxValue: {
      type: String,
      default: undefined,
    },
    showTitle: {
      type: Boolean,
      default: false,
    },
  },
});
