
import { DisputeDateFilterEnum } from '@/store/dispute/dispute-models';
import { TranslateResult } from 'vue-i18n';
import Vue from 'vue';
import ComboBox from '../controls/ComboBox.vue';

export default Vue.extend({
  name: 'DisputeCsvDialog',
  components: {
    "combobox": ComboBox,
  },
  model: {
    event: 'change',
    prop: 'value',
  },
  props: {
    value: { required: true },
    inline: { type: Boolean, required: false, default: false },
  },
  data() {
    return {
      selectedDateFilter: DisputeDateFilterEnum.disputeDate,
    };
  },
  computed: {
    disputeDateFilterItems() {
      const values: string[] = [];
      for (const n in DisputeDateFilterEnum) {
        if (typeof n === 'string') {
          values.push(n);
        }
      }
      return values.map(value => {
        return { value: DisputeDateFilterEnum[value], text: this.dateFilterMessage(DisputeDateFilterEnum[value]) }
      });
    },
    dateFilterText(): TranslateResult {
      return this.$t('disputeDateFilter.date-filter');
    },
  },
  watch: {
    'value': function (newVal) {
      this.onDateFilterPropChanged(newVal);
    }
  },
  methods: {
    dateFilterMessage(dateFilter?: DisputeDateFilterEnum): TranslateResult | string {
      switch (dateFilter) {
        case DisputeDateFilterEnum.disputeDate:
          return this.$t('disputeDateFilter.date-filter-dispute-date');
        case DisputeDateFilterEnum.respondByDate:
          return this.$t('disputeDateFilter.date-filter-respond-by-date');
        case DisputeDateFilterEnum.settledDate:
          return this.$t('disputeDateFilter.date-filter-settled-date');
        default:
          return '';
      }
    },
    onDateFilterPropChanged(newValue: DisputeDateFilterEnum) {
      this.selectedDateFilter = newValue;
    },
    onDateFilterChanged(value: DisputeDateFilterEnum) {
      this.$emit('change', value);
    },
  },
  async mounted() {
    this.selectedDateFilter = this.value;
  },
});
