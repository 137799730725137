
import Help from '@/components/help/Help.vue';
import BasicLayout from '@/components/layouts/BasicLayout.vue';
import Vue from 'vue';

export default Vue.extend({
  name:'HelpPage',
  components: {
    'basic-layout': BasicLayout,
    'help': Help,
  }
})
