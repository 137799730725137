
import HeaderNotification from '@/components/common/HeaderNotification.vue';
import { contentStore, merchantStore } from '@/store/store';
import { Notification } from '@/store/content/content-models';
import { dashboardPageName, defaultPageName, mssIntegrationPageName, onboardingPageName } from '@/router'
import Vue from 'vue';


export default Vue.extend({
  name: 'HeaderNotificationList',
  components: {
    'header-notification': HeaderNotification
  },
  props: {
    fullWidth: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    hasNotifications(): boolean {
      return !!contentStore.topBarContentNotifications && contentStore.topBarContentNotifications.length > 0;
    },
    contentNotifications(): Notification[] {
      return contentStore.topBarContentNotifications;
    },
    showMerchantDisabledNotification(): boolean {
      if (merchantStore.isDueDiligenceApproved) {
        return false;
      }

      const routeNamesForNotification = [defaultPageName, dashboardPageName, onboardingPageName, mssIntegrationPageName];
      if (this.$route && routeNamesForNotification.some(n => this.$route.name === n)) {
        return true;
      }

      return false;
    },
  },
});
