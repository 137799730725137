
import { DisputeDetail } from '@/store/dispute/dispute-models';
import DisputeStatus from '../../DisputeStatus.vue';
import Vue, { PropType } from 'vue';

export default Vue.extend({
  name: 'DisputeDetailsSummaryTitle',
  components: {
    'dispute-status': DisputeStatus
  },
  props: {
    dispute: {
      type: Object as PropType<DisputeDetail>,
      required: true,
    },
  },
  computed: {
    disputeId(): any {
      return this.dispute.disputeId ?? this.dispute.id;
    },
    amount(): any {
      return this.$n(this.dispute.disputedAmount, 'currency');
    },
  },
});
