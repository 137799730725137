
import Vue from "vue";
import { merchantStore } from "@/store/store";
import LoadingSpinner from "../common/LoadingSpinner.vue";
import IntegrationSelector from "./integration/IntegrationSelector.vue";
import IntegrationFailure from "./integration/IntegrationFailure.vue";
import ConfirmWebsite from "./integration/update-website-flow/ConfirmWebsite.vue";
import UpdateWebsiteForm from "./integration/update-website-flow/UpdateWebsiteForm.vue";
import WebsiteUpdated from "./integration/update-website-flow/WebsiteUpdated.vue";
import ConfirmCloseDialog from "../common/ConfirmCloseDialog.vue";

export default Vue.extend({
  name: "OnboardingIntegration",
  components: {
    "integration-selector": IntegrationSelector,
    "loading-spinner": LoadingSpinner,
    "integration-failure": IntegrationFailure,
    "confirm-website": ConfirmWebsite,
    "update-website": UpdateWebsiteForm,
    "update-result": WebsiteUpdated,
    "confirm-close-dialog": ConfirmCloseDialog,
  },
  props: {
    visible: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      showCancellationModal: false,
      step: 1,
      isTestingIntegration: false,
      isIntegrationTestSuccessful: false,

      integrationMenuStep: 1,
      successFailureStep: 2,
      confirmWebsiteStep: 3,
      updateWebsiteStep: 4,
      viewUpdatedWebsiteStep: 5,
    };
  },
  async mounted() {
    await merchantStore.getOnboardingMerchant();
    await merchantStore.getDueDiligenceApprovalForOnboardingMerchant();
  },
  computed: {
    showIntegrationMenu() {
      return this.step === this.integrationMenuStep;
    },
    showLoader() {
      return this.step === this.successFailureStep && this.isTestingIntegration;
    },
    showFailure() {
      return (
        this.step === this.successFailureStep &&
        !this.isTestingIntegration &&
        !this.isIntegrationTestSuccessful
      );
    },
    showSuccess() {
      return (
        this.step === this.successFailureStep &&
        !this.isTestingIntegration &&
        this.isIntegrationTestSuccessful
      );
    },
    showUpdateWebsiteFlow() {
      return this.step === this.integrationMenu;
    },
  },
  methods: {
    async onTestIntegration() {
      this.isTestingIntegration = true;
      this.step = this.successFailureStep;
      const response = await merchantStore.checkOnboardingIntegration();

      this.isIntegrationTestSuccessful = !!response;
      this.isTestingIntegration = false;
    },
    confirmWebsite() {
      this.step = this.confirmWebsiteStep;
    },
    updateWebsite() {
      this.step = this.updateWebsiteStep;
    },
    viewUpdatedResult() {
      this.step = this.viewUpdatedWebsiteStep;
    },
    cancel() {
      this.showCancellationModal = true;
    },
    close() {
      this.$emit("close");
      this.reset();
    },
    reset() {
      // Note: I hate this... but it does stop flicker on close.
      setTimeout(() => {
        this.showCancellationModal = false;
        this.step = this.integrationMenuStep;
        this.isTestingIntegration = false;
        this.isIntegrationTestSuccessful = false;
      }, 200);
    },
  },
});
