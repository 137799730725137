
import Vue, { PropType } from "vue";
import { mdiClose } from "@mdi/js";

export default Vue.extend({
  name: "CardLayout",
  components: {},
  props: {
    icon: {
      required: false,
    },
    title: {
      type: String,
      required: false,
    },
    description: {
      type: String,
      required: false,
    },
    multilineDescription: {
      type: Array as PropType<string[]>,
      required: false,
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideClose: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  data() {
    return {
      close: mdiClose,
    };
  },
  methods: {
    emitClose() {
      this.$emit('close');
    },
  }
});
