import configuration from '@/configuration';
import logger from '@/logger';
import { applicationStore } from '@/store/store';
import { NavigationGuard, Route } from 'vue-router';

/**
 * Decorator used to implement direct access on router when necessary
 * and log request/response details to debug.
 */
export default (
  guardName: string, // since these are arrow functions, must specify guardName for debugging clariy
  descriptor: NavigationGuard, // (to: Route, from: Route, next: (param?: any) => void) => any
) =>  {
  // Keep a reference to the original function
  const originalMethod = descriptor;

  // Replace the original function with a wrapper
  descriptor = async function(to: Route, from: Route, next: (param?: any) => void) {
    try {
      // Toggle debug mode
      if (to.query?.debug === 'true') {
        applicationStore.enableDebugMode();
      } else if (to.query?.debug === 'false') {
        applicationStore.disableDebugMode();
      }

      logger.debug(`${guardName} to, from => `, to, from);

      // Check configuration direct access
      if (configuration.featureFlags.directPageAccess) {
        logger.debug(`${guardName} result <= `, 'directPageAccess');
        return next();
      }
      
      // Check route bypass authentication
      if (to.meta?.bypassAuthentication) {
        logger.debug(`${guardName} result <= `, 'bypassAuthentication');
        return next();
      }
      
      // Call the original function
      const result = await originalMethod(to, from, next);
      return result;
    } catch(e) {
      if (!(e instanceof Error)) {
        e = new Error(JSON.stringify(e));
      }
      logger.debug(`${guardName} exception <= `, e);
      return next(e);
    }
  };

  return descriptor;
};