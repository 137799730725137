
import BasicLayout from '@/components/layouts/BasicLayout.vue';
import { noop } from '@/router';
import AuthenticationStore from '@/store/authentication/authentication-store';
import { authenticationStore } from '@/store/store';
import Vue from 'vue';

export default Vue.extend({
  name: 'AuthCallbackPage',
  components: {
    'basic-layout': BasicLayout,
  },
  async mounted() {
    await authenticationStore.handleAuthenticationCallback();

    // Redirect user according to id token properties
    const redirectTarget = await AuthenticationStore.getPostAuthenticationRedirectPath(true);
    await this.$router.replace(redirectTarget).catch(noop);
  },
});
