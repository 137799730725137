
import TextBox from '@/components/controls/TextBox.vue';
import { DisputeEvidence, DisputeProductServiceTypeEnum } from '@/store/dispute/dispute-models';
import DisputeDetailsSection from '../DisputeDetailsSection.vue';
import Vue, { PropType } from 'vue';

export default Vue.extend({
    name: 'DisputeServiceDetailsSection',
    components: {
        'dispute-details-section': DisputeDetailsSection,
        'textbox': TextBox,
    },
    props: {
        evidence: {
            type: Object as PropType<DisputeEvidence>,
            required: true,
        },
        editable: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            serviceDate: '',
        };
    },
    computed: {
        shouldShowSection(): any {
            return this.evidence.productServiceType == DisputeProductServiceTypeEnum.offlineService || (!this.editable && this.evidence.serviceDate);
        },
    },
    watch: {
        'serviceDate': function (newVal) {
            this.onServiceDateChanged(newVal);
        },
    },
    async mounted() {
        this.serviceDate = this.evidence.serviceDate;
    },
    methods: {
        onServiceDateChanged(newValue) {
            this.$emit('evidencePropertyChanged', { propertyName: 'serviceDate', propertyValue: newValue });
        },
    },
});
