
import Logout from '@/components/login/Logout.vue';
import Vue from 'vue';

export default Vue.extend({
  name: 'LogoutPage',
  components: {
    'logout': Logout,
  },
});
