
import { disputesPageName } from '@/router';
import { disputeStore } from '@/store/store';
import StatusItem from './StatusItem.vue';
import Vue from 'vue';
export default Vue.extend({
  name: 'DisputesStatusItem',
  components: {
    'status-item': StatusItem,
  },
  data() {
    return {
      newDisputeCount: 0 as number | null,
      firstLoad: true,
      linkTo: { name: disputesPageName },
    };
  },
  computed: {
    valid(): any {
      return this.newDisputeCount !== null;
    },
    title(): any {
      let suffix = '';
      const newDisputeCount = this.newDisputeCount ?? 0;
      if (newDisputeCount === 1) {
        suffix = '-single';
      } else if (!newDisputeCount) {
        suffix = '-empty';
      }
      return this.$t(`insights.status-item-disputes-title${suffix}`, { count: this.$n(newDisputeCount) });
    },
    linkText(): any {
      return this.newDisputeCount ? this.$t('insights.status-item-disputes-link-review') : this.$t('insights.status-item-disputes-link');
    },
  },
  async mounted() {
    this.newDisputeCount = await disputeStore.getNewDisputeCount() ?? null;
    this.firstLoad = false;
  },
});
