
import Vue from "vue";
import configuration from "@/configuration";
import { merchantStore } from "@/store/store";

import ActionButton from "@/components/controls/ActionButton.vue";

export default Vue.extend({
  name: "IntegrationSuccess",
  components: {
    "action-button": ActionButton,
  },
  computed: {
    companyName() {
      return configuration.company.name;
    },
  },
  mounted() {
    merchantStore.completeOnboarding();
  },
  methods: {
    async close() {
      this.$emit("completed");
    },
  },
});
