
import { applicationStore } from '@/store/store';
import configuration from '@/configuration';
import { Invoice, InvoiceDetail, InvoiceDetailPayment } from '@/store/statement/statement-models';
import moment from 'moment';
import Vue, { PropType } from 'vue';
import { paymentDetailsPageName } from '@/router';

export default Vue.extend({
  name: 'StatementSummary',
  props: {
    details: {
      type: Object as PropType<InvoiceDetail | Invoice>,
      required: true,
    },
    compact: {
      type: Boolean,
      required: false,
    },
  },
  data: () => {
    return {
      paymentDetailsPageName: paymentDetailsPageName
    }
  },
  computed: {
    isMobile(): any {
      return this.$vuetify.breakpoint.xs;
    },
    currentLocale(): string {
      return applicationStore.currentLocale.locale;
    },
    isStatementTooLarge(): boolean {
      const invoiceDetails = this.details as InvoiceDetail;
      return !!(invoiceDetails?.items?.find(item => !!item.hiddenRowCount));
    },
    hasPayment(): boolean {
      const invoiceDetails = this.details as InvoiceDetail;
      return !!(invoiceDetails?.payments?.length);
    },
    payment(): InvoiceDetailPayment {
      const invoiceDetails = this.details as InvoiceDetail;
      return (invoiceDetails?.payments || [])[0];
    }
  },
  methods: {
    displayDate(date: Date | null): string {
      if (date) {
        return moment(date).utc().locale(this.currentLocale).format(configuration.dateFormat);
      }
      return '';
    },
    displayAmount(amount: number): string {
      return this.$n(amount, 'currency');
    },
    getInvoiceNumber(invoice: any) {
      return invoice.invoiceNumber ?? invoice.invoiceId;
    },
  },
});
