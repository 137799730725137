
import { DisputeEvidenceFile } from "@/store/dispute/dispute-models";
import { disputeStore } from "@/store/store";
import ActionButton from "@/components/controls/ActionButton.vue";
import Vue, { PropType } from 'vue';

const FileNameCropLength = 36;
export default Vue.extend({
  name: 'DisputeIssuerEvidenceFile',
  components: {
    "action-button": ActionButton,
  },
  props: {
    disputeId: {
      type: String,
      required: true,
    },
    file: {
      type: Object as PropType<DisputeEvidenceFile>,
      required: true,
    },
  },
  computed: {
    name(): any {
      if (this.file.title) {
        return this.file.title;
      }

      if (this.file.filename) {
        return this.cropName(this.file.filename);
      }

      return this.cropName(this.file.id);
    },
    extension(): any {
      if (!this.file?.type) {
        return null;
      }

      return this.file.type.toLowerCase().replace(/^\./g, "");
    },
  },
  methods: {
    cropName(filename: string) {
      const parts = filename.split('.');
      const name = parts[0];
      const ext = filename.substring(parts[0].length);
      //if file name without extension contains more than 15 characters   
      if (name.length > FileNameCropLength) {
        return `${name.substring(0, FileNameCropLength - 10)}...${name.substring(name.length - 6)}${ext}`;
      }
      return filename;
    },
    async downloadFile() {
      const filename = this.file.filename ?? `${this.file.id}.${this.extension}`;
      await disputeStore.downloadEvidenceFile({
        disputeId: this.disputeId,
        categoryOrFileId: this.file.id,
        filename,
      });
    },
  },
});
