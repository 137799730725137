
import { applicationStore } from "@/store/store";
import Vue from "vue";
import ConfirmationBox from "./ConfirmationBox.vue";

export default Vue.extend({
  name: "ConfirmCloseDialog",
  components: {
    "confirmation-box": ConfirmationBox,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    visible: {
      type: Boolean,
      required: true,
    },
    confirmationTitle: {
      type: String,
      required: false,
      default: "Are you sure you want to close?",
    },
    confirmationMessage: {
      type: String,
      required: false,
      default: "You will lose any unsaved information.",
    },
    okText: {
      type: String,
      required: false,
      default: "Close without saving",
    },
    cancelText: {
      type: String,
      required: false,
      default: "Cancel",
    },
    showUnsavedModal: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      unsavedModalVisible: false,
    };
  },
  computed: {
    displayModal() {
      return this.visible;
    },
    isMobile(): boolean {
      return applicationStore.isMobile;
    },
  },
  methods: {
    onUnsavedClose() {
      this.unsavedModalVisible = true;
    },
    onUnsavedConfirm() {
      this.unsavedModalVisible = false;
      this.$emit("close");
    },
    onUnsavedCancel() {
      this.unsavedModalVisible = false;
    },
  },
});
