
import { contentStore, merchantStore } from "@/store/store";
import { WhatsNewItem } from "@/store/content/content-models";
import Vue from "vue";
import WhatsNew from "./WhatsNew.vue";

export default Vue.extend({
  name: "Updates", // eslint-disable-line vue/multi-word-component-names
  components: {
    "whats-new-item": WhatsNew
  },
  data() {
    return {
      loading: true
    };
  },
  async mounted() {
    contentStore.clearWhatsNewItems();
    await merchantStore.getOnboardingMerchant();
    await contentStore.refreshWhatsNewItems();
    this.loading = false;
  },
  computed: {
    hasItems(): boolean {
      return (
        !!contentStore.whatsNewItems && contentStore.whatsNewItems.length > 0
      );
    },
    whatsNewItems(): WhatsNewItem[] {
      return contentStore.whatsNewItems;
    }
  }
});
