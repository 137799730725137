
import Vue from 'vue';

export default Vue.extend({
    name: 'CheckBox',
    components: { },
    model: {
        prop: 'checked',
        event: 'change'
    },
    props: {
        checked: { required: false, default: undefined },
        label: { required: false, default: undefined },
        hideDetails: { required: false, default: undefined },
        disabled: { type: Boolean, required: false, default: false },
    },
    data() {
        return {
            state: false as (any | undefined),
        };
    },
    watch: {
        checked: function(newVal) {
            this.state = newVal;
        },
    },
    async mounted() {
        this.state = this.checked;
    },
    methods: {
        onStateChanged(newState: any) {
            this.$emit('change', newState);
        }
    },
});
