
import BasicLayout from '@/components/layouts/BasicLayout.vue';
import PaymentDetails from '@/components/payments/PaymentDetails.vue';
import { paymentsPageName } from '@/router';
import { PaymentDetails as PaymentDetailsDto } from '@/store/payment/payment-models';
import { paymentStore } from '@/store/store';
import Vue from 'vue';

export default Vue.extend({
  name: 'PaymentDetailsPage',
  components: {
    'basic-layout': BasicLayout,
    'payment-details': PaymentDetails,
  },
  computed: {
    loading(): boolean {
      return paymentStore.isPaymentLoading;
    },
    payment(): PaymentDetailsDto {
      return paymentStore.payment;
    },
  },
  data: () => {
    return {
      paymentsLink: { name: paymentsPageName },
    };
  },
  async created() {
    // Make sure data are loaded before displaying the page
    const paymentId = this.$route.params.paymentId;
    if (paymentId && typeof paymentId === "string") {
      await paymentStore.getPayment(paymentId);
    }
  },
  beforeRouteLeave(to, from, next) {
    // Relay the call to the child component to determine what to do
    if (this.$refs.details && !(this.$refs.details as any).onBeforeRouteLeave(to, from, next)) {
      return false;
    } else {
      next();
    }
  },
})
