import AggregatedResultChartDate from './AggregatedResultChartDate';

export default class ChartTooltipCallback {
    static defaultCallback(instance, datasets, keySets, context, isWeekly, formatValueFunc, formatLabelFunc?) {
        const model = context?.tooltip;
        instance.chartTooltipOpacity = "" + model?.opacity;
        if (model?.opacity) {
            instance.chartTooltipLeft = model.caretX + 'px';
            instance.chartTooltipTop = model.caretY + 'px';

            const arr: Array<any> = [];
            context.tooltip.dataPoints.forEach(element => {
                arr.push({ raw: element.raw, label: element.label, datasetIndex: element.datasetIndex, dataIndex: element.dataIndex, formattedValue: element.formattedValue });
            });

            instance.chartTooltipMainTitle = datasets[arr[0].datasetIndex].label;
            let label = keySets[arr[0].datasetIndex][arr[0].dataIndex];
            instance.chartTooltipMainLabel = formatLabelFunc ? formatLabelFunc(label) : AggregatedResultChartDate.formatDate(label, instance.currentLocale, isWeekly);
            instance.chartTooltipMainValue = formatValueFunc(arr[0].raw, arr[0]);
            if (arr.length > 1) {
                instance.chartTooltipAuxTitle = datasets[arr[1].datasetIndex].label;
                label = keySets[arr[1].datasetIndex][arr[1].dataIndex];
                instance.chartTooltipAuxLabel = formatLabelFunc ? formatLabelFunc(label) : AggregatedResultChartDate.formatDate(label, instance.currentLocale, isWeekly);
                instance.chartTooltipAuxValue = formatValueFunc(arr[1].raw, arr[1]);
            } else {
                instance.chartTooltipAuxTitle = undefined;
                instance.chartTooltipAuxLabel = undefined;
                instance.chartTooltipAuxValue = undefined;
            }
        }
    }
}