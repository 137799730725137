
import TextBox from '@/components/controls/TextBox.vue';
import Tooltip from '@/components/controls/Tooltip.vue';
import { DisputeEvidence, DisputeProductServiceTypeEnum } from '@/store/dispute/dispute-models';
import DisputeDetailsSection from '../DisputeDetailsSection.vue';
import Vue, { PropType } from 'vue';

export default Vue.extend({
    name: 'DisputeDigitalServiceDetailsSection',
    components: {
        'dispute-details-section': DisputeDetailsSection,
        'tooltip': Tooltip,
        'textbox': TextBox,
    },
    props: {
        evidence: {
            type: Object as PropType<DisputeEvidence>,
            required: true,
        },
        editable: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            accessActivityLog: '',
        };
    },
    computed: {
        shouldShowSection(): any {
            return this.evidence.productServiceType === DisputeProductServiceTypeEnum.digitalProductOrService || (!this.editable && this.evidence.accessActivityLog);
        },
    },
    watch: {
        'accessActivityLog': function (newVal) {
            this.onAccessActivityLogChanged(newVal);
        },
    },
    async mounted() {
        this.accessActivityLog = this.evidence.accessActivityLog;
    },
    methods: {
        onAccessActivityLogChanged(newValue) {
            this.$emit('evidencePropertyChanged', { propertyName: 'accessActivityLog', propertyValue: newValue });
        },
    },
});
