import configuration from '@/configuration';
import LogMethod from '@/decorators/logger-decorator';
import { handleResponseErrors } from '@/errors';
import eventBus from '@/event-bus';
import logger from '@/logger';
import { mssBusinessInfoPageName, routerPush } from '@/router';
import {
  Address,
  BusinessInfoModel,
  Merchant,
  MerchantIdentities,
  MerchantIdentity,
  MssStage,
  OnboardingMerchant,
  PlatformUpdateModel,
  UpdateSfdcOnboardingUserBusinessInfoCommand
} from '@/store/merchant/merchant-models';
import store, { authenticationStore, featureStore } from '@/store/store';
import { AuthorizationError } from '@/store/store-models';
import { orderBy } from '@/utils';
import {
  config,
  Action,
  Module,
  Mutation,
  VuexModule,
} from 'vuex-module-decorators';
import { shopify } from './merchant-settings';

// Set rawError for all Actions in module to true
config.rawError = true;

// QP Merchant API Url
const merchantApiUrl = `${configuration.links.apiDomain}${configuration.links.apiPath}/`;

const DefaultMerchant = {};
const DefaultOnboardingMerchant = {};
const DefaultIsExistingMerchant = false;
const DefaultUtm = '';
const DefaultMerchantIdentities = [];
const DefaultIsIntegrationCompletedLocally = false;

/**
 * The merchant store is responsible for managing merchant data and business logic
 */
@Module({
  name: 'merchant',
  namespaced: true,
  store,
})
export default class MerchantStore extends VuexModule {
  merchant: Merchant = DefaultMerchant;
  merchantIdentities: MerchantIdentity[] = DefaultMerchantIdentities;
  onboardingMerchant: OnboardingMerchant = DefaultOnboardingMerchant;
  isExistingMerchant: boolean = DefaultIsExistingMerchant;
  utm: string = DefaultUtm;
  isIntegrationCompletedLocally = DefaultIsIntegrationCompletedLocally;
  isDiligenceApproved?: boolean = undefined;
  mssMerchant?: boolean = undefined;
  
  get merchantName(): string {
    return this.merchant.tradingName || this.onboardingMerchant.businessInfo?.businessName || '';
  }
  get merchantId(): string {
    return this.merchant.id || '';
  }
  get merchantPostalAddress(): Address  {
    return this.merchant.postalAddress || {line1:'', line2:'', city:'', state:'', postCode:''};
  }
  get hasDueDiligenceApprovalValue(): boolean {
    return this.isDiligenceApproved !== undefined;
  }
  get isDueDiligenceApproved(): boolean {
    return this.isDiligenceApproved !== undefined
      ? this.isDiligenceApproved
      : true;
  }
  get isOnboardingMerchant(): boolean {
    return !(!!this.merchant?.id && !this.onboardingMerchant?.id);
  }
  get isOnboardedMerchant(): boolean {
    return (!!this.merchant?.id && !this.onboardingMerchant?.id)
      || this.isOnboardingIntegrationCompleted;
  }
  get isMssMerchant(): boolean {
    return this.merchant.mssMerchant || false;
  }

  get onboardingMerchantId(): string {
    return this.onboardingMerchant.id || '';
  }
  get clientId(): string {
    return this.merchant?.apiKey?.clientId || '';
  }
  get clientSecret(): string {
    return this.merchant?.apiKey?.clientSecret || '';
  }
  get platform(): string {
    return this.merchant?.platform || this.onboardingMerchant?.businessInfo?.platform || '';
  }
  get customPlatform(): string {
    return this.merchant?.customPlatform || this.onboardingMerchant?.businessInfo?.customPlatform || '';
  }
  get industry(): string {
    return this.onboardingMerchant?.businessInfo?.industry || '';
  }
  get merchantTier(): string {
    return this.merchant?.merchantTier || this.onboardingMerchant?.businessInfo?.merchantTier || '';
  }
  get isShopify(): boolean {
    return this.merchant.platform === shopify.systemName;
  }
  get merchantContactName(): string {
    const firstName = this.onboardingMerchant?.firstName;
    const lastName = this.onboardingMerchant?.lastName;
    let businessInfoContactName: string|null = null;

    if (firstName && lastName) {
      businessInfoContactName = (`${this.onboardingMerchant?.firstName} ${this.onboardingMerchant?.lastName}`).trim();
    }

    return this.merchant?.ownerName || this.merchant?.ownerEmail || businessInfoContactName || '';
  }
  get merchantEmail(): string {
    return this.merchant?.ownerEmail || this.onboardingMerchant?.email || '';
  }

  get merchantBusinessPhone(): string {
   return this.onboardingMerchant.businessInfo?.businessPhoneNumber || '';
  }

  get hasMerchantLoaded(): boolean {
    return !!this.merchant.id || !!this.onboardingMerchant.id;
  }

  get hasMerchantIdentitiesLoaded(): boolean {
    return !!this.merchantIdentities && !!this.merchantIdentities.length;
  }

  get availableMerchantIdentities(): MerchantIdentity[] {
    return this.merchantIdentities || [];
  }
  get isOnboardingBusinessInfoSubmitted(): boolean {
    return this.merchant.isOnboardingBusinessInfoSubmitted ?? false;
  }
  get isStripeAccountCompleted(): boolean {
    return !!this.onboardingMerchant?.stripeIntegrationBypassed
      || !!this.onboardingMerchant?.stripeAccountCompleted;
  }
  get isOnboardingIntegrationCompleted(): boolean {
    return (this.onboardingMerchant?.integrationCompleted ?? false)
      || (featureStore.skipOnboardingIntegrationForMerchant && !!this.onboardingMerchant.stripeAccountCompleted);
  }

  /**
   * Get Merchant by merchantId from Merchants SQL Table
   * @param throwAllErrors default *false* for checking existence (return boolean), pass *true* to throw errors
   */
  @Action
  async getMerchant(throwAllErrors = false): Promise<boolean> {
    const { accessToken } = authenticationStore;
    const merchantId = authenticationStore.merchantId;
    const url = `${merchantApiUrl}${merchantId}`;
    try {
      const response = await fetch(url, {
        headers: {
          authorization: `Bearer ${accessToken}`,
          'qp-territory': authenticationStore.currentTerritory,
        },
      });
      logger.debug("Table response", response);
      if (response.status > 226) {
          await handleResponseErrors(response, 'Merchant ID', false, throwAllErrors);
          return false;
      } else {
        const body = await response.json();
        // Verify there is a merchant in the body (and not just {})
        if (!body.merchant || !Object.keys(body.merchant).length) {
          logger.error(`GET merchant empty body error\n${response.toString()}`);
          return false;
        } else {
          this.setMerchant(body.merchant);
          this.setDueDiligenceApproval(body.merchant.isDiligenceApproved);
          this.setIsExistingMerchant();
          return true;
        }
      }
    } catch (e) {
      // If throwing all errors or find 401, throw
      if (throwAllErrors || e instanceof AuthorizationError) {
        throw e;
      }
      // Else log error and return false
      logger.error(`Undefined error fetching merchant ${merchantId}`, e);
      return false;
    }
  }
  
  /**
   * Get manual approval status from Merchants SQL Table, even is merchant hasn't fully onboarded yet
   */
   @Action
   async getDueDiligenceApprovalForOnboardingMerchant(throwAllErrors = false): Promise<boolean> {
     const { accessToken } = authenticationStore;
     const merchantId = authenticationStore.merchantId;
     const url = `${merchantApiUrl}${merchantId}`;
     try {
       const response = await fetch(url, {
         headers: {
           authorization: `Bearer ${accessToken}`,
           'qp-territory': authenticationStore.currentTerritory,
         },
       });
       logger.debug("Table response", response);
       if (response.status > 226) {
           await handleResponseErrors(response, 'Merchant ID', false, throwAllErrors);
           return false;
       } else {
         const body = await response.json();
         // Verify there is a merchant in the body (and not just {})
         if (!body.merchant || !Object.keys(body.merchant).length) {
           logger.error(`GET merchant empty body error\n${response.toString()}`);
           return false;
         } else {
           this.setDueDiligenceApproval(body.merchant.isDiligenceApproved);
           return true;
         }
       }
     } catch (e) {
       // If throwing all errors or find 401, throw
       if (throwAllErrors || e instanceof AuthorizationError) {
         throw e;
       }
       // Else log error and return false
       logger.error(`Undefined error fetching merchant ${merchantId}`, e);
       return false;
     }
   }

  @Action
  async getMerchantIdentitiesForTerritory(territory: string, throwAllErrors = false): Promise<MerchantIdentity[]> {
    const { accessToken } = authenticationStore;
    const url = `${merchantApiUrl}identities`;
    try {
      const response = await fetch(url, {
        headers: {
          authorization: `Bearer ${accessToken}`,
          'qp-territory': territory,
        },
      });
      logger.debug("Table response", response);
      if (response.status > 226) {
          await handleResponseErrors(response, 'Merchant ID', false, throwAllErrors);
          return [];
      } else {
        const body = await response.json() as MerchantIdentities;
        // Verify there is a merchant in the body (and not just {})
        if (!body.merchants || !body.merchants.length) {
          logger.debug(`GET merchant identities empty body error\n${response.toString()}`);
          return [];
        } else {
          return body.merchants || [];
        }
      }
    } catch (e) {
      // If throwing all errors or find 401, throw
      if (throwAllErrors || e instanceof AuthorizationError) {
        throw e;
      }
      // Else log error and return false
      logger.error(`Undefined error fetching merchant identities`, e);

      return [];
    }
  }

  /**
   * Get the identities available to a merchant (for the current territory)
   */
  @Action
  async getMerchantIdentities(throwAllErrors = false): Promise<void> {
    const territories = authenticationStore.identitiesByTerritory || [{ territory: authenticationStore.currentTerritory }];

    const identities = [] as MerchantIdentity[];
    for (const territory of territories) {
      const territoryIdentities = await this.getMerchantIdentitiesForTerritory(territory.territory, throwAllErrors);
      territoryIdentities.forEach(id => identities.push({ ...id, territory: territory.territory }));
    }

    // now that we have all of the identities order by trading name
    const orderedIdentities = orderBy(identities, id => id.tradingName);
    this.setMerchantIdentities(orderedIdentities);
  }
  
  /**
   * Get Merchant Client ID and API key/Client Secretby merchantId from Merchants SQL Table
   */
  @Action
  async getKeys(errorModal = false): Promise<void> {
    const { accessToken } = authenticationStore;
    const merchantId = authenticationStore.merchantId;
    const url = `${merchantApiUrl}${merchantId}/api-key`;
    const response = await fetch(url, {
      headers: {
        authorization: `Bearer ${accessToken}`,
        'qp-territory': authenticationStore.currentTerritory,
      },
    });
    logger.debug("Keys response", response);
    if (response.status > 226) {
      // Throw errors if non 200
      await handleResponseErrors(response, 'Merchant ID', errorModal, !errorModal);
    } else {
      const apiKey = await response.json();
      this.setMerchant({ ...this.merchant, ...apiKey });
    }
  }
  
  /**
   * Get Merchant by merchantId from Onboarding Merchants Cosmos Collection
   * @param throwAllErrors default *false* for checking existence (return boolean), pass *true* to throw errors
   */
  @Action
  async getOnboardingMerchant(): Promise<OnboardingMerchant | null> {
    const { accessToken } = authenticationStore;
    const merchantId = authenticationStore.merchantId;
    const url = `${merchantApiUrl}${merchantId}/onboarding-users`;
    try {
      const response = await fetch(url, {
        headers: {
          authorization: `Bearer ${accessToken}`,
          'qp-territory': authenticationStore.currentTerritory,
        },
      });
      logger.debug("Collection response", response);
      if (response.status > 226) {
        await handleResponseErrors(response, 'Merchant ID', false);
        this.setOnboardingMerchant(DefaultOnboardingMerchant);
        return null;
      } else {
        const body = await response.json();
        // Verify there is a merchant stage in the body (and not just {})
        if (!body.stage) {
          logger.error(`GET onboarding merchant missing stage error\n${response.toString()}`);
          return null;
        } else {
          this.setOnboardingMerchant(body);
          return body as OnboardingMerchant;
        }
      }
    } catch (e) {
      // If throwing all errors or find 401, throw
      if (e instanceof AuthorizationError) {
        throw e;
      }
      // Else log error and return false
      logger.error(`Undefined error fetching onboarding merchant ${merchantId}`, e);
      return null;
    }
  }
  
  @Action
  handleEmailVerification(success: boolean, message: string) {
    if (success) {
      // If successful, advance stage
      const updatedOnboardingMerchant = { ...this.onboardingMerchant, stage: MssStage.emailVerified, emailVerified: true };
      this.setOnboardingMerchant(updatedOnboardingMerchant);
      eventBus.publishEmailVerificationEvent(true, authenticationStore.merchantId);
      routerPush({ name: mssBusinessInfoPageName });
    } else {
      eventBus.publishEmailVerificationEvent(false, authenticationStore.merchantId, message);
      throw new Error(`Error verifying email: ${message}`);
    }
  }

  /**
   * Submit Onboarding Merchant Business Info for SFDC onboarded users
   * @param businessInfoCommand BusinessInfoModel
   */
  @Action
  async submitSfdcOnboardingBusinessInfo(businessInfoCommand: UpdateSfdcOnboardingUserBusinessInfoCommand) {
    const { accessToken } = authenticationStore;
    const merchantId = authenticationStore.merchantId;
    const url = `${merchantApiUrl}${merchantId}/onboarding-users/sfdc-business-info`;
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          authorization: `Bearer ${accessToken}`,
          "content-type": "application/json-patch+json",
          'qp-territory': authenticationStore.currentTerritory,
        },
        body: JSON.stringify({ ...businessInfoCommand, merchantId: merchantId }),
      });
      if (response.status > 226) {
        // Throw error despite status
        await handleResponseErrors(response, 'Merchant ID', false, true);
      } else {
        const body = await response.json();
        // Set business info in state
        const { msaSigned } = body;
        const stage = msaSigned ? MssStage.msaSigned : MssStage.businessInfoAdded;
        const updatedOnboardingMerchant: OnboardingMerchant = {
          ...this.onboardingMerchant,
          stage: stage,
          msaSigned: !!msaSigned,
          businessInfo: {
            ...this.onboardingMerchant.businessInfo,
            businessAddress: businessInfoCommand.businessAddress,
            ein: businessInfoCommand.ein }
        };
        this.setOnboardingMerchant(updatedOnboardingMerchant);
        eventBus.publishMssStageCompletedEvent(stage);
      }
    } catch (e) {
      logger.error(`Error submitting SFDC onboarding merchant business info: `, e);
      throw e;
    }
  }

  /**
   * Submit Onboarding Merchant Business Info
   * @param businessInfoCommand BusinessInfoModel
   */
  @Action
  async submitOnboardingBusinessInfo(businessInfoCommand: BusinessInfoModel) {
    const { accessToken } = authenticationStore;
    const merchantId = authenticationStore.merchantId;
    const url = `${merchantApiUrl}${merchantId}/onboarding-users/business-info`;
    try {
      const response = await fetch(url, {
        method: "PUT",
        headers: {
          authorization: `Bearer ${accessToken}`,
          "content-type": "application/json-patch+json",
          'qp-territory': authenticationStore.currentTerritory,
        },
        body: JSON.stringify(businessInfoCommand),
      });
      if (response.status > 226) {
        // Throw error despite status
        await handleResponseErrors(response, 'Merchant ID', false, true);
      } else {
        const body = await response.json();
        // Set business info in state
        const { website, msaSigned } = body;
        const stage = msaSigned ? MssStage.msaSigned : MssStage.businessInfoAdded;
        const updatedOnboardingMerchant = { ...this.onboardingMerchant, businessInfo: businessInfoCommand, website, msaSigned, stage };
        this.setOnboardingMerchant(updatedOnboardingMerchant);
        eventBus.publishMssStageCompletedEvent(stage);
      }
    } catch (e) {
      logger.error(`Error submitting onboarding merchant business info: `, e);
      throw e;
    }
  }
  
  /**
   * Update website URL
   */
  @Action
  async updateWebsiteUrl(website: string) {
    const { accessToken } = authenticationStore;
    const merchantId = authenticationStore.merchantId;
    const url = `${merchantApiUrl}${merchantId}/onboarding-users/update-website`;
    try {
      const response = await fetch(url, {
        method: "PUT",
        headers: {
          authorization: `Bearer ${accessToken}`,
          "content-type": "application/json-patch+json",
          'qp-territory': authenticationStore.currentTerritory,
        },
        body: JSON.stringify({ website }),
      });
      if (response.status > 226) {
        // Throw error despite status
        await handleResponseErrors(response, 'Merchant ID', false, true);
      } else {
        const body = await response.json();
        // Update website in state
        const { website } = body;
        const updatedOnboardingMerchant = { ...this.onboardingMerchant, website };
        this.setOnboardingMerchant(updatedOnboardingMerchant);
        eventBus.publishWebsiteUpdatedEvent();
      }
    } catch (e) {
      logger.error(`Error updating merchant website URL: `, e);
      throw e;
    }
  }

  /**
   * Update the merchant's selected platform
   */
  @Action
  async updatePlatform(command: PlatformUpdateModel) {
    const { accessToken } = authenticationStore;
    const merchantId = authenticationStore.merchantId;
    const url = `${merchantApiUrl}${merchantId}/platform`;

    try {
      const response = await fetch(url, {
        method: "PUT",
        headers: {
          authorization: `Bearer ${accessToken}`,
          "content-type": "application/json-patch+json",
          'qp-territory': authenticationStore.currentTerritory,
        },
        body: JSON.stringify(command),
      });
      if (response.status > 226) {
        // Throw error despite status
        await handleResponseErrors(response, 'Merchant ID', false, true);
      } else {
        this.setMerchantPlatform(command.platform, command.customPlatform);
      }
    } catch (e) {
      logger.error(`Error updating merchant platform: `, e);
      throw e;
    }
  }

  /**
   * Check Onboarding Merchant Integration
   */
  @Action
  async checkOnboardingIntegration(): Promise<boolean> {
    const { accessToken } = authenticationStore;
    const merchantId = authenticationStore.merchantId;
    const url = `${merchantApiUrl}${merchantId}/onboarding-users`;

    const response = await fetch(url, {
      headers: {
        authorization: `Bearer ${accessToken}`,
        'qp-territory': authenticationStore.currentTerritory,
      },
    });
    logger.debug("Collection response", response);
    if (response.status > 226) {
      // There should be a Merchant in the collection, throw error if not
      await handleResponseErrors(response, 'Merchant ID', false, true);
      return false;
    } else {
      const body = await response.json();
      // Save updated onboarding merchant
      const updatedOnboardingMerchant = { ...this.onboardingMerchant, ...body };
      // Verify both integration flags are true before 
      if (body.widgetVerified && body.integrationVerified) {
        // Slightly preemptively advance stage in FE to avoid calling BE for simple change
        updatedOnboardingMerchant.stage = MssStage.integrationCompleted;
        updatedOnboardingMerchant.integrationCompleted = true;
        this.setOnboardingMerchant(updatedOnboardingMerchant);
        eventBus.publishIntegrationTestResultsEvent(merchantId, true, body.widgetVerified, body.integrationVerified);
        return true;
      } else {
        // Update merchant regardless to determine integration test results
        this.setOnboardingMerchant(updatedOnboardingMerchant);
        eventBus.publishIntegrationTestResultsEvent(merchantId, false, body.widgetVerified, body.integrationVerified);
        return false;
      }
    }
  }

  /**
   * Bypass widget integration for merchant
   */
   @Action
   async bypassWidgetIntegration() {
     const { accessToken } = authenticationStore;
     const merchantId = authenticationStore.merchantId;
     const url = `${merchantApiUrl}${merchantId}/skipwidgetintegration`;
 
     try {
       const response = await fetch(url, {
        method: "PUT",
         headers: {
          "content-type": "application/json",
           authorization: `Bearer ${accessToken}`,
           'qp-territory': authenticationStore.currentTerritory,
         }
       });
       logger.debug("Collection response", response);
       if (response.status > 226) {
         // There should be a Merchant in the collection, throw error if not
         await handleResponseErrors(response, 'Merchant ID', false, true);
         return false;
       }
     } catch (e) {
       logger.error(`Error bypassing widget integration: `, e);
       throw e;
     }
   }

  /**
   * Bypass integration tests for merchant
   */
  @Action
  async bypassIntegrationTests() {
    const { accessToken } = authenticationStore;
    const merchantId = authenticationStore.merchantId;
    const url = `${merchantApiUrl}${merchantId}/skipgatewayintegration`;

    try {
      const response = await fetch(url, {
        method: "PUT",
        headers: {
          "content-type": "application/json",
          authorization: `Bearer ${accessToken}`,
          'qp-territory': authenticationStore.currentTerritory,
        },
      });
      logger.debug("Collection response", response);
      if (response.status > 226) {
        // There should be a Merchant in the collection, throw error if not
        await handleResponseErrors(response, 'Merchant ID', false, true);
        return false;
      } else {
        // Save updated onboarding merchant
        const updatedOnboardingMerchant = { ...this.onboardingMerchant };
        updatedOnboardingMerchant.stage = MssStage.integrationCompleted;
        updatedOnboardingMerchant.integrationCompleted = true;
        this.setIsIntegrationCompletedLocally();

        this.setOnboardingMerchant(updatedOnboardingMerchant);
      }
    } catch (e) {
      logger.error(`Error bypassing gateway integration: `, e);
      throw e;
    }
  }


  /**
   * Trigger BE official completion of onboarding
   */
  @Action
  async completeOnboarding(): Promise<boolean> {
    const { accessToken } = authenticationStore;
    const merchantId = authenticationStore.merchantId;
    const url = `${merchantApiUrl}${merchantId}/onboarding-users/integration-completed`;
    const response = await fetch(url, {
      headers: {
        authorization: `Bearer ${accessToken}`,
        'qp-territory': authenticationStore.currentTerritory,
      },
    });
    if (response.status > 226) {
      await handleResponseErrors(response, 'Merchant ID', false, true);
      return false;
    } else {
      this.setIsIntegrationCompletedLocally();
      eventBus.publishMssStageCompletedEvent(MssStage.integrationCompleted);
      return true;
    }
  }

  /**
   * Intermediary step so that newly successfully integrated merchants can see completed screen before moving to dashboard
   */
  @Action
  transitionFromOnboarding() {
    this.setIsExistingMerchant();
  }

  // Not private because Stripe store requires access
  @Mutation
  setMerchant(merchant: Merchant) {
    this.merchant = merchant;
  }

  @Mutation
  setDueDiligenceApproval(dueDiligenceApproval?: boolean) {
    this.isDiligenceApproved = dueDiligenceApproval;
  }

  // Not private because Stripe store requires access
  @Mutation
  setOnboardingMerchant(onboardingMerchant: OnboardingMerchant) {
    this.onboardingMerchant = onboardingMerchant;
  }
  
  @Mutation
  setMerchantPlatform(platform: string, customPlatform?: string) {
    this.merchant = {
      ...this.merchant,
      platform,
      customPlatform
    };
  }
  
  // Not private because router requires access
  /**
   * Store a utm query string value from sign-up url to use in onboarding merchant creation
   * @param queryValue 
   */
  @Mutation
  setUtm(queryValue: string) {
    this.utm = queryValue;
  }
  
  // Not private because router requires access
  /**
   * Use a platform query string value from sign-up url to use in onboarding merchant creation
   * @param queryValue 
   */
  @Mutation
  setPlatformOnSignUp(queryValue: string) {
    const newInfo = Object.assign({}, this.onboardingMerchant, { businessInfo: { platform: queryValue } });
    this.onboardingMerchant = newInfo;
  }

  /**
   * Used to set whether an integration test has completed successfully, locally.
   * 
   * This allows us to patch a small eventually-consistent bug where users clicking quickly through
   * integration test and "Continue to Merchant Portal" end up getting looped because the
   * onboardingMerchant's `integrationCompleted` property hasn't been persistent quickly enough
   * via onboarding merchant projections.
   */
  @Mutation
  setIsIntegrationCompletedLocally() {
    this.isIntegrationCompletedLocally = true;
  }

  @Mutation
  private setIsExistingMerchant() {
    this.isExistingMerchant = true;
  }

  @Mutation
  private setMerchantIdentities(identities: MerchantIdentity[]) {
    this.merchantIdentities = identities;
  }

  @Mutation
  @LogMethod
  reset() {
    this.isExistingMerchant = DefaultIsExistingMerchant;
    this.merchant = DefaultMerchant;
    this.merchantIdentities = DefaultMerchantIdentities;
    this.onboardingMerchant = DefaultOnboardingMerchant;
    this.utm = DefaultUtm;
  }
}
