import moment from "moment-timezone";
import configuration from "./configuration";
import { applicationStore, authenticationStore } from "./store/store";
import { getItemForMerchantUser, setItemForMerchantUser } from "./storage";

const TIMEZONE_PREFERENCE_KEY = "timezone";

export const getBrowserTimeZone = (): string => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

export const getOffsetForTimezone = (timezoneName: string): number | null => {
  const zone = moment.tz.zone(timezoneName);
  if (!zone) {
    return null;
  }

  const offset = zone.utcOffset(Date.now()); // offset in minutes
  if (offset === 0) {
    return offset;
  }

  const offsetHrs = offset / 60;

  // Offset is stored in the zone as the number of minutes to add to become UTC
  // This means we need to reverse it to show the time difference from UTC.
  return offsetHrs * -1;
};

export const getTimezoneForMerchantUser = (): string => {
  const auth0User = authenticationStore?.userInfo?.sub || "";
  const merchantId = authenticationStore?.merchantId;

  if (!auth0User || !merchantId) {
    return 'Etc/UTC'; // default to UTC
  }

  const supportedTimezones = applicationStore.supportedTimezones;
  const persistedTimezoneName = getItemForMerchantUser(
    auth0User,
    merchantId,
    TIMEZONE_PREFERENCE_KEY
  );
  const matchingPersisted = supportedTimezones.find(
    tz => tz.ianaTimezoneName === persistedTimezoneName
  );
  if (matchingPersisted) {
    return matchingPersisted.ianaTimezoneName;
  }

  const browserTimezoneName = getBrowserTimeZone();
  const matchingBrowser = supportedTimezones.find(
    tz => tz.ianaTimezoneName === browserTimezoneName
  );
  if (matchingBrowser) {
    return matchingBrowser.ianaTimezoneName;
  }

  return supportedTimezones[0].ianaTimezoneName;
};

export const setTimezoneForMerchantUser = (timezoneName: string): void => {
  const auth0User = authenticationStore.userInfo.sub || "";
  const merchantId = authenticationStore.merchantId;

  setItemForMerchantUser(
    auth0User,
    merchantId,
    TIMEZONE_PREFERENCE_KEY,
    timezoneName
  );
};

export const toLocalDate = (date?: Date) => {
  if (!date) {
    return "";
  }

  const tz = getTimezoneForMerchantUser();

  return moment(date)
    .tz(tz)
    .locale(applicationStore.currentLocale.locale)
    .format(configuration.dateFormat);
};

export const toLocalDateTime = (date?: Date): string => {
  if (!date) {
    return "";
  }

  const tz = getTimezoneForMerchantUser();

  return moment(date)
    .tz(tz)
    .locale(applicationStore.currentLocale.locale)
    .format(configuration.dateTimeFormat);
};

export const toRelativeDateTime = (date?: Date) => {
  if (!date) {
    return "";
  }

  const tz = getTimezoneForMerchantUser();

  return moment(date)
    .tz(tz)
    .locale(applicationStore.currentLocale.locale)
    .fromNow();
};
