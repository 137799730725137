
import Tooltip from '../Tooltip.vue';
import Vue from 'vue';

export default Vue.extend({
  name: 'ScalarChart',
  components: {
    'tooltip': Tooltip,
  },
  props: {
    title: { type: String, required: true },
    value: { type: String, required: true },
    comparisonPercentage: { type: Number, required: false },
    comparisonValue: { type: String, required: false },
    comparisonPeriod: { type: String, required: false },
    loading: { type: Boolean, required: false, default: true },
    showProgress: { type: Boolean, required: false, default: true },
    selectedReportedPeriod: { type: String, required: false },
    tooltipText: { type: String, required: false },
    useLargeFont: { type: Boolean, required: false },
  },
  data() {
    return {
      loadCount: 0,
    };
  },
  computed: {
    hasMenu(): any {
      return this.$slots.menu;
    },
    firstLoad(): any {
      return this.loadCount == 0 && this.loading;
    },
  },
  watch: {
    loading: function(newVal) {
      if (!newVal) {
        this.loadCount++;
      }
    },
  },
  methods: {
    formatPercentage(percentage: number): string {
      return `${percentage >= 0 ? '+' : ''}${percentage.toFixed(2)}%`;
    },
  },
});
