
import BasicLayout from '@/components/layouts/BasicLayout.vue';
import AllSettings from '@/components/settings/AllSettings.vue';
import Vue from 'vue';
export default Vue.extend({
  name: 'SettingsPage',
  components: {
    'basic-layout': BasicLayout,
    'all-settings': AllSettings,
  },
});
