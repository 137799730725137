
import Vue from "vue";
import { merchantStore } from "@/store/store";
import CardLayout from "@/components/layouts/CardLayout.vue";
import ActionButton from "@/components/controls/ActionButton.vue";

import CheckIcon from "@/assets/images/icons/resultCheckIcon.svg?inline";
import XIcon from "@/assets/images/icons/xIcon.svg?inline";
import configuration from "@/configuration";

export default Vue.extend({
  name: "IntegrationFailure",
  components: {
    "x-icon": XIcon,
    "check-icon": CheckIcon,
    "card-layout": CardLayout,
    "action-button": ActionButton,
  },
  computed: {
    companyName() {
      return configuration.company.name;
    },
    websiteUrl(): string {
      return merchantStore.onboardingMerchant?.businessInfo?.website || "";
    },
    widgetVerified(): boolean {
      return merchantStore.onboardingMerchant?.widgetVerified || false;
    },
    gatewayVerified(): boolean {
      return merchantStore.onboardingMerchant?.integrationVerified || false;
    },
  },
  methods: {
    startConfirmWebsiteFlow() {
      this.$emit("confirm-website");
    },
    back() {
      this.$emit("back");
    },
    cancel() {
      this.$emit('cancel');
    }
  },
});
