import configuration from "@/configuration";
import { FeatureFlagConfig } from "./feature-models";
import { authenticationStore } from "../store";

export const featureFlagConfiguration: FeatureFlagConfig[] = [
  {
    name: 'shouldDisplayTaxInfo',
    key: configuration.statements.optimizely.displayTaxInfoKey,
    off: () => configuration.statements.taxInfoEnabledTerritories.includes(authenticationStore.currentTerritory),
  },
  {
    name: 'shouldAllowInsightPagesAccess',
    key: configuration.insights.optimizely.showInsightPagesKey,
    off: () => configuration.featureFlags.insights,
  },
  {
    name: 'shouldAllowDisputePagesAccess',
    key: configuration.disputes.optimizely.showDisputePagesKey,
    on: () => configuration.featureFlags.disputes,
  },
  {
    name: 'isNewMerchantComplianceFlow',
    key: configuration.onboardingCompliance.optimizely.isNewMerchantComplianceFlowKey,
    off: () => configuration.featureFlags.isNewMerchantComplianceFlow,
  },
  {
    name: 'skipOnboardingIntegrationForMerchant',
    key: configuration.onboardingCompliance.optimizely.skipOnboardingIntegrationForMerchantKey,
    off: () => configuration.featureFlags.skipOnboardingIntegrationForMerchant,
  },
  {
    name: 'showComplianceIntegrationTypeDropdown',
    key: configuration.onboardingCompliance.optimizely.showComplianceIntegrationTypeDropdownKey,
    off: () => configuration.featureFlags.showComplianceIntegrationTypeDropdown,
  },
  {
    name: 'isIntercomEnabled',
    key: configuration.intercom.optimizely.key,
    off: () => configuration.featureFlags.intercom,
  }
]
