
import DoubleDatePicker from '@/components/controls/DoubleDatePicker.vue';
import ReportingPeriodFilter from '@/components/dashboard/insights/ReportingPeriodFilter.vue';
import InsightsDashboard, { DefaultInsightsDashboardSearchOptions, InsightsDashboardSearchOptions } from '@/components/dashboard/InsightsDashboard.vue';
import SimpleDashboard from '@/components/dashboard/SimpleDashboard.vue';
import BasicLayout from '@/components/layouts/BasicLayout.vue';
import configuration from '@/configuration';
import { InsightsComparisonOffsetEnum, InsightsReportingPeriodEnum } from '@/store/insights/insights-models';
import { applicationStore, insightsStore, userStore } from '@/store/store';
import moment from 'moment';
import Vue from 'vue';
export default Vue.extend({
  name: 'DashboardPage',
  components: {
    'basic-layout': BasicLayout,
    'insights-dashboard': InsightsDashboard,
    'simple-dashboard': SimpleDashboard,
    'double-date-picker': DoubleDatePicker,
    'reporting-period-filter': ReportingPeriodFilter,
  },
  data() {
    return {
      reportingPeriod: InsightsReportingPeriodEnum.thisWeek,
      comparisonOffset: InsightsComparisonOffsetEnum.lastWeek,
      lastUpdatedDate: null as Date | null,
      endDate: moment.utc().startOf('day').toISOString(),
      startDate: moment.utc().startOf('day').subtract(1, 'week').toISOString(),
      options: DefaultInsightsDashboardSearchOptions as InsightsDashboardSearchOptions,
    };
  },
  computed: {
    currentLocale(): string {
      return applicationStore.currentLocale.locale;
    },
    canAccessInsights(): any {
      return userStore.canAccessInsights;
    },
    showCustomDateRange(): any {
      return this.reportingPeriod === InsightsReportingPeriodEnum.custom;
    },
  },
  watch: {
    'lastUpdatedDate': function(newVal, oldVal) {
      this.onLastUpdatedDateChanged(newVal, oldVal);
    },
    'reportingPeriod': function(newVal, oldVal) {
      this.onReportingPeriodChanged(newVal, oldVal);
    },
    'canAccessInsights': function() {
      this.onInsightsAccessChanged();
    },
  },
  async mounted() {
    if (this.canAccessInsights) {
      this.lastUpdatedDate = await insightsStore.getLastUpdatedDate() ?? null;
    }
  },
  methods: {
    /**
     * Display the date, in Utc using the configuration.dateFormat
     */
    displayDate(date: Date | null): string {
      if (date) {
        return moment(date).utc().locale(this.currentLocale).format(configuration.dateFormat);
      }
      return '';
    },
    displayHeadingText(date: Date | null) {
      if (!date) {
        return undefined;
      }
      return this.$t('pages.dashboard-last-updated', { date: this.displayDate(date) });
    },
    updateOptions() {
      this.options = {
        reportingPeriod: this.reportingPeriod,
        comparisonOffset: this.comparisonOffset,
        lastUpdatedDate: this.lastUpdatedDate,
        startDate: this.startDate,
        endDate: this.endDate,
      };
    },
    async startDateUpdated(newDate: string) {
      this.startDate = moment.utc(newDate, "YYYY-MM-DD").startOf("day").toISOString();
      this.updateOptions();
    },
    async endDateUpdated(newDate: string) {
      this.endDate = moment.utc(newDate, "YYYY-MM-DD").startOf("day").toISOString();
      this.updateOptions();
    },
    onLastUpdatedDateChanged(newVal: Date | null, oldVal: Date | null) {
      if (newVal === oldVal) {
        return;
      }
      this.refreshDateRange(this.reportingPeriod);
      this.updateOptions();
    },
    onReportingPeriodChanged(newVal: InsightsReportingPeriodEnum, oldVal: InsightsReportingPeriodEnum) {
      if (newVal === oldVal) {
        return;
      }

      this.refreshDateRange(newVal);
      this.updateOptions();
    },
    refreshDateRange(newVal: InsightsReportingPeriodEnum) {
      switch (newVal) {
        case InsightsReportingPeriodEnum.thisWeek:
          this.comparisonOffset = InsightsComparisonOffsetEnum.lastWeek;
          break;
        case InsightsReportingPeriodEnum.lastWeek:
          this.comparisonOffset = InsightsComparisonOffsetEnum.priorWeek;
          break;
        case InsightsReportingPeriodEnum.thisMonth:
          this.comparisonOffset = InsightsComparisonOffsetEnum.lastMonth;
          break;
        case InsightsReportingPeriodEnum.lastMonth:
          this.comparisonOffset = InsightsComparisonOffsetEnum.priorMonth;
          break;
        case InsightsReportingPeriodEnum.yearToDate:
          this.comparisonOffset = InsightsComparisonOffsetEnum.lastYear;
          break;
        default:
          this.comparisonOffset = InsightsComparisonOffsetEnum.none;
          break;
      }

      if (newVal !== InsightsReportingPeriodEnum.custom) {
        this.endDate = moment.utc(this.lastUpdatedDate).toISOString();

        switch (newVal) {
          case InsightsReportingPeriodEnum.thisWeek:
            this.startDate = moment.utc(this.lastUpdatedDate).startOf('week').startOf('day').toISOString();
            break;
          case InsightsReportingPeriodEnum.lastWeek:
            this.startDate = moment.utc(this.lastUpdatedDate).subtract(1, 'week').startOf('week').startOf('day').toISOString();
            this.endDate = moment.utc(this.startDate).add(1, 'week').subtract(1, 'day').startOf('day').toISOString();
            break;
          case InsightsReportingPeriodEnum.thisMonth:
            this.startDate = moment.utc(this.lastUpdatedDate).startOf('month').startOf('day').toISOString();
            break;
          case InsightsReportingPeriodEnum.lastMonth:
            this.startDate = moment.utc(this.lastUpdatedDate).subtract(1, 'month').startOf('month').startOf('day').toISOString();
            this.endDate = moment.utc(this.startDate).add(1, 'month').subtract(1, 'day').startOf('day').toISOString();
            break;
          case InsightsReportingPeriodEnum.yearToDate:
            this.startDate = moment.utc(this.lastUpdatedDate).startOf('year').startOf('day').toISOString();
            break;
        }
      }
    },
    async onInsightsAccessChanged() {
      if (this.canAccessInsights) {
        this.lastUpdatedDate = await insightsStore.getLastUpdatedDate() ?? null;
      } else {
        this.lastUpdatedDate = null;
      }
    },
  },
});
