
import configuration from '@/configuration';
import SimpleView from '../layouts/SimpleView.vue';
import { PaymentDetails, PaymentDetailsInvoice } from '@/store/payment/payment-models';
import {
  applicationStore,
  authenticationStore,
  paymentStore,
} from '@/store/store';
import { toLocalDate } from '@/datetime';
import Vue from 'vue';
import { DataTableHeader } from 'vuetify';
import { statementDetailsPageName } from '@/router';

export default Vue.extend({
  name: 'PaymentDetails', // eslint-disable-line vue/multi-word-component-names
  components: {
    'simple-view': SimpleView,
  },
  data() {
    return {
      loading: false,
      statementDetailsPageName: statementDetailsPageName,
    };
  },
  computed: {
    companyName(): string {
      return configuration.company.name;
    },
    payment(): PaymentDetails {
      return paymentStore.payment;
    },
    invoices(): PaymentDetailsInvoice[] {
      return paymentStore.payment?.invoices ?? [];
    },
    currentIdentity() {
      return authenticationStore.currentIdentity;
    },
    currentLocale(): string {
      return applicationStore.currentLocale.locale;
    },
    tableHeaders(): DataTableHeader[] {
      return [
        {
          text: this.$t('paymentDetails.statements-invoice-no').toString(),
          value: 'invoiceId',
          sortable: false,
        },
        {
          text: this.$t('paymentDetails.statements-amount').toString(),
          value: 'amount',
          sortable: false,
          align: 'end',
        },
        {
          text: this.$t('paymentDetails.statements-date').toString(),
          value: 'openDate',
          sortable: false,
        },
      ];
    },
  },
  methods: {
    displayDate(date: Date): string {
      return toLocalDate(date);
    },
    displayAmount(amount: number) {
      return this.$n(amount, 'currency');
    },
    getInvoiceNumber(invoice: PaymentDetailsInvoice) {
      return invoice.invoiceNumber || invoice.invoiceId;
    },
  },
});
