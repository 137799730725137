
import CenteredImage from '@/components/common/CenteredImage.vue';
import Vue from 'vue';
export default Vue.extend({
  name: 'LoadingSpinner',
  components: {
    'centered-image': CenteredImage,
  },
  props: {
    absolute: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    absoluteStyles(): any {
      if (!this.absolute) {
        return {};
      }

      return {
        'position': 'absolute',
        'background-color': 'white',
        'top': '0',
        'bottom': '0',
        'left': '0',
        'right': '0',
        'height': '100vh',
        'width': '100vw',
        'z-index': 'var(--z-index-loader)',
      }
    },
    spinnerSrc(): string {
      return 'loading-light.gif';
    },
  },
});
