
import Vue, { PropType } from 'vue';
export default Vue.extend({
  name: 'DashboardView',
  components: {},
  props: {
    mode: {
      type: String as PropType<'normal' | 'raw' | undefined>,
      required: false,
    },
  },
});
