
import Vue from "vue";
import ComboBox from "@/components/controls/ComboBox.vue";
import BasicIntegration from "./BasicIntegration.vue";
import Magento2Integration from "./Magento2Integration.vue";
import ShopifyIntegration from "./ShopifyIntegration.vue";
import WooCommerceIntegration from "./WooCommerceIntegration.vue";
import CardLayout from "@/components/layouts/CardLayout.vue";
import configuration from "@/configuration";
import ActionButton from "@/components/controls/ActionButton.vue";
import { featureStore, merchantStore } from "@/store/store";
import TextBox from "@/components/controls/TextBox.vue";
import { DefaultValidatorArray, RequiredFieldValidator } from "@/validators";

export default Vue.extend({
  name: "IntegrationSelector",
  components: {
    "action-button": ActionButton,
    "card-layout": CardLayout,
    "combo-box": ComboBox,
    "textbox": TextBox,
    "basic-integration": BasicIntegration,
    "magento-integration": Magento2Integration,
    "shopify-integration": ShopifyIntegration,
    "woocommerce-integration": WooCommerceIntegration,
  },
  data() {
    return {
      requiredField: DefaultValidatorArray,
      platform: "",
      customPlatform: "",
      integrations: [
        {
          value: "shopify",
          text: this.$t("shopifyIntegration.name"),
        },
        {
          value: "magento_2",
          text: this.$t("magento2Integration.name"),
        },
        {
          value: "woocommerce",
          text: this.$t("wooCommerceIntegration.name"),
        },
        {
          value: "salesforce",
          text: this.$t("salesforceIntegration.name"),
        },
        {
          value: "spree",
          text: this.$t("spreeIntegration.name"),
        },
        {
          value: "sylius",
          text: this.$t("syliusIntegration.name"),
        },
        {
          value: "oracle_atg",
          text: this.$t("oracleAtgIntegration.name"),
        },
        {
          value: "custom",
          text: this.$t("fallbackIntegration.name"),
        },
      ],
    };
  },
  created() {
    this.requiredField = RequiredFieldValidator;
  },
  mounted() {
    this.platform = merchantStore.platform ?? "";
    this.customPlatform = merchantStore.customPlatform ?? "";
  },
  computed: {
    companyName() {
      return configuration.company.name;
    },
    isShopify() {
      return /shopify/i.test(this.platform);
    },
    isMagento_2() {
      return /magento_2/i.test(this.platform);
    },
    isWoocommerce() {
      return /woocommerce/i.test(this.platform);
    },
    isSalesforce() {
      return /salesforce/i.test(this.platform);
    },
    isSpree() {
      return /spree/i.test(this.platform);
    },
    isSylius() {
      return /sylius/i.test(this.platform);
    },
    isOracle_atg() {
      return /oracle_atg/i.test(this.platform);
    },
    isCustom() {
      return /custom/i.test(this.platform);
    },
    showDropdown() {
      return featureStore.showComplianceIntegrationTypeDropdown;
    }
  },
  methods: {
    onIntegrationTypeChanged(value) {
      this.platform = value;
      this.customPlatform = "";
    },
    validate() {
      this.valid = (this.$refs.platformForm as HTMLFormElement).validate();
      return this.valid;
    },
    async onTestIntegration() {
      if(!this.validate()) {
        return;
      }

      if (this.platform !== merchantStore.platform || this.customPlatform !== merchantStore.customPlatform) {
        // Note: updating the platform here will also update the merchantStore.merchant model, which closes out all these modals.
        // All good though, the success page lives outside the modal now, we're good.
        await merchantStore.updatePlatform({ platform: this.platform, customPlatform: this.customPlatform });
      }

      this.$emit("test-integration");
    },
    cancel() {
      this.$emit('cancel')
    }
  },
});
