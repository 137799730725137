
import { mdiCheck } from "@mdi/js";
import { applicationStore, userStore } from "@/store/store";
import configuration from "@/configuration";
import ActionButton from "../controls/ActionButton.vue";
import { mdiClose } from '@mdi/js';
import UserPermissionsDialog from "./UserPermissionsDialog.vue";
import Vue from 'vue';

export default Vue.extend({
  name: 'InviteUserDialog',
  components: {
    'action-button': ActionButton,
    'user-permissions-dialog': UserPermissionsDialog,
  },
  data() {
    return {
      showDialog: false,
      email: "",
      successIcon: mdiCheck,
      valid: false,
      submitting: false,
      success: false,
      closeIcon: mdiClose,
      editRoles: false,
      emailRules: [
        (v) => !!v || this.$t("users.email-required"),
        (v) => /.+@.+\..+/.test(v) || this.$t("users.email-invalid"),
      ],
    };
  },
  computed: {
    companyName(): string {
      return configuration.company.name;
    },
  },
  watch: {
    showDialog(newShowDialog) {
      if (!newShowDialog) {
        this.close();
      }
    },
  },
  methods: {
    async onInvite() {
      if (!this.valid) {
        return;
      }

      this.submitting = true;
      const inviteSuccess = await userStore.inviteUser(this.email);

      if (!inviteSuccess) {
        applicationStore.triggerErrorModal(
          this.$t("users.invite-user-error", {
            companyName: this.companyName,
          }).toString()
        );
        this.submitting = false;
        this.close();
        return;
      }

      this.submitting = false;
      this.success = true;
    },
    close() {
      if (this.showDialog) {
        this.showDialog = false;
      }
      this.success = false;
      this.email = "";
      this.submitting = false;
      this.$emit('closed');
    },
  },
  });
