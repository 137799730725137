
import SearchIcon from '@/assets/images/search.svg?inline';
import DoubleDatePicker from '@/components/controls/DoubleDatePicker.vue';
import ExportMenu from '@/components/common/ExportMenu.vue';
import PaymentsIcon from '@/assets/images/icons/sidebar/PaymentsIconLarge.svg?inline';
import configuration from '@/configuration';
import moment from 'moment-timezone';
import { Payment } from '@/store/payment/payment-models';
import {
  applicationStore,
  authenticationStore,
  merchantStore,
  paymentStore,
} from '@/store/store';
import { DataOptions, DataTableHeader } from 'vuetify';
import { TranslateResult } from 'vue-i18n';
import TableFooter, { TableFooterStatic } from '../controls/TableFooter.vue';
import SearchView from '../layouts/SearchView.vue';
import { toLocalDate } from '@/datetime';
import TextBox from '../controls/TextBox.vue';
import Vue from 'vue';
import { paymentDetailsPageName } from '@/router';

export default Vue.extend({
  name: 'Payments', // eslint-disable-line vue/multi-word-component-names
  components: {
    'search-view': SearchView,
    'double-date-picker': DoubleDatePicker,
    'export-menu': ExportMenu,
    'payments-icon': PaymentsIcon,
    'search-icon': SearchIcon,
    'table-footer': TableFooter,
    textbox: TextBox,
  },
  data() {
    return {
      paymentDetailsPageName: paymentDetailsPageName,
      // Allow skeleton loader to appear before table finishes loading (the first load only)
      firstLoad: true,
      loading: true,
      csvLoading: false,
      searchInput: '',
      startFilterOff: false,
      endFilterOff: false,
      defaultDateFilter: true,
      startDate: moment
        .utc()
        .startOf('month')
        .toISOString(),
      endDate: moment
        .utc()
        .endOf('month')
        .toISOString(),
      tableOptions: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['createdDateTime'],
        sortDesc: [true], // default date desc
        groupBy: [],
        groupDesc: [],
        multiSort: false, // ability to sort by more than one column at once
        mustSort: true, // table must be sorted by at least one column
      } as DataOptions,
      queryStartDate: undefined as string | undefined,
    };
  },
  computed: {
    companyName(): string {
      return configuration.company.name;
    },
    footerOptions(): any {
      return TableFooterStatic.getDefaultOptions((key) => this.$t(key));
    },
    paymentArray(): Payment[] {
      return paymentStore.paymentArray;
    },
    paymentCount(): number {
      return paymentStore.paymentCount;
    },
    tableHeaders(): DataTableHeader[] {
      return [
        {
          text: this.$t('payments.payment-id').toString(),
          value: 'paymentId',
          sortable: false,
        },
        {
          text: this.$t('payments.date').toString(),
          value: 'createdDateTime',
        },
        {
          text: this.$t('payments.transfer-id').toString(),
          value: 'transferId',
          sortable: false,
        },
        {
          text: this.$t('payments.amount').toString(),
          value: 'amount',
          sortable: false,
          align: 'center',
        },
      ];
    },
    currentIdentity() {
      return authenticationStore.currentIdentity;
    },
    searchText(): TranslateResult {
      return this.$t('payments.search');
    },
    currentLocale(): string {
      return applicationStore.currentLocale.locale;
    },
  },
  methods: {
    /** Display the date, in Utc using the configuration.dateFormat */
    displayDate(date: Date): string {
      return toLocalDate(date);
    },
    onSearch() {
      if (this.defaultDateFilter) {
        this.startFilterOff = true;
        this.endFilterOff = true;
      }

      this.searchPayments(1);
    },
    async searchPayments(pageNumber: number | null = null) {
      this.loading = true;
      this.tableOptions.page = pageNumber || this.tableOptions.page;
      const { page, itemsPerPage, sortDesc } = this.tableOptions;
      const minimumCreateDateTime = this.startFilterOff ? '' : this.startDate;
      const maximumCreateDateTime = this.endFilterOff ? '' : this.endDate;
      await paymentStore.searchPayments({
        text: this.searchInput,
        page,
        pageSize: itemsPerPage,
        minimumCreateDateTime,
        maximumCreateDateTime,
        orderBy: sortDesc[0] ? 2 : 1,
      });
      this.loading = false;
      // Must use history push to ensure the page is not reloaded
      history.pushState(
        {},
        '',
        `?page=${this.tableOptions.page}&pageSize=${this.tableOptions.itemsPerPage}&desc=${this.tableOptions.sortDesc[0]}`
      );
    },
    async startDateUpdated(newDate: string) {
      this.startFilterOff = false;
      this.defaultDateFilter = false;
      this.startDate = moment
        .utc(newDate, 'YYYY-MM-DD')
        .startOf('day')
        .toISOString();
      await this.searchPayments(1);
    },
    async endDateUpdated(newDate: string) {
      this.endFilterOff = false;
      this.defaultDateFilter = false;
      this.endDate = moment
        .utc(newDate, 'YYYY-MM-DD')
        .endOf('day')
        .toISOString();
      await this.searchPayments(1);
    },
    async exportToCsv() {
      // Do not allow the user to click export while already exporting
      if (this.csvLoading) {
        return;
      }
      this.csvLoading = true;

      // Arrange options
      // TODO convert sortDesc into orderBy
      const { page, itemsPerPage, sortDesc } = this.tableOptions;
      const minimumCreateDateTime = this.startDate;
      const maximumCreateDateTime = this.endDate;

      // Get download url
      const csvDownloadUrl = await paymentStore.exportPaymentsCsv({
        text: '',
        page,
        pageSize: itemsPerPage,
        minimumCreateDateTime,
        maximumCreateDateTime,
        orderBy: sortDesc[0] ? 2 : 1,
      });

      // If the downloard url is undefined, csv request failed
      if (!csvDownloadUrl) {
        return applicationStore.triggerErrorModal(
          this.$t('payments.export-csv-error').toString()
        );
      } else {
        // Create downloading anchor
        const downloadAnchor = document.createElement('a');
        downloadAnchor.href = csvDownloadUrl as string;
        downloadAnchor.download = `${merchantStore.merchant.legalName}${this.companyName}Payments.csv`;

        // Add, use, and remove anchor
        document.body.appendChild(downloadAnchor);
        downloadAnchor.click();
        document.body.removeChild(downloadAnchor);
      }
      this.csvLoading = false;
    },
  },
  watch: {
    currentIdentity: async function() {
      // Update payments list as identity changes
      await this.searchPayments();
    },
    tableOptions: async function() {
      await this.searchPayments();
    },
  },
  mounted() {
    // Check query params
    if (
      this.$route.query.page &&
      !isNaN(parseInt(this.$route.query.page as string, 10))
    ) {
      this.tableOptions.page = parseInt(this.$route.query.page as string, 10);
    }
    if (
      this.$route.query.pageSize &&
      !isNaN(parseInt(this.$route.query.pageSize as string, 10))
    ) {
      this.tableOptions.itemsPerPage = parseInt(this.$route.query.pageSize as string, 10);
    }
    if (this.$route.query.desc) {
      this.tableOptions.sortDesc[0] = this.$route.query.desc === 'true';
    }

    // Disable skeleton loader
    this.firstLoad = false;
  },
});
