
import { ordersPageName } from '@/router';
import { orderStore } from '@/store/store';
import StatusItem from './StatusItem.vue';
import Vue from 'vue';
export default Vue.extend({
  name: 'OrdersStatusItem',
  components: {
    'status-item': StatusItem,
  },
  data() {
    return {
      newOrderCount: 0 as number | null,
      firstLoad: true,
      linkTo: { name: ordersPageName },
    };
  },
  computed: {
    valid(): any {
      return this.newOrderCount !== null;
    },
    title(): any {
      let suffix = '';
      const newOrderCount = this.newOrderCount ?? 0;
      if (newOrderCount === 1) {
        suffix = '-single';
      } else if (!newOrderCount) {
        suffix = '-empty';
      }
      return this.$t(`insights.status-item-orders-title${suffix}`, { count: this.$n(newOrderCount) });
    },
    linkText(): any {
      return this.$t('insights.status-item-orders-link');
    },
  },
  async mounted() {
    this.newOrderCount = await orderStore.getNewOrderCount() ?? null;
    this.firstLoad = false;
  },
});
