
import LineChart, { LineChartStatic } from '../../controls/charts/LineChart.vue';
import {
  AggregatedCustomer,
  ComparableAggregatedResult,
  CustomerDailyMetricsTypeEnum,
  CustomerDataTypeEnum,
} from '@/store/insights/insights-models';
import InsightsStore from '@/store/insights/insights-store';
import ChartTooltipCallback from './ChartTooltipCallback';
import AggregatedResultChartDate from './AggregatedResultChartDate';
import { applicationStore } from '@/store/store';
import ChartTooltip from './ChartTooltip.vue';
import Vue, { PropType } from 'vue';
export default Vue.extend({
  name: 'CustomerCountChart',
  components: {
    'line-chart': LineChart,
    'chart-tooltip': ChartTooltip,
  },
  props: {
    data: {
      type: Object as PropType<ComparableAggregatedResult>,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      datasets: [] as Array<any>,
      labels: [] as Array<string>,
      options: LineChartStatic.createOptionsTemplate(),
      calculating: false,
      // NOTE: Composite object binding is not working in tooltip callback
      // It would cause infinite loop. Root cause is unknown.
      // Therefore, we have to use individual value bindings...
      chartTooltipOpacity: '0',
      chartTooltipLeft: '',
      chartTooltipTop: '',
      chartTooltipMainTitle: '',
      chartTooltipMainLabel: '',
      chartTooltipMainValue: '',
      chartTooltipAuxTitle: '',
      chartTooltipAuxLabel: '',
      chartTooltipAuxValue: '',
    };
  },
  computed: {
    title(): any {
      return this.$t('insights.chart-title-customers-over-time');
    },
    currentLocale(): string {
      return applicationStore.currentLocale.locale;
    },
  },
  watch: {
    'data': async function(newVal) {
      await this.onDataChange(newVal);
    },
  },
  methods: {
    formatPercentage(value: number): string {
      return `${isNaN(value) ? 0 : value.toFixed(2)}%`;
    },
    getValuesByCategory(
      categories: string[],
      values: number[][],
      category: CustomerDailyMetricsTypeEnum
    ) {
      const index = categories.indexOf(CustomerDailyMetricsTypeEnum[category]);
      if (index === -1) {
        return [];
      }
      return values[index];
    },
    async onDataChange(newVal: ComparableAggregatedResult) {
      this.calculating = true;
      const result = newVal?.current;

      if (!result || !result.data) {
        return;
      }
      const { keys, categories, values, isWeekly } =
        await InsightsStore.createKeyValueArraysByCategory(
          result.data as AggregatedCustomer[],
          CustomerDataTypeEnum.DailyMetrics,
          result.startDate,
          result.endDate,
          newVal.reportingPeriod,
        );
      const newCustData = this.getValuesByCategory(
        categories,
        values,
        CustomerDailyMetricsTypeEnum.NewCustomerCount
      );
      const returnCustData = this.getValuesByCategory(
        categories,
        values,
        CustomerDailyMetricsTypeEnum.ReturningCustomerCount
      );
      const totalCustData = new Array<number>();
      for (let i = 0; i < newCustData.length; i++) {
        totalCustData[i] = newCustData[i] + returnCustData[i];
      }

      const mainLabels = [this.$t('insights.chart-label-new-customers'), this.$t('insights.chart-label-returning-customers')];
      const tooltipCallback = (raw, context) => {
        return `${this.$n(raw)} (${this.displayPercentage(
          context.raw,
          totalCustData,
          context.dataIndex
        )})`;
      };

      const mainDataset = {
        ...LineChartStatic.createDatasetTemplate(this.$vuetify, newCustData.length),
        label: mainLabels[0],
        data: newCustData,
      };

      const comparisonDataSet = {
        ...LineChartStatic.createDatasetTemplate(this.$vuetify, returnCustData.length, true),
        label: mainLabels[1],
        data: returnCustData,
      };

      this.datasets = [mainDataset, comparisonDataSet];
      this.labels = keys.map((l) => AggregatedResultChartDate.formatDate(l, this.currentLocale, false, newVal.reportingPeriod));

      const options = LineChartStatic.createOptionsTemplate();
      const instance = this;
      options.plugins.tooltip.mode = 'index';
      options.plugins.tooltip.enabled = false;
      options.plugins.tooltip.external = (context) => ChartTooltipCallback.defaultCallback(instance, instance.datasets, [keys, keys], context, isWeekly, tooltipCallback);
      this.options = options;

      this.calculating = false;
    },
    displayPercentage(value, totals: number[], index: number) {
      return totals[index] === 0 ? this.$t('insights.chart-not-available').toString() : this.formatPercentage((value * 100) / Math.max(1, totals[index]));
    },
  },
});
