
import { getOffsetForTimezone } from '@/datetime';
import eventBus from '@/event-bus';

import { settingsPageName } from '@/router';
import { applicationStore } from '@/store/store';
import Vue from 'vue';
export default Vue.extend({
  name: 'TimezoneNotice',
  computed: {
    canSwitchTimezones(): boolean {
      if (!applicationStore.supportedTimezones) {
        return false;
      }

      if (Array.isArray(applicationStore.supportedTimezones)) {
        return applicationStore.supportedTimezones.length > 1;
      }

      return Object.keys(applicationStore.supportedTimezones).length > 1;
    },
    currentTimezone(): string {
      return this.$t(`timezones.${applicationStore.userTimezone.ianaTimezoneName}`, { offset: getOffsetForTimezone(applicationStore.userTimezone.ianaTimezoneName) }).toString();
    },
    settingsPageName(): string {
      return settingsPageName;
    },
  },
  mounted() {
    if (this.$refs['timezoneSwitcher']) {
      const ref = this.$refs['timezoneSwitcher'] as HTMLElement;
      ref.getElementsByTagName('a').item(0)?.addEventListener('click', () => {
        eventBus.publishTrackClickEvent('Timezone Switcher Clicked');
      })
    }
  },
});
