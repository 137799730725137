
import configuration from '@/configuration';
import { authenticationStore } from '@/store/store';
import Vue from 'vue';
export default Vue.extend({
  name: 'SimpleLogoHeader',
  props: {
    hideLogoutButton: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    companyName(): string {
      return configuration.company.name;
    },
  },
  methods: {
    async logout(event: Event) {
      event.preventDefault();
      try {
        // Logout and redirect to logout page
        await authenticationStore.logout(false);
      } catch (e) {
        throw new Error(`Error logging out: ${JSON.stringify(e)}`);
      }
    },
  },
});
