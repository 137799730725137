
import configuration from "@/configuration";
import { defaultPageName, noop } from "@/router";
import { merchantStore, onboardingStore } from "@/store/store";
import Vue from "vue";
import OnboardingBusinessInfo from "./OnboardingBusinessInfo.vue";
import OnboardingChecklist from "./OnboardingChecklist.vue";
import OnboardingFirstCheckout from "./OnboardingFirstCheckout.vue";
import OnboardingIntegration from "./OnboardingIntegration.vue";
import IntegrationSuccess from "./integration/IntegrationSuccess.vue";

export default Vue.extend({
  name: "OnboardingBanner",
  components: {
    "onboarding-first-checkout": OnboardingFirstCheckout,
    "onboarding-checklist": OnboardingChecklist,
    "onboarding-business-info": OnboardingBusinessInfo,
    "onboarding-integration": OnboardingIntegration,
    "integration-success": IntegrationSuccess,
  },
  data() {
    return {
      displayBusinessInfoModal: false,
      displayIntegrationModal: false,
      unsavedBusinessInfoDialogVisible: false,
    };
  },
  computed: {
    companyName() {
      return configuration.company.name;
    },
    isOnboarding() {
      return onboardingStore.isOnboarding;
    },
    isDueDiligenceApproved(): boolean {
      return merchantStore.isDueDiligenceApproved;
    },
    isOnboardingBusinessInfoSubmitted(): boolean {
      return merchantStore.isOnboardingBusinessInfoSubmitted;
    },
    isOnboardingIntegrationCompleted(): boolean {
      return merchantStore.isOnboardingIntegrationCompleted;
    },
    shouldMerchantIntegrationInstructionsBeSkipped(): boolean {
      return onboardingStore.shouldMerchantIntegrationInstructionsBeSkipped;
    },
    checklistItems() {
      return [
        {
          disabled: !this.isDueDiligenceApproved,
          complete: this.isOnboardingBusinessInfoSubmitted,
          text: this.$t('onboarding.checklist-step-1-text'),
          time: this.$t('onboarding.checklist-step-1-time'),
          onClick: () => {
            this.toggleBusinessInfoModal();
          },
        },
        {
          disabled: !this.isOnboardingBusinessInfoSubmitted,
          hide: this.shouldMerchantIntegrationInstructionsBeSkipped,
          complete: this.isOnboardingIntegrationCompleted,
          text: this.$t('onboarding.checklist-step-2-text', { companyName: this.companyName }),
          time: this.$t('onboarding.checklist-step-2-time'),
          onClick: () => {
            this.toggleIntegrationModal();
          },
        }
      ];
    },
  },
  methods: {
    async toggleBusinessInfoModal() {
      this.displayBusinessInfoModal = !this.displayBusinessInfoModal;

      if(!this.displayBusinessInfoModal) {
        await merchantStore.getOnboardingMerchant();
        await merchantStore.getMerchant();
      }
    },
    toggleIntegrationModal() {
      this.displayIntegrationModal = !this.displayIntegrationModal;
    },
    routeToDashboard() {
      this.$router.push({ name: defaultPageName }).catch(noop);
    }
  },
});
