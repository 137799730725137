/**
 * An authorization error encapsulates a user's authentication that is not valid or expired.
 */
export class AuthorizationError extends Error {
  constructor(message: string) {
    super(message);
    // Set the prototype explicitly per below link
    // tslint:disable-next-line: max-line-length
    // https://github.com/Microsoft/TypeScript-wiki/blob/master/Breaking-Changes.md#extending-built-ins-like-error-array-and-map-may-no-longer-work
    Object.setPrototypeOf(this, AuthorizationError.prototype);
  }
}