// Complete list of file signatures is available at https://en.wikipedia.org/wiki/List_of_file_signatures
const knownFileSignatures = [
    { mime: 'image/png', pattern: [0x89, 0x50, 0x4e, 0x47] },
    { mime: 'image/jpeg', pattern: [0xff, 0xd8, 0xff] },
    { mime: 'application/pdf', pattern: [0x25, 0x50, 0x44, 0x46, 0x2D] },
];
const numBytesNeeded = Math.max(...knownFileSignatures.map(({ pattern }) => pattern.length));

const fromFile = (file: File) => {
    const blob = file.slice(0, numBytesNeeded);
    const reader = new FileReader();

    return new Promise(resolve => {
        reader.onloadend = (e) => {
            if (e.target?.readyState === FileReader.EMPTY) {
                resolve(undefined);
                return;
            }

            if (e.target?.readyState !== FileReader.DONE) {
                return;
            }

            const bytes = new Uint8Array(e.target.result as ArrayBuffer);
            const type = knownFileSignatures.find(({ pattern }) => pattern.every((p,i) => !p || bytes[i] === p));

            resolve(type?.mime);
        };

        reader.readAsArrayBuffer(blob);
    });
}

export default {
    fromFile
}