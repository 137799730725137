
import Vue from 'vue';
export default Vue.extend({
  name: 'SalesChartMenu',
  components: {},
  computed: {
    menuItems(): any {
      return [
        this.$t('insights.sales-chart-menu-item-total-order-value'),
        this.$t('insights.sales-chart-menu-item-total-sales'),
      ];
    },
  },
  methods: {
    onMenuItemClicked(index) {
      this.$emit('change', index !== 0);
    },
  },
  });
