import logger from '@/logger';

/**
 * Decorator used to log a method's request/response details to debug.
 */
export default (
  target: any,
  propertyKey: string,
  descriptor: PropertyDescriptor,
) =>  {
  // Keep a reference to the original function
  const originalMethod = descriptor.value;

  // Replace the original function with a wrapper
  descriptor.value = async function(...args: any[]) {

    logger.debug(`${propertyKey} arguments =>`, args);

    // Call the original function
    try {
        const result = await originalMethod.apply(this, args);
        logger.debug(`${propertyKey} result <=`, result);
        return result;
    } catch (e) {
        logger.debug(`${propertyKey} exception <=`, e);
        throw e;
    }
  };

  return descriptor;
};
