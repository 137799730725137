
import { SupportedLocale } from '@/store/application/application-models';
import { applicationStore, authenticationStore, merchantStore } from '@/store/store';
import { MerchantIdentity } from '@/store/merchant/merchant-models';
import TickIcon from '@/assets/images/icons/tick.svg?inline';
import Vue, { PropType } from 'vue';

const territoryMap = {
  US: 'USA',
  GB: 'UK'
};
export default Vue.extend({
  name: 'PageHeader',
  components: {
    'tick-icon': TickIcon
  },
  props: {
    isMobile: {
      type: Boolean,
      default: false,
    },
    drawerOpen: {
      type: Boolean,
      default: false,
    },
    toggleNavDrawerFn: {
      type: Function as PropType<() => void>,
      required: true,
    },
  },
  data() {
    return {
      headerMenuOpened: false,
    };
  },
  computed: {
    merchantName(): string {
      return merchantStore.merchantName;
    },
    userName(): string {
      return authenticationStore.userName;
    },
    merchantId(): string {
      return authenticationStore.merchantId;
    },
    canSwitchIdentities(): boolean {
      return this.hasMultipleMerchantIdentities;
    },
    hasMultipleMerchantIdentities(): boolean {
      return !!this.merchantIdentitiesForUser &&
        this.merchantIdentitiesForUser.length > 1;
    },
    selectedMerchantName(): string {
      const identities = this.merchantIdentitiesForUser || [];
      const currentIdentity = identities.find(m => m.merchantId === this.merchantId);
      return currentIdentity
        ? currentIdentity.legalName || this.merchantName
        : this.merchantName;
    },
    merchantIdentitiesForUser(): MerchantIdentity[] {
      return merchantStore.availableMerchantIdentities;
    },
    hasMultipleSupportedLocales(): boolean {
      return applicationStore.supportedLocales.length > 1;
    },
    supportedLocales(): SupportedLocale[] {
      return applicationStore.supportedLocales;
    },
    currentTerritory(): string {
      return authenticationStore.currentTerritory;
    },
    currentLocale(): string {
      return applicationStore.currentLocale.locale;
    },
    canDisplay(): boolean {
      // Defaults to false at beginning of page load when meta = {}
      return !!this.$route.meta?.showHeader;
    },
  },
  methods: {
    getTerritoryName(territory: string) {
      return territoryMap[territory] || territory;
    },
    toggleSidebar() {
      this.toggleNavDrawerFn();
    },
    hideDropdownMenu() {
      this.headerMenuOpened = false;
    },
    async switchMerchant(identity: MerchantIdentity) {
      await applicationStore.switchMerchant(identity);
      this.hideDropdownMenu();
    },
    async switchLanguage(locale: SupportedLocale) {
      await applicationStore.switchLanguage(locale);
      this.hideDropdownMenu();
    },
    async logout() {
      this.hideDropdownMenu();
      this.$emit('logout');
    },
  },
});
