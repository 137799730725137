// These "types" make me very very sad 😭
export type MerchantTier = "Whale" | "Enterprise" | "Tier 1" | "Tier 2" | "Tier 3" | "Tier 4" | string;
export type MerchantPlatform = string; // "shopify" | ???
export type MerchantIndustry = string; // No idea

/**
 * Existing Merchant Model
 * https://master.branch.quadpay.xyz/swagger/?urls.primaryName=Merchants%20Api#/Merchants/GetMerchant
 */
export interface Merchant {
  id?: string;
  legalName?: string;
  portalEnabledRefund?: boolean;
  deferFundsCapture?: boolean;
  minimumOrderAmount?: number;
  maximumOrderAmount?: number;
  feeAmount?: number;
  feePercent?: number;
  fullFeeRefundPeriodWeeks?: number;
  partialFeeRefundPercentage?: number;
  noFeeRefundStartWeeks?: number;
  postalAddress?: Address;
  tradingName?: string;
  stripeAccountId?: string;
  apiKey?: ApiKey;
  platform?: MerchantPlatform;
  merchantTier?: MerchantTier;
  customPlatform?: string;
  installmentServiceFeeOverride?: number;
  ownerEmail?: string;
  ownerName?: string;
  isDiligenceApproved?: boolean;
  isOnboardingBusinessInfoSubmitted?: boolean;
  mssMerchant?: boolean;
}

export interface User {
  email: string;
  nickName: string;
  fullName?: string;
  roles: string[];
  canEdit?: boolean;
  picture?: string;
}

export enum UserPermissions {
  admin = 'mp-administrator',
  transactionsRecon = 'mp-recon-transactions',
  paymentsRecon = 'mp-recon-payments',
  refunds = 'mp-manage-refunds',
  disputes = 'mp-manage-disputes',
  settings = 'mp-manage-settings',
}

export interface MerchantIdentities {
  merchants?: MerchantIdentity[];
}

export interface MerchantIdentity {
  merchantId: string;
  legalName?: string;
  tradingName?: string;
  territory?: string;
}

/**
 * Onboarding/MSS Merchant Model
 */
export interface OnboardingMerchant {
  id?: string;
  email?: string;
  stage?: MssStage;
  businessInfo?: BusinessInfoModel;
  widgetVerified?: boolean;
  integrationVerified?: boolean;
  firstName?: string;
  lastName?: string;
  mssMerchant?: boolean;

  // the following are intended to replace 'stage'
  emailVerified?: boolean;
  msaSigned?: boolean;
  integrationCompleted?: boolean;
  stripeAccountCreated?: boolean;
  stripeAccountCompleted?: boolean;
  stripeIntegrationBypassed?: boolean;
}

/**
 * String-based enum used to track MSS stages
 * Based on Merchant Service OnboardingStage Enum
 */
export enum MssStage {
  inquiryReceived = 'inquiryReceived',
  emailVerified = 'emailVerified',
  businessInfoAdded = 'businessInfoAdded',
  msaSigned = 'msaSigned',
  stripeAccountCreated = 'stripeAccountCreated',
  stripeAccountCompleted = 'stripeAccountCompleted',
  integrationCompleted = 'integrationCompleted',
}

export interface Platform {
  displayName: string;
  systemName: string;
}

export interface Address {
  line1: string;
  line2: string;
  city: string;
  state: string;
  postCode: string;
}

export interface BusinessAddress {
  line1?: string;
  line2?: string;
  city?: string;
  state?: string;
  postCode?: string;
  country?: string;
}

export interface ApiKey {
  clientId: string;
  clientSecret: string;
}

/**
 * MODEL & COMMAND: MSS Submit Business Info
 */
export interface BusinessInfoModel {
  merchantId?: string;
  firstName?: string;
  lastName?: string;
  businessName?: string;
  legalName?: string;
  businessPhoneNumber?: string;
  businessAddress: BusinessAddress;
  ein?: string;
  website?: string;
  annualSalesMin?: number;
  annualSalesMax?: number;
  averageOrderValue?: number;
  currency?: string;
  platform?: MerchantPlatform;
  merchantTier?: MerchantTier;
  customPlatform?: string;
  industry?: MerchantIndustry;
  secondaryIndustry?: string;
  howYouHeardAboutQuadpay?: string;
  msaSigned?: boolean;
  msaVersion?: MsaVersionEnum;
}

export interface PlatformUpdateModel {
  platform: string;
  customPlatform?: string;
}

export enum MsaVersionEnum {
  standard = 'standard',
  hair = 'hair',
}

export interface UpdateSfdcOnboardingUserBusinessInfoCommand {
  legalName?: string;
  ein?: string;
  businessAddress: BusinessAddress;
  msaSigned?: boolean;
  msaVersion?: MsaVersionEnum;
}
