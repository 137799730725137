
import ActionButton from '@/components/controls/ActionButton.vue';
import CheckBox from '@/components/controls/CheckBox.vue';
import { mdiCheck } from '@mdi/js';
import Vue from 'vue';

export default Vue.extend({
  name: 'DisputeSubmissionSection',
  components: {
    'action-button': ActionButton,
    'checkbox': CheckBox,
  },
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    submittable: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      singleSubmissionAcknowledged: false,
      saving: false,
      saveDone: false,
      successIcon: mdiCheck as string,
    };
  },
  computed: {
    canSubmit(): any {
      return this.singleSubmissionAcknowledged && !this.loading;
    },
    canSave(): any {
      return false; // NOTE: hidden feature - turn this on to display the save button
    },
  },
  watch: {
    'loading': function (newVal) {
      this.onLoadingChanged(newVal);
    },
  },
  methods: {
    onLoadingChanged(newVal: boolean) {
      if (!newVal && this.saving) {
        this.saveDone = true;
        this.saving = false;
        setTimeout(() => {
          this.saveDone = false;
        }, 500);
      }
    },
    onSubmit(evt) {
      this.$emit('submit', evt);
    },
    onSave(evt) {
      this.saving = true;
      this.$emit('save', evt);
    },
  },
});
