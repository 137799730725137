import i18n from "@/i18n";
import { TranslateResult } from "vue-i18n";

export function isNotEmptyString(str: string): boolean {
  return !!str && str.trim().length > 0;
}

export function IsNotEmptyValue(value: any): boolean {
  if (typeof value === "string") {
    return isNotEmptyString(value);
  }
  return value !== null && value !== undefined;
}

export function isLessThanMaxCharacters(str: string, max: number): boolean {
  return !!str && str.trim().length <= max;
}

export function isValidEinString(str: string): boolean {
  /*
  This pattern verifies that the first two digits are not in the exclusion list, followed by a
  hyphen, then finally 7 additional digits. There are other considerations, such as an SSN as
  the EIN, which is not currently accepted in our pattern. Additionally, there may be a single
  character suffix that denotes what type of employer they are. That is also not considered in
  this pattern checking.

  https://secure.ssa.gov/apps10/poms.nsf/lnx/0101103015#b
*/
  return /^(?!(?:07|08|09|17|18|19|28|29|49|69|70|78|79|89|96|97))\d{2}-\d{7}$/.test(
    str
  );
}

export function isNumber(str: string): boolean {
  return /^-?[0-9]+(e[0-9]+)?(\.[0-9]+)?$/.test(str);
}

export function isWholeNumber(str: string): boolean {
  return /^-?[0-9]+(e[0-9]+)?$/.test(str);
}

export type FieldValidatorType = Array<
  (v: any) => TranslateResult | boolean
>;

// This is used to initialize VUE component local state
export const DefaultValidatorArray: FieldValidatorType = [];

// Assign this during 'created()' call
export const RequiredFieldValidator = [
  (value: any) =>
    IsNotEmptyValue(value) || i18n.t("validators.validation-required"),
];

export const EinFieldValidator = [
  (value: any) =>
    IsNotEmptyValue(value) || i18n.t("validators.validation-required"),
  (str: string) =>
    isLessThanMaxCharacters(str, 10) ||
    i18n.t("businessInfo.validation-character-max", [10]),
  (str: string) =>
    isValidEinString(str) || i18n.t("businessInfo.validation-invalid-ein"),
];

export const PercentageFieldValidator = [
  (value: any) => IsNotEmptyValue(value) || i18n.t("validators.validation-required"),
  (str: string) => isWholeNumber(str) || i18n.t("validators.whole-number-input"),
  (str: string) => parseInt(str) > 0 || i18n.t("validators.positive-numbers-only"),
  (str: string) => parseFloat(str) <= 100 || i18n.t("validators.less-than-100"),
]
