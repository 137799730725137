
import Vue, { PropType } from 'vue';
export default Vue.extend({
  name: 'SimpleView',
  components: {},
  props: {
    mode: {
      type: String as PropType<'normal' | 'thick' | undefined>,
      required: false,
    },
  },
});
