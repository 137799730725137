
import BarChart, { BarChartStatic } from '../../controls/charts/BarChart.vue';
import {
  AggregatedCustomer,
  ComparableAggregatedResult,
  CustomerAgeGroupEnum,
  CustomerDataTypeEnum,
} from '@/store/insights/insights-models';
import InsightsStore from '@/store/insights/insights-store';
import ChartTooltip from './ChartTooltip.vue';
import ChartTooltipCallback from './ChartTooltipCallback';
import Vue, { PropType } from 'vue';
export default Vue.extend({
  name: 'CustomerAgeGroupChart',
  components: {
    'bar-chart': BarChart,
    'chart-tooltip': ChartTooltip,
  },
  props: {
    data: {
      type: Object as PropType<ComparableAggregatedResult>,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
    },
    selectedReportedPeriodLabel: {
      type: String,
      required: false,
    },
    selectedComaprisonPeriodLabel: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      datasets: [] as Array<any>,
      labels: [] as Array<string>,
      calculating: false,
      options: BarChartStatic.createOptionsTemplate(),
      // NOTE: Composite object binding is not working in tooltip callback
      // It would cause infinite loop. Root cause is unknown.
      // Therefore, we have to use individual value bindings...
      chartTooltipOpacity: '0',
      chartTooltipLeft: '',
      chartTooltipTop: '',
      chartTooltipMainTitle: '',
      chartTooltipMainLabel: '',
      chartTooltipMainValue: '',
      chartTooltipAuxTitle: '',
      chartTooltipAuxLabel: '',
      chartTooltipAuxValue: '',
    };
  },
  computed: {
    title(): any {
      return this.$t('insights.chart-title-customer-age-distribution');
    },
  },
  watch: {
    'data': async function(newVal) {
      await this.onDataChange(newVal);
    },
  },
  methods: {
    getLabels(): string[] {
      return [
        CustomerAgeGroupEnum[CustomerAgeGroupEnum.From16To24],
        CustomerAgeGroupEnum[CustomerAgeGroupEnum.From25To34],
        CustomerAgeGroupEnum[CustomerAgeGroupEnum.From35To44],
        CustomerAgeGroupEnum[CustomerAgeGroupEnum.From45To55],
        CustomerAgeGroupEnum[CustomerAgeGroupEnum.Above55],
        CustomerAgeGroupEnum[CustomerAgeGroupEnum.Unknown],
      ];
    },
    displayAgeGroup(label: string) {
      const ageGroup = CustomerAgeGroupEnum[label];
      switch (ageGroup) {
        case CustomerAgeGroupEnum.From16To24:
        case CustomerAgeGroupEnum.From25To34:
        case CustomerAgeGroupEnum.From35To44:
        case CustomerAgeGroupEnum.From45To55:
        case CustomerAgeGroupEnum.Above55:
          return this.$t(`insights.customer-age-group-${ageGroup}`).toString();
        default:
          return this.$t('insights.customer-age-group-other').toString();
      }
    },
    filterDataset(allLabels: string[], allValues: number[]) {
      const keys: string[] = [];
      const values: number[] = [];

      for (let i = 0; i < allLabels.length; i++) {
        // We want to ignore Unknown data
        if (CustomerAgeGroupEnum[allLabels[i]] === CustomerAgeGroupEnum.Unknown) {
          continue;
        }
        keys.push(allLabels[i]);
        values.push(allValues[i] === undefined ? 0 : allValues[i]);
      }
      return { keys, values };
    },
    async onDataChange(newVal: ComparableAggregatedResult) {
      this.calculating = true;

      const result = newVal?.current;

      if (!result || !result.data) {
        return;
      }

      const allLabels = this.getLabels();
      const aggregatedData = await InsightsStore.aggregateByCategory(
        result.data as AggregatedCustomer[],
        CustomerDataTypeEnum.AgeGroup,
        allLabels
      );

      const { keys, values } = this.filterDataset(allLabels, aggregatedData.values);
      const total = values.reduce((acc, cur) => acc + cur, 0);
      const percentageValues = values.map(v => v / Math.max(1, total));

      const mainLabels = [
        this.selectedReportedPeriodLabel,
        this.selectedComaprisonPeriodLabel,
      ];
      const tooltipCallback = (raw, context) => {
        return `${this.formatPercentage(raw * 100)} (${this.displayCount(context.raw, total)})`;
      };

      const mainDataset = {
        ...BarChartStatic.createDatasetTemplate(this.$vuetify, true),
        label: mainLabels[0],
        data: percentageValues,
      };

      const datasets = [mainDataset];
      this.datasets = datasets;

      const labels = keys.map(this.displayAgeGroup);
      this.labels = labels;

      const options = BarChartStatic.createOptionsTemplate();
      options.scales = {
        x: {
          ticks: {
            format: {
              style: 'percent'
            }
          },
          grid: {
            display: false,
            drawBorder: false,
          },
        },
        y: {
          grid: {
            display: false,
            drawBorder: false,
          },
        }
      };

      const instance = this;
      options.plugins.legend.display = false;
      options.plugins.tooltip = {
        enabled: false,
        external: (context) => ChartTooltipCallback.defaultCallback(instance, datasets, [labels], context, false, tooltipCallback, v => v),
      };
      this.options = options;

      this.calculating = false;
    },
    displayCount(value, total: number) {
      return this.$n(Math.round(total * value));
    },
    formatPercentage(value: number): string {
      return `${isNaN(value) ? 0 : value.toFixed(2)}%`;
    },
  },
});
