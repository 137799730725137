import { InsightsReportingPeriodEnum } from "@/store/insights/insights-models";
import moment from 'moment';
import configuration from '@/configuration';
import i18n from '@/i18n';

export default class AggregatedResultChartDate {
    static formatDate(date: Date, locale: any, isWeekly: boolean, reportingPeriod?: InsightsReportingPeriodEnum): string {
        let format = configuration.dateFormat;
        if (reportingPeriod !== undefined) {
          switch (reportingPeriod) {
            case InsightsReportingPeriodEnum.lastMonth:
            case InsightsReportingPeriodEnum.thisMonth:
            case InsightsReportingPeriodEnum.yearToDate:
              format = configuration.dateInYearFormat;
              break;
            case InsightsReportingPeriodEnum.lastWeek:
            case InsightsReportingPeriodEnum.thisWeek:
              format = configuration.dayInWeekFormat;
              break;
          }
        }
        // getMonth is 0 based
        const dateStr = moment.utc(`${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`, 'YYYY-MM-DD').locale(locale).format(format);
        return isWeekly ? i18n.t("insights.chart-label-weekly-suffix", { date: dateStr }).toString() : dateStr;
      }
}