
import ActionButton from '@/components/controls/ActionButton.vue';
import CheckBox from '@/components/controls/CheckBox.vue';
import ComboBox from '@/components/controls/ComboBox.vue';
import BasicLayout from '@/components/layouts/BasicLayout.vue';
import SearchIcon from '@/assets/images/search.svg?inline';
import TextBox from '@/components/controls/TextBox.vue';
import RadioGroup from '@/components/controls/RadioGroup.vue';
import Vue from 'vue';

export default Vue.extend({
  name: 'SamplesPage',
  components: {
    'basic-layout': BasicLayout,
    'action-button': ActionButton,
    'checkbox': CheckBox,
    'combobox': ComboBox,
    'search-icon': SearchIcon,
    'textbox': TextBox,
    'radio-group': RadioGroup,
  },
  data() {
    return {
      checkboxState: false,
      comboboxState: '',
      comboboxItems: [
        {
          text: 'Item 1',
          value: '1',
        },
        {
          text: 'Item 2',
          value: '2',
        },
        {
          text: 'Item 3',
          value: '3',
        }
      ],
    };
  },
});
