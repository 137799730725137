import LogMethod from '@/decorators/logger-decorator';
import store from '@/store/store';
import moment from 'moment';
import {
  config,
  Action,
  Module,
  VuexModule,
  Mutation,
} from 'vuex-module-decorators';
import {
  AggregatedOrdersResult,
  AggregatedTransactionsResult,
  CustomerDataTypeEnum,
  AggregatedCustomersResult,
  AggregatedCustomer,
  AggregatedEntry,
  ComparableAggregatedResult,
  InsightsComparisonOffsetEnum,
  LastUpdatedDateResult,
  InsightsReportingPeriodEnum
} from './insights-models';
import { getObject } from '../store-requests';

// Set rawError for all Actions in module to true
config.rawError = true;

const DefaultComparableAggregatedResult = {
  current: {
    startDate: new Date(),
    endDate: new Date(),
    data: [],
  },
  reportingPeriod: InsightsReportingPeriodEnum.thisWeek,
  comparisonOffset: InsightsComparisonOffsetEnum.lastWeek,
};

const WeeklyAggregationThreshold = 183;

/**
 * The insights store is responsible for managing insights data and business logic
 */
@Module({
  name: 'insights',
  namespaced: true,
  store,
})
export default class InsightsStore extends VuexModule {
  aggregatedOrdersResult: ComparableAggregatedResult | undefined = DefaultComparableAggregatedResult;
  aggregatedTransactionsResult: ComparableAggregatedResult | undefined = DefaultComparableAggregatedResult;
  aggregatedCustomersResult: ComparableAggregatedResult | undefined = DefaultComparableAggregatedResult;
  loadingCustomers = false;
  loadingOrders = false;
  loadingTransactions = false;

  @Mutation
  setLoadingCustomers(loading: boolean) {
    this.loadingCustomers = loading;
  }

  @Mutation
  setLoadingOrders(loading: boolean) {
    this.loadingOrders = loading;
  }

  @Mutation
  setLoadingTransactions(loading: boolean) {
    this.loadingTransactions = loading;
  }

  @Mutation
  setAggregatedTransactionsResult(result?: ComparableAggregatedResult) {
    InsightsStore.reconcileEndDate(result);
    this.aggregatedTransactionsResult = result;
  }

  @Mutation
  setAggregatedOrdersResult(result?: ComparableAggregatedResult) {
    InsightsStore.reconcileEndDate(result);
    this.aggregatedOrdersResult = result;
  }

  @Mutation
  setAggregatedCustomersResult(result?: ComparableAggregatedResult) {
    InsightsStore.reconcileEndDate(result);
    this.aggregatedCustomersResult = result;
  }

  @Action
  async getLastUpdatedDate(): Promise<Date | undefined> {
    const url = {
      service: 'insights/last-updated-date',
      query: { }
    };

    const result: LastUpdatedDateResult = await getObject({
      url,
      options: {
        dataType: `last updated date of insights data`
      }
    });

    return result?.date;
  }
  
  /**
   * Get AggregatedOrders for order related insights charts
   */
  @Action
  async loadAggregatedOrders(args: { startDate: string, endDate: string, reportingPeriod: InsightsReportingPeriodEnum, comparisonOffset: InsightsComparisonOffsetEnum }): Promise<any> {
    this.setLoadingOrders(true);

    const cd = this.getAggregatedOrdersInternal({ startDate: args.startDate, endDate: args.endDate });

    let pd = Promise.resolve(undefined as AggregatedOrdersResult | undefined);
    if (args.comparisonOffset !== InsightsComparisonOffsetEnum.none) {
      const { start, end } = InsightsStore.getComparisonDates(args.startDate, args.endDate, args.comparisonOffset);
      pd = this.getAggregatedOrdersInternal({ startDate: start, endDate: end });
    }

    const results = await Promise.all([cd, pd]);
    this.setAggregatedOrdersResult({ current: results[0], previous: results[1], reportingPeriod: args.reportingPeriod, comparisonOffset: args.comparisonOffset });

    this.setLoadingOrders(false);
  }

  @Action
  private async getAggregatedOrdersInternal(args: { startDate: string, endDate: string }): Promise<AggregatedOrdersResult | undefined> {
    const url = {
      service: 'insights/aggregated-orders',
      query: args
    };

    return getObject({
      url,
      options: {
        dataType: `orders data`
      }
    });
  }

  /**
   * Get AggregatedTransactions for transaction related insights charts
   */
  @Action
  async loadAggregatedTransactions(args: { startDate: string, endDate: string, reportingPeriod: InsightsReportingPeriodEnum, comparisonOffset: InsightsComparisonOffsetEnum }): Promise<any> {
    this.setLoadingTransactions(true);

    const cd = this.getAggregatedTransactionsInternal({ startDate: args.startDate, endDate: args.endDate });

    let pd = Promise.resolve(undefined as AggregatedTransactionsResult | undefined);
    if (args.comparisonOffset !== InsightsComparisonOffsetEnum.none) {
      const { start, end } = InsightsStore.getComparisonDates(args.startDate, args.endDate, args.comparisonOffset);
      pd = this.getAggregatedTransactionsInternal({ startDate: start, endDate: end });
    }

    const results = await Promise.all([cd, pd]);
    this.setAggregatedTransactionsResult({ current: results[0], previous: results[1], reportingPeriod: args.reportingPeriod, comparisonOffset: args.comparisonOffset });

    this.setLoadingTransactions(false);
  }
 
   @Action
   private async getAggregatedTransactionsInternal(args: { startDate: string, endDate: string }): Promise<AggregatedTransactionsResult | undefined> {
    const url = {
      service: 'insights/aggregated-transactions',
      query: args
    };
 
     return getObject({
      url,
      options: {
        dataType: `transactions data`
      }
    });
   }

   /**
   * Get AggregatedCustomers for customer related insights charts
   */
  @Action
  async loadAggregatedCustomers(args: { startDate: string, endDate: string, type?: CustomerDataTypeEnum, reportingPeriod: InsightsReportingPeriodEnum, comparisonOffset: InsightsComparisonOffsetEnum }): Promise<any> {
    this.setLoadingCustomers(true);

    const cd = this.getAggregatedCustomersInternal({ startDate: args.startDate, endDate: args.endDate });

    let pd = Promise.resolve(undefined as AggregatedCustomersResult | undefined);
    if (args.comparisonOffset !== InsightsComparisonOffsetEnum.none) {
      const { start, end } = InsightsStore.getComparisonDates(args.startDate, args.endDate, args.comparisonOffset);
      pd = this.getAggregatedCustomersInternal({ startDate: start, endDate: end });
    }

    const results = await Promise.all([cd, pd]);
    this.setAggregatedCustomersResult({ current: results[0], previous: results[1], reportingPeriod: args.reportingPeriod, comparisonOffset: args.comparisonOffset });

    this.setLoadingCustomers(false);
  }
 
  @Action
  private async getAggregatedCustomersInternal(args: { startDate: string, endDate: string, type?: CustomerDataTypeEnum }): Promise<AggregatedCustomersResult | undefined> {
    const url = {
      service: 'insights/aggregated-customers',
      query: args
    };

    return getObject({
      url,
      options: {
        dataType: `customers data`
      }
    });
  }

  /*
  * Utility Functions - Called by individual charts
  */
  static async createKeyValueArrays(arr: Array<AggregatedEntry>, startDate: Date, endDate: Date, reportingPeriod: InsightsReportingPeriodEnum, getValueFunc: (entry?: any) => number, autoWeeklyAggregation = true) {
    let keys = new Array<Date>();
    const values = new Array<number>();
    let index = 0;
    // Dates could be a string. Parse them again to prevent bad comparison.
    startDate = new Date(startDate);
    endDate = new Date(endDate);
    for (let curDate = new Date(startDate); curDate <= endDate; curDate.setDate(curDate.getDate() + 1)) {
      keys.push(new Date(curDate));
      let matched = false;
      if (index < arr.length) {
        if (new Date(arr[index].date).setHours(0,0,0,0) === curDate.setHours(0,0,0,0)) {
          values.push(getValueFunc(arr[index]));
          matched = true;
        }
      }
      if (matched) {
        index++;
      } else {
        values.push(getValueFunc(null));
      }
      await InsightsStore.sleep(); // Don't stall UI!!
    }
    keys = InsightsStore.extendPeriod(keys, reportingPeriod);

    // See if we need to perform secondary aggregation at weekly level
    if (!autoWeeklyAggregation) {
      return { keys, values, isWeekly: false };
    }
    const aggregatedResult = await InsightsStore.aggregateIntoWeeklyInterval(keys, values);
    return { keys: aggregatedResult.keys, values: aggregatedResult.values, isWeekly: aggregatedResult.isWeekly };
  }

  static async aggregateIntoWeeklyInterval(keys: Date[], values: number[]) {
    if (keys.length <= WeeklyAggregationThreshold) {
      return { keys, values, isWeekly: false };
    }
    const newKeys = new Array<Date>();
    const newValues = new Array<number>();
    let currentWeek:Date | undefined = undefined;
    let currentValue = 0;
    for (let i = 0; i < keys.length; i++) {
      if (!currentWeek || keys[i].getDay() === 0) {
        if (currentWeek) {
          newKeys.push(currentWeek);
          newValues.push(currentValue);
          await InsightsStore.sleep(); // Don't stall UI!!
        }
        currentWeek = new Date(keys[i]);
        currentValue = 0;
      }
      currentValue += values[i];
    }
    if (currentWeek) {
      newKeys.push(currentWeek);
      newValues.push(currentValue);
    }
    return { keys: newKeys, values: newValues, isWeekly: true };
  }

  static async createKeyValueArraysByCategory(arr: Array<AggregatedCustomer>, type: CustomerDataTypeEnum, startDate: Date, endDate: Date, reportingPeriod: InsightsReportingPeriodEnum) {
    // Expensive multi-pass looping!!
    // Optimize it to use single pass looping!!
    const data = arr.filter(entry => entry.type === type);
    const categories = new Array<string>();

    let count = 0;
    for (const entry of data) {
      if (!categories.find(s => s === entry.category)) {
        categories.push(entry.category);
      }
      count++;
      if (count % 10 === 0) {
        await InsightsStore.sleep(); // Don't stall UI!!
      }
    }

    let keys = new Array<Date>();
    const values = new Array<Array<number>>();
    let isWeekly = false;
    for (const c of categories) {
      const r = await InsightsStore.createKeyValueArrays(data.filter(entry => entry.category === c), startDate, endDate, reportingPeriod, entry => entry?.entityCount ?? 0);
      values.push(r.values);
      if (keys.length === 0) {
        keys = r.keys;
      }
      isWeekly = isWeekly || r.isWeekly;
    }
    if (!isWeekly) {
      keys = InsightsStore.extendPeriod(keys, reportingPeriod);
    }
    return { keys, categories, values, isWeekly };
  }

  static async aggregateByCategory(arr: Array<AggregatedCustomer>, type: CustomerDataTypeEnum, labels?: Array<string>) {
    // NOTE: the sorting of category value is done at the back end!!
    const keys = labels ?? new Array<string>();
    const values = new Array<number>();

    // If we supplied the labels, make sure we initialize the values to the same length of the labels
    if (labels) {
      for (let i = 0; i < labels.length; i++) {
        values.push(0);
      }
    }

    for (const entry of arr.filter(entry => entry.type === type)) {
      // These are the entries with the correct type requested, now create the aggregates
      let index;
      if (!keys.find((k, i) => {
          if (k === entry.category) {
            index = i;
            return true;
          }
          return false;
        })) {
        index = keys.push(entry.category);
      }
      if (values.length > index) {
        values[index] = values[index] + entry.entityCount;
      } else {
        values.push(entry.entityCount);
      }
      await InsightsStore.sleep(); // Don't stall UI!!
    }

    return { keys, values };
  }

  private static extendPeriod(dates: Date[], reportingPeriod: InsightsReportingPeriodEnum) {
    const lastDate = dates[dates.length - 1];
    if (!lastDate) {
      return dates;
    }
    let newEnd = moment.utc(`${lastDate.getFullYear()}-${lastDate.getMonth() + 1}-${lastDate.getDate()}`, 'YYYY-MM-DD');
    switch (reportingPeriod) {
      case InsightsReportingPeriodEnum.yearToDate:
      case InsightsReportingPeriodEnum.custom:
      case InsightsReportingPeriodEnum.lastMonth:
      case InsightsReportingPeriodEnum.lastWeek:
          return dates;
      case InsightsReportingPeriodEnum.thisMonth:
        newEnd = newEnd.endOf('month').startOf('day');
        break;
      default:
        newEnd = newEnd.endOf('week').startOf('day');
        break;
    }

    // Get last date
    const arr = new Array<Date>();
    dates.forEach(d => arr.push(d));

    const curDate = new Date(lastDate);
    curDate.setDate(curDate.getDate() + 1);
    const endDate = new Date(newEnd.toISOString());
    for (; curDate <= endDate; curDate.setDate(curDate.getDate() + 1)) {
      arr.push(new Date(curDate));
    }

    return arr;
  }

  private static reconcileEndDate(result?: ComparableAggregatedResult) {
    // NOTE: this is needed as backend is exclusive, so need to subtract one day
    if (result?.current?.endDate) {
      result.current.endDate = new Date(moment.utc(result.current.endDate).subtract(1, 'day').toISOString());
    }
    if (result?.previous?.endDate) {
      result.previous.endDate = new Date(moment.utc(result.previous.endDate).subtract(1, 'day').toISOString());
    }
  }

  private static getComparisonDates(startDate: string, endDate: string, comparisonOffset: InsightsComparisonOffsetEnum) {
    const days = moment(endDate).diff(moment(startDate), 'days');

    let start = '';
    switch (comparisonOffset) {
      case InsightsComparisonOffsetEnum.lastMonth:
      case InsightsComparisonOffsetEnum.priorMonth:
        start = moment.utc(startDate, "YYYY-MM-DD").subtract(1, 'month').startOf('month').startOf("day").toISOString();
        break;
      case InsightsComparisonOffsetEnum.lastYear:
        start = moment.utc(startDate, "YYYY-MM-DD").subtract(1, 'year').startOf('year').startOf("day").toISOString();
        break;
      default:
        start = moment.utc(startDate, "YYYY-MM-DD").subtract(1, 'week').startOf('week').startOf("day").toISOString();
        break;
    }

    const end =  moment.utc(start).add(days, 'days').startOf("day").toISOString();
    return { start, end };
  }

  static async sleep(ms = 0) {
    return new Promise(res => setTimeout(res, ms));
  }

  @Mutation
  @LogMethod
  reset() {
    this.aggregatedCustomersResult = DefaultComparableAggregatedResult;
    this.aggregatedOrdersResult = DefaultComparableAggregatedResult;
    this.aggregatedTransactionsResult = DefaultComparableAggregatedResult;
    this.loadingCustomers = false;
    this.loadingOrders = false;
    this.loadingTransactions = false;
  }
}