
import SearchIcon from "@/assets/images/search.svg?inline";
import Vue from 'vue';

export default Vue.extend({
  name: 'UserSearchBar',
  components: {
    "search-icon": SearchIcon,
  },
  data() {
    return {
      searchText: "",
    };
  },
  async mounted() {
    this.loadUserSearchOptionsFromQuery();
    await this.searchUsers();
  },
  methods: {
    loadUserSearchOptionsFromQuery() {
      const { search } = this.$route.query;

      if (search) {
        this.searchText = search as string;
      }
    },
    searchUsers() {
      this.$emit('on-search', this.searchText);
    },
  },
});
