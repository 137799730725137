
import BasicLayout from '@/components/layouts/BasicLayout.vue';
import Orders from '@/components/orders/Orders.vue';
import Vue from 'vue';

export default Vue.extend({
  name: 'OrdersPage',
  components: {
    'basic-layout': BasicLayout,
    'orders': Orders,
  },
});
