
import Vue from 'vue';

const events = ['dragenter', 'dragover', 'dragleave', 'drop'];

export default Vue.extend({
  name: 'DropZone',
  components: {
  },
  props: {
  },
  data() {
    return {
      active: false,
      setInactiveTimeout: undefined as any,
    };
  },
  methods: {
    onDrop(e) {
      this.$emit('files-dropped', [...e.dataTransfer.files]);
    },
    preventDefaults(e) {
      e.preventDefault();
    },
    setActive() {
      this.active = true;
      clearTimeout(this.setInactiveTimeout); // clear the timeout
    },
    setInactive() {
      this.setInactiveTimeout = setTimeout(() => {
        this.active = false;
      }, 50);
    },
  },
  async destroyed() {
    events.forEach((eventName) => {
        document.body.removeEventListener(eventName, this.preventDefaults);
    });
  },
  async mounted() {
    events.forEach((eventName) => {
        document.body.addEventListener(eventName, this.preventDefaults);
    });
  },
});
