import { render, staticRenderFns } from "./DisputeCancellationSection.vue?vue&type=template&id=f8d94f80&"
import script from "./DisputeCancellationSection.vue?vue&type=script&lang=ts&"
export * from "./DisputeCancellationSection.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports