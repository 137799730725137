import logger from '@/logger';

/**
 * Decorator used to facilitate event subscription handlers for analytics services
 * that require extra time to load (namely due to Fullstory)
 */
export default (
  propertyKey: string,
  startUpStatus: { enabled: boolean, initialized: boolean }, // an object for pass by reference
  descriptor: (...args: any[]) => void,
) =>  {
  // Keep a reference to the original function
  const originalMethod = descriptor;

  // Replace the original function with a wrapper
  descriptor = function(...args: any[]) {
    // Note start of call
    logger.debug(`Checking initialization for ${propertyKey}`);

    // Skip interval and process immediately if already enabled
    const { enabled, initialized } = startUpStatus;

    if (initialized && enabled) {
      logger.debug(`Initialied and enabled for ${propertyKey}`);
      try {
        const result = originalMethod.apply(this, args);
        return result;
      } catch (e) {
        logger.warn(`Error processing ${propertyKey}`);
      }
    } else if (!initialized) {
      logger.debug(`Awaiting initialization for ${propertyKey}`);
      let attempts = 0;
      const maxAttemptsCount = 20;
      const isInitialized = setInterval(() => {
        const { enabled, initialized } = startUpStatus;
        attempts++;
        if (initialized) {
          clearInterval(isInitialized);
          if (enabled) {
            // Call the original function
            try {
              const result = originalMethod.apply(this, args);
              return result;
            } catch (e) {
              logger.warn(`Error processing ${propertyKey}`);
            }
          } else {
            logger.debug(`Disabled for ${propertyKey}`);
            clearInterval(isInitialized);
            return;
          }
        } else {
          if (attempts > maxAttemptsCount) {
            clearInterval(isInitialized);
            logger.error(`Unable to initialize for ${propertyKey}`);
            return;
          }
        }
      }, 50);
    } else {
      logger.debug(`Disabled for ${propertyKey}`);
    }
  };

  return descriptor;
};
