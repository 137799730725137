
import SessionExpired from '@/components/error/SessionExpired.vue';
import Vue from 'vue';

export default Vue.extend({
  name: 'SessionExpiredPage',
  components: {
    'session-expired': SessionExpired,
  },
});
