
import configuration from '@/configuration';
import MerchantIntegrationInformation from '@/components/common/MerchantIntegrationInformation.vue';
import BusinessInformation from "./BusinessInformation.vue";
import PrimaryContactInformation from "./PrimaryContactInformation.vue";
import SecuritySettings from '@/components/settings/SecuritySettings.vue';
import StripeSettings from '@/components/settings/StripeSettings.vue';
import TimezoneSettings from './TimezoneSettings.vue';
import SimpleView from '../layouts/SimpleView.vue';
import Vue from 'vue';
import { featureStore, onboardingStore } from '@/store/store';
export default Vue.extend({
  name: 'AllSettings',
  components: {
    'simple-view': SimpleView,
    'merchant-integration-info': MerchantIntegrationInformation,
    'business-information': BusinessInformation,
    'primary-contact-information': PrimaryContactInformation,
    'security-settings': SecuritySettings,
    'stripe-settings': StripeSettings,
    'timezone-settings': TimezoneSettings
  },
  computed: {
    showStripeConnect(): boolean {
      return configuration.stripe.stripeConnectEnabled;
    },
    isNewMerchantComplianceFlowEnabled() {
      return featureStore.isNewMerchantComplianceFlow;
    },
  },
  async mounted() {
    await onboardingStore.getBusinessInfo();
  }
});
