
import moment from 'moment-timezone';
import { applicationStore, authenticationStore, insightsStore, userStore } from '@/store/store';
import { InsightsComparisonOffsetEnum, InsightsReportingPeriodEnum } from '@/store/insights/insights-models';
import DashboardView from '../layouts/DashboardView.vue';
import { DoubleDatePickerStatic } from '@/components/controls/DoubleDatePicker.vue';

import OrderValueChart from './insights/OrderValueChart.vue';
import OrderCountChart from './insights/OrderCountChart.vue'
import OrderAverageValueChart from './insights/OrderAverageValueChart.vue'
import CustomerAgeGroupChart from './insights/CustomerAgeGroupChart.vue';
import CustomerLocationChart from './insights/CustomerLocationChart.vue';
import CustomerCountChart from './insights/CustomerCountChart.vue';
import CustomerRateChart from './insights/CustomerRateChart.vue';
import TransactionValueChart from './insights/TransactionValueChart.vue';
import SalesChartMenu from './insights/SalesChartMenu.vue';
import DisputesStatusItem from './insights/DisputesStatusItem.vue';
import OrdersStatusItem from './insights/OrdersStatusItem.vue';
import ContentStatusItem from './insights/ContentStatusItem.vue';
import Vue, { PropType } from 'vue';

export interface InsightsDashboardSearchOptions {
  startDate: string | null;
  endDate: string | null;
  lastUpdatedDate: Date | null,
  reportingPeriod: InsightsReportingPeriodEnum;
  comparisonOffset: InsightsComparisonOffsetEnum;
}

export const DefaultInsightsDashboardSearchOptions: InsightsDashboardSearchOptions = {
  reportingPeriod: InsightsReportingPeriodEnum.thisWeek,
  comparisonOffset: InsightsComparisonOffsetEnum.lastWeek,
  lastUpdatedDate: null,
  startDate: null,
  endDate: null,
}

export default Vue.extend({
  name: 'InsightsDashboard',
  components: {
    'dashboard-view': DashboardView,
    'order-value-chart': OrderValueChart,
    'order-count-chart': OrderCountChart,
    'order-average-value-chart': OrderAverageValueChart,
    'customer-count-chart': CustomerCountChart,
    'customer-rate-chart': CustomerRateChart,
    'customer-age-group-chart': CustomerAgeGroupChart,
    'customer-location-chart': CustomerLocationChart,
    'transaction-value-chart': TransactionValueChart,
    'sales-chart-menu': SalesChartMenu,
    'disputes-status-item': DisputesStatusItem,
    'orders-status-item': OrdersStatusItem,
    'content-status-item': ContentStatusItem,
  },
  props: {
    options: {
      type: Object as PropType<InsightsDashboardSearchOptions>,
      default: () => DefaultInsightsDashboardSearchOptions,
    },
  },
  data() {
    return {
      useTransactionsForSales: true,
    };
  },
  computed: {
    loadingCustomers(): any {
      return insightsStore.loadingCustomers;
    },
    loadingOrders(): any {
      return insightsStore.loadingOrders;
    },
    loadingTransactions(): any {
      return insightsStore.loadingTransactions;
    },
    aggregatedOrdersResult(): any {
      return insightsStore.aggregatedOrdersResult;
    },
    aggregatedTransactionsResult(): any {
      return insightsStore.aggregatedTransactionsResult;
    },
    aggregatedCustomersResult(): any {
      return insightsStore.aggregatedCustomersResult;
    },
    currentIdentity(): any {
      return authenticationStore.currentIdentity;
    },
    currentLocale(): string {
      return applicationStore.currentLocale.locale;
    },
    reportingPeriodText(): any {
      if (this.options.reportingPeriod === undefined || InsightsReportingPeriodEnum[this.options.reportingPeriod] === undefined) {
        return '';
      }
      return this.$t(`insights.reporting-period-type-text-${this.options.reportingPeriod}`);
    },
    reportingPeriodLabel(): any {
      if (this.options.reportingPeriod === undefined || InsightsReportingPeriodEnum[this.options.reportingPeriod] === undefined) {
        return '';
      }
      return this.$t(`insights.reporting-period-type-label-${this.options.reportingPeriod}`);
    },
    comparisonOffsetText(): any {
      if (this.options.comparisonOffset === undefined || InsightsComparisonOffsetEnum[this.options.comparisonOffset] === undefined) {
        return '';
      }
      return this.$t(`insights.comparison-offset-type-text-${this.options.comparisonOffset}`);
    },
    comparisonOffsetLabel(): any {
      if (this.options.comparisonOffset === undefined || InsightsComparisonOffsetEnum[this.options.comparisonOffset] === undefined) {
        return '';
      }
      return this.$t(`insights.comparison-offset-type-label-${this.options.comparisonOffset}`);
    },
    canAccessDisputes() {
      return userStore.canAccessDisputes;
    },
  },
  watch: {
    options: async function(newVal, oldVal) {
      await this.onOptionschanged(newVal, oldVal);
    },
  },
  methods: {
    async onOptionschanged(newVal: InsightsDashboardSearchOptions, oldVal: InsightsDashboardSearchOptions) {
      if (!newVal.lastUpdatedDate) {
        return;
      }

      if (newVal.lastUpdatedDate !== oldVal?.lastUpdatedDate
        || newVal.reportingPeriod !== oldVal?.reportingPeriod
        || newVal.comparisonOffset !== oldVal?.comparisonOffset
        || newVal.startDate !== oldVal?.startDate
        || newVal.endDate !== oldVal?.endDate) {
        await this.searchInsights(newVal);
      }
    },
    onSalesChartSelectionChange(useTransactionsForSales: boolean) {
      this.useTransactionsForSales = useTransactionsForSales;
    },
    async searchInsights(options: InsightsDashboardSearchOptions) {
      let endDate = options.endDate, startDate = options.startDate;
      const comparisonOffset = options.comparisonOffset;
      const reportingPeriod = options.reportingPeriod;

      if (!startDate || !endDate) {
        return;
      }

      // We don't want data to include "future" dates
      const comparand = moment.utc(options.lastUpdatedDate);
      if (moment.utc(startDate) > comparand) {
        startDate = comparand.toISOString();
      }
      if (moment.utc(endDate) > comparand) {
        endDate = comparand.toISOString();
      }
      endDate = DoubleDatePickerStatic.getInclusiveEndDate(endDate); // SQL search is EXCLUSIVE so use next day as end date
      await Promise.all([
        insightsStore.loadAggregatedOrders({ startDate, endDate, reportingPeriod, comparisonOffset }),
        insightsStore.loadAggregatedTransactions({ startDate, endDate, reportingPeriod, comparisonOffset }),
        insightsStore.loadAggregatedCustomers({ startDate, endDate, reportingPeriod, comparisonOffset: InsightsComparisonOffsetEnum.none })
      ]);
    },
  },
});
