import {
  SupportedCountry,
  Territory
} from "@/store/application/application-models";
/**
 * This defines environments and their specific configuration overrides.  Overrides can be driven
 * by matching node environments or matching window hostnames.
 */
interface EnvironmentConfiguration {
  name:
    | "Local"
    | "Local Prod"
    | "E2E Test"
    | "Unit Test"
    | "Development"
    | "Sandbox"
    | "Production";
  /**
   * Hostnames always should be lower case.
   */
  hostnames?: string[];
  nodeEnv?: "e2e-test" | "local-prod" | "test";
  overrideConfiguration?: () => void;
  applyPreviewConfiguration?: () => void;
}

/**
 * This is the base or default configuration that applies to local and all non-standard
 * environments.
 */
const configuration = {
  address: {
    includeState: true
  },
  company: {
    name: "Zip",
    companyFormalName: "Zip Co."
  },
  dayInWeekFormat: "ddd",
  dateInYearFormat: "MMM D",
  dateFormat: "MMM D, YYYY",
  dateTimeFormat: "MMM D, YYYY HH:mm",
  csvDateFormat: "MM/DD/YYYY",
  timeFormat: "hh:mm:ss A z",
  dateAndTimeFormat: " MMM D, YYYY hh:mm:ss A z",
  isVatEnabled: false,
  environmentName: process.env.NODE_ENV,
  isUnitTestEnvironment: false,
  segmentKey: "dQxGk3CA3SrT2C22QBVDTPxiqEglcnLL",
  serviceDomains: ["quadpay.com", "quadpay.xyz"],
  supportEmail: "partners@us.zip.co",
  contactUs: "https://zip.co/us/contact-us",
  auth: {
    audience: "https://merchants-auth-dev.quadpay.com",
    clientId: "B3OBCpBobt3doU6W9ZkKELsjShVyJKOY",
    domain: "merchant-login.dev.us.zip.co",
    // https://auth0.com/docs/libraries/auth0js/v9#initialization
    leeway: 5
  },
  contentful: {
    // Quadpay > Merchants space.  Make changes using pull requests in [this repo](https://github.com/the-urge-tech/contentful-terraforms).  Chat to Andre Susanto for access
    spaceId: "l6e5nwwfjxup",
    environment: "staging",
    contentDeliveryToken: "QXdimRpTSbX_bz4-_h35qvvZMvWJ9uIvxb0sqwu4H_o",
    contentPreviewToken: "dRzzA7SBvuCx3E-xhimGm_NiY-dgGdcbniJPQKS7ZD4"
  },
  datadog: {
    applicationId: "e7e78309-d5a1-4b14-823d-4a85bba8dfdf",
    clientToken: "pub0bca794cc55f614b1c619317639af531"
  },
  debug: {
    appLogEnabled: true,
    segment: false,
    vuexStrict: false,
    fullstory: false
  },
  featureFlags: {
    datadog: true,
    directPageAccess: false,
    segment: true,
    sessionExpiration: true,
    fullstory: false,
    intercom: false,
    disputes: true,
    insights: false,
    transactionNotification: true,
    showZipEmailsInUserManagement: false,
    csvV2Enabled: false,
    csvV2NoDateLimitMerchants: [
      "5419535A-69CC-44C6-9FF7-F14A9E7C7FC4",
      "D09260F1-6E69-4337-8E5F-1164B385F485",
      "7CBDA528-35BD-4D16-BC14-9F62D7B17845"
    ],
    isNewMerchantComplianceFlow: false,
    skipOnboardingIntegrationForMerchant: false,
    showComplianceIntegrationTypeDropdown: false
  },
  google: {
    mapsAutocompleteApiKey: "AIzaSyDZkSmtwHGLcF5X6G0y_Ts_JX7Derr9K6I"
  },
  intercom: {
    applicationId: "nzbattsv",
    optimizely: {
      key: "intercom_mss"
    }
  },
  statements: {
    taxInfoEnabledTerritories: ["GB"],
    showStatementStatus: false,
    optimizely: {
      showStatementPagesKey: "merchant_force_show_statement_pages",
      displayTaxInfoKey: "merchant_force_display_tax_info",
      hideTransactionPagesKey: "merchant_force_hide_transaction_pages"
    }
  },
  insights: {
    optimizely: {
      showInsightPagesKey: "merchant_force_show_insight_pages"
    }
  },
  disputes: {
    allowEvidenceStaging: false,
    optimizely: {
      showDisputePagesKey: "merchant_force_show_dispute_pages"
    }
  },
  users: {
    optimizely: {
      showUserPagesKey: "merchant_force_show_user_pages"
    }
  },
  onboardingCompliance: {
    optimizely: {
      isNewMerchantComplianceFlowKey: "merchant_onboarding_compliance",
      skipOnboardingIntegrationForMerchantKey:
        "skip_onboarding_integration_for_merchant",
      showComplianceIntegrationTypeDropdownKey:
        "show_compliance_integration_type_dropdown"
    }
  },
  links: {
    apiDomain: "https://master.branch.quadpay.xyz/",
    apiPath:"merchants",
    shopifyIntegrationVideoLink: "https://www.youtube.com/embed/YuhO-2Bce1s",
    msaTermsAndConditionsStandardLink:
      "https://assets.quadpay.com/assets/msas/Zip_US_Merchant_Services_Agreement_18_Aug_2021.pdf",
    msaTermsAndConditionsHairLink:
      "https://assets.quadpay.com/assets/msas/Zip_US_Merchant_Services_Agreement_18_Aug_2021.pdf",
    privacyLink: "https://zip.co/us/privacy-policy",
    customerTermsLink: "https://zip.co/us/quadpay-terms-of-service",
    merchantTermsLink:
      "https://assets.quadpay.com/assets/msas/Zip_US_Merchant_Services_Agreement_18_Aug_2021.pdf",
    merchantHelpCenter: "https://merchant-help.us.zip.co/hc/en-us",
    disputesAndRefundsLink:
      "https://merchant-help.us.zip.co/hc/en-us/articles/10252017432475",
    respondingToDisputeLink:
      "https://merchant-help.us.zip.co/hc/en-us/articles/9381791717659-Responding-to-disputes",
    preventingDisputesLink:
      "https://merchant-help.us.zip.co/hc/en-us/articles/9383186562459-Best-practices-for-preventing-disputes",
    onboardingHub: "https://merchant-hub.dev{rootDomain}/"
  },
  stripe: {
    stripeClientId: "ca_BO1C0mXmozYeitcsDBCKVzXcAEgbNGX4",
    stripeClientIdDev: "ca_BO1CWHu6KY48cVOu1nzLVLcGFWLggEYC",
    stripeConnectBaseUrl: "https://connect.stripe.com/express/oauth/authorize",
    stripeConnectRedirectUrl: "https://redirect",
    stripeConnectEnabled: true
  },
  supportedCountries: [
    Object.freeze({
      code: "US",
      displayName: "United States",
      currencyCode: "USD",
      currencySymbol: "$",
      defaultMinTransaction: "35",
      defaultMaxTransaction: "1500",
      minPhoneNumberLength: 7,
      maxPhoneNumberLength: 11,
      rootDomain: ".us.zip.co",
      supportedLocales: [
        {
          locale: "en-US",
          language: "en",
          displayName: "English"
        }
      ],
      territories: [Territory.US],
      supportedTimezones: [
        {
          ianaTimezoneName: "Etc/UTC"
        },
        {
          ianaTimezoneName: "America/New_York" // Eastern
        },
        {
          ianaTimezoneName: "America/Chicago" // Central
        },
        {
          ianaTimezoneName: "America/Denver" // Mountain
        },
        {
          ianaTimezoneName: "America/Los_Angeles" // Pacific
        },
        {
          ianaTimezoneName: "Pacific/Honolulu" // Hawaii
        },
        {
          ianaTimezoneName: "America/Phoenix" // Arizona
        },
        {
          ianaTimezoneName: "America/Anchorage" // Alaska
        },
        {
          ianaTimezoneName: "America/Adak" // Aleutian
        }
      ]
    }),
    Object.freeze({
      code: "CA",
      displayName: "Canada",
      currencyCode: "CAD",
      currencySymbol: "$",
      defaultMinTransaction: "35",
      defaultMaxTransaction: "1500",
      minPhoneNumberLength: 7,
      maxPhoneNumberLength: 11,
      rootDomain: ".ca.zip.co",
      supportedLocales: [
        {
          locale: "en-CA",
          language: "en",
          displayName: "English"
        },
        {
          locale: "fr-CA",
          language: "fr",
          displayName: "Français"
        }
      ],
      supportedTimezones: [
        {
          ianaTimezoneName: "Etc/UTC"
        },
        {
          ianaTimezoneName: "America/Halifax" // Atlantic
        },
        {
          ianaTimezoneName: "America/Toronto" // Eastern
        },
        {
          ianaTimezoneName: "America/Winnipeg" // Central
        },
        {
          ianaTimezoneName: "America/Edmonton" // Mountain
        },
        {
          ianaTimezoneName: "America/Vancouver" // Pacific
        },
        {
          ianaTimezoneName: "America/Regina" // Saskatchewan
        },
        {
          ianaTimezoneName: "America/Whitehorse" // Yukon
        },
        {
          ianaTimezoneName: "America/St_Johns" // Newfoundland
        }
      ],
      territories: [Territory.CA]
    })
  ] as SupportedCountry[]
};

const environments: EnvironmentConfiguration[] = [
  {
    name: "Local",
    hostnames: ["localhost"],
    overrideConfiguration: () => {
      configuration.environmentName = "Local";
      configuration.featureFlags.insights = true;
      configuration.featureFlags.csvV2Enabled = true;
      configuration.featureFlags.showZipEmailsInUserManagement = true;
      configuration.featureFlags.showComplianceIntegrationTypeDropdown = true;
      configuration.links.apiPath = "merchant-portal-api";
      // Useful flags for development
      // configuration.featureFlags.directPageAccess = true;
      // configuration.featureFlags.sessionExpiration = false;
    },
    applyPreviewConfiguration: () => {
      configuration.featureFlags.isNewMerchantComplianceFlow = true;
    }
  },
  {
    name: "Local Prod",
    nodeEnv: "local-prod",
    overrideConfiguration: () => {
      configuration.environmentName = "Local";
      configuration.contentful.environment = "master";
      configuration.contentful.contentDeliveryToken =
        "MRd6K6FgaB413cwi4IWgFdZwF1eTrRnnCihblfC9qIY";
      configuration.contentful.contentPreviewToken =
        "Z60ImCurByqY4Xeye9Xs1g-noPLV0vXjBFdNTXwoUao";
    }
  },
  {
    name: "E2E Test",
    nodeEnv: "e2e-test",
    overrideConfiguration: () => {
      configuration.environmentName = "E2E Test";
      configuration.featureFlags.intercom = false;
      configuration.featureFlags.segment = false;
    }
  },
  {
    name: "Unit Test",
    nodeEnv: "test",
    overrideConfiguration: () => {
      configuration.debug.appLogEnabled = false;
      configuration.environmentName = "Unit Test";
      configuration.featureFlags.intercom = false;
      configuration.featureFlags.segment = false;
      configuration.isUnitTestEnvironment = true;
      configuration.featureFlags.fullstory = false;
      configuration.featureFlags.csvV2Enabled = true;
    }
  },
  {
    name: "Development",
    hostnames: [
      "merchant-portal-development.quadpay.xyz",
      "merchant-portal-development.quadpay.ca",
      "merchant.dev.us.zip.co",
      "merchant.dev.ca.zip.co"
    ],
    overrideConfiguration: () => {
      configuration.environmentName = "Development";
      configuration.google.mapsAutocompleteApiKey =
        "AIzaSyBazTUhhuTpdP_lfm1gHNk16JM9-u-Sl3E";
      configuration.disputes.allowEvidenceStaging = true;
      configuration.featureFlags.csvV2Enabled = true;
      configuration.featureFlags.insights = true;
      configuration.featureFlags.showZipEmailsInUserManagement = true;
      configuration.links.apiPath = "merchant-portal-api";
    },
    applyPreviewConfiguration: () => {
      // NO OP
      configuration.featureFlags.isNewMerchantComplianceFlow = true;
    }
  },
  {
    name: "Sandbox",
    hostnames: [
      "merchant-portal-sandbox.quadpay.com",
      "merchant-portal-sandbox.quadpay.ca",
      "merchant.sand.us.zip.co",
      "merchant.sand.ca.zip.co"
    ],
    overrideConfiguration: () => {
      configuration.environmentName = "Sandbox";
      configuration.auth.clientId = "OElLErAEwkOzD2X3R9he8jFIItY45D4o";
      configuration.debug.appLogEnabled = false;
      configuration.google.mapsAutocompleteApiKey =
        "AIzaSyD3OF-_3zrf8ksgSPM7MFRMAvdcmvQ0qPE";
      configuration.links.apiDomain = "https://sandbox.api.quadpay.com/";
      configuration.links.onboardingHub =
        "https://merchant-hub.sand{rootDomain}/";
      configuration.segmentKey = "GFYeOZl7wEmE8DTcDA1Qr9M0LCfaJB42";
      configuration.featureFlags.csvV2Enabled = true;
      configuration.contentful.environment = "master";
      configuration.contentful.contentDeliveryToken =
        "MRd6K6FgaB413cwi4IWgFdZwF1eTrRnnCihblfC9qIY";
      configuration.contentful.contentPreviewToken =
        "Z60ImCurByqY4Xeye9Xs1g-noPLV0vXjBFdNTXwoUao";
      configuration.links.apiPath = "merchant-portal-api";
    },
    applyPreviewConfiguration: () => {
      configuration.featureFlags.insights = true;
      configuration.featureFlags.showZipEmailsInUserManagement = true;
    }
  },
  {
    name: "Production",
    hostnames: [
      "merchant-portal.quadpay.com",
      "merchant.us.zip.co",
      "merchant.ca.zip.co"
    ],
    overrideConfiguration: () => {
      configuration.environmentName = "Production";
      configuration.auth.audience = "https://merchants-auth.quadpay.com";
      configuration.auth.clientId = "PlgiNyStPRkObbMdLzco03cedi0vsunx";
      configuration.auth.domain = "merchant-login.us.zip.co";
      configuration.debug.appLogEnabled = false;
      configuration.google.mapsAutocompleteApiKey =
        "AIzaSyC39dgupFn7YOwZE8Ezr6y1G8iiRu_5tL4";
      configuration.intercom.applicationId = "psvwjnbo";
      configuration.links.apiDomain = "https://api.quadpay.com/";
      configuration.links.onboardingHub = "https://merchant-hub{rootDomain}/";
      configuration.segmentKey = "xaNctDFEUj8G9HPTE9RuvZnEJ7umxzfI";
      configuration.featureFlags.fullstory = false;
      configuration.featureFlags.csvV2Enabled = true;
      configuration.contentful.environment = "master";
      configuration.contentful.contentDeliveryToken =
        "MRd6K6FgaB413cwi4IWgFdZwF1eTrRnnCihblfC9qIY";
      configuration.contentful.contentPreviewToken =
        "Z60ImCurByqY4Xeye9Xs1g-noPLV0vXjBFdNTXwoUao";
        configuration.links.apiPath = "merchant-portal-api";
    },
    applyPreviewConfiguration: () => {
      configuration.featureFlags.insights = true;
      configuration.featureFlags.showZipEmailsInUserManagement = true;
    }
  }
];

function getEeApiDomain() {
  if (!process.env.VUE_APP__QUADPAY_ENV__EE) {
    return null;
  }

  return `https://pr-${process.env.VUE_APP__QUADPAY_ENV__EE}.branch.quadpay.xyz/`;
}

function addEnvironmentVariableConfigurationOverrides() {
  configuration.serviceDomains = process.env
    .VUE_APP__QUADPAY_ENV__serviceDomains
    ? [
        ...configuration.serviceDomains,
        ...process.env.VUE_APP__QUADPAY_ENV__serviceDomains.split(",")
      ]
    : configuration.serviceDomains;

  configuration.auth.audience =
    process.env.VUE_APP__QUADPAY_ENV__auth__audience ||
    configuration.auth.audience;
  configuration.auth.clientId =
    process.env.VUE_APP__QUADPAY_ENV__auth__clientId ||
    configuration.auth.clientId;
  configuration.auth.domain =
    process.env.VUE_APP__QUADPAY_ENV__auth__domain || configuration.auth.domain;
  configuration.csvDateFormat =
    process.env.VUE_APP__QUADPAY_ENV__csvDateFormat ||
    configuration.csvDateFormat;
  configuration.links.apiDomain =
    getEeApiDomain() ||
    process.env.VUE_APP__QUADPAY_ENV__links__apiDomain ||
    configuration.links.apiDomain;
  configuration.links.customerTermsLink =
    process.env.VUE_APP__QUADPAY_ENV__links__customerTermsLink ||
    configuration.links.customerTermsLink;
  configuration.links.msaTermsAndConditionsHairLink =
    process.env.VUE_APP__QUADPAY_ENV__links__msaTermsAndConditionsHairLink ||
    configuration.links.msaTermsAndConditionsHairLink;
  configuration.links.msaTermsAndConditionsStandardLink =
    process.env
      .VUE_APP__QUADPAY_ENV__links__msaTermsAndConditionsStandardLink ||
    configuration.links.msaTermsAndConditionsStandardLink;
  configuration.links.privacyLink =
    process.env.VUE_APP__QUADPAY_ENV__links__privacyLink ||
    configuration.links.privacyLink;
  configuration.links.shopifyIntegrationVideoLink =
    process.env.VUE_APP__QUADPAY_ENV__links__shopifyIntegrationVideoLink ||
    configuration.links.shopifyIntegrationVideoLink;
  configuration.segmentKey =
    process.env.VUE_APP__QUADPAY_ENV__segmentKey || configuration.segmentKey;
  configuration.stripe.stripeClientId =
    process.env.VUE_APP__QUADPAY_ENV__stripe__stripeClientId ||
    configuration.stripe.stripeClientId;
  configuration.stripe.stripeClientIdDev =
    process.env.VUE_APP__QUADPAY_ENV__stripe__stripeClientIdDev ||
    configuration.stripe.stripeClientIdDev;
  configuration.stripe.stripeConnectBaseUrl =
    process.env.VUE_APP__QUADPAY_ENV__stripe__stripeConnectBaseUrl ||
    configuration.stripe.stripeConnectBaseUrl;
  configuration.stripe.stripeConnectRedirectUrl =
    process.env.VUE_APP__QUADPAY_ENV__stripe__stripeConnectRedirectUrl ||
    configuration.stripe.stripeConnectRedirectUrl;
  configuration.supportEmail =
    process.env.VUE_APP__QUADPAY_ENV__supportEmail ||
    configuration.supportEmail;

  if (process.env.VUE_APP__QUADPAY_ENV__links__proxyApiDomain) {
    configuration.links.apiDomain = "https://localhost:8080/api/";
  }
}

const nodeEnvMatchedEnvironment = environments.find(
  environment =>
    environment.nodeEnv?.toLowerCase() === process.env.NODE_ENV?.toLowerCase()
);
const hostnameMatchedEnvironment = environments.find(environment =>
  environment.hostnames?.includes(window.location.hostname.toLowerCase())
);

/**
 * Override configuration for the specific environment.  If a node process env is matched, we'll use that.  Otherwise,
 * we use the hostname matched environment.  If both are matched, only the node process env match is used.
 */
if (nodeEnvMatchedEnvironment?.overrideConfiguration) {
  nodeEnvMatchedEnvironment.overrideConfiguration();
} else if (hostnameMatchedEnvironment?.overrideConfiguration) {
  hostnameMatchedEnvironment.overrideConfiguration();
}

/**
 * Apply preview configurations for the specific environment.
 * This is useful to switch on new features for only users who know how to enable them, and the reset of the users
 * would be still getting standard set of features.
 */
try {
  if (localStorage.getItem("PreviewEnabled") === "true") {
    if (nodeEnvMatchedEnvironment?.applyPreviewConfiguration) {
      nodeEnvMatchedEnvironment.applyPreviewConfiguration();
    } else if (hostnameMatchedEnvironment?.applyPreviewConfiguration) {
      hostnameMatchedEnvironment.applyPreviewConfiguration();
    }
  }
} catch {
  // silently consumed the error as it is not critical. app can continue as usual.
}

addEnvironmentVariableConfigurationOverrides();

export default Object.freeze(configuration);
