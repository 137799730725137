
import ErrorImage from "@/assets/images/error.svg?inline";
import SimpleCopyrightFooter from "@/components/common/SimpleCopyrightFooter.vue";
import SimpleLogoHeader from "@/components/common/SimpleLogoHeader.vue";
import configuration from "@/configuration";
import { authenticationStore } from "@/store/store";
import Vue from "vue";
import ActionButton from "../controls/ActionButton.vue";

const ProductionEnvironment = "Production";
const SandboxEnvironment = "Sandbox";
const DevelopmentEnvironment = "Development";

const mapEnvToUrl = (env: string): string => {
  if (env === ProductionEnvironment) {
    return "";
  }

  if (env === SandboxEnvironment) {
    return "sand";
  }

  // map anything unknown to dev
  return "dev";
}

const getDefaultUrl = (env: string, territory: string): string => {
  const envSegment = mapEnvToUrl(env);
  const urlEnv = envSegment ? envSegment + "." : "";
  return `https://merchant.${urlEnv}${territory.toLowerCase()}.zip.co/`;
}

export default Vue.extend({
  name: "NoMerchant",
  components: {
    "simple-footer": SimpleCopyrightFooter,
    "simple-header": SimpleLogoHeader,
    "error-image": ErrorImage,
    "action-button": ActionButton,
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    companyName(): string {
      return configuration.company.name;
    },
    isProduction(): boolean {
      return configuration.environmentName === ProductionEnvironment;
    },
    isSandbox(): boolean {
      return configuration.environmentName === SandboxEnvironment;
    },
    isDevelopment(): boolean {
      return configuration.environmentName === DevelopmentEnvironment;
    },
    devUrl(): string | undefined {
      if (!this.isSandbox) {
        return undefined;
      }

      return getDefaultUrl(DevelopmentEnvironment, authenticationStore.currentTerritory);
    },
    sandboxUrl(): string | undefined {
      if (!this.isDevelopment) {
        return undefined;
      }
      
      return getDefaultUrl(SandboxEnvironment, authenticationStore.currentTerritory);
    },
  },
  methods: {
    async navigateLogin() {
      // UI idempotency in case a user clicks more than once
      if (this.loading) {
        return;
      }

      this.loading = true;

      try {
        // Logout and redirect to login page
        await authenticationStore.logout(true);
      } catch (e) {
        throw new Error(`Error logging out: ${JSON.stringify(e)}`);
      }
    },
    switchTo(url?: string) {
      if(url) {
        window.location.href = url;
      }
    },
  },
});
