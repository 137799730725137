
import TextBox from '@/components/controls/TextBox.vue';
import Tooltip from '@/components/controls/Tooltip.vue';
import { DisputeEvidence, DisputeProductServiceTypeEnum } from '@/store/dispute/dispute-models';
import DisputeEvidenceAddress from '../components/DisputeEvidenceAddress.vue';
import DisputeDetailsSection from '../DisputeDetailsSection.vue';
import Vue, { PropType } from 'vue';

export default Vue.extend({
    name: 'DisputeShippingDetailsSection',
    components: {
        'dispute-details-section': DisputeDetailsSection,
        'dispute-evidence-address': DisputeEvidenceAddress,
        'tooltip': Tooltip,
        'textbox': TextBox,
    },
    props: {
        evidence: {
            type: Object as PropType<DisputeEvidence>,
            required: true,
        },
        editable: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            shippingTrackingNumber: '',
            shippingCarrier: '',
            shippingDate: '',
            shippingAddress: '',
        };
    },
    computed: {
        billingAddressType(): any {
            return this.$t('disputeFormShippingSection.billing');
        },
        hasShippingEvidence(): any {
            return (
                this.evidence.shippingAddress ||
                this.evidence.shippingCarrier ||
                this.evidence.shippingDate ||
                this.evidence.shippingTrackingNumber
            );
        },
        shouldShowSection(): any {
            return this.evidence.productServiceType === DisputeProductServiceTypeEnum.physicalProduct || (!this.editable && this.hasShippingEvidence);
        },
    },
    watch: {
        'shippingTrackingNumber': function (newVal) {
            this.onShippingTrackingNumberChanged(newVal);
        },
        'shippingCarrier': function (newVal) {
            this.onShippingCarrierChanged(newVal);
        },
        'shippingDate': function (newVal) {
            this.onShippingDateChanged(newVal);
        },
        'shippingAddress': function (newVal) {
            this.onShippingAddressChanged(newVal);
        },
    },
    async created() {
        this.shippingAddress = this.evidence.shippingAddress;
        this.shippingDate = this.evidence.shippingDate;
        this.shippingCarrier = this.evidence.shippingCarrier;
        this.shippingTrackingNumber = this.evidence.shippingTrackingNumber;
    },
    methods: {
        onShippingTrackingNumberChanged(newValue) {
            this.$emit('evidencePropertyChanged', { propertyName: 'shippingTrackingNumber', propertyValue: newValue });
        },
        onShippingCarrierChanged(newValue) {
            this.$emit('evidencePropertyChanged', { propertyName: 'shippingCarrier', propertyValue: newValue });
        },
        onShippingDateChanged(newValue) {
            this.$emit('evidencePropertyChanged', { propertyName: 'shippingDate', propertyValue: newValue });
        },
        onShippingAddressChanged(newValue) {
            this.$emit('evidencePropertyChanged', { propertyName: 'shippingAddress', propertyValue: newValue });
        },
    },
});
