
import BasicLayout from '@/components/layouts/BasicLayout.vue';
import OnboardingBanner from '@/components/onboarding/OnboardingBanner.vue';
import Vue from 'vue';
export default Vue.extend({
  name: 'OnboardingPage',
  components: {
    'basic-layout': BasicLayout,
    'onboarding-banner': OnboardingBanner,
  },
});
