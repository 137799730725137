
import Unauthorized from '@/components/error/Unauthorized.vue';
import Vue from 'vue';

export default Vue.extend({
  name: 'UnauthorizedPage',
  components: {
    'unauthorized': Unauthorized,
  },
});
