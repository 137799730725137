
import {
  DefaultValidatorArray,
  EinFieldValidator,
  FieldValidatorType,
  PercentageFieldValidator,
  RequiredFieldValidator
} from "@/validators";
import Vue, { PropType } from "vue";
import ActionButton from "../controls/ActionButton.vue";
import GooglePlacesAutocomplete, {
  AddressResult
} from "@/components/common/GooglePlacesAutocomplete.vue";
import { onboardingStore, applicationStore, merchantStore } from "@/store/store";
import {
  SubmitBusinessInfoCommand,
  Address
} from "@/store/onboarding/onboarding-models";
import moment from "moment-timezone";
import "moment/locale/fr-ca";
import DatePicker from "../controls/DatePicker.vue";
import TextBox from "../controls/TextBox.vue";
import RadioGroup from '@/components/controls/RadioGroup.vue';
import ComboBox from "../controls/ComboBox.vue";
import ConfirmCloseDialog from "../common/ConfirmCloseDialog.vue";
import { industries } from "@/store/merchant/merchant-settings";

enum OnboardingBusinessStep {
  Initial = 1,
  SignificantController,
  UltimateBeneficialOwners,
  Done
}

export type SignificantController = {
  firstName: string;
  middleName: string;
  lastName: string;
  personalAddress: Address;
  dateOfBirth: string;
  role: string;
};

export type UltimateBeneficialOwner = {
  firstName: string;
  middleName: string;
  lastName: string;
  personalAddress: Address;
  dateOfBirth: Date | null;
  ownershipPercentage: string | null;
};

const newUltimateBeneficialOwner = (): UltimateBeneficialOwner => {
  return {
    firstName: "",
    middleName: "",
    lastName: "",
    personalAddress: {} as Address,
    dateOfBirth: null,
    ownershipPercentage: ''
  };
};

export type OnboardingBusinessInfoFormData = {
  ein: string;
  publiclyTraded: boolean | null;
  stockSymbol: string;
  twentyFivePercentOwned: boolean | null;
  industry: string;
  secondaryIndustry: string;
  businessAddress: Address;
  businessLegalName: string;
  significantController: SignificantController;
  ultimateBeneficialOwners: UltimateBeneficialOwner[];
};

type OnboardingBusinessInfoData = {
  showCancellationModal: boolean;
  minimumDateOfBirth: string;
  maximumDateOfBirth: string;
  locale: string;
  einField: FieldValidatorType;
  requiredField: FieldValidatorType;
  percentageField: FieldValidatorType;
  step: OnboardingBusinessStep;
  openUboPanels: number[];
  fields: OnboardingBusinessInfoFormData;
};

export default Vue.extend({
  name: "OnboardingBusinessInfo",
  components: {
    'action-button': ActionButton,
    'google-places-autocomplete': GooglePlacesAutocomplete,
    'date-picker': DatePicker,
    'textbox': TextBox,
    'radio-group': RadioGroup,
    'combobox': ComboBox,
    "confirm-close-dialog": ConfirmCloseDialog,
  },
  props: {
    visible: {
      type: Boolean,
      required: false,
      default: true,
    },
    formData: {
      type: Object as PropType<OnboardingBusinessInfoFormData>,
      required: false
    }
  },
  data(): OnboardingBusinessInfoData {
    return {
      showCancellationModal: false,
      minimumDateOfBirth: moment
        .utc()
        .subtract(100, "years")
        .toISOString(),
      maximumDateOfBirth: moment
        .utc()
        .endOf("month")
        .toISOString(),
      locale: applicationStore.currentLocale.locale,
      einField: DefaultValidatorArray,
      requiredField: DefaultValidatorArray,
      percentageField: DefaultValidatorArray,
      step: OnboardingBusinessStep.Initial,
      openUboPanels: [0],
      fields: {
        ein: "",
        publiclyTraded: null,
        stockSymbol: "",
        twentyFivePercentOwned: null,
        industry: "",
        secondaryIndustry: "",
        businessAddress: {} as Address,
        businessLegalName: "",
        significantController: {
          firstName: "",
          middleName: "",
          lastName: "",
          personalAddress: {} as Address,
          dateOfBirth: "",
          role: ""
        },
        ultimateBeneficialOwners: [newUltimateBeneficialOwner()]
      }
    };
  },
  mounted() {
    this.requiredField = RequiredFieldValidator;
    this.percentageField = [
      ...PercentageFieldValidator,
      (str: string) => parseInt(str) >= 25 || this.$t("validators.ubo-over-25-perc"),
    ];
    this.einField = EinFieldValidator;

    if(merchantStore.onboardingMerchant?.businessInfo) {
      this.fields.industry = merchantStore.onboardingMerchant?.businessInfo?.industry ?? "";
      this.fields.secondaryIndustry = merchantStore.onboardingMerchant?.businessInfo?.secondaryIndustry ?? "";
      this.fields.businessLegalName = merchantStore.onboardingMerchant?.businessInfo?.businessName ?? "";
    }
  },
  computed: {
    canAddUltimateBeneficialOwner() {
      return this.fields.ultimateBeneficialOwners.length < 4;
    },
    canRemoveUltimateBeneficialOwner() {
      return this.fields.ultimateBeneficialOwners.length > 1;
    },
    currentLocale(): string {
      return applicationStore.currentLocale.locale;
    },
    radioGroupYesNoItems() {
      return [{ value: true, text: this.$t('onboarding.yes') }, { value: false, text: this.$t('onboarding.no') }];
    },
    businessIndustriesItems() {
      const values: string[] = [];
      for (const primaryIndustry in industries) {
        if (isNaN(Number(primaryIndustry))) {
          values.push(primaryIndustry);
        }
      }
      return values.map(value => ({ value, text: value }));
    },
    secondaryBusinessIndustriesItems() {
      if(!this.fields.industry) {
        return [];
      }

      const values: string[] = [];
      for(const secondaryIndustry of industries[this.fields.industry]) {
        if (isNaN(Number(secondaryIndustry))) {
          values.push(secondaryIndustry);
        }
      }
      return values.map(value => ({ value, text: value }));
    },
  },
  methods: {
    async next() {
      if (!(this.$refs.form as HTMLFormElement).validate()) {
        return;
      }

      switch (this.step) {
        case OnboardingBusinessStep.Initial:
          if (this.fields.publiclyTraded) {
            this.step = OnboardingBusinessStep.Done;
            break;
          }
          this.step++;
          break;

        case OnboardingBusinessStep.SignificantController:
          if (!this.fields.twentyFivePercentOwned) {
            this.step = OnboardingBusinessStep.Done;
            break;
          }
          this.step++;
          break;

        default:
          this.step++;
      }

      if (this.step === OnboardingBusinessStep.Done) {
        await this.save();
      }

      (this.$refs.form as HTMLFormElement).resetValidation();
    },
    addUltimateBeneficialOwner() {
      if (this.canAddUltimateBeneficialOwner) {
        this.fields.ultimateBeneficialOwners.push(newUltimateBeneficialOwner());
      }
    },
    removeUltimateBeneficialOwner() {
      this.fields.ultimateBeneficialOwners = this.fields.ultimateBeneficialOwners.slice(0, -1);
    },
    async save() {
      const hasSignificantController = !this.fields.publiclyTraded;
      const hasUltimateBeneficialOwners = this.fields.twentyFivePercentOwned;
      const body: SubmitBusinessInfoCommand = {
        ein: this.fields.ein,
        isPubliclyTraded: this.fields.publiclyTraded,
        stockSymbol: this.fields.stockSymbol,
        industry: this.fields.industry,
        secondaryIndustry: this.fields.secondaryIndustry,
        businessName: this.fields.tradeName,
        legalName: this.fields.businessLegalName,
        businessAddress: this.fields.businessAddress,
        ultimateBeneficialOwners: !hasUltimateBeneficialOwners ? [] : this.fields.ultimateBeneficialOwners.map(ubo => {
          return {
            ...ubo,
            ownershipPercentage: isNaN(ubo.ownershipPercentage || '') ? 0 : Number(ubo.ownershipPercentage) / 100,
          }
        }),
        significantController: !hasSignificantController ? undefined : { ...this.fields.significantController },
      }
      await onboardingStore.submitOnboardingBusinessInfo(body);
    },
    cancel() {
      (this.$refs.form as HTMLFormElement).resetValidation();
      this.showCancellationModal = true;
    },
    close() {
      this.$emit("close");

      // Note: I hate this... but it does stop flicker on close.
      setTimeout(() => {
        this.step = OnboardingBusinessStep.Initial;
        this.showCancellationModal = false;
      }, 200);
    },
    getAddress(addressData: AddressResult) {
      return {
        line1: addressData ? addressData.street_number + " " + addressData.route : undefined,
        line2: addressData?.line_two,
        state: addressData?.administrative_area_level_1,
        city:
          addressData?.locality ||
          addressData?.sublocality ||
          addressData?.neighborhood ||
          addressData?.postal_town,
        postCode: addressData?.postal_code,
        country: addressData?.country
      };
    },
    controllerOnDateOfBirthUpdated(newValue) {
      this.dateOfBirthMenuOpened = false;
      this.$emit("dateOfBirthUpdate", newValue);
    },
  }
});
