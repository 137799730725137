
import Vue, { PropType } from 'vue';
import OnboardingTag from './OnboardingTag.vue';
import { mdiCheckboxBlankCircleOutline, mdiCheckboxMarkedCircle, mdiArrowRight, mdiLockOutline } from "@mdi/js";

export interface ChecklistItem {
  complete: boolean,
  hide: boolean,
  text: string,
  time: string,
  disabled: boolean,
  onClick: () => void
}

export default Vue.extend({
  name: 'OnboardingChecklist',
  components: {
    'onboarding-tag': OnboardingTag,
  },
  props: {
    items: {
      type: Array as PropType<Array<ChecklistItem>>,
      required: true
    }
  },
  computed: {
    visibleItems() {
      return this.items.filter(i => !i.hide);
    },
    checkboxOffIcon() {
      return mdiCheckboxBlankCircleOutline;
    },
    checkboxOnIcon() {
      return mdiCheckboxMarkedCircle;
    },
    lockIcon() {
      return mdiLockOutline;
    },
    arrowRight() {
      return mdiArrowRight;
    }
  },
  methods: {
    toggleCheckbox(item) {
      item.complete = !item.complete;
    },
    arrowStyles(item) {
      return {
        marginLeft: 'auto',
        color: item.complete ? 'var(--purple-medium)' : 'var(--grey-quinary)'
      };
    },
    clickItem(item) {
      if(!item.disabled && !item.complete) {
        item.onClick();
      }
    },
  }
})
