
import SessionExpiredImage from '@/assets/images/session-expired.svg?inline';
import SimpleCopyrightFooter from '@/components/common/SimpleCopyrightFooter.vue';
import SimpleLogoHeader from '@/components/common/SimpleLogoHeader.vue';
import { authenticationStore } from '@/store/store';
import Vue from 'vue';

/**
 * This component is used to signify a session that expired for a user.
 */
export default Vue.extend({
  name: 'SessionExpired',
  components: {
    'session-expired-image': SessionExpiredImage,
    'simple-footer': SimpleCopyrightFooter,
    'simple-header': SimpleLogoHeader,
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    /**
     * Allow the user to return to login page
     */
    async navigateLogin(event: Event) {
      event.preventDefault();

      // UI idempotency in case a user clicks more than once
      if (this.loading) {
        return;
      }

      this.loading = true;
      await authenticationStore.logout(true);
    },
  },
});
