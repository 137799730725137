
import eventBus from '@/event-bus';
import SimpleView from '../layouts/SimpleView.vue';
import Vue from 'vue';

export default Vue.extend({
  name: 'WhatsNew', // eslint-disable-line vue/multi-word-component-names
  components: {
    'simple-view': SimpleView,
  },
  props: {
    title: {
      required: true,
      type: String,
    },
    body: {
      required: true,
      type: String,
    },
    trackingEventName: {
      required: false,
      type: String,
    },
  },
  mounted() {
    if (this.$refs['updateBody']) {
      const ref = this.$refs['updateBody'] as HTMLElement;
      ref
        .getElementsByTagName('a')
        .item(0)
        ?.addEventListener('click', () => {
          eventBus.publishTrackClickEvent('Updates Page', {
            title: this.trackingEventName,
            updateTitle: this.title,
          });
        });
    }
  },
});
