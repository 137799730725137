
import ActionButton from '../controls/ActionButton.vue';
import Vue, { PropType } from 'vue';
export default Vue.extend({
  name: 'ExportMenu',
  components: {
    'action-button': ActionButton,
  },
  props: {
    menuList: {
      type: Array as PropType<{ title: string, exportFunction: any }[]>,
      required: true,
    },
    csvLoading: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    onlyOneExportOption(): boolean {
      return this.menuList.length === 1;
    },
  },
});
