
import { LineItem } from '@/store/order/order-models';
import Vue, { PropType } from 'vue';

export default Vue.extend({
  name: 'LineItemsTable',
  props: {
    lineItems: {
      type: Array as PropType<LineItem[]>,
      required: true,
    },
  },
  methods: {
    formatPrice(price) {
      return this.$n(price, 'currency');
    },
  }
});
