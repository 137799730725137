var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"qp-settings-container"},[_c('h4',[_vm._v(" "+_vm._s(_vm.$t("merchantIntegrationInformation.payment-gateway"))+" ")]),(_vm.showMerchantName)?[_c('p',{staticClass:"qp-secondary-title"},[_vm._v(" "+_vm._s(_vm.$t("merchantIntegrationInformation.merchant-name"))+" ")]),_c('div',{staticClass:"qp-flex-row-wrap"},[_c('v-text-field',{staticClass:"qp-readonly-text-field qp-default-font-size",attrs:{"id":"merchant-name","hide-details":"","readonly":"","filled":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-tooltip',{attrs:{"color":"transparent","top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_vm._t("activator",function(){return [_c('v-btn',_vm._b({attrs:{"icon":"","color":"transparent"}},'v-btn',attrs,false),[_c('v-icon',{attrs:{"size":"34","id":"copy-merchant-name-btn"},on:{"click":function($event){_vm.copy(
                        $event,
                        'merchant-name',
                        visible => (_vm.showMerchantNameTooltip = visible)
                      )}}},[_vm._v("$copy-icon")])],1)]},{"on":on,"attrs":attrs})]}}],null,true),model:{value:(_vm.showMerchantNameTooltip),callback:function ($$v) {_vm.showMerchantNameTooltip=$$v},expression:"showMerchantNameTooltip"}},[_c('span',{staticClass:"zip-tooltip auto-width"},[_vm._t("default",function(){return [_vm._v(" "+_vm._s(_vm.$t("merchantIntegrationInformation.copy"))+" ")]})],2)])]},proxy:true}],null,true),model:{value:(_vm.merchantName),callback:function ($$v) {_vm.merchantName=$$v},expression:"merchantName"}})],1)]:_vm._e(),(_vm.showMerchantId)?[_c('p',{staticClass:"qp-secondary-title"},[_vm._v(" "+_vm._s(_vm.$t("merchantIntegrationInformation.merchant-id"))+" ")]),_c('div',{staticClass:"qp-flex-row-wrap"},[_c('v-text-field',{staticClass:"qp-readonly-text-field qp-default-font-size",attrs:{"id":"merchant-id","hide-details":"","readonly":"","filled":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-tooltip',{attrs:{"color":"transparent","top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_vm._t("activator",function(){return [_c('v-btn',_vm._b({attrs:{"icon":"","color":"transparent"}},'v-btn',attrs,false),[_c('v-icon',{attrs:{"size":"34","id":"copy-merchant-id-btn"},on:{"click":function($event){_vm.copy(
                        $event,
                        'merchant-id',
                        visible => (_vm.showMerchantIdTooltip = visible)
                      )}}},[_vm._v("$copy-icon")])],1)]},{"on":on,"attrs":attrs})]}}],null,true),model:{value:(_vm.showMerchantIdTooltip),callback:function ($$v) {_vm.showMerchantIdTooltip=$$v},expression:"showMerchantIdTooltip"}},[_c('span',{staticClass:"zip-tooltip auto-width"},[_vm._t("default",function(){return [_vm._v(" "+_vm._s(_vm.$t("merchantIntegrationInformation.copy"))+" ")]})],2)])]},proxy:true}],null,true),model:{value:(_vm.merchantId),callback:function ($$v) {_vm.merchantId=$$v},expression:"merchantId"}})],1)]:_vm._e(),(!_vm.showApiKey)?[_c('div',{staticClass:"qp-flex-row-wrap"},[_c('action-button',{attrs:{"id":"show-api-key-btn"},on:{"click":_vm.enableShowApiKey}},[_vm._v(" "+_vm._s(_vm.$t("merchantIntegrationInformation.show-api-key"))+" ")])],1)]:_vm._e(),(_vm.showClientId)?[_c('p',{staticClass:"qp-secondary-title"},[_vm._v(" "+_vm._s(_vm.$t("merchantIntegrationInformation.client-id"))+" ")]),_c('div',{staticClass:"qp-flex-row-wrap"},[_c('v-text-field',{staticClass:"qp-readonly-text-field qp-default-font-size",attrs:{"id":"client-id","hide-details":"","readonly":"","filled":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-tooltip',{attrs:{"color":"transparent","top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_vm._t("activator",function(){return [_c('v-btn',_vm._b({attrs:{"icon":"","color":"transparent"}},'v-btn',attrs,false),[_c('v-icon',{attrs:{"size":"34"},on:{"click":function($event){_vm.copy(
                        $event,
                        'client-id',
                        visible => (_vm.showClientIdTooltip = visible)
                      )}}},[_vm._v("$copy-icon")])],1)]},{"on":on,"attrs":attrs})]}}],null,true),model:{value:(_vm.showClientIdTooltip),callback:function ($$v) {_vm.showClientIdTooltip=$$v},expression:"showClientIdTooltip"}},[_c('span',{staticClass:"zip-tooltip auto-width"},[_vm._t("default",function(){return [_vm._v(" "+_vm._s(_vm.$t("merchantIntegrationInformation.copy"))+" ")]})],2)])]},proxy:true}],null,true),model:{value:(_vm.clientId),callback:function ($$v) {_vm.clientId=$$v},expression:"clientId"}})],1)]:_vm._e(),(_vm.showClientSecret)?[_c('p',{staticClass:"qp-secondary-title"},[_vm._v(" "+_vm._s(_vm.$t("merchantIntegrationInformation.client-secret"))+" ")]),_c('div',{staticClass:"qp-flex-row-wrap"},[_c('v-text-field',{staticClass:"qp-readonly-text-field qp-default-font-size",attrs:{"id":"client-secret","hide-details":"","readonly":"","filled":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-tooltip',{attrs:{"color":"transparent","top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_vm._t("activator",function(){return [_c('v-btn',_vm._b({attrs:{"icon":"","color":"transparent"}},'v-btn',attrs,false),[_c('v-icon',{attrs:{"size":"34"},on:{"click":function($event){_vm.copy(
                        $event,
                        'client-secret',
                        visible => (_vm.showClientSecretTooltip = visible)
                      )}}},[_vm._v("$copy-icon")])],1)]},{"on":on,"attrs":attrs})]}}],null,true),model:{value:(_vm.showClientSecretTooltip),callback:function ($$v) {_vm.showClientSecretTooltip=$$v},expression:"showClientSecretTooltip"}},[_c('span',{staticClass:"zip-tooltip auto-width"},[_vm._t("default",function(){return [_vm._v(" "+_vm._s(_vm.$t("merchantIntegrationInformation.copy"))+" ")]})],2)])]},proxy:true}],null,true),model:{value:(_vm.clientSecret),callback:function ($$v) {_vm.clientSecret=$$v},expression:"clientSecret"}})],1)]:_vm._e(),(_vm.showApiKey)?[_c('p',{staticClass:"qp-secondary-title"},[_vm._v(" "+_vm._s(_vm.$t("merchantIntegrationInformation.api-key"))+" ")]),_c('div',{staticClass:"qp-flex-row-wrap"},[_c('v-text-field',{staticClass:"qp-readonly-text-field qp-default-font-size",attrs:{"id":"api-key","hide-details":"","readonly":"","filled":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-tooltip',{attrs:{"color":"transparent","top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_vm._t("activator",function(){return [_c('v-btn',_vm._b({attrs:{"icon":"","color":"transparent"}},'v-btn',attrs,false),[_c('v-icon',{attrs:{"size":"34"},on:{"click":function($event){_vm.copy(
                        $event,
                        'api-key',
                        visible => (_vm.showApiKeyTooltip = visible)
                      )}}},[_vm._v("$copy-icon")])],1)]},{"on":on,"attrs":attrs})]}}],null,true),model:{value:(_vm.showApiKeyTooltip),callback:function ($$v) {_vm.showApiKeyTooltip=$$v},expression:"showApiKeyTooltip"}},[_c('span',{staticClass:"zip-tooltip auto-width"},[_vm._t("default",function(){return [_vm._v(" "+_vm._s(_vm.$t("merchantIntegrationInformation.copy"))+" ")]})],2)])]},proxy:true}],null,true),model:{value:(_vm.clientSecret),callback:function ($$v) {_vm.clientSecret=$$v},expression:"clientSecret"}})],1)]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }