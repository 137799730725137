var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-btn',_vm._g(_vm._b({staticClass:"zip-action-button",class:[
    ..._vm.cssClassesNormalized,
    {
      'text-capitalize': _vm.capitalize,
      'text-none': !_vm.capitalize,
    },
  ],attrs:{"disabled":_vm.disabled,"text":"","ripple":false,"href":_vm.href},on:{"click":_vm.emitClick}},'v-btn',_vm.attrs,false),_vm.on),[(_vm.hasIconSlot)?_c('v-icon',{staticClass:"button-icon"},[_vm._t("icon")],2):_vm._e(),(!_vm.loading)?_vm._t("default"):_c('v-progress-circular',{attrs:{"indeterminate":""}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }