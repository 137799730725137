
import { orderPageName } from '@/router';
import { InvoiceDetail, InvoiceLineItem, InvoiceTransaction } from '@/store/statement/statement-models';
import { DataTableHeader } from 'vuetify';
import StatementCsvDialog from './StatementCsvDialog.vue';
import Vue, { PropType } from 'vue';

const MaxReasonLength = 150;

export default Vue.extend({
  name: 'StatementTransactionOrders',
  components: {
    'statement-csv-dialog': StatementCsvDialog,
  },
  data: () => {
    return {
      orderPageName: orderPageName,
    };
  },
  props: {
    item: {
      type: Object as PropType<InvoiceLineItem>,
      required: true,
    },
    details: {
      type: Object as PropType<InvoiceDetail>,
      required: true,
    },
    showTax: {
      type: Boolean,
      required: true,
    },
    header: {
      type: Object as PropType<DataTableHeader>,
      required: false,
    },
    isDue: {
      type: Function as PropType<(type: InvoiceLineItem) => boolean>,
      required: true,
    },
  },
  computed: {
    isMobile(): any {
      return this.$vuetify.breakpoint.xs;
    },
  },
  methods: {
    getTransactionRows(item: InvoiceLineItem) {
      if (item.hiddenRowCount &&
        item.hiddenRowCount > 0 &&
        item.transactions.length > 0 &&
        !item.transactions[item.transactions.length - 1].shouldDisplayHiddenRowMessage) {
        item.transactions[item.transactions.length - 1].shouldDisplayHiddenRowMessage = true;
      }
      return item.transactions;
    },
    getOrderIdentifier(row: InvoiceTransaction) {
      return row.orderNumber ?? row.id;
    },
    getTransactionReason(row: InvoiceTransaction) {
      if (!row.transactionReason || row.transactionReason.length <= MaxReasonLength) {
        return row.transactionReason;
      }
      return row.transactionReason.slice(0, MaxReasonLength - 3) + '...';
    }
  },
});
