
import eventBus from '@/event-bus';
import Vue from 'vue';
export default Vue.extend({
  name: 'HeaderNotification',
  props: {
    routeToDisplay: {
      type: String,
      required: true,
    },
    notificationTitle: {
      type: String,
      required: true,
    },
    notificationDescription: {
      type: String,
      required: true,
    },
    trackingEventName: {
      type: String,
      required: false,
    },
  },
  mounted() {
    if (this.$refs['notificationBody']) {
      const ref = this.$refs['notificationBody'] as HTMLElement;
      ref.getElementsByTagName('a').item(0)?.addEventListener('click', () => {
        eventBus.publishTrackClickEvent('Header Notification', { title: this.trackingEventName });
      });
    }
  },
});
