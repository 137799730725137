
import { Bar } from 'vue-chartjs/legacy'; // legacy is used in vue 2.
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, LineElement, CategoryScale, PointElement, LinearScale, } from 'chart.js';
import ZipTooltip from '../Tooltip.vue';
import Vue from 'vue';
import { PropType } from 'vue';

ChartJS.register(Title, Tooltip, Legend, BarElement, LineElement, CategoryScale, LinearScale, PointElement);

/*
Based on chartjs and its vue wrapper vue-chartjs
for documentation about chartjs see https://www.chartjs.org/
for documentation about vue-chartjs see https://vue-chartjs.org/
*/
export default Vue.extend({
  name: 'BarChart',
  components: {
    'tooltip': ZipTooltip,
    Bar: Bar,
  },
  props: {
    title: { type: String, required: true },
    labels: { type: Array as PropType<Array<string>>, required: true },
    datasets: { type: Array as PropType<Array<{ label: string; backgroundColor: string; data: Array<number>; }>>, required: true },
    loading: { type: Boolean, required: false, default: true },
    cssClasses: { type: Array as PropType<Array<string>>, required: false },
    tooltipText: { type: String, required: false },
    options: { required: false, default: null },
    verticalAxisTitle: { type: String, required: false },
    horizontalAxisTitle: { type: String, required: false },
  },
  data() {
    return {
      chartId: 'bar-chart',
      datasetIdKey: 'label',
      loadCount: 0,
    };
  },
  computed: {
    hasMenu(): any {
      return this.$slots.menu;
    },
    cssClassesNormalized(): any {
      return this.cssClasses?.join(' ');
    },
    chartOptions(): any {
      return this.options ?? BarChartStatic.createOptionsTemplate();
    },
    firstLoad(): any {
      return this.loadCount == 0 && this.loading;
    },
  },
  watch: {
    loading: function(newVal) {
      if (!newVal) {
        this.loadCount++;
      }
    },
  },
  async created() {
    ChartJS.defaults.color = this.$vuetify.theme.themes.light.scale?.toString() as string;
  }
});

export class BarChartStatic {
  static createOptionsTemplate(): any {
    return {
      responsive: true,
      maintainAspectRatio: false,
      indexAxis: 'y',
      plugins: {
        legend: {
          position: 'bottom',
          labels: {
            boxHeight: '4',
          }
        }
      },
    };
  }

  static createDatasetTemplate(vuetify: any, secondary = false): any {
    return {
      borderColor: secondary ? vuetify.theme.themes.light.secondary : vuetify.theme.themes.light.primary,
      backgroundColor: secondary ? vuetify.theme.themes.light.secondary : vuetify.theme.themes.light.primary,
    };
  }
}
