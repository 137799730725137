
import Vue from 'vue';
export default Vue.extend({
  name: 'Tooltip', // eslint-disable-line vue/multi-word-component-names
  props: {
    text: {
      default: undefined,
    },
    location: {
      default: 'bottom',
    },
    autoWidth: {
      type: Boolean,
      default: false,
    },
  },
});
