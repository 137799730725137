
import SearchIcon from '@/assets/images/search.svg?inline';
import DoubleDatePicker, { DoubleDatePickerStatic } from '@/components/controls/DoubleDatePicker.vue';
import InvoicesIcon from '@/assets/images/icons/sidebar/InvoicesIconLarge.svg?inline';
import configuration from '@/configuration';
import moment from 'moment-timezone';
import { TranslateResult } from 'vue-i18n';
import { Invoice, InvoiceStatusEnum } from '@/store/statement/statement-models';
import { applicationStore, authenticationStore, statementStore } from '@/store/store';
import { DataOptions, DataTableHeader } from 'vuetify';
import { statementDetailsPageName } from '@/router';
import TableFooter, { TableFooterStatic } from '../controls/TableFooter.vue';
import StatementCsvDialog from './StatementCsvDialog.vue';
import SearchView from '../layouts/SearchView.vue';
import Tooltip from '../controls/Tooltip.vue';
import ComboBox from '../controls/ComboBox.vue';
import Vue from 'vue';

export default Vue.extend({
  name: 'Statements', // eslint-disable-line vue/multi-word-component-names
  components: {
    'search-view': SearchView,
    'double-date-picker': DoubleDatePicker,
    'invoices-icon': InvoicesIcon,
    'search-icon': SearchIcon,
    'table-footer': TableFooter,
    'statement-csv-dialog': StatementCsvDialog,
    'tooltip': Tooltip,
    'combobox': ComboBox,
  },
  data() {
    return {
      firstLoad: true,
      statusFilter: undefined as (InvoiceStatusEnum | undefined),
      tableOptions: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['openDate'],
        sortDesc: [true], // default date desc
        groupBy: [],
        groupDesc: [],
        multiSort: false, // ability to sort by more than one column at once
        mustSort: true, // table must be sorted by at least one column
      } as DataOptions,
      startDate: moment.utc().startOf('month').toISOString(),
      endDate: moment.utc().endOf('month').toISOString(),
      selectedStatus: undefined as (InvoiceStatusEnum | undefined),
      statementDetailsPageName: statementDetailsPageName,
    };
  },
  computed: {
    showStatementStatus(): any {
      return configuration.statements.showStatementStatus;
    },
    loading(): boolean {
      return statementStore.loadingStatements;
    },
    notificationFeatureFlag(): boolean {
      return configuration.featureFlags.transactionNotification;
    },
    footerOptions(): any {
      return TableFooterStatic.getDefaultOptions(key => this.$t(key));
    },
    statementArray(): Invoice[] {
      return statementStore.statementArray;
    },
    statementCount(): number {
      return statementStore.statementCount;
    },
    tableHeaders(): DataTableHeader[] {
      const statusColumn: DataTableHeader[] = [
        {
          text: this.$t('statements.status').toString(),
          value: 'status',
          sortable: false,
        },
      ];

      return [
        {
          text: this.$t('statements.id').toString(),
          value: 'invoiceId',
          sortable: false,
        },
        {
          text: this.$t('statements.openDate').toString(),
          value: 'openDate',
        },
        {
          text: this.$t('statements.closeDate').toString(),
          value: 'closeDate',
        },
        ...(this.showStatementStatus ? statusColumn : []),
        {
          text: this.$t('statements.payoutDate').toString(),
          value: 'payoutDate',
          sortable: false,
        },
        {
          text: this.$t('statements.currencyCode').toString(),
          value: 'currencyCode',
          sortable: false,
          align: 'center',
        },
        // ...(configuration.isVatEnabled ? vatColumns : []),
        {
          text: this.$t('statements.amount').toString(),
          value: 'amount',
          sortable: false,
          align: 'end',
        },
        {
          text: this.$t('statements.download').toString(),
          value: 'download',
          sortable: false,
          align: 'center',
        }
      ];
    },
    currentLocale(): string {
      return applicationStore.currentLocale.locale;
    },
    currentIdentity(): any {
      return authenticationStore.currentIdentity;
    },
    statusFilterText(): TranslateResult {
      return this.$t('statements.status-filter');
    },
    statusItems(): any {
      // Exclude the unknown status
      const values: string[] = [];
      for (const n in InvoiceStatusEnum) {
        if (typeof InvoiceStatusEnum[n] === 'string' && n !== InvoiceStatusEnum[InvoiceStatusEnum.Draft]) {
          values.push(n);
        }
      }
      return values.map(value => {
        return { value: InvoiceStatusEnum[value], text: this.displayStatus(InvoiceStatusEnum[value]) }
      });
    },
  },
  watch: {
    async currentIdentity() {
      await this.updateSearch();
    },
    async tableOptions() {
      await this.updateTable();
    },
  },
  async mounted() {
    // Check query params
    if (this.$route.query.page && !isNaN(parseInt(this.$route.query.page as string, 10))) {
      this.tableOptions.page = parseInt(this.$route.query.page as string, 10);
    }
    if (this.$route.query.pageSize && !isNaN(parseInt(this.$route.query.pageSize as string, 10))) {
      this.tableOptions.itemsPerPage = parseInt(this.$route.query.pageSize as string, 10);
    }
    if (this.$route.query.statusFilter && !isNaN(parseInt(this.$route.query.statusFilter as string, 10))) {
      this.statusFilter = parseInt(this.$route.query.statusFilter as string, 10);
    }
    if (this.$route.query.desc) {
      this.tableOptions.sortDesc[0] = this.$route.query.desc === 'true';
    }
    if (this.$route.query.sortBy) {
      this.tableOptions.sortBy[0] = this.$route.query.sortBy as string;
    }

    // Disable skeleton loader
    this.firstLoad = false;
  },
  methods: {
    /**
     * Display the date, in Utc using the configuration.dateFormat
     */
    displayDateUtc(date?: Date): string {
      if (date) {
        return moment(date).utc().locale(this.currentLocale).format(configuration.dateFormat);
      }
      return '';
    },
    displayStatusString(statusString: string): string {
      return this.displayStatus(InvoiceStatusEnum[statusString]);
    },
    displayStatus(status: InvoiceStatusEnum): string {
      switch (status) {
        case InvoiceStatusEnum.Ready:
          return this.$t("statements.status-ready").toString();
        case InvoiceStatusEnum.InProgress:
          return this.$t("statements.status-in-progress").toString();
        case InvoiceStatusEnum.Settled:
          return this.$t("statements.status-settled").toString();
        case InvoiceStatusEnum.Failed:
          return this.$t("statements.status-failed").toString();
        case InvoiceStatusEnum.Void:
          return this.$t("statements.status-void").toString();
        default:
          return '';
      }
    },
    displayInvoiceNumber(invoice) {
      return invoice.invoiceNumber ?? invoice.invoiceId;
    },
    async updateSearch() {
      // Update invoices list as identity changes
      await this.searchStatements();
    },
    async updateTable() {
      await this.searchStatements();
    },
    async paginate(newPage) {
      this.tableOptions.page = newPage;
      await this.searchStatements();
    },
    onStatusFilterChanged(value: InvoiceStatusEnum) {
      this.statusFilter = value;

      this.searchStatements(1);
    },
    async searchStatements(pageNumber: number | null = null) {
      this.tableOptions.page = pageNumber || this.tableOptions.page;
      const { page, itemsPerPage, sortDesc, sortBy } = this.tableOptions;

      await statementStore.searchStatements({
        options: {
          status: this.statusFilter,
          page,
          pageSize: itemsPerPage,
          orderBy: sortDesc[0] ? `${sortBy[0]},true` : `${sortBy[0]},false`,
          startDate: this.startDate,
          endDate: this.endDate,
        },
        apiEndDate: DoubleDatePickerStatic.getInclusiveEndDate(this.endDate),
      });
      // Must use history push to ensure the page is not reloaded
      history.pushState({}, '', `?status=${this.statusFilter}&page=${this.tableOptions.page}&pageSize=${this.tableOptions.itemsPerPage}&desc=${this.tableOptions.sortDesc[0]}$sortBy=${this.tableOptions.sortBy[0]}`);
    },
    async startDateUpdated(newDate: string) {
      this.startDate = moment.utc(newDate, "YYYY-MM-DD").startOf("day").toISOString();
      await this.searchStatements(1);
    },
    async endDateUpdated(newDate: string) {
      this.endDate = moment.utc(newDate, "YYYY-MM-DD").endOf("day").toISOString();
      await this.searchStatements(1);
    },
  },
});
