
import ScalarChart from '../../controls/charts/ScalarChart.vue';
import {
  AggregatedCustomer,
  ComparableAggregatedResult,
  CustomerDailyMetricsTypeEnum,
  CustomerDataTypeEnum,
} from '@/store/insights/insights-models';
import InsightsStore from '@/store/insights/insights-store';
import Vue, { PropType } from 'vue';
export default Vue.extend({
  name: 'CustomerRateChart',
  components: {
    'scalar-chart': ScalarChart,
  },
  props: {
    data: {
      type: Object as PropType<ComparableAggregatedResult>,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      returningRate: 0,
      totalCustomerCount: 0,
      calculating: false,
    };
  },
  computed: {
    returningCustomerTitle(): any {
      return this.$t('insights.chart-title-returning-customer-rate');
    },
    newCustomerTitle(): any {
      return this.$t('insights.chart-title-new-customer-rate');
    },
  },
  watch: {
    'data': async function(oldVal, newVal) {
      await this.onDataChange(oldVal, newVal);
    },
  },
  methods: {
    formatPercentage(value: number, total: number): string {
      return total === 0 ? this.$t('insights.chart-not-available').toString() : `${isNaN(value) ? 0 : value.toFixed(2)}%`;
    },
    getValuesByCategory(
      categories: string[],
      values: number[][],
      category: CustomerDailyMetricsTypeEnum
    ) {
      const index = categories.indexOf(CustomerDailyMetricsTypeEnum[category]);
      if (index === -1) {
        return [];
      }
      return values[index];
    },
    async onDataChange(newVal: ComparableAggregatedResult) {
      this.calculating = true;
      const result = newVal?.current;

      if (!result || !result.data) {
        return;
      }
      const { categories, values } = await InsightsStore.createKeyValueArraysByCategory(result.data as AggregatedCustomer[], CustomerDataTypeEnum.DailyMetrics, result.startDate, result.endDate, newVal.reportingPeriod);
      const newCustData = this.getValuesByCategory(categories, values, CustomerDailyMetricsTypeEnum.NewCustomerCount);
      const returnCustData = this.getValuesByCategory(categories, values, CustomerDailyMetricsTypeEnum.ReturningCustomerCount);

      let returnCustCount = 0, totalCustCount = 0;
      for (let i = 0; i < newCustData.length; i++) {
        totalCustCount += (newCustData[i] + returnCustData[i]);
        returnCustCount += returnCustData[i];
      }

      this.returningRate = totalCustCount === 0 ? 0 : returnCustCount / totalCustCount * 100;
      this.totalCustomerCount = totalCustCount;

      this.calculating = false;
    },
  },
});
