
import BasicLayout from '@/components/layouts/BasicLayout.vue';
import Payments from '@/components/payments/Payments.vue';
import Vue from 'vue';

export default Vue.extend({
  name: 'PaymentsPage',
  components: {
    'basic-layout': BasicLayout,
    'payments': Payments,
  }
})
