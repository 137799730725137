
import Vue from 'vue';
import configuration from '@/configuration';
import logger from '@/logger';
import { merchantStore } from '@/store/store';

import ThemesImage from '@/assets/images/mss/shopify-integration/Themes.svg?inline';
import ThemeLiquidImage from '@/assets/images/mss/shopify-integration/ThemeLiquid.svg?inline';
import CodeScreenshotImage from '@/assets/images/mss/shopify-integration/CodeScreenshot.svg?inline';
import WidgetImage from '@/assets/images/mss/shopify-integration/Widget.svg?inline';
import MobileNumberImage from '@/assets/images/mss/shopify-integration/MobileNumber.svg?inline';
import SelectAccountImage from '@/assets/images/mss/shopify-integration/SelectAccount.svg?inline';
import EnterIdImage from '@/assets/images/mss/shopify-integration/EnterId.svg?inline';
import EnterTestIdImage from '@/assets/images/mss/shopify-integration/EnterTestId.svg?inline';
import MerchantIntegrationInformation from '@/components/common/MerchantIntegrationInformation.vue';
import { mdiClockOutline, mdiInformationOutline, mdiTriangle } from '@mdi/js';
import ActionButton from '@/components/controls/ActionButton.vue';

// note that we need to keep this useless escape on / because without it eslint returns a false positive
// eslint-disable-next-line no-useless-escape
const widgetCode = '{% comment %} <!-- Insert Zip Widget Lite --> {% endcomment %}\n <script\n src="https://widgets.quadpay.com/quadpay-shopify.js" defer="defer"><\/script>';

export default Vue.extend({
  name: 'ShopifyIntegration',
  components: {
    ThemesImage,
    ThemeLiquidImage,
    CodeScreenshotImage,
    WidgetImage,
    MobileNumberImage,
    SelectAccountImage,
    EnterIdImage,
    EnterTestIdImage,
    "action-button": ActionButton,
    'merchant-integration-info': MerchantIntegrationInformation,
  },
  data() {
    return {
      loading: false,
      shouldUseNewVerbiage: true,
      clock: mdiClockOutline as string,
      info: mdiInformationOutline as string,
      triangle: mdiTriangle as string,
      youtubeLink: configuration.links.shopifyIntegrationVideoLink as string,
      code: widgetCode,
      showSteps: {
        gateway: false,
        widget: false,
      } as any,
    };
  },
  computed: {
    companyName(): string {
      return configuration.company.name;
    },
    website(): string {
      const storedWebsite = merchantStore.onboardingMerchant?.businessInfo?.website || '';
      
      // Add on / if necessary
      const websiteWithtrailingSlash = (storedWebsite[storedWebsite.length - 1] === '/') ? `${storedWebsite}` : `${storedWebsite}/`;
      
      //Add HTTPS protocol to ensure a non-relative link is generated
      const websiteWithProtocolAndTrailingSlash = `https://${websiteWithtrailingSlash.replace('https://', '').replace('http://', '')}`
      return websiteWithProtocolAndTrailingSlash;
    },
    themesLink(): string {
      const path = 'admin/themes';
      return `${this.website}${path}`;
    },
    alternativeProvidersLink(): string {
      const path = 'admin/settings/payments/alternative-providers/1052742';
      return `${this.website}${path}`;
    },
  },
  async mounted() {
    if (this.$route?.params?.showWidgetStep) {
      await this.toggleShowStep('widget');
      document.getElementById('step-widget-container')?.scrollIntoView();
    } else if (this.$route?.params?.showGatewayStep) {
      await this.toggleShowStep('gateway');
      document.getElementById('step-gateway-container')?.scrollIntoView();
    }
  },
  methods: {
    toggleShowStep(key: string) {
      this.showSteps[key] = !this.showSteps[key];
    },
    copy(event: ClipboardEvent, elementId: string) {
      const elementToCopy = document.getElementById(elementId);
      if (!elementToCopy) {
        return;
      }
      (elementToCopy as any).select();

      try {
        document.execCommand('copy');
        // TODO better UX for successful copy
        logger.info('Copy successful');
      } catch (err) {
        alert(this.$t("shopifyIntegration.unable-copy-alert"));
      }
      // Unselect the range
      (window as any).getSelection().removeAllRanges();
    },
  },
});
