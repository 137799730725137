
import ECharts from 'vue-echarts';
import Tooltip from '../Tooltip.vue';
import Vue from 'vue';
import { PropType } from 'vue';

/*
 * To use this chart, make sure 'loadMapFeature()' of the corresponding map (e.g. USA) has been called.
 */

/*
Based on echarts and its vue wrapper vue-echarts
for documentation about echarts see https://echarts.apache.org/en/index.html
for documentation about vue-echarts see https://github.com/ecomfe/vue-echarts

Geo US map chart is based on https://echarts.apache.org/examples/en/editor.html?c=map-usa
*/
export default Vue.extend({
  name: 'MapChart',
  components: {
    echarts: ECharts,
    'tooltip': Tooltip,
  },
  props: {
    title: { type: String, required: true },
    options: { type: Object as PropType<any | undefined>, required: false },
    loading: { type: Boolean, required: false, default: true },
    cssClasses: { type: Array as PropType<Array<string>>, required: false },
    tooltipText: { type: String, required: false },
  },
  data() {
    return {
      loadCount: 0,
    };
  },
  computed: {
    firstLoad(): any {
      return this.loadCount == 0 && this.loading;
    },
  },
  watch: {
    loading: function(newVal) {
      if (!newVal) {
        this.loadCount++;
      }
    },
  },
});
