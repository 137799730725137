
import DisputeCsvDialog from '@/components/disputes/DisputeCsvDialog.vue';
import Disputes from '@/components/disputes/Disputes.vue';
import BasicLayout from '@/components/layouts/BasicLayout.vue';
import { DisputeSearchOptions } from '@/store/dispute/dispute-models';
import { disputeStore } from '@/store/store';
import Vue from 'vue';

export default Vue.extend({
  name: 'DisputesPage',
  components: {
    'basic-layout': BasicLayout,
    'disputes': Disputes,
    'dispute-csv-dialog': DisputeCsvDialog
  },
  computed: {
    lastSearchOptions(): DisputeSearchOptions {
      return disputeStore.lastSearchOptions;
    },
  },
});
