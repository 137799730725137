import LogMethod from '@/decorators/logger-decorator';
import {
  Dispute,
  DisputeDetail,
  DisputeEvidence,
  DisputeEvidenceFileCategoryEnum,
  DisputeProductServiceTypeEnum,
  DisputeReasonEnum,
  DisputeDetailResponse,
  DisputeSearchOptions,
  DisputeSearchResult,
  DisputeReconciledStatusEnum,
  DisputeNewCountResult,
  DisputeEnabledResult,
} from '@/store/dispute/dispute-models';
import store from '@/store/store';
import {
  config,
  Action,
  Module,
  VuexModule,
  Mutation,
} from 'vuex-module-decorators';
import { deleteObject, downloadFile, getBlob, getObject, postFile, postObject, RequestErrorModeEnum, RequestUrl } from '../store-requests';

// Set rawError for all Actions in module to true
config.rawError = true;

const DefaultDisputeArray = [];
const DefaultDisputeCount = 0;
const DefaultLoadingDisputes = true;
const DefaultLoadingDisputeDetails = true;
const DefaultLoadingDisputeEvidence = true;
const DefaultSearchOptions = { page: 0, pageSize: 0, startDate: '', endDate: '' };
const DefaultDisputeDetails = {
  id: '',
  disputeId: '',
  disputedAmount: 0,
  currency: '',
  isChargeRefundable: false,
  disputedOn: new Date(),
  status: DisputeReconciledStatusEnum.needsResponse,
  reason: DisputeReasonEnum.general,
  metadata: {
    accepted: false,
  },
  customerFirstName: '',
  customerLastName: '',
  paymentPlanId: '',
  orderAmount: 0,
  currencyCode: '',
} as DisputeDetail;

export function getCategoryStrings() {
  const arr: string[] = [];
  for (const n in DisputeEvidenceFileCategoryEnum) {
      if (typeof DisputeEvidenceFileCategoryEnum[n] === 'number') {
          arr.push(n);
      }
  }
  return arr;
}

/**
 * The dispute store is responsible for managing merchant dispute data and business logic
 */
@Module({
  name: 'dispute',
  namespaced: true,
  store,
})
export default class DisputeStore extends VuexModule {
  disputeArray: Dispute[] = DefaultDisputeArray;
  disputeCount: number = DefaultDisputeCount;
  disputeDetails: DisputeDetail = DefaultDisputeDetails;
  loadingDisputes: boolean = DefaultLoadingDisputes;
  loadingDisputeDetails: boolean = DefaultLoadingDisputeDetails;
  loadingDisputeEvidence: boolean = DefaultLoadingDisputeEvidence;
  lastSearchOptions: DisputeSearchOptions = DefaultSearchOptions;

  @Action
  async getNewDisputeCount(): Promise<number | undefined> {
    const url = {
      service: 'disputes/new-count',
      query: { }
    };

    const result: DisputeNewCountResult = await getObject({
      url,
      options: {
        dataType: `count of new disputes`
      }
    });

    return result?.count;
  }

  @Action
  async getFallbackFeatureFlag(): Promise<boolean> {
    const result: DisputeEnabledResult = await getObject({
      url: {
        service: 'disputes/enabled'
      }
    })

    return result.isEnabled;
  }

  /**
   * Get Merchant Disputes by merchantId
   */
  @Action
  async searchDisputes(searchOptions: { options: DisputeSearchOptions, apiEndDate: string }): Promise<any> {
    this.setLoadingDisputes(true);
    // record last search options
    this.setLastSearchOptions(searchOptions.options);

    // We want to capture the UI search values separately from what actually gets searched.
    // To do this, take the end date from the caller and overwrite its value in a clone used only for searching.
    const searchOptionsWithUpdatedEndDate: DisputeSearchOptions = {
      ...searchOptions.options,
      endDate: searchOptions.apiEndDate
    };

    const url = {
      service: 'disputes/search',
      query: searchOptionsWithUpdatedEndDate
    };

    const result = await getObject<DisputeSearchResult>({
      url,
      options: {
        dataType: "disputes",
        errorMode: RequestErrorModeEnum.showErrorModal
      }
    });
    if (result) {
      this.setDisputeSearchResult(result);
    }
    this.setLoadingDisputes(false);
  }

  /**
    * Get Dispute details by disputeId
    * @param disputeId  
    */
  @Action
  async loadDisputeDetails(disputeId: string): Promise<any> {
    this.setLoadingDisputeDetails(true);
    this.setLoadingDisputeEvidence(true);

    const url = {
      service: `disputes/${disputeId}`,
    };

    const result = await getObject<DisputeDetailResponse>({
      url,
      options: {
        dataType: 'dispute details',
        errorMode: RequestErrorModeEnum.showErrorModal,
      },
    });
    
    this.setDisputeDetailsResult(result);

    this.setLoadingDisputeEvidence(false);
    this.setLoadingDisputeDetails(false);
  }

  /**
    * Provide (update) dispute evidence without submitting to bank (staging).
    */
  @Action
  async provideDisputeEvidence(args: { disputeId: string, evidence: DisputeEvidence }): Promise<any> {
    this.setLoadingDisputeEvidence(true);

    const url = {
      service: `disputes/${args.disputeId}/provide-evidence`,
    };

    const result = await postObject<DisputeDetailResponse>({
      url,
      options: {
        dataType: 'dispute evidence (update mode)',
      },
    }, args.evidence);

    this.setDisputeDetailsResult(result);
    this.setLoadingDisputeEvidence(false);
  }

  /**
    * Submit dispute evidence to bank. Note this freezes the dispute and no more changes can be made.
    */
  @Action
  async submitDisputeEvidence(args: { disputeId: string, evidence: DisputeEvidence }): Promise<any> {
    this.setLoadingDisputeEvidence(true);

    const url = {
      service: `disputes/${args.disputeId}/submit-evidence`,
    };

    const result = await postObject<DisputeDetailResponse>({
      url,
      options: {
        dataType: 'dispute evidence (submit mode)',
      },
    }, args.evidence);

    this.setDisputeDetailsResult(result);
    this.setLoadingDisputeEvidence(false);
  }

  /**
    * Upload an evidence file for a dispute.
    */
  @Action
  async uploadEvidenceFile(args: { disputeId: string, category: DisputeEvidenceFileCategoryEnum, file: File}): Promise<any> {
    this.setLoadingDisputeEvidence(true);

    const url = {
      service: `disputes/${args.disputeId}/file/${args.category}`,
    };

    const result =
      await postFile<DisputeDetailResponse>(
        {
          url,
          options: {
            dataType: {
              description: `dispute evidence file for ${args.category}`,
              action: 'uploading',
            },
          },
        },
        args.file
      );

    this.setDisputeDetailsResult(result);
    this.setLoadingDisputeEvidence(false);
  }

  /**
  * Delete an evidence file from a dispute.
  */
  @Action
  async deleteEvidenceFile(args: { disputeId: string, category: DisputeEvidenceFileCategoryEnum }): Promise<any> {
    this.setLoadingDisputeEvidence(true);

    const url = {
      service: `disputes/${args.disputeId}/file/${args.category}`,
    };

    const result =
      await deleteObject<DisputeDetailResponse>({
        url,
        options: {
          dataType: `dispute evidence file for ${args.category}`,
        },
      });
    
    this.setDisputeDetailsResult(result);
    this.setLoadingDisputeEvidence(false);
  }

  /**
  * Download an evidence file from a dispute.
  */
  @Action
  async downloadEvidenceFile(args: { disputeId: string, categoryOrFileId: DisputeEvidenceFileCategoryEnum | string, filename: string }): Promise<boolean> {
    const url = {
      service: `disputes/${args.disputeId}/file/${args.categoryOrFileId}`,
    };

    return await downloadFile(args.filename, {
      url,
      options: {
        dataType: `dispute evidence file ${args.categoryOrFileId}`,
        errorMode: RequestErrorModeEnum.showErrorModal
      }
    });
  }

  @Action
  async getEvidenceFileBlob(args: { disputeId: string, categoryOrFileId: DisputeEvidenceFileCategoryEnum | string }): Promise<Blob> {
    const url = {
      service: `disputes/${args.disputeId}/file/${args.categoryOrFileId}`,
    };

    return await getBlob({
      url,
      options: { errorMode: RequestErrorModeEnum.returnWithError }
    });
  }

  static getDisputesCsvByDateRangeUrl(query: any): RequestUrl {
    const url: RequestUrl = {
      service: 'disputes/date-range/csv',
      query
    }
    return url;
  }

  @Action
  async closeDispute(disputeId: string) {
    this.setLoadingDisputeEvidence(true);
    
    const url: RequestUrl = {
      service: `disputes/${disputeId}/close`
    }

    const result = await postObject<DisputeDetailResponse>({
      url,
      options: {
        dataType: {
          description: 'dispute',
          action: 'closing',
        },
      },
    });
    this.setDisputeDetailsResult(result);
    this.setLoadingDisputeEvidence(false);
  }

  @Mutation
  setLastSearchOptions(searchOptions: DisputeSearchOptions) {
    this.lastSearchOptions = searchOptions;
  }

  @Mutation
  setLoadingDisputes(bool: boolean) {
    this.loadingDisputes = bool;
  }

  @Mutation
  setLoadingDisputeDetails(bool: boolean) {
    this.loadingDisputeDetails = bool;
  }

  @Mutation
  setLoadingDisputeEvidence(bool: boolean) {
    this.loadingDisputeEvidence = bool;
  }

  @Mutation
  setDisputeSearchResult(searchResult: DisputeSearchResult) {
    const { value, total } = searchResult;
    this.disputeArray = value;
    this.disputeCount = total;
  }

  @Mutation
  setDisputeDetailsResult(result: DisputeDetailResponse) {
    if (!result || !result.disputeDetail) {
      this.disputeDetails = DefaultDisputeDetails;
      return;
    }

    const dispute = result.disputeDetail;
    if (dispute.evidence) {
      dispute.evidence.productServiceType = DisputeStore.fixupEnum(DisputeProductServiceTypeEnum, dispute.evidence.productServiceType);
    }

    this.disputeDetails = dispute;
  }

  static fixupEnum<E, K extends string>(enumDef: { [key in K]: E }, enumValue: any): E {
    if (typeof enumValue === 'string' || enumValue instanceof String) {
      return enumDef[enumValue as K] as E;
    }
    return enumValue;
  }

  @Mutation
  @LogMethod
  reset() {
    this.disputeArray = DefaultDisputeArray;
    this.disputeCount = DefaultDisputeCount;
    this.disputeDetails = DefaultDisputeDetails;
    this.loadingDisputes = DefaultLoadingDisputes;
    this.loadingDisputeDetails = DefaultLoadingDisputeDetails;
    this.loadingDisputeEvidence = DefaultLoadingDisputeEvidence;
    this.lastSearchOptions = DefaultSearchOptions;
  }
}

