
import { userStore } from "@/store/store";
import { DataOptions, DataTableHeader } from "vuetify";
import UserPermissionsDialog from "./UserPermissionsDialog.vue";
import UserIcon from "@/assets/images/icons/sidebar/UserIcon.svg?inline";
import { toRelativeDateTime } from "@/datetime";
import SearchView from "../layouts/SearchView.vue";
import RemoveUserDialog from "./RemoveUserDialog.vue";
import Vue from 'vue';

export default Vue.extend({
  // eslint-disable-next-line
  name: 'Users',
  components: {
    "user-permissions-dialog": UserPermissionsDialog,
    "user-icon": UserIcon,
    "search-view": SearchView,
    "remove-user-dialog": RemoveUserDialog,
  },
  props: {
    searchTerms: {
      type: String,
      required: true,
      default: '',
    },
  },
  data() {
    return {
      firstLoad: true,
      selectedUserEmail: "",
      loading: false,
      tableOptions: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ["lastLogin"],
        sortDesc: [false],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: true,
      } as DataOptions,
    };
  },
  computed: {
    currentUserEmail(): any {
      return userStore.loggedInUser?.email ?? "";
    },
    users(): any {
      return userStore.users;
    },
    userCount(): any {
      return userStore.userCount;
    },
    tableHeaders(): DataTableHeader[] {
      return [
        {
          text: "",
          value: "picture",
          sortable: false,
        },
        {
          text: "Username",
          value: "userName",
          sortable: true,
        },
        {
          text: "Email",
          value: "email",
          sortable: true,
        },
        {
          text: "Last active",
          value: "lastLogin",
          sortable: true,
        },
        {
          text: "",
          value: "edit",
          sortable: false,
        },
        {
          text: "",
          value: "remove",
          sortable: false,
        },
      ];
    },
  },
  watch: {
    async tableOptions() {
      await this.updateTable();
    },
    async searchTerms() {
      await this.updateTable();
    },
  },
  async mounted() {
    this.loadUserSearchOptionsFromQuery();
    await this.searchUsers();
  },
  methods: {
    loadUserSearchOptionsFromQuery() {
      const { page, pageSize, sortBy, desc } = this.$route.query;

      if (page && !isNaN(parseInt(page as string))) {
        this.tableOptions.page = parseInt(page as string);
      }

      if (pageSize && !isNaN(parseInt(pageSize as string))) {
        this.tableOptions.itemsPerPage = parseInt(pageSize as string);
      }

      if (sortBy) {
        this.tableOptions.sortBy = [sortBy as string];
      }

      if (desc && (desc === 'true')) {
        this.tableOptions.sortDesc = [true];
      }
    },
    async searchUsers(pageNumber: number | null = null) {
      this.loading = true;
      await userStore.getMerchantUsers({
        search: this.searchTerms,
        page: pageNumber || this.tableOptions.page,
        pageSize: this.tableOptions.itemsPerPage,
        sortBy: this.tableOptions.sortBy[0],
        sortDesc: this.tableOptions.sortDesc[0],
      });
      this.firstLoad = false;
      this.loading = false;
    },
    displayRelativeDate(date?: Date): string {
      return toRelativeDateTime(date) || this.$t("users.no-login").toString();
    },
    async updateTable() {
      await this.searchUsers();
      this.setUsersSearchParams();
    },
    setUsersSearchParams() {
      const url = new URL(window.location.href);

      const params = new URLSearchParams("");

      if (this.searchTerms) {
        params.set('search', this.searchTerms as string);
      }
      params.set("page", this.tableOptions.page.toString());
      params.set("pageSize", this.tableOptions.itemsPerPage.toString());
      params.set('desc', this.tableOptions.sortDesc[0].toString());
      params.set('sortBy', this.tableOptions.sortBy[0]);

      url.search = "" + params;

      // Must use history push to ensure the page is not reloaded
      history.pushState({}, "", url.toString());
    },
  },
});
