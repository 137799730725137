import configuration from "@/configuration";
import LogMethod from "@/decorators/logger-decorator";
import store, { authenticationStore, featureStore, merchantStore } from "@/store/store";
import {
  config,
  Action,
  Module,
  Mutation,
  VuexModule
} from "vuex-module-decorators";
import {
  BusinessInfo,
  UpdateBusinessInfoCommand,
  SubmitBusinessInfoCommand,
  UpdatePrimaryContactCommand,
  Address,
  BusinessInfoResult
} from "@/store/onboarding/onboarding-models";
import { getObject, postObject, putObject } from "../store-requests";

// Set rawError for all Actions in module to true
config.rawError = true;

// ZIP onboarding API Url
const onboardingApiUrl = `${configuration.links.apiDomain}${configuration.links.apiPath}/`;

const DefaultBusinessInfo: BusinessInfo = {
  firstName: '',
  lastName: '',
  businessEmail: '',
  businessPhoneNumber: '',
  businessName: '',
  legalName: '',
  annualSalesMax: 0,
  businessAddress: {
    line1: '',
    line2: '',
    state: '',
    city: '',
    postCode: '',
    country: ''
  } as Address
};

/**
 * The onboarding store is responsible for managing onboarding data and business logic
 */
@Module({
  name: "onboarding",
  namespaced: true,
  store
})
export default class OnboardingStore extends VuexModule {
  businessInfo: BusinessInfo = DefaultBusinessInfo;

  get shouldMerchantIntegrationInstructionsBeSkipped(): boolean {
    // Merchants in these tiers are taken care of by the Integrations Team, and so should not be shown integration instructions.
    if(["whale", "enterprise", "tier 1", "tier 2"].includes(merchantStore.merchantTier.toLowerCase())) {
      return true;
    }

    // Feature flag used in case the tier data is not available, but a merchant shouldn't be shown integration instructions
    return featureStore.skipOnboardingIntegrationForMerchant;
  }

  get isOnboarding(): boolean {
    if(!featureStore.isNewMerchantComplianceFlow) {
      // Can't be onboarding if the feature flag isn't turned on
      return false;
    }

    // For merchants that never underwent the onboarding process, always enable access to the settings page.
    // For those that have gone through the onboarding process, only enable if they have provided business info (e.g. name, address)
    if(!merchantStore.isOnboardingMerchant) {
      return false;
    }

    // Onboarded merchants shouldn't see the onboarding tab
    if(merchantStore.isOnboardedMerchant) {
      return false;
    }

    return (
      !merchantStore.isDueDiligenceApproved ||
      !merchantStore.isOnboardingBusinessInfoSubmitted ||
      !merchantStore.isOnboardingIntegrationCompleted
    );
  }

  /**
   * Get Onboarding business info
   */
  @Action
  async getBusinessInfo(): Promise<any> {
    const merchantId = authenticationStore.merchantId;
    const url = `${onboardingApiUrl}${merchantId}/onboarding/business-info`;

    const result = await getObject<BusinessInfoResult>({
      url: url,
      options: {
        dataType: "onboarding"
      }
    });

    if (result) {
      this.setBusinessInfo(result.businessInfo);
      
    }
    return true;
  }

  /**
   * Update Merchant's existing business info
   */
  @Action
  async updateOnboardingBusinessInfo(
    updateBusinessInfoCommand: UpdateBusinessInfoCommand
  ) {
    const merchantId = authenticationStore.merchantId;
    const url = `${onboardingApiUrl}${merchantId}/onboarding/business-info`;
    const result = await postObject<BusinessInfoResult>(
      {
        url,
        options: {
          dataType: "onboarding business info"
        }
      },
      updateBusinessInfoCommand
    );

    if (result) {
      const updatedOnboardingMerchant = { ...result.businessInfo };
      this.setBusinessInfo(updatedOnboardingMerchant);
    }
  }

  /**
   * Submit Onboarding business info
   */
  @Action
  async submitOnboardingBusinessInfo(
    submitBusinessInfoCommand: SubmitBusinessInfoCommand
  ) {
    const merchantId = authenticationStore.merchantId;
    const url = `${onboardingApiUrl}${merchantId}/onboarding/business-info`;
    const result = await putObject<BusinessInfoResult>(
      {
        url,
        options: {
          dataType: "onboarding business info"
        }
      },
      submitBusinessInfoCommand
    );

    if (result) {
      const updatedOnboardingMerchant = { ...result.businessInfo };
      this.setBusinessInfo(updatedOnboardingMerchant);
    }
  }

  /**
   * Update Merchant's existing onboarding primary contact
   */
  @Action
  async updateOnboarding0PrimaryContact(
    updatePrimaryContactCommand: UpdatePrimaryContactCommand
  ) {
    const merchantId = authenticationStore.merchantId;
    const url = `${onboardingApiUrl}${merchantId}/onboarding/update-primary-contact`;
    const result = await postObject<BusinessInfoResult>(
      {
        url,
        options: {
          dataType: "onboarding primary contact"
        }
      },
      updatePrimaryContactCommand
    );

    if (result) {
      const updatedOnboardingMerchant = { ...result.businessInfo };
      this.setBusinessInfo(updatedOnboardingMerchant);
    }
  }


  @Mutation
  setBusinessInfo(businessInfo: BusinessInfo) {
    this.businessInfo = businessInfo;
  }

  @Mutation
  @LogMethod
  reset() {
    this.businessInfo = DefaultBusinessInfo;
  }
}
