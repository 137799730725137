
import ErrorImage from '@/assets/images/error.svg?inline';
import SimpleCopyrightFooter from '@/components/common/SimpleCopyrightFooter.vue';
import SimpleLogoHeader from '@/components/common/SimpleLogoHeader.vue';
import configuration from '@/configuration';
import { noop } from '@/router';
import Vue from 'vue';

/**
 * Component used to denote errors returned from the server.  It can be used
 * to convey a number of expected and unexpected error flows to our customers.
 */
export default Vue.extend({
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Error',
  components: {
    'error-image': ErrorImage,
    'simple-footer': SimpleCopyrightFooter,
    'simple-header': SimpleLogoHeader,
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    /**
     * Get the Error ID for the unhandled error to present to the user.  They can share
     * this with customer support to help the QP team track down what happened.
     */
    errorId(): string {
      return this.$route.params.errorId;
    },
    companyName(): string {
      return configuration.company.name;
    },
  },
  methods: {
    /**
     * Return to the previous page to attempt the action again.
     */
    tryAgain(event: Event) {
      event.preventDefault();

      // UI idempotency in case a user clicks more than once
      if (this.loading) {
        return;
      }

      this.loading = true;
      const routeName = this.$route.meta.backRouteName;
      if (routeName) {
        this.$router.push({ name: routeName }).catch(noop);
      } else {
        this.$router.go(-1);
      }
    },
  },
});
