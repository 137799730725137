
import Vue from 'vue';
export default Vue.extend({
  name: 'SearchView',
  components: {},
  props: {
    paddingless: {
      type: Boolean,
      required: false,
    },
  },
});
