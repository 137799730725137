// Datadog Real User Monitoring
import configuration from '@/configuration';
import { datadogRum, RumInitConfiguration } from '@datadog/browser-rum';
import eventBus from '@/event-bus';
import logger from '@/logger';
import packageInfo from '@/../package.json';
import { authenticationStore, merchantStore } from '@/store/store';

function initialize() {
  const allowList = configuration.serviceDomains.map((element) => new RegExp(`https://((?!${configuration.auth.domain}).*).${element}`));

  const config: RumInitConfiguration = {
    applicationId: configuration.datadog.applicationId,
    clientToken: configuration.datadog.clientToken,
    site: "us3.datadoghq.com",
    env: configuration.environmentName?.toLowerCase(),
    allowedTracingOrigins: allowList,
    version: packageInfo.version,
    sampleRate: 100,
    service: 'merchant-portal',
    silentMultipleInit: true,
    trackResources: true,
    trackLongTasks: true,
    trackUserInteractions: true,
    trackFrustrations: true,
    defaultPrivacyLevel: "mask-user-input",
  };
  datadogRum.init(config);
  datadogRum.startSessionReplayRecording();
}

function handleAuthenticated(subClaim: string, merchantId?: string) {
  datadogRum.addAction('User Authenticated', { subClaim, merchantId, platform: merchantStore.platform, tier: merchantStore.merchantTier });
}

function handleEmailVerification(verified: boolean, merchantId: string, reason: string) {
  if (verified) {
    datadogRum.addAction('MP User Email Verified Success', { merchantId });
  } else {
    datadogRum.addAction('MP User Email Verified Failed', { merchantId, reason });
  }
}

function handleError(error: Error, handled: boolean, id?: string) {
  const type = (error as any).constructor?.name ? (error as any).constructor.name : 'Error';
  datadogRum.addError(error, {
    qpErrorId: id,
    errorDescription: error.toString(),
    handled,
    type,
    qpMerchantId: authenticationStore.merchantId,
  });
}

function handleExistingUser(exists: boolean) {
  if (exists) {
    datadogRum.addAction('Existing User Received', { email: authenticationStore.userInfo.email, merchantId: authenticationStore.merchantId });
  } else {
    datadogRum.addAction('New User Received', { email: authenticationStore.userInfo.email });
  }
  datadogRum.addRumGlobalContext('Portal_Context', { email: authenticationStore.userInfo.email, merchantId: authenticationStore.merchantId })
}

function handleIntegrationTestResults(merchantId: string, success: boolean, widgetVerified: boolean, integrationVerified: boolean) {
  datadogRum.addAction('MSS Integration Test Results', {
    success,
    merchantId,
    widgetVerified,
    integrationVerified,
  });
}

function handleWebsiteUpdated() {
  datadogRum.addAction('MSS Website Url Updated', {
    merchantId: authenticationStore.merchantId,
  });
}

function handleMssDeclined(declineReason: string) {
  datadogRum.addAction('MSS Declined', {
    declineReason,
    merchantId: authenticationStore.merchantId,
    email: authenticationStore.userInfo.email,
  });
}

function handleMssStageCompleted(stage: string, attempts: number) {
  // Attempts for return from Stripe
  // if attempts = 0 there was no counting
  // else (attempts * 10) = number of seconds it took to complete
  datadogRum.addAction('MSS Stage Completed',
    { merchantId: authenticationStore.merchantId, stage, attempts });
}

function handleTrackAction(actionName: string, actionInfo?: Record<string, any>) {
  if (actionInfo) {
    datadogRum.addAction(`Action ${actionName}`, { ...actionInfo });
  } else {
    datadogRum.addAction(`Action ${actionName}`, {});
  }
}
function handleTrackClick(itemClicked: string, itemInfo?: Record<string, any>) {
  if (itemInfo) {
    datadogRum.addAction(`Click ${itemClicked}`, { ...itemInfo });
  } else {
    datadogRum.addAction(`Click ${itemClicked}`, {});
  }
}

function handleVerificationEmailResent() {
  datadogRum.addAction('Verification Email Resent', { merchantId: authenticationStore.merchantId });
}

export default {
  /**
   * Load datadog RUM and register all of the event consumers.
   */
  load() {
    initialize();

    eventBus.subscribeAuthenticatedEvent(handleAuthenticated);
    eventBus.subscribeEmailVerificationEvent(handleEmailVerification);
    eventBus.subscribeErrorEvent(handleError);
    eventBus.subscribeExistingUserEvent(handleExistingUser);
    eventBus.subscribeIntegrationTestResultsEvent(handleIntegrationTestResults);
    eventBus.subscribeWebsiteUpdatedEvent(handleWebsiteUpdated);
    eventBus.subscribeMssDeclinedEvent(handleMssDeclined);
    eventBus.subscribeMssStageCompletedEvent(handleMssStageCompleted);
    eventBus.subscribeTrackActionEvent(handleTrackAction);
    eventBus.subscribeTrackClickEvent(handleTrackClick);
    eventBus.subscribeVerificationEmailResentEvent(handleVerificationEmailResent);

    logger.info('Successfully loaded Datadog!');
  }
};
