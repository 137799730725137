
import { InvoiceDetail } from '@/store/statement/statement-models';
import { DataTableHeader } from 'vuetify';
import Vue, { PropType } from 'vue';

export default Vue.extend({
  name: 'StatementTransactionTotals',
  components: {
  },
  props: {
    details: {
      type: Object as PropType<InvoiceDetail>,
      required: true,
    },
    showTax: {
      type: Boolean,
      required: true,
    },
    header: {
      type: Object as PropType<DataTableHeader>,
      required: false,
    },
  },
  computed: {
    isMobile(): any {
      return this.$vuetify.breakpoint.xs;
    },
  },
  methods: {
    isDebit(n?: number) : boolean {
      return !!n && n < 0;
    },
  }
});
