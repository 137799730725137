
import { DataOptions } from 'vuetify';
import TextBox from './TextBox.vue';
import Vue, { PropType } from 'vue';

export default Vue.extend({
  name: 'TableFooter',
  components: {
    'textbox': TextBox,
  },
  props: {
    tableOptions: { type: Object as PropType<DataOptions>, required: true },
    itemsLength: { type: Number, required: true },
  },
  data() {
    return {
      tableOptionsState: { } as any,
    };
  },
  watch: {
    tableOptions: function(newVal) {
      this.tableOptionsState = newVal;
    },
  },
  methods: {
    onPageNumberChange(newPage) {
      if (isNaN(newPage) || newPage === null || newPage === undefined || newPage === '' || parseInt(newPage, 10) < 1) {
        this.$emit('paginate', 1);
        return;
      }
      newPage = parseInt(newPage, 10);

      const maxPages = Math.ceil(this.itemsLength / this.tableOptions.itemsPerPage);

      if (newPage <= 0) {
        newPage = 1;
      } else if(newPage > maxPages) {
        newPage = maxPages;
      }

      this.$emit('paginate', newPage);
    }
  },
  async mounted() {
    this.tableOptionsState = this.tableOptions;
  },
});

export class TableFooterStatic {
  static getDefaultOptions(translate: (key: string) => any): any {
    // Data type for footer props is not available
    // See https://vuetifyjs.com/en/components/data-iterators/#api (must choose v-data-footer)
    return {
      'items-per-page-options': [10, 25, 50, 100],
      'items-per-page-text': translate('tableFooter.items-per-page'),
    };
  }
}
