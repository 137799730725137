
import DisputeDetailsSection from '../DisputeDetailsSection.vue';
import Vue from 'vue';
export default Vue.extend({
  name: 'DisputeCollectEvidenceHeaderSection',
  components: {
    'dispute-details-section': DisputeDetailsSection
  },
  props: {
    editable: {
      type: Boolean,
      required: true,
    },
  },
});
