
import Vue from 'vue';
import configuration from '@/configuration';
import MerchantIntegrationInformation from '@/components/common/MerchantIntegrationInformation.vue';
import { mdiClockOutline, mdiTriangle } from '@mdi/js';

export default Vue.extend({
  name: 'Magento2Integration',
  components: {
    'merchant-integration-info': MerchantIntegrationInformation,
  },
  data() {
    return {
      loading: false,
      clock: mdiClockOutline as string,
      triangle: mdiTriangle as string,
      showSteps: {
        gateway: false,
        widget: false,
      } as any,
    };
  },
  computed: {
    companyName(): string {
      return configuration.company.name;
    },
  },
  methods: {
    toggleShowStep(key: string) {
      this.showSteps[key] = !this.showSteps[key];
    }
  },
});
