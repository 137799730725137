
import {
  authenticationStore,
  merchantStore,
  userStore,
} from "@/store/store";
import { UserPermissions } from "@/store/merchant/merchant-models";
import UserPermissionSwitch from "./UserPermissionSwitch.vue";
import { mdiClose } from "@mdi/js";
import Vue from 'vue';

export default Vue.extend({
  name: 'UserPermissionsDialog',
  components: {
    "user-permission-switch": UserPermissionSwitch,
  },
  props: {
    userEmail: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    noTrigger: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showDialog: false,
      loading: false,
      submitting: false,
      userRoles: [] as string[],
      closeIcon: mdiClose,
    };
  },
  computed: {
    activatorText(): any {
      return this.disabled ? this.$t("users.view-role") : this.$t("users.edit-role")
    },
  },
  watch: {
    async showDialog(opened) {
      if (opened) {
        this.loading = true;
        const res = await userStore.getUserInfo(this.userEmail);
        this.userRoles = res?.roles ?? [];
        this.loading = false;
      } else {
        this.close();
      }
    },
  },
  created() {
    if (this.noTrigger) {
      this.showDialog = true;
    }
  },
  methods: {
    areRefundsEnabledForMerchant() {
      return merchantStore.merchant.portalEnabledRefund;
    },
    areDisputesEnabledForMerchant() {
      return userStore.canAccessDisputes;
    },
    isViewingOwnRoles() {
      return this.userEmail === authenticationStore.userInfo.email;
    },
    async addRole(role: UserPermissions) {
      if (role === UserPermissions.admin) {
        this.userRoles = [role];
      } else {
        this.userRoles = [...this.userRoles, role];
      }

      await this.updateRoles();
    },
    async removeRole(role: UserPermissions) {
      this.userRoles = this.userRoles.filter((r) => r !== role);

      await this.updateRoles();
    },
    async updateRoles() {
      this.submitting = true;
      await userStore.updateUserRoles({
        roles: this.userRoles,
        userEmail: this.userEmail,
      });
      this.submitting = false;
    },
    close() {
      this.$emit('on-close');
    },
  },
});
