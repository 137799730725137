
import BasicLayout from '@/components/layouts/BasicLayout.vue';
import StatementCsvDialog from '@/components/statements/StatementCsvDialog.vue';
import Statements from '@/components/statements/Statements.vue';
import { StatementSearchOptions } from '@/store/statement/statement-models';
import { statementStore } from '@/store/store';
import Vue from 'vue';

export default Vue.extend({
  name: 'StatementsPage',
  components: {
    'basic-layout': BasicLayout,
    'statement-csv-dialog': StatementCsvDialog,
    'statements': Statements,
  },
  computed: {
    lastSearchOptions(): StatementSearchOptions {
      return statementStore.lastSearchOptions;
    },
  },
});
