// Import order matters here
import Vue from 'vue';
import vuetify from '@/plugins/vuetify';
import '@/assets/styles/main.scss';
import store from '@/store/store';
import router from '@/router';
import i18n from '@/i18n';
import App from '@/App.vue';
import '@/plugins/load-script';
import '@/errors';
import configuration from '@/configuration';
import segment from '@/services/analytics/segment';
import datadog from '@/services/analytics/datadog';
import { VueMaskDirective } from 'v-mask';

Vue.config.productionTip = false;

Vue.directive('mask', VueMaskDirective);

// Setup analytics and their internal event subscribers
if (configuration.featureFlags.datadog) {
  datadog.load();
}
if (configuration.featureFlags.segment) {
  segment.load();
}

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount('#app')
