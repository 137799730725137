
import Vue, { PropType } from 'vue';

export default Vue.extend({
    name: 'RadioGroup',
    components: {},
    model: {
        prop: 'value',
        event: 'change',
    },
    props: {
        value: { required: true },
        items: { type: Array as PropType<Array<any>>, required: true },
        itemText: { type: String, required: false, default: 'text' },
        itemValue: { type: String, required: false, default: 'value' },
        label: { type: String, required: false },
        disabled: { type: Boolean, required: false, default: false },
        rules: { type: Array as PropType<Array<any>>, required: false, default: () => [] },
    },
    data() {
        return {
            selectedValue: '' as any,
        };
    },
    computed: {
    },
    watch: {
        value: function(newVal) {
            this.onPropChanged(newVal);
        },
    },
    methods: {
        onRadioValueChanged(value: any) {
            this.$emit('change', value);
        },
        onPropChanged(newVal) {
            this.selectedValue = newVal;
        },
    },
    async mounted() {
        if (this.value === undefined || this.value === null) {
            this.selectedValue = '';
        } else {
            this.onPropChanged(this.value);
        }
    }
});
