
import BasicLayout from '@/components/layouts/BasicLayout.vue';
import Updates from '@/components/updates/Updates.vue';
import Vue from 'vue';

export default Vue.extend({
  name: 'UpdatesPage',
  components: {
    'basic-layout': BasicLayout,
    'updates': Updates
  }
})
