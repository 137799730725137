
import Vue, { PropType } from 'vue';
export default Vue.extend({
    name: 'TextBox',
    components: { },
    model: {
        prop: 'value',
        event: 'change'
    },
    props: {
        value: { required: false, default: undefined },
        label: { required: false, default: undefined },
        hideDetails: { required: false, default: false },
        rules: { type: Array as PropType<Array<any>>, required: false, default: () => [] },
        disabled: { type: Boolean, required: false, default: false },
        multiline: { type: Boolean, required: false, default: false },
        readonly: { type: Boolean, required: false, default: false },
        disableMainStyle: { type: Boolean, required: false, default: false },
        on: { required: false, default: undefined },
        attrs: { required: false, default: undefined },
        suffix: { required: false, default: undefined }
    },
    data() {
        return {
            state: '' as any,
            isNumber: false,
        };
    },
    watch: {
        value: function(newVal) {
            if (this.value === undefined || this.value === null) {
                this.state = '';
            } else {
                this.state = newVal;
            }
        },
    },
    methods: {
        onStateChanged(newState: any) {
            this.$emit('change', this.isNumber ? Number(newState) : newState);
        },
        onClick(evt) {
            this.$emit('click', evt);
        },
        onInput(evt) {
            this.$emit('input', evt);
        },
        onKeyDown(evt) {
            this.$emit('keydown', evt);
        },
    },
    async mounted() {
        if (this.value === undefined || this.value === null) {
            this.state = '';
            this.state = this.value;
        } else {
            this.state = this.value;
        }
        this.isNumber = typeof this.value === 'number';
    }
});
