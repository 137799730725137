
import configuration from '@/configuration';
import eventBus from '@/event-bus';
import SimpleView from '../layouts/SimpleView.vue';
import { applicationStore, merchantStore } from '@/store/store';
import { Territory } from '@/store/application/application-models';
import Vue from 'vue';

export default Vue.extend({
  name: 'Help', // eslint-disable-line vue/multi-word-component-names
  components: {
    'simple-view': SimpleView,
  },
  data() {
    return {
      supportEmail: configuration.supportEmail,
      privacyLink: configuration.links.privacyLink,
      customerTermsLink: configuration.links.customerTermsLink,
      merchantTermsLink: configuration.links.merchantTermsLink,
      merchantHelpCenter: configuration.links.merchantHelpCenter,
    };
  },
  methods: {
    trackClick(item: string) {
      eventBus.publishTrackClickEvent(item);
    },
  },
  computed: {
    isMssRequired(): boolean {
      const territories = applicationStore.currentCountry?.territories || [];
      const isUSterritory = territories.some((t) => t === Territory.US);
      const isMssMerchant = merchantStore.isMssMerchant;
      return isUSterritory && isMssMerchant;
    },
  },
});
