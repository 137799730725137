import configuration from '@/configuration';
import LogMethod from '@/decorators/logger-decorator';
import i18n from '@/i18n';
import moment from 'moment-timezone';
import { defaultPageName, routerReplace } from '@/router';
import {
  SupportedCountry,
  SupportedLocale,
  Territory,
  Timezone,
} from '@/store/application/application-models';
import { IdentityByTerritory } from '@/store/authentication/authentication-models';
import store, {
  authenticationStore,
  statementStore,
  contentStore,
  merchantStore,
  orderStore,
  paymentStore,
  featureStore,
  insightsStore,
  placeStore,
  disputeStore,
  userStore,
  onboardingStore,
} from '@/store/store';
import {
  config,
  Action,
  Module,
  Mutation,
  VuexModule,
} from 'vuex-module-decorators';
import { MerchantIdentity } from '../merchant/merchant-models';
import intercom from '@/services/intercom';
import { getTimezoneForMerchantUser, setTimezoneForMerchantUser } from '@/datetime';

// Set rawError for all Actions in module to true
config.rawError = true;

const DefaultDebugMode = false;
const DefaultCountry = { ...configuration.supportedCountries[0] };
const DefaultSupportedCountries = [ configuration.supportedCountries[0] ];
const DefaultSupportedLocales = [ ...configuration.supportedCountries[0].supportedLocales ];
const DefaultCurrentLocale = { ...configuration.supportedCountries[0].supportedLocales[0] };
const DefaultErrorModalMessage = '';
const DefaultShowErrorModal = false;
const DefaultTimezone = { ...configuration.supportedCountries[0].supportedTimezones[0] };
const DefaultTimezones = [ ...configuration.supportedCountries[0].supportedTimezones ];

/**
 * Application store is used to manage global application state for cross-cutting concerns.
 */
@Module({
  name: 'application',
  namespaced: true,
  store,
})
export default class ApplicationStore extends VuexModule {
  // Is application-level debug mode enabled?
  debugMode: boolean = DefaultDebugMode;
  // The current country being used by the user
  currentCountry: SupportedCountry = DefaultCountry;
  supportedCountries: SupportedCountry[] = DefaultSupportedCountries;
  // Organize locales by country
  supportedLocales: SupportedLocale[] = DefaultSupportedLocales;
  currentLocale: SupportedLocale = DefaultCurrentLocale;
  // For error messaging
  showErrorModal: boolean = DefaultShowErrorModal;
  errorModalMessage: string = DefaultErrorModalMessage;
  supportedTimezones: Timezone[] = DefaultTimezones;
  currentTimezone: Timezone = DefaultTimezone;
  
  get userTimezone(): Timezone {
    const tzName = getTimezoneForMerchantUser();
    const defaultTzName = DefaultTimezone.ianaTimezoneName;

    return tzName !== defaultTzName
      ? (this.supportedTimezones.find(tz => tz.ianaTimezoneName === tzName) || DefaultTimezone)
      : DefaultTimezone;
  }

  get isMobile(): boolean {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      return true
    } else {
      return false
    }
  }



  /**
   * Set the current merchant being used for a given territory
   * @param identity 
   */
   @Action
   @LogMethod
   async switchMerchant(identity: MerchantIdentity) {
    const currentMerchantId = authenticationStore.merchantId;

    const currentTerritory = this.currentCountry.code;
    // most of the time this won't be invoked, but if we're switching
    // to a merchant in a different territory, we'll need to switch too
    if (currentTerritory !== identity.territory) {
      const identities = Array.isArray(authenticationStore.identitiesByTerritory)
        ? authenticationStore.identitiesByTerritory || []
        : Object.values(authenticationStore.identitiesByTerritory || {}) as IdentityByTerritory[];

      const targetTerritory = identities.find(id => id.territory.toString() === identity.territory);
      if (targetTerritory) {
        await this.updateTerritory(targetTerritory);
      }
    }

     await authenticationStore.setMerchant(identity);
     await merchantStore.getMerchant(false);
     await merchantStore.getOnboardingMerchant();

     await merchantStore.getMerchantIdentities();
     await contentStore.refresh();

     // always redirect to default page when switching merchant
     // makes it clear we've changed merchant.
     if (currentMerchantId !== identity.merchantId) {
       routerReplace({ name: defaultPageName });
     }
   }

  /**
   * Set the current territory being used and update the country
   * @param identity 
   */
  @Action
  @LogMethod
  async switchTerritory(identity: IdentityByTerritory) {
    await this.updateTerritory(identity);
  }

  @Action
  async updateTerritory(identity: IdentityByTerritory) {
    const currentLocale = this.currentLocale;
    
    await authenticationStore.setIdentity(identity);
    await merchantStore.getMerchantIdentities();
    await merchantStore.getMerchant(false);
    await merchantStore.getOnboardingMerchant();

    // Set list of supported countries
    this.setSupportedCountries(identity.territory);
    // Default to current country
    this.defaultToCurrentCountry();
    // Set list of supported locales based on country
    this.setSupportedLocales();
    // Either use current locale if supported OR default to first supported locale
    const isIncluded = !!this.supportedLocales.filter((locale) => locale.locale === currentLocale.locale).length;
    const localeToSet = isIncluded ? currentLocale : this.supportedLocales[0];
    this.setCurrentLocale(localeToSet);
    await contentStore.refresh();
  }
  
  /**
   * Switch the user language
   */
  @Action
  @LogMethod
  async switchLanguage(locale: SupportedLocale) {
    this.setCurrentLocale(locale);
    await contentStore.refresh();
  }

  @Mutation
  @LogMethod
  setSupportedCountries(territory: Territory) {
    // Grab list of supported countries
    const countriesWithTerritoryMatch = configuration.supportedCountries.filter((country) => country.territories.includes(territory));
    this.supportedCountries = countriesWithTerritoryMatch;
  }

  @Mutation
  @LogMethod
  defaultToCurrentCountry() {
    // Default to first supported country in list
    this.currentCountry = this.supportedCountries[0];
  }

  @Mutation
  @LogMethod
  setCurrentTimezone(newTimezone: Timezone) {
    this.currentTimezone = newTimezone;
    setTimezoneForMerchantUser(newTimezone.ianaTimezoneName);
  }

  @Mutation
  @LogMethod
  setSupportedLocales() {
    // Grab supported locales from current country
    this.supportedLocales = this.currentCountry.supportedLocales;
  }

  @Mutation
  @LogMethod
  setCurrentLocale(supportedLocale: SupportedLocale) {
    this.currentLocale = supportedLocale;

    moment.locale(supportedLocale.locale);
    i18n.locale = supportedLocale.locale;

    const htmlElement = document.getElementsByTagName('html');
    if (htmlElement && htmlElement[0]) {
      htmlElement[0].lang = supportedLocale.language;
    }
  }

  @Mutation
  @LogMethod
  enableDebugMode() {
    this.debugMode = true;
  }
  @Mutation
  @LogMethod
  disableDebugMode() {
    this.debugMode = false;
  }
  
  @Mutation
  @LogMethod
  toggleShowErrorModal(bool: boolean) {
    this.showErrorModal = bool;
    // If closing modal, reset message after
    if (!bool) {
      this.errorModalMessage = DefaultErrorModalMessage;
    }
  }

  /**
   * Set modal message and toggle open
   * @param message 
   */
  @Mutation
  @LogMethod
  triggerErrorModal(message: string) {
    this.errorModalMessage = message;
    this.showErrorModal = true;
  }

  @Mutation
  reset() {
    this.debugMode = DefaultDebugMode;
    this.showErrorModal = DefaultShowErrorModal;
    this.errorModalMessage = DefaultErrorModalMessage;
    // Can reset country and locale preferences because they will be reset on relogin to match identity
    this.supportedCountries = DefaultSupportedCountries;
    this.currentCountry = DefaultCountry;
    this.supportedLocales = DefaultSupportedLocales;
    this.currentLocale = DefaultCurrentLocale;
    this.currentTimezone = DefaultTimezone;
    this.supportedTimezones = DefaultTimezones;
  }

  @Action
  async initialize() {
    await featureStore.initialize();
    await intercom.load();
  }

  @Action
  @LogMethod
  async resetAllStores() {
    await Promise.all([
      this.reset(),
      authenticationStore.clearAuthenticated(),
      merchantStore.reset(),
      orderStore.reset(),
      paymentStore.reset(),
      statementStore.reset(),
      contentStore.reset(),
      featureStore.reset(),
      insightsStore.reset(),
      placeStore.reset(),
      disputeStore.reset(),
      userStore.reset(),
      onboardingStore.reset(),
    ]);
  }
}
