
import {
  AggregatedOrder,
  AggregatedResult,
  ComparableAggregatedResult,
} from '@/store/insights/insights-models';
import AggregatedResultChart from './AggregatedResultChart.vue';
import Vue, { PropType } from 'vue';
export default Vue.extend({
  name: 'OrderAverageValueChart',
  components: {
    'aggregated-result-chart': AggregatedResultChart,
  },
  props: {
    data: {
      type: Object as PropType<ComparableAggregatedResult>,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
    },
    selectedReportedPeriod: {
      type: String,
      required: false,
    },
    selectedComaprisonPeriod: {
      type: String,
      required: false,
    },
    selectedReportedPeriodLabel: {
      type: String,
      required: false,
    },
    selectedComaprisonPeriodLabel: {
      type: String,
      required: false,
    },
  },
  computed: {
    title(): any {
      return this.$t('insights.chart-title-average-order-value');
    },
  },
  methods: {
    getValue(entry: any): number {
      return entry?.averageValue ?? 0;
    },
    formatValue(val: number): string {
      return this.$n(val, 'currency');
    },
    formatVerticalAxisValue(val: number): string {
      return this.$n(val, 'currencyNoFraction');
    },
    getAverageValue(values: number[], data: AggregatedResult) {
      const mapped = data.data.map((r) => r as AggregatedOrder);
      const total = mapped.map((m) => m.totalValue).reduce((acc, cur) => (acc += cur), 0);
      const count = mapped.length == 0 ? 1 : mapped.map((m) => m.entityCount).reduce((acc, cur) => (acc += cur));
      return total / count;
    },
  },
});
