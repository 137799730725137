
import { v4 as uuidv4 } from 'uuid';
import { DisputeEvidenceFile, DisputeEvidenceFileCategoryEnum } from "@/store/dispute/dispute-models";
import { TranslateResult } from "vue-i18n";
import { disputeStore } from '@/store/store';
import DisputeStore from '@/store/dispute/dispute-store';
import DropZone from '@/components/controls/DropZone.vue';
import ActionButton from '@/components/controls/ActionButton.vue';
import { getCategoryStrings } from "@/store/dispute/dispute-store";
import { DefaultValidatorArray, RequiredFieldValidator } from '@/validators';
import ComboBox from '@/components/controls/ComboBox.vue';
import Vue from 'vue';
import fileTypeParser from '@/services/file-type-parser';
import logger from '@/logger';

const Megabyte = 1048576;
const MaxFileSize = 5 * Megabyte; // 5MB
const MaxFileCount = getCategoryStrings().length;
const SupportedFileExtensions = ['png', 'jpg', 'jpeg', 'pdf'];

// Internal file entry model
interface FileEntry {
    key: string; // Computed key
    filename?: string;
    details?: DisputeEvidenceFile; // the remote file if the file has already been uploaded
    pendingFile?: File; // the file pending to upload
    categoryModel?: string; // This is just for combo box binding
    categoryValue?: DisputeEvidenceFileCategoryEnum; // Recording the real selected value and prompt the save button
    validationErrors?: string[];
    loading?: boolean; // loading state specifically for that row in the list
}

export default Vue.extend({
    name: 'DisputeEvidenceFiles',
    components: {
        'drop-zone': DropZone,
        'action-button': ActionButton,
        'combobox': ComboBox,
    },
    props: {
        editable: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            selectedCategory: '',
            files: [] as FileEntry[],
            selfLoading: false,
            validationError: null as string | null,
            requiredField: DefaultValidatorArray,
        };
    },
    computed: {
        disputeId(): any {
            return disputeStore.disputeDetails.id;
        },
        evidence(): any {
            return disputeStore.disputeDetails.evidence;
        },
        loading(): any {
            return disputeStore.loadingDisputeEvidence;
        },
        showFileList(): any {
            return this.editable || this.files?.length;
        },
        totalFileCountText(): any {
            return this.$t("disputeEvidenceFiles.total-file-count", { count: this.files?.length ?? 0 });
        },
        disputeEvidenceFileCategoryItems(): any {
            // Exclude categories that are already in use
            const values: string[] = [];
            const evidence = this.evidence;
            for (const catStr of getCategoryStrings()) {
                if (!evidence || !evidence[catStr]) {
                    values.push(catStr);
                }
            }
            return values.map(value => {
                return { value: DisputeEvidenceFileCategoryEnum[value], text: this.getCategoryText(DisputeEvidenceFileCategoryEnum[value]) };
            });
        },
        categoryComboBoxLabel(): TranslateResult {
            return this.$t('disputeEvidenceFiles.choose-category');
        },
        isMobile(): any {
            return this.$vuetify.breakpoint.xs;
        }
    },
    async created() {
        this.requiredField = RequiredFieldValidator;
    },
    async mounted() {
        this.refreshFiles();
    },
    methods: {
        isEntryLoading(entry: FileEntry) {
            return entry.loading || this.loading || this.selfLoading;
        },
        displayFileName(entry: FileEntry) {
            return entry.filename;
        },
        getCategoryText(category?: DisputeEvidenceFileCategoryEnum) {
            category = DisputeStore.fixupEnum(DisputeEvidenceFileCategoryEnum, category);
            switch (category) {
                case DisputeEvidenceFileCategoryEnum.cancellationPolicy:
                    return this.$t("disputeEvidenceFiles.category-cancellation-policy");
                case DisputeEvidenceFileCategoryEnum.customerCommunication:
                    return this.$t("disputeEvidenceFiles.category-customer-communication");
                case DisputeEvidenceFileCategoryEnum.customerSignature:
                    return this.$t("disputeEvidenceFiles.category-customer-signature");
                case DisputeEvidenceFileCategoryEnum.duplicateChargeDocumentation:
                    return this.$t("disputeEvidenceFiles.category-duplicate-charge-documentation");
                case DisputeEvidenceFileCategoryEnum.receipt:
                    return this.$t("disputeEvidenceFiles.category-receipt");
                case DisputeEvidenceFileCategoryEnum.refundPolicy:
                    return this.$t("disputeEvidenceFiles.category-refund-policy");
                case DisputeEvidenceFileCategoryEnum.serviceDocumentation:
                    return this.$t("disputeEvidenceFiles.category-service-documentation");
                case DisputeEvidenceFileCategoryEnum.shippingDocumentation:
                    return this.$t("disputeEvidenceFiles.category-shipping-documentation");
                case DisputeEvidenceFileCategoryEnum.uncategorizedFile:
                    return this.$t("disputeEvidenceFiles.category-uncategorized-file");
                default:
                    return category;
            }
        },
        async validateFileEntry(entry: FileEntry) {
            const arr: string[] = [];
            if (entry.pendingFile) {
                if (entry.pendingFile.size > MaxFileSize) {
                    arr.push(this.$t("disputeEvidenceFiles.error-file-too-large", { size: (MaxFileSize / Megabyte).toFixed(1) }).toString());
                }
                const extension = entry.pendingFile.name.substring(entry.pendingFile.name.lastIndexOf('.') + 1).toLowerCase();
                if (!SupportedFileExtensions.includes(extension)) {
                    arr.push(this.$t("disputeEvidenceFiles.error-unsupported-file-type").toString());
                }

                const signatureType = await fileTypeParser.fromFile(entry.pendingFile);
                if (!!entry.pendingFile.type && entry.pendingFile.type !== signatureType) {
                    logger.warn(`Dispute file ${entry.pendingFile.name} interpreted as ${entry.pendingFile.type} from its extension, but it doesn’t appear to be a valid file of that type as it was interpreted as ${signatureType} from its signature.`);
                    arr.push(this.$t("disputeEvidenceFiles.error-mismatch-file-type", { extensionType: entry.pendingFile.type }).toString())
                }

            }
            if (arr.length) {
                entry.validationErrors = arr;
            }
        },
        async onUploadFile(e: any) {
            await this.onFilesDropped(e.target.files);
        },
        async onFilesDropped(e: File[]) {
            this.clearValidationError();
            let currentCount = 0;
            if (this.files) {
                currentCount += this.files.length;
            }
            currentCount += e.length;
            if (currentCount > MaxFileCount) {
                let errorMessage = this.$t("disputeEvidenceFiles.error-too-many-files", { count: MaxFileCount }).toString();
                if (e.length > 1) {
                    errorMessage = `${this.$t("disputeEvidenceFiles.error-too-many-files-selected").toString()} ${errorMessage}`;
                }
                this.validationError = errorMessage;
                return; // Failed validation
            }
            let entries: FileEntry[] = [];
            if (this.files) {
                entries = [...this.files];
            }
            for (const f of e) {
                if (f) {
                    const entry = { key: uuidv4(), filename: f.name, pendingFile: f };
                    await this.validateFileEntry(entry);
                    entries.push(entry);
                }
            }
            this.files = entries;
            (this.$refs.upload as any).value = null; // Clear upload control files
        },
        async onDownloadFile(file: FileEntry) {
            // Need to check loading flag again because the event could be from a div which is not disabled
            if (file.details && !this.isEntryLoading(file)) {
                this.setFileLoading(file, true);
                await disputeStore.downloadEvidenceFile({ disputeId: this.disputeId, categoryOrFileId: file.details?.category as DisputeEvidenceFileCategoryEnum, filename: file.details?.filename ?? `${file.details?.category}.${file.details?.type}` });
                this.setFileLoading(file, false);
            }
        },
        setFileLoading(file: FileEntry, loading: boolean) {
            file.loading = loading;
            this.files = [...this.files];
        },
        onCategoryChanged(file: FileEntry, value: DisputeEvidenceFileCategoryEnum) {
            file.categoryValue = value;
        },
        onUploadClick() {
            (this.$refs.upload as any).click();
        },
        clearValidationError() {
            this.validationError = null;
        },
        async onSaveCategory(file: FileEntry) {
            this.clearValidationError();
            this.selfLoading = true;
            file.loading = true;

            await disputeStore.uploadEvidenceFile({ disputeId: this.disputeId, category: file.categoryValue as DisputeEvidenceFileCategoryEnum, file: file.pendingFile as File });
            this.refreshFiles(file); // refresh the list - keep the entry list ordering!

            file.loading = false;
            this.selfLoading = false;

            this.$emit('remoteFileUploaded', file);
        },
        async onDeleteFile(deletingFile: FileEntry) {
            this.clearValidationError();
            // If this is a remote file, call API
            if (deletingFile.details) {
                deletingFile.loading = true; // Looks like the "selfLoading" call will also trigger a refresh
                this.selfLoading = true;
                await disputeStore.deleteEvidenceFile({ disputeId: this.disputeId, category: deletingFile.details.category as DisputeEvidenceFileCategoryEnum });
                deletingFile.loading = false;
                this.selfLoading = false;
            }
            this.removeEntry(deletingFile);

            this.$emit('remoteFileDeleted', deletingFile);
        },
        removeEntry(deletingFile: FileEntry) {
            // Removing only the entry
            const entries: FileEntry[] = [];
            for (const file of this.files) {
                if (file.key !== deletingFile.key) {
                    entries.push(file);
                }
            }
            this.files = entries;
        },
        refreshFiles(replacing?: FileEntry) {
            // NOTE: this refresh function will try its best to keep the existing ordering of file rows in the list
            const entries: FileEntry[] = [];
            const remoteEntries: FileEntry[] = [];
            const index: any = {};
            const consumedCategories: any = {};
            if (this.evidence) {
                for (const catStr of getCategoryStrings()) {
                    const uploadedFile = this.evidence[catStr];
                    if (uploadedFile) {
                        remoteEntries.push({ key: uploadedFile.id, filename: uploadedFile.filename, details: uploadedFile });
                        consumedCategories[DisputeEvidenceFileCategoryEnum[catStr]] = uploadedFile;
                    }
                }
            }
            if (this.files) {
                for (const file of this.files) {
                    if (file.pendingFile && file.key !== replacing?.key) {
                        entries.push(file);
                        if (file.categoryValue !== undefined && consumedCategories[file.categoryValue]) {
                            file.categoryValue = undefined;
                            file.categoryModel = undefined;
                        }
                    } else if (file.key === replacing?.key) {
                        const arr = remoteEntries.filter(r => r.details?.category === file.categoryValue);
                        if (arr.length) {
                            index[arr[0].details?.category as DisputeEvidenceFileCategoryEnum] = arr[0];
                            entries.push(arr[0]);
                        }
                    } else if (file.details) {
                        const arr = remoteEntries.filter(r => r.details?.category === file.details?.category);
                        if (arr.length) {
                            index[arr[0].details?.category as DisputeEvidenceFileCategoryEnum] = arr[0];
                            entries.push(arr[0]);
                        }
                    }
                }
            }
            for (const file of remoteEntries) {
                if (!index[file.details?.category as DisputeEvidenceFileCategoryEnum]) {
                    entries.push(file);
                }
            }
            this.files = entries;
        },
    },
});
