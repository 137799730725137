import { render, staticRenderFns } from "./OrdersStatusItem.vue?vue&type=template&id=f9dbfcea&"
import script from "./OrdersStatusItem.vue?vue&type=script&lang=ts&"
export * from "./OrdersStatusItem.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports