
import DialogBox from '@/components/common/DialogBox.vue';
import eventBus from '@/event-bus';
import { orderStore } from '@/store/store';
import { TranslateResult } from 'vue-i18n';
import Vue from 'vue';
import { Refund } from '@/store/order/order-models';

export default Vue.extend({
  name: 'RefundDialog',
  components: {
    'dialog-box': DialogBox,
  },
  props: {
    orderId: {
      type: String,
      required: true,
    },
    orderNumber: {
      type: String,
      required: true,
    },
    customerName: {
      type: String,
      required: true,
    },
    orderAmount: {
      type: Number,
      required: true,
    },
    currencyCode: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
    refunds: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      valid: false,
      loading: false,
      success: false,
      maxRefundAmount: 0,
      refundReference: '' as string,
      refundAmount: 0 as number,
      refundReferenceRules: [] as Array<(string: string) => TranslateResult | boolean>,
      refundAmountRules: [] as Array<(num: number) => TranslateResult | boolean>,
      showRefundFormDialog: false,
      showResultDialog: false,
      resultTitle: '' as TranslateResult,
      resultMessage: '' as TranslateResult,
      isNotEmpty: (val: string | number): boolean => {
        if (typeof val === 'string') {
          return !!val && val.trim().length > 0;
        } else {
          return val !== undefined;
        }
      },
      isNonNegative: (num: number): boolean => {
        return num >= 0;
      },
      isLessThanMaxAmount: (num: number, maxRefundAmount: number): boolean => {
        return num <= maxRefundAmount;
      },
      isUnusedRefundReference: (reference: string, refunds: Refund[]): boolean => {
        if (!refunds || !refunds.length) {
          return true;
        }
        return !refunds.some(r => r.merchantRefundReference === reference);
      },
    };
  },
  async mounted() {
    this.maxRefundAmount = await orderStore.getMaxRefundAmount(this.orderId);
    this.initializeValidationRules(this.maxRefundAmount, this.refunds);
  },
  methods: {
    /**
     * Initialize all validation rules for the component.
     *
     * Rules check if the supplied field is valid.  If not, the localized messages
     * is returned and displayed via Vuetify's validation framework.
     */
    initializeValidationRules(maxRefundAmount: number, refunds: Refund[]) {
      this.refundReferenceRules = [
        (str: string) => this.isNotEmpty(str) || this.$t('refundDialog.required'),
        (str: string) => this.isUnusedRefundReference(str, refunds) || this.$t('refundDialog.unique-refund-reference'),
      ];
      this.refundAmountRules = [
        (num: number) => this.isNotEmpty(num) || this.$t('refundDialog.required'),
        (num: number) => this.isNonNegative(num) || this.$t('refundDialog.refund-amount-non-negative-rule'),
        (num: number) => this.isLessThanMaxAmount(num, maxRefundAmount) || this.$t('refundDialog.refund-amount-over-max-rule'),
      ];
    },
    async submitRefund(event: Event) {
      event.preventDefault();

      // UI idempotency so a user doesn't submit a form that's already been submitted
      if (this.loading) {
        return;
      }

      this.valid = (this.$refs.form as HTMLFormElement).validate();
      if (!this.valid) {
        return;
      }

      this.loading = true;

      try {
        const refundCommand = {
          orderId: this.orderId,
          merchantReference: this.refundReference,
          amount: this.refundAmount,
          currencyCode: this.currencyCode,
        }
        await orderStore.refundOrder(refundCommand);
        this.success = true;
        this.resultTitle = this.$t('refundDialog.submit-success-title');
        this.resultMessage = this.$t('refundDialog.submit-success-message', { orderNumber: this.orderNumber });
      } catch (e) {
        this.success = false;
        this.resultTitle = this.$t('refundDialog.submit-failure-title');
        this.resultMessage = e.message == this.$t('globalMessages.403-error') ? e.message : this.$t('refundDialog.submit-failure-message');
      } finally {
        eventBus.publishTrackActionEvent('MP Refund', {
          success: this.success,
          orderId: this.orderId,
          orderNumber: this.orderNumber,
          refundAmount: this.refundAmount,
          refundReference: this.refundReference,
        });
        // TODO do we need to reset input fields or should we leave for merchant ref?/how often will a merchant go to refund twice in a row?
        // this.refundReference = '';
        // this.refundAmount = undefined;
        this.showRefundFormDialog = false;
        this.showResultDialog = true;
        this.loading = false;
      }
    },
    async closeResultDialog() {
      this.showResultDialog = false;
      // Refresh order page regardless (if there was an error a refund may have been made just before this was submitted)
      await orderStore.getOrder(this.orderId);
    },
  },
});
