import configuration from '@/configuration';
import eventBus from '@/event-bus';
import logger from '@/logger';
import router, { errorPageName, routerPush, unauthorizedPageName } from '@/router';
import { generateNonce } from '@/security';
import { authenticationStore, applicationStore } from '@/store/store';
import { AuthorizationError } from '@/store/store-models';
import Vue from 'vue';
import i18n from '@/i18n'

// Error message if a property is incorrectly redefined on the store
const VuexPropertyRedefinedMessagePrefix = 'Cannot redefine property:';

/**
 * Global error handler is responsible for logging and sending the router to the appropriate error page.
 *
 * Specifically, authorizaton and approval errors are handled here to go to other pages.
 *
 * @param error the error cause
 * @param redirect whether or not redirect to the error page
 */
export function errorHandler(error?: any, redirect = true) {
  if (!error || !(error instanceof Error)) {
    logger.warn('Error is undefined or incorrect type', error);
    return;
  }

  const e = error as Error;

  if (e.name === 'NavigationDuplicated'
    && e.message?.includes('Avoided redundant navigation to current location')) {
      logger.warn(e.message)
    return;
  }

  if (e.message?.startsWith(VuexPropertyRedefinedMessagePrefix)) {
    eventBus.publishErrorEvent(error, true);
    router.go(0);
    return;
  }

  // Authorization error
  if (e instanceof AuthorizationError) {
    eventBus.publishErrorEvent(error, true);
    logger.warn('Handling authorization error', e);
    authenticationStore.clearAuthenticated();
    return routerPush({ name: unauthorizedPageName });

  // Global handler
  } else {
    /**
     * Set a unique ID that will display to the user and be presented in telemetery/analytics
     * services to aid in debugging unexpected user issues.
     */
    const errorId = generateNonce(6);
    let message = error.message;
    if (!message) {
      message = JSON.stringify(error);
    }

    eventBus.publishErrorEvent(error, false, errorId);

    if (redirect) {
      return routerPush({ name: errorPageName, params: { errorId } });
    }
  }
}

// Vue component error handler
// Only override when it's not a unit test environment as vue test utils will otherwise complain
if (!configuration.isUnitTestEnvironment) {
  Vue.config.errorHandler = (error: Error, vm: Vue, info: string) => {
    logger.info(`Vue error handler: ${error?.message}`, error, vm, info);
    errorHandler(error);
  };
}

// Window error handler
window.onerror = (event: Event | string, source?: string, fileno?: number, columnNumber?: number, error?: Error) => {

  logger.info('Window error handler');
  if (error) {
    // Handle error without redirecting
    errorHandler(error, false);
  } else {
    logger.warn('Window error handling caught event', event, source, fileno, columnNumber);
  }
};

/**
 * Swagger-generated APIs throw a response object instead of an actual Error type
 * handleResponseErrors handles various error response codes either by
 * strongly typing as Errors to be redirected to error pages *-OR-*
 * generating UI error messages (displayed to user in modal based on third param)
 * 
 * 401 and 500 will *always* throw and redirect
 * @param e Error
 * @param idType For API calls associated to an ID (only relevant to 404 error messaging)
 * @param displayErrorModal Should the error be displayed to user in error modal? (Default false)
 * @param throwAllErrors Should the error be thrown no matter what status? (Default false) (throwAllErrors true overrides displayErrorModal)
 */
export async function handleResponseErrors(exception: Response, idType: string, displayErrorModal = false, throwAllErrors = false): Promise<string> {
  const e = new Error(JSON.stringify(exception));

  // Allow a customized error message for UI
  let errorMessage = "";

  // 400 returns
  // EITHER errors as and {} with keys relative to errors
  // OR ValidationExceptionMessage as a string[], usually with only one error
  if (exception?.status === 400) {
    logger.warn('Error status 400');
    const body = await exception.json();
    errorMessage = i18n.t('globalMessages.400-error') as string;
    if (!displayErrorModal)  {
      if (body.errors) {
        errorMessage = JSON.stringify(body.errors);
      } else if (body.ValidationExceptionMessage && body.ValidationExceptionMessage[0]) {
        errorMessage = JSON.stringify(body.ValidationExceptionMessage[0]);
      }
    }
  } else if (exception?.status === 401) {
    // 401 means the user is unauthorized to make that kind of request and should always bubble up to redirect page
    logger.warn('Throwing Authorization Error');
    throw new AuthorizationError('Unauthorized request');
  } else if (exception?.status === 403) {
    // 403 means the user is not allowed to perform this action
    logger.warn('Error status 403', { roles: authenticationStore.roles });
    if (!authenticationStore.roles.length) {
      logger.warn('User received 403 because they have no roles assigned');
    }

    const userRoles = (authenticationStore.roles || []).join(', ');
    eventBus.publishErrorEvent(new Error('API Response 403. The user has the following roles: ' + userRoles), true);

    errorMessage = i18n.t('globalMessages.403-error') as string;
    if (!displayErrorModal)  {
      const body = await exception.json();
      if (body.serverError?.message) {
        errorMessage = JSON.stringify(body.serverError.message);
      }
    }
  } else if (exception?.status === 404) {
    // 404 Not Found returns no body at all  
    logger.warn('Error status 404');
    errorMessage = i18n.t('globalMessages.404-error', { idType }) as string;
  } else if (exception?.status === 500) {
    // 500 Error on backend  
    logger.warn('Error status 500');
    errorMessage = i18n.t('globalMessages.500-error') as string;
    if (!displayErrorModal)  {
      const body = await exception.json();
      if (body.serverError?.message) {
        errorMessage = JSON.stringify(body.serverError.message);
      }
    }
  } else {
    // Catch any other potential errors
    logger.warn('Unidentified error', e);
    errorMessage = i18n.t('globalMessages.unidentified-error',{brandName: configuration.company.name}) as string;
    if (!displayErrorModal)  {
      const exceptionText = await exception.text();
      errorMessage = `${exception.status}: ${exceptionText}`;
    }
  }

  /**
   * Either display modal and return errorMessage string,
   * throw errorMessage Error,
   * or return errorMessage string (do nothing)
   */
  if (displayErrorModal && !throwAllErrors) {
    applicationStore.triggerErrorModal(errorMessage);
  } else if (throwAllErrors) {
    // Only publish Error event when we are throwing the error
    eventBus.publishErrorEvent(e, true);
    throw new Error(errorMessage);
  }
  return errorMessage;
}