
import { UserPermissions } from '@/store/merchant/merchant-models';
import Vue, { PropType } from 'vue';

export default Vue.extend({
  name: 'UserPermissionSwitch',
  props: {
    userRoles: {
      type: Array as PropType<string[]>,
      required: true,
    },
    role: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: "$invoices",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      permissionEnabled: false,
    };
  },
  watch: {
    userRoles(newVal) {
      this.refreshPermissionEnabled(newVal);
    },
  },
  computed: {
    classes(): string[] {
      return [
        `${this.role}`,
        this.loading ? "loading" : ''
      ]
    },
    shouldToggleBeDisabled(): boolean {
      if (this.disabled) {
        return true;
      }

      if (this.userHasAdminRole) {
        // Displays only the admin role as true, and everything else as false
        return this.role !== UserPermissions.admin;
      }

      return false;
    },
    userHasAdminRole(): boolean {
      return this.userRoles.includes(UserPermissions.admin);
    }
  },
  async mounted() {
    this.refreshPermissionEnabled(this.userRoles);
  },
  methods: {
    refreshPermissionEnabled(roles) {
      this.permissionEnabled = (roles.includes(UserPermissions.admin) || roles.includes(this.role));
    },
    onAddRole() {
      this.$emit('add-role', this.role);
    },
    onRemoveRole() {
      this.$emit('remove-role', this.role);
    },
    onRoleEnabledChange(newValue) {
      if (newValue) {
        this.onAddRole();
      } else {
        this.onRemoveRole();
      }
    }
  },
});
