
import ActionButton from '../controls/ActionButton.vue';
import Vue, { PropType } from 'vue';
export default Vue.extend({
  name: 'ConfirmationBox',
  components: {
    'action-button': ActionButton,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    dialogClass: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    message: {
      type: String,
      default: null,
    },
    okText: {
      type: String,
      default: null,
    },
    okButtonClasses: {
      type: String,
      default: null,
    },
    cancelText: {
      type: String,
      default: null,
    },
    confirmAction: {
      type: Function as PropType<() => Promise<any>>,
      required: true,
    },
    cancelAction: {
      type: Function as PropType<() => Promise<any>>,
      required: true,
    },
  },
  data() {
    return {
      isRunningAction: false,
      displayConfirmation: false,
    };
  },
  computed: {
    actualOkText(): any {
      return this.okText ?? this.$t('confirmationBox.ok');
    },
    actualCancelText(): any {
      return this.cancelText ?? this.$t('confirmationBox.cancel');
    },
  },
  watch: {
    'show': function() {
      this.syncShowValue();
    },
    'displayConfirmation': async function() {
      if (!this.displayConfirmation) {
        this.runCancel();
      }
    },
  },
  mounted() {
    this.syncShowValue();
  },
  methods: {
    syncShowValue() {
      this.displayConfirmation = this.show;
    },
    async runConfirmAction() {
      this.isRunningAction = true;
      await this.confirmAction();
      this.isRunningAction = false;
    },
    async runCancel() {
      this.isRunningAction = true;
      await this.cancelAction();
      this.isRunningAction = false;
    },
  },
});
