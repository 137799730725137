
import { updatesPageName } from '@/router';
import { contentStore } from '@/store/store';
import StatusItem from './StatusItem.vue';
import Vue from 'vue';
export default Vue.extend({
  name: 'ContentStatusItem',
  components: {
    'status-item': StatusItem,
  },
  data() {
    return {
      newFeatureCount: 0 as number | null,
      firstLoad: true,
      linkTo: { name: updatesPageName },
    };
  },
  computed: {
    valid(): any {
      return this.newFeatureCount !== null;
    },
    title(): any {
      let suffix = '';
      const newFeatureCount = this.newFeatureCount ?? 0;
      if (newFeatureCount === 1) {
        suffix = '-single';
      } else if (!newFeatureCount) {
        suffix = '-empty';
      }
      return this.$t(`insights.status-item-content-title${suffix}`, { count: this.$n(newFeatureCount) });
    },
    linkText(): any {
      return this.newFeatureCount ? this.$t('insights.status-item-content-link') : undefined;
    },
  },
  async mounted() {
    this.newFeatureCount = contentStore.whatsNewItems?.length ?? null;
    this.firstLoad = false;
  },
});
