
import moment from 'moment-timezone';
import configuration from '@/configuration';
import 'moment/locale/fr-ca';
import TextBox from './TextBox.vue';
import Vue from 'vue';

export default Vue.extend({
  name: 'DatePicker',
  components: {
    'textbox': TextBox,
  },
  props: {
    label: { type: String, required: true },
    date: { type: String, required: false, default: moment.utc().startOf('month').toISOString() },
    minimumDate: { type: String, required: false },
    maximumDate: { type: String, required: false },
    locale: { type: String, required: true },
    initialPicker: { type: String, required: false },
    center: { type: Boolean, required: false, default: false, }
  },
  data() {
    return {
      dateState: "",
      dateMenuOpened: false,
      activePicker: null,
    };
  },
  computed: {
    dateText(): string {
      if(!this.date) {
        return ''
      }

      return moment
        .utc(this.date)
        .locale(this.locale)
        .format(configuration.dateFormat);
    }
  },
  watch: {
    date: function(newVal) {
      this.dateState = newVal;
    },
    dateMenuOpened: async function() {
      if(this.dateMenuOpened && this.initialPicker) {
        await this.$nextTick();
        this.activePicker = this.initialPicker;
      }
    }
  },
  async mounted() {
    this.dateState = this.date;
  },
  methods: {
    onDateUpdated(newValue) {
      this.dateMenuOpened = false;
      this.$emit('dateUpdated', newValue);
    },
  },
});
