
import ActionButton from '@/components/controls/ActionButton.vue';
import DisputeDetailsSection from '../DisputeDetailsSection.vue';
import Vue from 'vue';

export default Vue.extend({
  name: 'DisputeDetailsSummaryActionsSection',
  components: {
    'dispute-details-section': DisputeDetailsSection,
    'action-button': ActionButton,
  },
  props: {
    hasSubmittedEvidence: {
      type: Boolean,
      required: true,
    },
    editable: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    onAcceptDispute(evt) {
      this.$emit('acceptDispute', evt);
    },
    onCounterDispute(evt) {
      this.$emit('counterDispute', evt);
    },
  },
});
