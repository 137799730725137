
import { Refund } from '@/store/order/order-models';
import Vue, { PropType } from 'vue';
import { toLocalDate } from '@/datetime';

export default Vue.extend({
  name: 'RefundsTable',
  props: {
    refunds: {
      type: Array as PropType<Refund[]>,
      required: true,
    },
  },
  computed: {
    noRefunds(): boolean {
      return !this.refunds.length;
    },
  },
  methods: {
    displayDate(date: any) {
      return toLocalDate(date);
    },
  },
});
