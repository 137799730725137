
import moment from 'moment-timezone';
import configuration from '@/configuration';
import { applicationStore, merchantStore } from '@/store/store';
import DownloadCsvDialog from '../common/DownloadCsvDialog.vue';
import DisputeStore from '@/store/dispute/dispute-store';
import { DisputeDateFilterEnum } from '@/store/dispute/dispute-models';
import DisputeDateFilter from './DisputeDateFilter.vue';
import Vue, { PropType } from 'vue';

export default Vue.extend({
  name: 'DisputeCsvDialog',
  components: {
    'download-csv-dialog': DownloadCsvDialog,
    'dispute-date-filter': DisputeDateFilter,
  },
  props: {
    startDate: {
      type: String,
      required: false,
    },
    endDate: {
      type: String,
      required: false,
    },
    mode: {
      type: String as PropType<undefined | 'normal' | 'compact' | 'embedded'>,
      required: false,
    },
    dateType: {
      required: true,
    },
  },
  data() {
    return {
      dateFilterLocalState: DisputeDateFilterEnum.disputeDate,
    };
  },
  computed: {
    companyName(): string {
      return configuration.company.name;
    },
    currentLocale(): string {
      return applicationStore.currentLocale.locale;
    },
    dataType(): string {
      return 'disputes as CSV';
    },
  },
  watch: {
    'dateType': function(newVal) {
      this.onDateFilterPropChanged(newVal);
    },
  },
  methods: {
    getFilename(args: any) {
      const dateFormat = 'YYYY-MM-DD';
      const prefix = `${merchantStore.merchant.legalName} ${this.$t('disputeCsvDialog.csv-export')} `;
      return `${prefix}- ${this.dateFilterLocalState} ${moment.utc(args.startDate).format(dateFormat).toString()} to ${moment.utc(args.endDate).format(dateFormat).toString()}.csv`
    },
    getCsvDownloadUrl(query: any) {
      const updatedQuery = {
        ...query,
        dateType: this.dateFilterLocalState,
      }
      return DisputeStore.getDisputesCsvByDateRangeUrl(updatedQuery);
    },
    onDateFilterPropChanged(newValue: DisputeDateFilterEnum) {
      this.dateFilterLocalState = newValue;
    },
    onDateFilterChanged(value: DisputeDateFilterEnum) {
      this.dateFilterLocalState = value;
    },
  },
});
