
import TextBox from '@/components/controls/TextBox.vue';
import Tooltip from '@/components/controls/Tooltip.vue';
import { DisputeDetail, DisputeEvidence, DisputeReasonEnum } from '@/store/dispute/dispute-models';
import DisputeDetailsSection from '../DisputeDetailsSection.vue';
import Vue, { PropType } from 'vue';
export default Vue.extend({
    name: 'DisputeCancellationSection',
    components: {
        'dispute-details-section': DisputeDetailsSection,
        'tooltip': Tooltip,
        'textbox': TextBox,
    },
    props: {
        evidence: {
            type: Object as PropType<DisputeEvidence>,
            required: true,
        },
        dispute: {
            type: Object as PropType<DisputeDetail>,
            required: true,
        },
        editable: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            cancellationPolicyDisclosure: '',
            cancellationRebuttal: '',
        };
    },
    computed: {
        shouldShowSection(): any {
            return this.dispute.reason === DisputeReasonEnum.subscriptionCanceled;
        },
    },
    watch: {
        'cancellationPolicyDisclosure': function (newVal) {
            this.onCancellationPolicyDisclosureChanged(newVal);
        },
        'cancellationRebuttal': function (newVal) {
            this.onCancellationRebuttalChanged(newVal);
        },
    },
    async mounted() {
        this.cancellationPolicyDisclosure = this.evidence.cancellationPolicyDisclosure;
        this.cancellationRebuttal = this.evidence.cancellationRebuttal;
    },
    methods: {
        onCancellationPolicyDisclosureChanged(newValue) {
            this.$emit('evidencePropertyChanged', { propertyName: 'cancellationPolicyDisclosure', propertyValue: newValue });
        },
        onCancellationRebuttalChanged(newValue) {
            this.$emit('evidencePropertyChanged', { propertyName: 'cancellationRebuttal', propertyValue: newValue });
        },
    },
});
