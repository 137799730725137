
import Vue, { PropType } from 'vue';
export default Vue.extend({
  name: 'DisputeEvidenceFormStageHeader',
  components: {
  },
  props: {
    editable: {
      type: Boolean,
      required: true,
    },
    currentStage: {
      type: Number,
      required: true,
    },
    stageEnum: {
      type: Object as PropType<any>,
      required: true,
    },
  },
  computed: {
    maxStages(): any {
      let count = 0;
      for (const n in this.stageEnum) {
        if (typeof this.stageEnum[n] === 'number') {
          count++;
        }
      }
      return count;
    },
  },
});
