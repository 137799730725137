export interface ComparableAggregatedResult {
  current?: AggregatedResult;
  previous?: AggregatedResult;
  reportingPeriod: InsightsReportingPeriodEnum;
  comparisonOffset: InsightsComparisonOffsetEnum;
}

export interface AggregatedResult{
  startDate: Date;
  endDate: Date;
  data: Array<AggregatedEntry>;
}

export interface AggregatedEntry {
  date: Date;
}

export interface AggregatedOrder {
  merchantId: string;
  date: Date;
  entityCount: number;
  totalValue: number;
  averageValue: number;
}

export interface AggregatedOrdersResult {
  startDate: Date;
  endDate: Date;
  data: Array<AggregatedOrder>;
}

export interface AggregatedTransaction {
  merchantId: string;
  date: Date;
  totalValue: number;
}

export interface AggregatedTransactionsResult {
  startDate: Date;
  endDate: Date;
  data: Array<AggregatedTransaction>;
}

export interface AggregatedCustomer {
  merchantId: string;
  date: Date;
  type: CustomerDataTypeEnum,
  category: string,
  entityCount: number,
}

export enum CustomerAgeGroupEnum
{
  Unknown = 'Unknown',
  From16To24 = 'From16To24',
  From25To34 = 'From25To34',
  From35To44 = 'From35To44',
  From45To55 = 'From45To55',
  Above55 = 'Above55',
}

export enum CustomerDataTypeEnum
{
  Location = 'location',
  AgeGroup = 'ageGroup',
  DailyMetrics = 'dailyMetrics',
}

export enum CustomerDailyMetricsTypeEnum
{
    ReturningCustomerCount = 'ReturningCustomerCount',
    NewCustomerCount = 'NewCustomerCount',
}

export interface AggregatedCustomersResult {
  startDate: Date;
  endDate: Date;
  data: Array<AggregatedCustomer>;
}

export enum InsightsReportingPeriodEnum {
  thisWeek = 'thisWeek',
  lastWeek = 'lastWeek',
  thisMonth = 'thisMonth',
  lastMonth = 'lastMonth',
  yearToDate = 'yearToDate',
  custom = 'custom',
}

export enum InsightsComparisonOffsetEnum {
  lastWeek = 'lastWeek',
  priorWeek = 'priorWeek',
  lastMonth = 'lastMonth',
  priorMonth = 'priorMonth',
  lastYear = 'lastYear',
  none = 'none',
}

export interface LastUpdatedDateResult {
  date: Date,
}
