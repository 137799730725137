
import { toLocalDate } from '@/datetime';
import { DisputeDetail, DisputeReconciledStatusEnum } from '@/store/dispute/dispute-models';
import DisputeIssuerEvidence from '../components/DisputeIssuerEvidence.vue';
import DisputeDetailsSection from '../DisputeDetailsSection.vue';
import Vue, { PropType } from 'vue';

export default Vue.extend({
  name: 'DisputeDetailsSummaryStepsSection',
  components: {
    'dispute-details-section': DisputeDetailsSection,
    'dispute-issuer-evidence': DisputeIssuerEvidence
  },
  props: {
    dispute: {
      type: Object as PropType<DisputeDetail>,
      required: true,
    },
  },
  computed: {
    displaySteps(): any {
      switch (this.dispute.status) {
        case DisputeReconciledStatusEnum.needsResponse:
          return true;
      }
      return false;
    },
    customerFollowUpEmailUrl(): any {
      const subject = encodeURIComponent(this.$t('disputeDetailsSummary.customer-email-subject', { orderDate: this.orderDate }).toString());
      const body = encodeURIComponent(this.$t('disputeDetailsSummary.customer-email-body', { customerName: this.customerName, disputeDate: this.disputeDate, amount: this.amount, orderDate: this.orderDate }).toString());
      return `mailto:${encodeURIComponent(this.dispute.customerEmail as string)}?body=${body}&subject=${subject}`
    },
    customerName(): any {
      return `${this.dispute.customerFirstName} ${this.dispute.customerLastName}`;
    },
    amount(): any {
      return this.$n(this.dispute.disputedAmount, 'currency');
    },
    disputeDate(): any {
      return toLocalDate(this.dispute?.disputedOn);
    },
    disputeDueDate(): any {
      return toLocalDate(this.dispute?.evidenceDueOn);
    },
    orderDate(): any {
      return toLocalDate(this.dispute?.orderDate);
    },
  },
  methods: {
    onAcceptDispute(evt) {
      this.$emit('acceptDispute', evt);
    },
  },
});
