// We deliberately do not persist any page/application state into localStorage.
// This is largely to avoid any state confusion between page loads.
// However, there are some user preferences that we would like to persist.
// Examples of this would be storing the user's timezone preference.
// Things that should not be persisted are things like API responses, and other page state.

// There is an additional complication, which is that each auth0 user can have multiple
// merchants associated with them. This means that any preferences need to be scoped to the merchant for a given user.
// Lastly, because this state can possibly be visible to other users and/or merchants, we need
// to ensure that no sensitive state is retained. Generally things like timezones are the intention for this.

const STORAGE_PREFIX = "qmp_";

const TEST_CACHE_KEY = "mp_insert_check";
const TEST_CACHE_VALUE = "mp_insert_check_value";

let localStorageTested = false;
let localStorageAvailable = false;

const isLocalStorageAvailable = (): boolean => {
  try {
    if (!window || !window.localStorage) {
      return false;
    }

    const key = `${STORAGE_PREFIX}_${TEST_CACHE_KEY}`;
    window.localStorage.setItem(key, TEST_CACHE_VALUE);
    const value = window.localStorage.getItem(key);
    window.localStorage.removeItem(key);

    return value === TEST_CACHE_VALUE;
  } catch {
    return false;
  }
};

const createStorageKey = (auth0User: string, merchantId: string, key: string): string => {
  return `${STORAGE_PREFIX}_${auth0User}_${merchantId}_${key}`;
}

export const canUseLocalStorage = (): boolean => {
  if (!localStorageTested) {
    localStorageAvailable = isLocalStorageAvailable();
    localStorageTested = true;
  }

  return localStorageAvailable;
}

export const getItemForMerchantUser = (auth0User: string, merchantId: string, key: string): string | null => {
  if (!canUseLocalStorage()) {
    return null;
  }

  try {
    const storageKey = createStorageKey(auth0User, merchantId, key);
    return window.localStorage.getItem(storageKey);
  } catch {
    // ignore
    return null;
  }
}

export const setItemForMerchantUser = (auth0User: string, merchantId: string, key: string, value: string): void => {
  if (!canUseLocalStorage()) {
    return;
  }

  try {
    const storageKey = createStorageKey(auth0User, merchantId, key);
    window.localStorage.setItem(storageKey, value);
  } catch {
    // ignore
  }
}

export const removeItemForMerchantUser = (auth0User: string, merchantId: string, key: string): void => {
  if (!canUseLocalStorage()) {
    return;
  }

  try {
    const storageKey = createStorageKey(auth0User, merchantId, key);
    window.localStorage.removeItem(storageKey);
  } catch {
    // ignore
  }
}
