
import configuration from '@/configuration';
import { statementDetailsPageName } from '@/router';
import { InvoiceTransactionsByOrderResponse } from '@/store/statement/statement-models';
import { applicationStore, featureStore, statementStore } from '@/store/store';
import moment from 'moment';
import Vue from 'vue';

export default Vue.extend({
  name: 'TransactionTable',
  props: {
    orderId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      statementTransactions: { rows: [] } as InvoiceTransactionsByOrderResponse,
      statementDetailsPageName: statementDetailsPageName,
    };
  },
  computed: {
    showTax(): any {
      return featureStore.shouldDisplayTaxInfo;
    },
    footerColSpan(): any {
      return this.showTax ? 6 : 4;
    },
  },
  async mounted() {
    this.statementTransactions = await statementStore.getStatementTransactionsForOrder(this.orderId);
  },
  methods: {
    displayDate(date: Date): string {
      if (date) {
        return moment(date).utc().locale(applicationStore.currentLocale.locale).format(configuration.dateFormat);
      }
      return '';
    },
  },
});
