
import Vue from 'vue';
/**
 * Component is used to center an image with a fixed width
 * in a flex container.
 */
export default Vue.extend({
  name: 'CenteredImage',
  props: {
    imagePath: {
      type: String,
      required: true,
    },
    image2xPath: {
      type: String,
      default: undefined,
    },
    image3xPath: {
      type: String,
      default: undefined,
    },
    imageWidth: {
      type: Number,
      default: 0,
    },
    alt: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      imageSrcSet: '',
    };
  },
  /**
   * Update the image srcset if higher-res images are available
   */
  created() {
    this.imageSrcSet = `${(this as any).imagePath} ${this.imageWidth}w`;

    if (this.image2xPath) {
      this.imageSrcSet = `${this.image2xPath} ${2 * this.imageWidth}w, ${this.imageSrcSet}`;
    }

    if (this.image3xPath) {
      this.imageSrcSet = `${this.image3xPath} ${3 * this.imageWidth}w, ${this.imageSrcSet}`;
    }
  },
});
