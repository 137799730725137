
import Vue from 'vue';
import configuration from '@/configuration';
import { applicationStore } from '@/store/store';
import ManageImage from '@/assets/images/mss/woocommerce-integration/Manage.svg?inline';
import PluginsImage from '@/assets/images/mss/woocommerce-integration/Plugins.svg?inline';
import AddPluginImage from '@/assets/images/mss/woocommerce-integration/AddPlugin.svg?inline';
import UploadPluginImage from '@/assets/images/mss/woocommerce-integration/UploadPlugin.svg?inline';
import ChoosePluginImage from '@/assets/images/mss/woocommerce-integration/ChoosePlugin.svg?inline';
import ActivatePluginImage from '@/assets/images/mss/woocommerce-integration/ActivatePlugin.svg?inline';
import PluginSettingsImage from '@/assets/images/mss/woocommerce-integration/PluginSettings.svg?inline';
import WidgetCustomizationImage from '@/assets/images/mss/woocommerce-integration/WidgetCustomization.svg?inline';
import IntegrationGatewayImage from '@/assets/images/mss/woocommerce-integration/IntegrationGateway.svg?inline';
import MerchantIntegrationInformation from '@/components/common/MerchantIntegrationInformation.vue';
import { mdiClockOutline, mdiTriangle } from '@mdi/js';

export default Vue.extend({
  name: 'WooCommerceIntegration',
  components: {
    ManageImage,
    PluginsImage,
    AddPluginImage,
    UploadPluginImage,
    ChoosePluginImage,
    ActivatePluginImage,
    PluginSettingsImage,
    WidgetCustomizationImage,
    IntegrationGatewayImage,
    'merchant-integration-info': MerchantIntegrationInformation,
  },
  data() {
    return {
      loading: false,
      clock: mdiClockOutline as string,
      triangle: mdiTriangle as string,
      showSteps: {
        gateway: false,
        widget: false,
      } as any,
    };
  },
  computed: {
    companyName(): string {
      return configuration.company.name;
    },
    currencySymbol(): string {
      return applicationStore.currentCountry.currencySymbol;
    },
    defaultMinTransaction(): string {
      return applicationStore.currentCountry.defaultMinTransaction;
    },
    defaultMaxTransaction(): string {
      return applicationStore.currentCountry.defaultMaxTransaction;
    },
  },
  async mounted() {
    if (this.$route?.params?.showWidgetStep) {
      await this.toggleShowStep('widget');
      document.getElementById('step-widget-container')?.scrollIntoView();
    } else if (this.$route?.params?.showGatewayStep) {
      await this.toggleShowStep('gateway');
      document.getElementById('step-gateway-container')?.scrollIntoView();
    }
  },
  methods: {
    toggleShowStep(key: string) {
      this.showSteps[key] = !this.showSteps[key];
    },
  },
});
