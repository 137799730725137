
import Vue from "vue";
import { onboardingStore } from "@/store/store";
import { UpdatePrimaryContactCommand } from "@/store/onboarding/onboarding-models";
import { DefaultValidatorArray, RequiredFieldValidator } from '@/validators';
import configuration from '@/configuration';

export type PrimaryContactInformation = {
  email: string;
  phone: string;
};

export default Vue.extend({
  name: "PrimaryContactInformation",
  data() {
    return {
      isEditMode: false,
      requiredField: DefaultValidatorArray,
      contactUsLink: configuration.contactUs,
    };
  },
  computed: {
    busienssEmail(): string {
      return onboardingStore.businessInfo.businessEmail;
    },
    businessPhoneNumber(): any {
      return onboardingStore.businessInfo.businessPhoneNumber;
    }
  },
  methods: {
    async submit() {
      const { form } = this.$refs;
      if (!this.isEditMode || !form.validate()) {
        return;
      }

      const body: UpdatePrimaryContactCommand = {
        businessEmail: onboardingStore.businessInfo.businessEmail,
        businessPhoneNumber: onboardingStore.businessInfo.businessPhoneNumber
      };
      await onboardingStore.updateOnboarding0PrimaryContact(body);
    

      this.$emit("submit", form);
      this.isEditMode = false;
    }
  },
  async created() {
      this.requiredField = RequiredFieldValidator;
  }
});
