
import { ComparableAggregatedResult } from '@/store/insights/insights-models';
import AggregatedResultChart from './AggregatedResultChart.vue';
import Vue, { PropType } from 'vue';
export default Vue.extend({
  name: 'TransactionValueChart',
  components: {
    'aggregated-result-chart': AggregatedResultChart
  },
  props: {
    data: {
      type: Object as PropType<ComparableAggregatedResult>,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
    },
    selectedReportedPeriod: {
      type: String,
      required: false,
    },
    selectedComaprisonPeriod: {
      type: String,
      required: false,
    },
    selectedReportedPeriodLabel: {
      type: String,
      required: false,
    },
    selectedComaprisonPeriodLabel: {
      type: String,
      required: false,
    },
    visible: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    title(): any {
      return this.$t('insights.chart-title-total-sales');
    },
  },
  methods: {
    getValue(entry: any): number {
      return entry?.totalValue ?? 0;
    },
    formatValue(val: number): string {
      return this.$n(val, 'currency');
    },
    formatVerticalAxisValue(val: number): string {
      return this.$n(val, 'currencyNoFraction');
    },
  },
});
