
import configuration from '@/configuration';
import moment from 'moment-timezone';
import StatementSummary from './StatementSummary.vue';
import { InvoiceDetail } from '@/store/statement/statement-models';
import { applicationStore, authenticationStore, featureStore, statementStore } from '@/store/store';
import SimpleView from '../layouts/SimpleView.vue';
import StatementTransactions from './StatementTransactions.vue';
import Vue from 'vue';

export default Vue.extend({
  name: 'StatementDetails',
  components: {
    'simple-view': SimpleView,
    'statement-summary': StatementSummary,
    'statement-transactions': StatementTransactions,
  },
  data() {
    return {
      showDialog: false,
    };
  },
  computed: {
    showTax(): any {
      return featureStore.shouldDisplayTaxInfo;
    },
    details(): InvoiceDetail {
      return statementStore.statementDetails;
    },
    currentLocale(): string {
      return applicationStore.currentLocale.locale;
    },
    currentIdentity(): any {
      return authenticationStore.currentIdentity;
    },
  },
  methods: {
    /**
     * Display the date, in Utc using the configuration.dateFormat
     */
    displayDate(date?: Date): string {
      if (date) {
        return moment(date).utc().locale(this.currentLocale).format(configuration.dateFormat);
      }
      return '';
    },
    toUnsignedAmount(n: number) : number {
      if (n) {
        return Math.abs(n);
      }
      return n;
    },
    isDebit(n?: number) : boolean {
      return !!n && n < 0;
    },
  },
});
