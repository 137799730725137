
import { mdiArrowRight } from '@mdi/js';
import Vue from 'vue';
export default Vue.extend({
  name: 'StatusItem',
  components: {},
  props: {
    title: {
      type: String,
      required: true,
    },
    linkText: {
      type: String,
      required: false,
    },
    linkTo: {
      type: Object,
      required: false,
    },
    firstLoad: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      forwardArrowIcon: mdiArrowRight as string,
    };
  },
});
