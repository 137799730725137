
import TextBox from '@/components/controls/TextBox.vue';
import Tooltip from '@/components/controls/Tooltip.vue';
import { DisputeEvidence } from '@/store/dispute/dispute-models';
import DisputeDetailsSection from '../DisputeDetailsSection.vue';
import Vue, { PropType } from 'vue';

export default Vue.extend({
    name: 'DisputeRefundSection',
    components: {
        'dispute-details-section': DisputeDetailsSection,
        'tooltip': Tooltip,
        'textbox': TextBox,
    },
    props: {
        evidence: {
            type: Object as PropType<DisputeEvidence>,
            required: true,
        },
        editable: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            refundPolicyDisclosure: '',
            refundRefusalExplanation: '',
        };
    },
    computed: {
        shouldShowSection(): any {
            return true;
        },
    },
    watch: {
        'refundPolicyDisclosure': function (newVal) {
            this.onRefundPolicyDisclosureChanged(newVal);
        },
        'refundRefusalExplanation': function (newVal) {
            this.onRefundRefusalExplanationChanged(newVal);
        },
    },
    async mounted() {
        this.refundPolicyDisclosure = this.evidence.refundPolicyDisclosure;
        this.refundRefusalExplanation = this.evidence.refundRefusalExplanation;
    },
    methods: {
        onRefundPolicyDisclosureChanged(newValue) {
            this.$emit('evidencePropertyChanged', { propertyName: 'refundPolicyDisclosure', propertyValue: newValue });
        },
        onRefundRefusalExplanationChanged(newValue) {
            this.$emit('evidencePropertyChanged', { propertyName: 'refundRefusalExplanation', propertyValue: newValue });
        },
    },
});
