
import BasicLayout from '@/components/layouts/BasicLayout.vue';
import StatementCsvDialog from '@/components/statements/StatementCsvDialog.vue';
import StatementDetails from '@/components/statements/StatementDetails.vue';
import { statementsPageName } from '@/router';
import { InvoiceDetail } from '@/store/statement/statement-models';
import { statementStore } from '@/store/store';
import Vue from 'vue';

export default Vue.extend({
  name: 'StatementDetailsPage',
  components: {
    'basic-layout': BasicLayout,
    'statement-details': StatementDetails,
    'statement-csv-dialog': StatementCsvDialog,
  },
  computed: {
    loading(): boolean {
      return statementStore.loadingStatementDetails;
    },
    details(): InvoiceDetail {
      return statementStore.statementDetails;
    },
  },
  data: () => {
    return {
      statementsLink: { name: statementsPageName },
    };
  },
  async created() {
    // Fetch statement details on page creation to ensure loadStatementDetails() is called before any components attempt to load and make api calls
    if (this.$route.params.statementId && typeof this.$route.params.statementId === "string") {
      await statementStore.loadStatementDetails(this.$route.params.statementId);
    }
  },
});
