
import SearchIcon from '@/assets/images/search.svg?inline';
import DoubleDatePicker, { DoubleDatePickerStatic } from '@/components/controls/DoubleDatePicker.vue';
import DisputesIcon from '@/assets/images/icons/sidebar/DisputesIconLarge.svg?inline';
import configuration from '@/configuration';
import moment from 'moment-timezone';
import { TranslateResult } from 'vue-i18n';
import { applicationStore, authenticationStore, disputeStore } from '@/store/store';
import { DataOptions, DataTableHeader } from 'vuetify';
import TableFooter, { TableFooterStatic } from '../controls/TableFooter.vue';
import SearchView from '../layouts/SearchView.vue';
import DisputeStatus, { DisputeStatusStatic } from './DisputeStatus.vue';
import DisputeReason from './DisputeReason.vue';
import { Dispute, DisputeDateFilterEnum, DisputeReconciledStatusEnum, DisputeStatusEnum } from '@/store/dispute/dispute-models';
import { disputeDetailsPageName } from '@/router';
import DisputeDateFilter from './DisputeDateFilter.vue';
import Tooltip from '../controls/Tooltip.vue';
import ComboBox from '../controls/ComboBox.vue';
import Vue from 'vue';

export default Vue.extend({
  name: 'Disputes', // eslint-disable-line vue/multi-word-component-names
  components: {
    'search-view': SearchView,
    'double-date-picker': DoubleDatePicker,
    'disputes-icon': DisputesIcon,
    'search-icon': SearchIcon,
    'table-footer': TableFooter,
    'dispute-status': DisputeStatus,
    'dispute-reason': DisputeReason,
    'dispute-date-filter': DisputeDateFilter,
    'tooltip': Tooltip,
    'combobox': ComboBox,
  },
  data() {
    const defaultStatusFilter = DisputeStatusEnum.needsResponse as DisputeStatusEnum;
    const defaultDateFilter = DisputeDateFilterEnum.disputeDate as DisputeDateFilterEnum;

    return {
      firstLoad: true,
      statusFilter: defaultStatusFilter,
      tableOptions: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['evidenceDueOn'],
        sortDesc: [true],
        groupBy: [],
        groupDesc: [],
        multiSort: false, // ability to sort by more than one column at once
        mustSort: true, // table must be sorted by at least one column
      } as DataOptions,
      endDate: moment.utc().endOf('day').toISOString(),
      startDate: moment.utc().subtract(3, 'month').startOf('month').toISOString(),
      selectedStatus: defaultStatusFilter,
      dateFilter: defaultDateFilter,
      selectedDateFilter: defaultDateFilter,
      disputeDetailsPageName: disputeDetailsPageName,
    };
  },
  computed: {
    loading(): boolean {
      return disputeStore.loadingDisputes;
    },
    notificationFeatureFlag(): boolean {
      return configuration.featureFlags.transactionNotification;
    },
    footerOptions(): any {
      return TableFooterStatic.getDefaultOptions(key => this.$t(key));
    },
    disputeArray(): Dispute[] {
      return disputeStore.disputeArray;
    },
    disputeCount(): number {
      return disputeStore.disputeCount;
    },
    tableHeaders(): DataTableHeader[] {
      return [
        {
          text: this.$t('disputes.id').toString(),
          value: 'id',
          sortable: false,
        },
        {
          text: this.$t('disputes.amount').toString(),
          value: 'amount',
          sortable: false,
          align: 'end',
        },
        {
          text: this.$t('disputes.status').toString(),
          value: 'status',
          sortable: false,
        },
        {
          text: this.$t('disputes.reason').toString(),
          value: 'reason',
          sortable: false,
        },
        {
          text: this.$t('disputes.customer-name').toString(),
          value: 'customerName',
          sortable: false,
        },
        {
          text: this.$t('disputes.order-id').toString(),
          value: 'orderId',
          sortable: false,
        },
        {
          text: this.$t('disputes.disputed-on').toString(),
          value: 'disputedOn',
        },
        {
          text: this.$t('disputes.evidence-due-on').toString(),
          value: 'evidenceDueOn',
        }
      ];
    },
    currentLocale(): string {
      return applicationStore.currentLocale.locale;
    },
    currentIdentity(): any {
      return authenticationStore.currentIdentity;
    },
    statusFilterText(): TranslateResult {
      return this.$t('disputes.status-filter');
    },
    disputeStatusItems(): any {
      const values: string[] = [];
      for (const n in DisputeStatusEnum) {
        if (typeof n === 'string') {
          values.push(n);
        }
      }
      return values.map(value => {
        return { value: DisputeStatusEnum[value], text: this.getStatusString(DisputeStatusEnum[value]) }
      });
    },
  },
  watch: {
    'currentIdentity': async function() {
      await this.updateSearch();
    },
    'tableOptions': async function() {
      await this.updateTable();
    },
  },
  async mounted() {
    // Check query params
    if (this.$route.query.page && !isNaN(parseInt(this.$route.query.page as string, 10))) {
      this.tableOptions.page = parseInt(this.$route.query.page as string, 10);
    }
    if (this.$route.query.pageSize && !isNaN(parseInt(this.$route.query.pageSize as string, 10))) {
      this.tableOptions.itemsPerPage = parseInt(this.$route.query.pageSize as string, 10);
    }
    if (this.$route.query.status && Object.values(DisputeStatusEnum).includes(this.$route.query.status as DisputeStatusEnum)) {
      this.statusFilter = this.$route.query.status as DisputeStatusEnum;
      this.selectedStatus = this.statusFilter;
    }
    if (this.$route.query.date && Object.values(DisputeDateFilterEnum).includes(this.$route.query.date as DisputeDateFilterEnum)) {
      this.dateFilter = this.$route.query.date as DisputeDateFilterEnum;
      this.selectedDateFilter = this.dateFilter;
    }
    if (this.$route.query.desc) {
      this.tableOptions.sortDesc[0] = this.$route.query.desc === 'true';
    }
    if (this.$route.query.sortBy) {
      this.tableOptions.sortBy[0] = this.$route.query.sortBy as string;
    }

    // Disable skeleton loader
    this.firstLoad = false;
  },
  methods: {
    /**
     * Display the date, in Utc using the configuration.dateFormat
     */
    displayDateUtc(date?: Date): string {
      if (date) {
        return moment(date).utc().locale(this.currentLocale).format(configuration.dateFormat);
      }
      return '';
    },
    displayCustomerName(dispute) {
      return `${dispute.customerFirstName} ${dispute.customerLastName}`;
    },
    displayOrderNumber(dispute) {
      return dispute.orderNumber ?? dispute.paymentPlanId;
    },
    displayDisputeNumber(dispute) {
      return dispute.disputeId ?? dispute.id;
    },
    getStatusString(status?: DisputeStatusEnum): TranslateResult | string {
      return DisputeStatusStatic.getStatusString(status === undefined ? undefined : DisputeReconciledStatusEnum[DisputeStatusEnum[status]]);
    },
    async updateSearch() {
      // Update results as identity changes
      // When logging out don't bother searching
      if (!this.currentIdentity?.merchant_id) {
        return;
      }
      await this.searchDisputes();
    },
    async updateTable() {
      await this.searchDisputes();
    },
    async paginate(newPage) {
      this.tableOptions.page = newPage;
      await this.searchDisputes();
    },
    onStatusFilterChanged(value: DisputeStatusEnum) {
      this.statusFilter = value;

      this.searchDisputes(1);
    },
    onDateFilterChanged(value: DisputeDateFilterEnum) {
      this.dateFilter = value;

      this.searchDisputes(1);
    },
    async searchDisputes(pageNumber: number | null = null) {
      this.tableOptions.page = pageNumber || this.tableOptions.page;
      const { page, itemsPerPage, sortDesc, sortBy } = this.tableOptions;

      await disputeStore.searchDisputes({
        options: {
          status: this.statusFilter,
          dateType: this.dateFilter,
          page,
          pageSize: itemsPerPage,
          orderBy: sortDesc[0] ? `${sortBy[0]},desc` : `${sortBy[0]},asc`,
          startDate: this.startDate,
          endDate: this.endDate,
        },
        apiEndDate: DoubleDatePickerStatic.getInclusiveEndDate(this.endDate)
      });

      this.setDisputesSearchParams();
    },
    async startDateUpdated(newDate: string) {
      this.startDate = moment.utc(newDate, "YYYY-MM-DD").startOf("day").toISOString();
      await this.searchDisputes(1);
    },
    async endDateUpdated(newDate: string) {
      this.endDate = moment.utc(newDate, "YYYY-MM-DD").endOf("day").toISOString();
      await this.searchDisputes(1);
    },
    setDisputesSearchParams() {
      const url = new URL(window.location.href);

      const params = new URLSearchParams('');

      if (this.statusFilter) {
        params.set('status', this.statusFilter as string);
      }
      params.set('page', this.tableOptions.page.toString());
      params.set('pageSize', this.tableOptions.itemsPerPage.toString());
      params.set('desc', this.tableOptions.sortDesc[0].toString());
      params.set('sortBy', this.tableOptions.sortBy[0]);

      url.search = '' + params;

      // Must use history push to ensure the page is not reloaded
      history.pushState({}, '', url.toString());
    },
  },
});
