
import Vue from "vue";
import { merchantStore } from "@/store/store";
import CardLayout from "@/components/layouts/CardLayout.vue";
import ActionButton from "@/components/controls/ActionButton.vue";

export default Vue.extend({
  name: "ConfirmWebsite",
  components: {
    "action-button": ActionButton,
    "card-layout": CardLayout,
  },
  computed: {
    website(): string {
      return merchantStore.onboardingMerchant.businessInfo?.website || "";
    },
  },
  methods: {
    back() {
      this.$emit("back");
    },
    update() {
      this.$emit("update");
    },
    cancel() {
      this.$emit('cancel')
    },
  },
});
