
import logger from "@/logger";
import {
  bigCommerce,
  magento2,
  oracleAtg,
  shopify,
  shopifyCartHook,
  spree,
  woocommerce
} from "@/store/merchant/merchant-settings";
import { authenticationStore, merchantStore } from "@/store/store";
import ActionButton from "@/components/controls/ActionButton.vue";
import Vue from "vue";
export default Vue.extend({
  name: "MerchantIntegrationInformation",
  data() {
    return {
      showMerchantNameTooltip: false,
      showMerchantIdTooltip: false,
      showClientIdTooltip: false,
      showClientSecretTooltip: false,
      showApiKeyTooltip: false,
      timerId: undefined as any,
      showMerchantId: true,
      showApiKeyValue: false
    };
  },
  components: {
    "action-button": ActionButton
  },
  computed: {
    /**
     * Return true if the merchant is an MSS-supported platform.  If not,
     * we will use that determination to show other integration credentials
     * to better ensure we can add more platforms without limiting the
     * merchant's experience with this component.
     */
    hasCredentialDisplayHandlingForPlatform(): boolean {
      return (
        [
          bigCommerce.systemName,
          magento2.systemName,
          oracleAtg.systemName,
          shopify.systemName,
          shopifyCartHook.systemName,
          spree.systemName,
          woocommerce.systemName
        ].indexOf(merchantStore.platform) >= 0
      );
    },
    merchantName(): string {
      return merchantStore.merchantName;
    },
    merchantId(): string {
      return merchantStore.isExistingMerchant
        ? merchantStore.merchantId
        : merchantStore.onboardingMerchantId;
    },
    clientId(): string {
      return merchantStore.clientId;
    },
    clientSecret(): string {
      return merchantStore.clientSecret;
    },
    showMerchantName(): boolean {
      return (
        [
          bigCommerce.systemName,
          magento2.systemName,
          shopifyCartHook.systemName,
          spree.systemName
        ].indexOf(merchantStore.platform) >= 0 ||
        !this.hasCredentialDisplayHandlingForPlatform
      );
    },
    showClientId(): boolean {
      return (
        this.showApiKeyValue &&
        ([
          magento2.systemName,
          oracleAtg.systemName,
          shopifyCartHook.systemName,
          spree.systemName,
          woocommerce.systemName
        ].indexOf(merchantStore.platform) >= 0 ||
          !this.hasCredentialDisplayHandlingForPlatform)
      );
    },
    showClientSecret(): boolean {
      return (
        this.showApiKeyValue &&
        ([
          magento2.systemName,
          oracleAtg.systemName,
          shopifyCartHook.systemName,
          woocommerce.systemName
        ].indexOf(merchantStore.platform) >= 0 ||
          !this.hasCredentialDisplayHandlingForPlatform)
      );
    },
    showApiKey(): boolean {
      return (
        this.showApiKeyValue && !this.hasCredentialDisplayHandlingForPlatform
      );
    },
    currentIdentity(): any {
      return authenticationStore.currentIdentity;
    }
  },
  watch: {
    merchantId: async function() {
      await this.updateSearch();
    }
  },
  beforeDestroy() {
    window.clearTimeout(this.timerId);
  },
  methods: {
    /**
     *     BigCommerce
     *     merchantId
     *     (SMI -- hide keys)
     *
     *     Magento 2
     *     merchantName
     *     merchantId
     *     clientID
     *     clientSecret
     *
     *     Oracle
     *     merchantId
     *     clientID
     *     clientSecret
     *
     *     Salesforce Commerce Cloud
     *     merchantId
     *
     *     Shopify
     *     merchantId
     *     api key (client secret)
     *
     *     Spree
     *     merchantName
     *     merchantId
     *     clientId
     *
     *     Sylius
     *     merchantId
     *
     *     WooCommerce
     *     merchantId
     *     clientID
     *     clientSecret
     */
    copy(
      event: ClipboardEvent,
      elementId: string,
      visibilitySetter: (visible: boolean) => void
    ) {
      const elementToCopy = document.getElementById(elementId);
      if (!elementToCopy) {
        return;
      }

      (elementToCopy as any).select();

      try {
        document.execCommand("copy");
        // TODO better UX for successful copy
        logger.info("Copy successful");

        this.showTooltip(visibilitySetter);
      } catch (err) {
        alert(this.$t("merchantIntegrationInformation.unable-copy-alert"));
      }
      // Unselect the range
      (window as any).getSelection().removeAllRanges();
    },
    showTooltip(visibilitySetter: (visible: boolean) => void) {
      visibilitySetter(true);
      this.timerId = window.setTimeout(() => {
        visibilitySetter(false);
      }, 1000);
    },
    async updateSearch() {
      if (this.merchantId) {
        await merchantStore.getKeys(true);
      }
    },
    async enableShowApiKey() {
      await merchantStore.getKeys(true);
      this.showApiKeyValue = true;
    }
  }
});
