
import BasicLayout from '@/components/layouts/BasicLayout.vue';
import InviteUserDialog from '@/components/users/InviteUserDialog.vue';
import Users from '@/components/users/Users.vue';
import UserSearchBar from '@/components/users/UserSearchBar.vue';
import Vue from 'vue';

export default Vue.extend({
  name: 'UsersPage',
  components: {
    'basic-layout': BasicLayout,
    "invite-user-dialog": InviteUserDialog,
    'users': Users,
    'user-search-bar': UserSearchBar,
  },
  data() {
    return {
      searchTerms: "",
    };
  },
  computed: {
    search(): any {
      return this.searchTerms;
    },
  },
  methods: {
    searchUsers(searchText) {
      this.searchTerms = searchText;
    },
  },
});
