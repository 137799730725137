
import { mdiArrowLeft } from '@mdi/js';
import LoadingSpinner from '../common/LoadingSpinner.vue';
import HeaderNotificationList from '../common/HeaderNotificationList.vue';
import Vue from 'vue';

export default Vue.extend({
  name: 'BasicLayout',
  components: {
    'loading-spinner': LoadingSpinner,
    'header-notification-list': HeaderNotificationList,
  },
  props: {
    pageHeading: {
      type: String,
      required: false,
    },
    pageSubHeading: {
      type: String,
      required: false,
    },
    navBackLinkText: {
      type: String,
      required: false,
    },
    navBackLinkTo: {
      type: Object,
      required: false,
    },
    isLoading: {
      type: Boolean,
      required: false,
    },
    disableMainStyle: {
      type: Boolean,
      required: false,
    },
    fullWidth: {
      type: Boolean,
      required: false,
    },
    transparentBackground: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      backArrowIcon: mdiArrowLeft as string,
    };
  },
});
