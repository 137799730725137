
import moment from 'moment-timezone';
import configuration from '@/configuration';
import { applicationStore, merchantStore, statementStore as invoiceStore } from '@/store/store';
import StatementSummary from './StatementSummary.vue';
import DownloadCsvDialog from '../common/DownloadCsvDialog.vue';
import { Invoice, InvoiceDetail } from '@/store/statement/statement-models';
import statementStore from '@/store/statement/statement-store';
import Vue, { PropType } from 'vue';

export default Vue.extend({
  name: 'StatementCsvDialog',
  components: {
    'statement-summary': StatementSummary,
    'download-csv-dialog': DownloadCsvDialog
  },
  props: {
    startDate: {
      type: String,
      required: false,
    },
    endDate: {
      type: String,
      required: false,
    },
    invoiceDetail: {
      type: Object as PropType<InvoiceDetail | Invoice>,
      required: false,
    },
    mode: {
      type: String as PropType<undefined | 'normal' | 'compact' | 'embedded'>,
      required: false,
    },
  },
  computed: {
    companyName(): string {
      return configuration.company.name;
    },
    currentLocale(): string {
      return applicationStore.currentLocale.locale;
    },
    dataType(): string {
      return 'statement transactions as CSV';
    },
    invoiceId(): any {
      const invoice: any = this.invoiceDetail;
      return invoice.invoiceId;
    },
    actionText(): any {
      return this.invoiceDetail ? undefined : this.$t('statementCsvDialog.download-csv-text');
    },
  },
  methods: {
    getFilename(args: any) {
      const dateFormat = 'YYYY-MM-DD';
      const prefix = `${merchantStore.merchant.legalName} ${this.$t('statementCsvDialog.csv-export')} `;
      if (this.invoiceDetail) {
        return `${prefix} ${this.invoiceDetail.invoiceNumber ?? this.invoiceId}.csv`;
      }
      return `${prefix} ${moment.utc(args.startDate).format(dateFormat).toString()} to ${moment.utc(args.endDate).format(dateFormat).toString()}.csv`
    },
    getCsvDownloadUrl(query: any) {
      return this.invoiceDetail
        ? statementStore.getStatementTransactionsCsvUrl({ ...query, invoiceId: this.invoiceId })
        : statementStore.getStatementTransactionsCsvByDateRangeUrl(query);
    },
    getCsvPagingInfo(query: any) {
      return this.invoiceDetail
        ? invoiceStore.getStatementTransactionsPagingInfo({ ...query, invoiceId: this.invoiceId })
        : invoiceStore.getStatementTransactionsByDateRangePagingInfo(query);
    },
  },
});
