
import configuration from '@/configuration';
import { stripeStore } from '@/store/store';
import Vue from 'vue';
export default Vue.extend({
  name: 'StripeSettings',
  data() {
    return {
      redirectLink: '',
    };
  },
  computed: {
    companyName(): string {
      return configuration.company.name;
    },
  },
  async mounted() {
    this.redirectLink = await stripeStore.getStripeConnectLink(false);
  },
});
