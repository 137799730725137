/**
 * TypeScript load-only version of load script plugin - https://github.com/tserkov/vue-plugin-load-script
 * Used to load external scripts within Vue using a promise-based solution.
 */
import Vue from 'vue';

export function loadScriptPlugin(vue: typeof Vue): void {
  // do stuff with options
  vue.prototype.$loadScript = loadScript;
}

/**
 * Load a script with the specified source in <head> and resolve
 * the promise once loaded.
 * @param src
 */
export const loadScript = (src: string) => {
  return new Promise((resolve, reject) => {
    if (document.querySelector('script[src="' + src + '"]')) {
      resolve(true);

      return;
    }

    const el = document.createElement('script');

    el.type = 'text/javascript';
    el.async = true;
    el.defer = true;
    el.src = src;

    el.addEventListener('load', resolve);
    el.addEventListener('error', reject);
    el.addEventListener('abort', reject);

    document.head.appendChild(el);
  });
};

Vue.use(loadScriptPlugin);
