
import logger from '@/logger';
import { authenticationStore } from '@/store/store';
import { TranslateResult } from 'vue-i18n';
import Vue, { PropType } from 'vue';
/**
 * Component is used to center an image with a fixed width
 * in a flex container.
 */
export default Vue.extend({
  name: 'SessionExpiringModal',
  components: {},
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    cancel: {
      type: Function as PropType<() => void>,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      done: false,
      title: '' as TranslateResult,
      text: '' as TranslateResult,
      dialogClass: 'qp-info',
      showState: false,
    };
  },
  watch: {
    'show': function(newVal) {
      this.showState = newVal;
    },
  },
  mounted() {
    // Initialize with i18n
    this.title = this.$t('sessionExpiringModal.title');
    this.text = this.$t('sessionExpiringModal.text');
    this.showState = this.show;
  },
  methods: {
    /**
     * Trigger authentication refresh
     */
    async refreshAuth(event: Event) {
      event.preventDefault();

      // UI idempotency so a user doesn't submit a form that's already been submitted
      if (this.loading) {
        return;
      }

      this.loading = true;

      try {
        await authenticationStore.refreshToken();
        this.text = this.$t('sessionExpiringModal.success');
        this.title = this.$t('sessionExpiringModal.title-success');
        this.dialogClass = 'qp-success';
      } catch (e) {
        logger.error('Error refreshing token: ', e);
        this.text = this.$t('sessionExpiringModal.failure');
        this.dialogClass = 'qp-error';
      } finally {
        this.loading = false;
        this.done = true;
      }
    },
  },
});
