
import CheckImage from '@/assets/images/check.svg?inline';
import DialogBox from '@/components/common/DialogBox.vue';
import SimpleCopyrightFooter from '@/components/common/SimpleCopyrightFooter.vue';
import SimpleLogoHeader from '@/components/common/SimpleLogoHeader.vue';
import logger from '@/logger';
import { authenticationStore } from '@/store/store';
import { TranslateResult } from 'vue-i18n';
import Vue from 'vue';

export default Vue.extend({
  name: 'CheckEmail',
  components: {
    'check-image': CheckImage,
    'dialog-box': DialogBox,
    'simple-footer': SimpleCopyrightFooter,
    'simple-header': SimpleLogoHeader,
  },
  data() {
    return {
      showDialog: false,
      loading: true,
      dialogClass: "",
      dialogTitle: "" as TranslateResult,
      dialogMessage: "" as TranslateResult,
    };
  },
  computed: {
    email(): string {
      return authenticationStore.userInfo?.email || "";
    },
  },
  methods: {
    closeDialogBox() {
      this.showDialog = false;
    },
    async resendVerificationEmail() {
      try {
        // Requires BE endpoint so for now will always succeed
        await authenticationStore.resendVerificationEmail();
        this.dialogClass = 'qp-success';
        this.dialogTitle = this.$t('checkEmail.resend-success-title');
        this.dialogMessage = this.$t('checkEmail.resend-success-message', { email: this.email });
      } catch (e) {
        logger.error(`Error resending verification email: ${e.message}`);
        this.dialogClass = 'qp-error';
        this.dialogTitle = this.$t('checkEmail.resend-failure-title');
        this.dialogMessage = this.$t('checkEmail.resend-failure-message');
      } finally {
        this.showDialog = true;
      }
    },
  },
});
