/**
 * Generates a unique alphanumeric string to the length specified.  Based on the accepted answer here:
 * https://stackoverflow.com/questions/1349404/generate-random-string-characters-in-javascript
 * @param length length of the resulting string
 */
export function generateNonce(length: number) {
  let result = '';
  const characters = 'ABCDEFGHJKLMNPQRSTUVWXYZabcdefghjkmnpqrstuvwxyz23456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    // tslint:disable-next-line: insecure-random
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}
export const cspNonce = generateNonce(12);

export function setupContentSecurityPolicy() {
  const metaTag = document.createElement('meta');
  metaTag.httpEquiv = 'Content-Security-Policy';
  // tslint:disable: max-line-length
  metaTag.content = `default-src 'self' 'nonce-${cspNonce}' *.quadpay.com *.quadpay.xyz *.zip.co *.zip.xyz;
    script-src 'self' 'nonce-${cspNonce}' www.google.com js.stripe.com cdn.segment.com;
    style-src 'self' 'unsafe-inline' fonts.googleapis.com;
    img-src 'self';
    connect-src 'self' dc.services.visualstudio.com sentry.io *.stripe.com *.quadpay.com *.quadpay.xyz *.zip.co *.zip.xyz api.segment.io fonts.googleapis.com *.fullstory.com;
    frame-src www.google.com www.facebook.com js.stripe.com *.quadpay.com *.zip.co *.zip.xyz *.fullstory.com;
    font-src 'self' fonts.gstatic.com;`;
  // tslint:enable: max-line-length
  document.getElementsByTagName('head')[0].appendChild(metaTag);
}

