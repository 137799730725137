
import { getBrowserTimeZone, getOffsetForTimezone } from '@/datetime';
import { Timezone } from '@/store/application/application-models';
import { applicationStore } from '@/store/store';
import Vue from 'vue';

const reorderTimezones = (timezones: Timezone[]): Timezone[] => {
  const browserTimeZone = getBrowserTimeZone();
  const matchingTimezone = timezones.find(tz => tz.ianaTimezoneName === browserTimeZone);
  if (!matchingTimezone) {
    return timezones;
  }

  const nonMatchingTzs = timezones.filter(tz => tz.ianaTimezoneName !== browserTimeZone);

  return [
    matchingTimezone,
    ...nonMatchingTzs
  ];
}
export default Vue.extend({
  name: 'TimezoneSettings',
  data() {
    return {
      supportedTimezones: reorderTimezones(applicationStore.supportedTimezones),
    };
  },
  computed: {
    currentTimezone: {
      get(): any {
        return applicationStore.userTimezone;
      },
      set(newTimezone: Timezone) {
        applicationStore.setCurrentTimezone(newTimezone);
      },
    },
  },
  methods: {
    timezoneDisplay(timeZoneName: string) {
      return this.$t(`timezones.${timeZoneName}`, { offset: getOffsetForTimezone(timeZoneName) });
    },
  },
});
