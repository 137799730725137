
import Error from '@/components/error/Error.vue';
import Vue from 'vue';

export default Vue.extend({
  name: 'ErrorPage',
  components: {
    'error': Error,
  },
});
