
import moment from 'moment-timezone';
import configuration from '@/configuration';
import { applicationStore } from '@/store/store';
import 'moment/locale/fr-ca';
import TextBox from './TextBox.vue';
import Vue from 'vue';

export default Vue.extend({
  name: 'DoubleDatePicker',
  components: {
    'textbox': TextBox,
  },
  props: {
    locale: { type: String, required: true },
    startDate: { type: String, required: false, default: moment.utc().startOf('month').toISOString() },
    endDate: { type: String, required: false, default: moment.utc().endOf('month').toISOString() },
    startDateFilterOff: { type: Boolean, required: false, default: false },
    endDateFilterOff: { type: Boolean, required: false, default: false },
  },
  data() {
    return {
      startDateState: "",
      endDateState: "",
      startDateFilterOffState: false,
      endDateFilterOffState: false,

      // v-date-picker accepts ISO 8601 date strings (YYYY-MM-DD)
      defaultStartDate: moment.utc().startOf('month').toISOString(),
      defaultEndDate: moment.utc().endOf('month').toISOString(),
      minimumStartDate: moment.utc().subtract(11, 'years').toISOString(),
      maximumEndDate: moment.utc().endOf('month').toISOString(),
      startDateMenuOpened: false,
      endDateMenuOpened: false,
    };
  },
  computed: {
    maximumStartDate(): string {
      return moment.utc(this.endDateState).toISOString();
    },
    startDateText(): string {
      return this.startDateFilterOffState ? this.$t('doubleDatePicker.start-date').toString() : moment.utc(this.startDateState).locale(this.currentLocale).format(configuration.dateFormat);
    },
    minimumEndDate(): string {
      return moment.utc(this.startDateState).toISOString();
    },
    endDateText(): string {
      return this.endDateFilterOffState ? this.$t('doubleDatePicker.end-date').toString() : moment.utc(this.endDateState).locale(this.currentLocale).format(configuration.dateFormat);
    },
    currentLocale(): string {
      return applicationStore.currentLocale.locale;
    },
  },
  watch: {
    startDate: function(newVal) {
      this.startDateState = newVal;
    },
    endDate: function(newVal) {
      this.endDateState = newVal;
    },
    startDateFilterOff: function(newVal) {
      this.startDateFilterOffState = newVal;
    },
    endDateFilterOff: function(newVal) {
      this.endDateFilterOffState = newVal;
    },
  },
  async mounted() {
    this.startDateState = this.startDate;
    this.endDateState = this.endDate;
    this.startDateFilterOffState = this.startDateFilterOff;
    this.endDateFilterOffState = this.endDateFilterOff;
  },
  methods: {
    onStartDateUpdated(newValue) {
      this.startDateFilterOffState = false;
      this.startDateMenuOpened = false;
      this.$emit('startDateUpdate', newValue);
    },
    onEndDateUpdated(newValue) {
      this.endDateFilterOffState = false;
      this.endDateMenuOpened = false;
      this.$emit('endDateUpdate', newValue);
    },
  },
});

export class DoubleDatePickerStatic {
  static getInclusiveEndDate(endDate: string): string {
    return moment.utc(endDate).startOf('day').add(1, 'day').toISOString();
  }
}
