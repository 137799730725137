import store from '@/store/store';
import { Action, config, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { CountryEntry, StateCollection, StateEntry } from './place-models';
import isoCountries from 'i18n-iso-countries';
import _states from 'states-us';

// Set rawError for all Actions in module to true
config.rawError = true;

/**
 * A special locale-aware store for country code / states / other place related data.
 */
@Module({
    name: 'place',
    namespaced: true,
    store,
})
export default class PlaceStore extends VuexModule {
    currentLanguage: string | null = null;
    currentCountry: string | null = null;
    countries: CountryEntry[] = [];
    states: StateCollection = {};

    /**
     * Reload countries and US states data
     */
    @Action
    async refreshCountries(args: { language: string, currentCountry: string }): Promise<any> {
        if (args.currentCountry !== this.currentCountry || args.language !== this.currentLanguage) {
            isoCountries.registerLocale(require(`i18n-iso-countries/langs/${args.language}.json`));
            const countries: CountryEntry[] = [];
            countries.push({ code: args.currentCountry, name: isoCountries.getName(args.currentCountry, args.language) });
            for (const code in isoCountries.getAlpha2Codes()) {
                countries.push({ code, name: isoCountries.getName(code, args.language) });
            }

            this.setCurrentCountry(args.currentCountry);
            this.setCurrentLanguage(args.language);
            this.setCountries(countries);
        }
    }

    @Action
    async ensureStates(country: string) {
        const states = this.states[country];
        if (states) {
            return states;
        }

        // NOTE: currently only supports US states data
        // Lazy load
        if (country === 'US') {
            const arr: StateEntry[] = [];
            for (const state of _states) {
                arr.push({ ...state, code: state.abbreviation });
            }
            this.cacheStates({ country, states: arr });
        }
    }

    @Mutation
    setCurrentCountry(country: string) {
        this.currentCountry = country;
    }

    @Mutation
    setCurrentLanguage(language: string) {
        this.currentLanguage = language;
    }

    @Mutation
    setCountries(countries: CountryEntry[]) {
        this.countries = countries;
    }

    @Mutation
    cacheStates(args: { country: string, states: StateEntry[] }) {
        this.states[args.country] = args.states;
    }

    @Mutation
    reset() {
        this.currentLanguage = null;
        this.currentCountry = null;
        this.countries = [];
        this.states = {};
    }
}