
import BasicLayout from '@/components/layouts/BasicLayout.vue';
import OrderDetails from '@/components/orders/OrderDetails.vue';
import { ordersPageName } from '@/router';
import { Order } from '@/store/order/order-models';
import { orderStore } from '@/store/store';
import Vue from 'vue';

export default Vue.extend({
  name: 'OrderDetailsPage',
  components: {
    'basic-layout': BasicLayout,
    'order-details': OrderDetails,
  },
  computed: {
    order(): Order {
      return orderStore.order;
    },
    loading(): boolean {
      return orderStore.loadingOrder;
    },
  },
  data: () => {
    return {
      ordersLink: { name: ordersPageName },
    };
  },
  async created() {
    // Fetch order on page creation to ensure getOrder is called before any components attempt to load and make api calls
    if (this.$route.params.orderId && typeof this.$route.params.orderId === "string") {
      await orderStore.getOrder(this.$route.params.orderId);
    }
  },
});
