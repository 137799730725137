
import { toLocalDate } from '@/datetime';
import { DisputeDetail } from '@/store/dispute/dispute-models';
import { mdiChevronDown, mdiChevronUp } from '@mdi/js';
import DisputeReason from '../../DisputeReason.vue';
import Tooltip from '../../../controls/Tooltip.vue';
import Vue, { PropType } from 'vue';
import { orderPageName } from '@/router';

export default Vue.extend({
  name: 'DisputeSummaryPanel',
  components: {
    "dispute-reason": DisputeReason,
    "tooltip": Tooltip,
  },
  props: {
    dispute: {
      type: Object as PropType<DisputeDetail>,
      required: true,
    },
    inline: { type: Boolean, required: false, default: false },
  },
  data() {
    return {
      collapseIcon: mdiChevronUp as string,
      expandIcon: mdiChevronDown as string,
      expandedRows: [] as any,
      expand: true,
      orderPageName: orderPageName,
    };
  },
  computed: {
    amount(): any {
      return this.$n(this.dispute.disputedAmount, 'currency');
    },
    dueDate(): any {
      return toLocalDate(this.dispute.evidenceDueOn);
    },
    orderId(): any {
      return this.dispute.orderNumber ?? this.dispute.orderId;
    },
    customerName(): any {
      return `${this.dispute.customerFirstName} ${this.dispute.customerLastName}`;
    },
    isMobile(): any {
      return this.$vuetify.breakpoint.xs;
    },
  },
  beforeDestroy() {
    clearTimeout(this.timerId);
  },
  methods: {
    isOpen() {
      this.expand = !this.expand;
    },
  },
});
