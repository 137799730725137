
import NotFound from '@/components/error/NotFound.vue';
import Vue from 'vue';

export default Vue.extend({
  name: 'NotFoundPage',
  components: {
    'not-found': NotFound,
  },
});
