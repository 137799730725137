import configuration from '@/configuration';
import LogMethod from '@/decorators/logger-decorator';
import { handleResponseErrors } from '@/errors';
import logger from '@/logger';
import {
  Order,
  OrderNewCountResult,
  OrderSearchObject,
  OrderSearchOptions,
  OrderSearchResult,
  RefundCommand,
} from '@/store/order/order-models';
import store, { authenticationStore } from '@/store/store';
import { AuthorizationError } from '@/store/store-models';
import {
  config,
  Action,
  Module,
  Mutation,
  VuexModule,
} from 'vuex-module-decorators';
import { ordersPageName, routerPush } from '@/router';
import moment from 'moment-timezone';
import { getObject } from '../store-requests';
import { getTimezoneForMerchantUser } from '@/datetime';

// Set rawError for all Actions in module to true
config.rawError = true;

// QP Merchant API Url
const merchantApiUrl = `${configuration.links.apiDomain}${configuration.links.apiPath}/`;

const DefaultOrderArray = [];
const DefaultOrderCount = 0;
const DefaultLoadingOrder = true;
const DefaultOrder = {};

/**
 * The order store is responsible for managing merchant order data and business logic
 */
@Module({
  name: 'order',
  namespaced: true,
  store,
})
export default class OrderStore extends VuexModule {
  orderArray: OrderSearchObject[] = DefaultOrderArray;
  orderCount: number = DefaultOrderCount;
  loadingOrder: boolean = DefaultLoadingOrder;
  order: Order = DefaultOrder;

  @Action
  async getNewOrderCount(): Promise<number | undefined> {
    const url = {
      service: 'orders/new-count',
      query: { }
    };

    const result: OrderNewCountResult = await getObject({
      url,
      options: {
        dataType: `count of new orders`
      }
    });

    return result?.count;
  }

  /**
   * Get Merchant Orders by merchantId
   */
  @Action
  async searchOrders(searchOptions: OrderSearchOptions): Promise<any> {
    const { page, pageSize, orderBy, text, minimumCreateDateTime, maximumCreateDateTime } = searchOptions;
    const { accessToken } = authenticationStore;
    const merchantId = authenticationStore.merchantId;
    let url = `${merchantApiUrl}${merchantId}/orders-v2`;
    const tz = getTimezoneForMerchantUser();
    const convertedMinTime = moment.utc(minimumCreateDateTime).tz(tz, true).startOf('day').format();
    const convertedMaxTime = moment.utc(maximumCreateDateTime).tz(tz, true).endOf('day').format();
    url += `?Page=${page}`;
    url += `&PageSize=${pageSize}`;
    url += `&OrderBy=${orderBy}`;
    url += `&SearchTerm=${text}`;
    url += `&MinimumCreateDateTime=${encodeURIComponent(moment(convertedMinTime).isValid() ? convertedMinTime : '')}`;
    url += `&MaximumCreateDateTime=${encodeURIComponent(moment(convertedMaxTime).isValid() ? convertedMaxTime : '')}`;

    try {
      const response = await fetch(url, {
        headers: {
          authorization: `Bearer ${accessToken}`,
          'qp-territory': authenticationStore.currentTerritory,
        },
      });
      logger.debug("Search response", response);
      if (response.status > 226) {
        // Trigger error modal
        await handleResponseErrors(response, 'orders', true);
        return;
      } else {
        const body = await response.json();
        this.setOrderSearchResult(body);
      }
    } catch (e) {
      // If 401, throw
      if (e instanceof AuthorizationError) {
        throw e;
      }
      // Else log error and trigger error modal
      logger.error(`Undefined error fetching merchant ${merchantId} orders`, e);
    }
  }

  /**
   * Get Order by orderId
   * @param orderId
   */
  @Action
  async getOrder(orderId: string): Promise<any> {
    this.setLoadingOrder(true);
    const { accessToken } = authenticationStore;
    const merchantId = authenticationStore.merchantId;
    const url = `${merchantApiUrl}${merchantId}/${orderId}`;
    try {
      const response = await fetch(url, {
        headers: {
          authorization: `Bearer ${accessToken}`,
          'qp-territory': authenticationStore.currentTerritory,
        },
      });
      logger.debug(`Order ${orderId}`, response);
      if (response.status > 226) {
        // Redirect to OrderMainPage
        routerPush({ name: ordersPageName });
        // Trigger error modal
        await handleResponseErrors(response, 'orderId', true);
        return;
      } else {
        const body = await response.json();
        this.setOrder(body);
        this.setLoadingOrder(false);
      }
    } catch (e) {
      // If 401, throw
      if (e instanceof AuthorizationError) {
        throw e;
      }
      // Else log error and trigger error modal
      logger.error(`Undefined error fetching order ${orderId}`, e);
    }
  }

  /**
   * Get maximum refund amount
   * @param orderId 
   */
  @Action
  async getMaxRefundAmount(orderId: string): Promise<number> {
    const { accessToken } = authenticationStore;
    const merchantId = authenticationStore.merchantId;
    const url = `${merchantApiUrl}${merchantId}/${orderId}/maximumrefundamount`;
    try {
      const response = await fetch(url, {
        headers: {
          authorization: `Bearer ${accessToken}`,
          'qp-territory': authenticationStore.currentTerritory,
        },
      });
      if (response.status > 226) {
        // Return 0
        await handleResponseErrors(response, 'getting max refund amount');
        return 0;
      } else {
        const body = await response.json();
        return body.amount;
      }
    } catch (e) {
      // If 401, throw
      if (e instanceof AuthorizationError) {
        throw e;
      }
      // Else log error and return 0
      logger.error(`Undefined error getting max refund amount ${orderId}`, e);
      return 0;
    }
  }

  /**
   * Refund an order
   * (returns a refundId)
   * @param refundCommand
   */
  @Action
  async refundOrder(refundCommand: RefundCommand): Promise<string> {
    const { orderId } = refundCommand;
    const { accessToken } = authenticationStore;
    const merchantId = authenticationStore.merchantId;
    const url = `${merchantApiUrl}${merchantId}/${orderId}/refund`;
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          authorization: `Bearer ${accessToken}`,
          "content-type": "application/json-patch+json",
          'qp-territory': authenticationStore.currentTerritory,
        },
        body: JSON.stringify(refundCommand),
      });
      if (response.status == 403) {
        // Return empty string
        await handleResponseErrors(response, 'Not authorized', true, true);
        return '';
      } else if (response.status > 226) {
        // Return empty string
        await handleResponseErrors(response, 'refund orderId', false, true);
        return '';
      } else {
        const body = await response.json();
        return body.refundId;
      }
    } catch (e) {
      // Log error
      logger.error(`Undefined error refunding order ${orderId}`, e);
      // Throw error
      throw e;
    }
  }

  @Mutation
  setOrderSearchResult(searchResult: OrderSearchResult) {
    const { value, total } = searchResult;
    this.orderArray = value as OrderSearchObject[];
    this.orderCount = total as number;
  }

  @Mutation
  setLoadingOrder(bool: boolean) {
    this.loadingOrder = bool;
  }

  @Mutation
  setOrder(order: Order) {
    this.order = order;
  }

  @Mutation
  @LogMethod
  reset() {
    this.orderArray = DefaultOrderArray;
    this.orderCount = DefaultOrderCount;
    this.order = DefaultOrder;
    this.loadingOrder = DefaultLoadingOrder;
  }
}
