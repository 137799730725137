
import NoMerchant from '@/components/error/NoMerchant.vue';
import Vue from 'vue';

export default Vue.extend({
  name: 'NoMerchantPage',
  components: {
    'no-merchant': NoMerchant,
  },
});
