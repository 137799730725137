

import { Line } from 'vue-chartjs/legacy';
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, LineElement, CategoryScale, PointElement, LinearScale } from 'chart.js';
import ZipTooltip from '../Tooltip.vue';
import Vue from 'vue';
import { PropType } from 'vue';

ChartJS.register(Title, Tooltip, Legend, BarElement, LineElement, CategoryScale, LinearScale, PointElement);

/*
Based on chartjs and its vue wrapper vue-chartjs
for documentation about chartjs see https://www.chartjs.org/
for documentation about vue-chartjs see https://vue-chartjs.org/
*/
export default Vue.extend({
  name: 'LineChart',
  components: {
    'Line1': Line, // using <Line> triggers errors since its used in native canvas?
    'tooltip': ZipTooltip,
  },
  props: {
    title: { type: String, required: false },
    labels: { type: Array as PropType<Array<string>>, required: true },
    datasets: { type: Array as PropType<Array<{ label: string, backgroundColor: string, data: Array<number> }>>, required: true },
    loading: { type: Boolean, required: false, default: true },
    showProgress: { type: Boolean, required: false, default: true },
    options: { required: false },
    cssClasses: { type: Array as PropType<Array<string>>, required: false },
    tooltipText: { type: String, required: false },
  },
  data() {
    return {
      chartId: 'line-chart',
      datasetIdKey:  'label',
      loadCount: 0,
      defaultOptions: LineChartStatic.createOptionsTemplate(),
    };
  },
  computed: {
    computedOptions(): any {
      return this.options || this.defaultOptions;
    },
    cssClassesNormalized(): any {
      return this.cssClasses?.join(' ');
    },
    firstLoad(): any {
      return this.loadCount == 0 && this.loading;
    },
  },
  watch: {
    loading: function(newVal) {
      if (!newVal) {
        this.loadCount++;
      }
    },
  },
  async created() {
    ChartJS.defaults.color = this.$vuetify.theme.themes.light.scale?.toString() as string;
  },
});

export class LineChartStatic {
  static createOptionsTemplate(): any {
    return {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        y: {
          beginAtZero: true,
          grid: {
            drawBorder: false,
          }
        },
        x: {
          beginAtZero: true,
          grid: {
            display: false,
            drawBorder: false,
          },
        }
      },
      plugins: {
        legend: {
          position: 'bottom',
          labels: {
            boxHeight: '2',
         }
        },
        tooltip: {
          boxPadding: 5,
        }
      },
    };
  }

  static createDatasetTemplate(vuetify: any, dataPointCount: number, secondary = false): any {
    return {
      borderColor: secondary ? vuetify.theme.themes.light.secondary : vuetify.theme.themes.light.primary,
      backgroundColor: secondary ? vuetify.theme.themes.light.secondary : vuetify.theme.themes.light.primary,
      pointRadius: dataPointCount === 1 ? 5 : 0,
      pointHitRadius: 8,
      borderWidth: secondary ? 1 : 3,
    };
  }
}
