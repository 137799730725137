
import CheckEmail from '@/components/login/CheckEmail.vue';
import Vue from 'vue';

export default Vue.extend({
  name: 'CheckEmailPage',
  components: {
    'check-email': CheckEmail,
  },
});
