
import { Address } from '@/store/order/order-models';
import configuration from "@/configuration";
import Vue, { PropType } from 'vue';

export default Vue.extend({
  name: 'AddressDisplay',
  props: {
    address: {
      type: Object as PropType<Address>,
      required: true,
    },
  },
  computed: {
    addressIsEmpty(): boolean {
      return !this.address || Object.values(this.address).every(x => !x);
    },
    cityStatePostCode(): string {
      // The following is a lot of code to do something very simple.
      // It's basically trying to do the following, but more tolerant of missing pieces.
      // return `${this.address.city}, ${configuration.address.includeState ? this.address.state + " " : ""}${this.address.postCode}`;
      // Note that `undefined` becomes "undefined" if you naively use it in this manner.

      let result = '';
      if (this.address.city) {
        result += this.address.city;
      }
      if (configuration.address.includeState && this.address.state) {
        if (result) {
          result += ", ";
        }
        result += this.address.state;
      }
      if (this.address.postCode) {
        if (result) {
          result += " ";
        }
        result += this.address.postCode;
      }

      //

      return result;
    },
  },
});
