import configuration from '@/configuration';
import fullstory from '@/services/analytics/fullstory';
import LogGuard from '@/decorators/router-decorator';
import { errorHandler } from '@/errors';
import eventBus from '@/event-bus';
import logger from '@/logger';
import { isPlatformSupportedOnSignUp } from '@/store/merchant/merchant-settings';
import OnboardingPage from '@/pages/onboarding/OnboardingPage.vue';
import DashboardPage from '@/pages/DashboardPage.vue';
import HelpPage from '@/pages/help/HelpPage.vue';
import AuthCallbackPage from '@/pages/login/AuthCallbackPage.vue';
import CheckEmailPage from '@/pages/login/CheckEmailPage.vue';
import LogoutPage from '@/pages/login/LogoutPage.vue';
import VerifyEmailPage from '@/pages/login/VerifyEmailPage.vue';
const MSSMainPage = async() => await import(/* webpackChunkName: "mss" */'@/pages/mss/MSSMainPage.vue').then(module => module.default);
const MSSAfterStripePage = async() => await import(/* webpackChunkName: "mss" */'@/pages/mss/MSSAfterStripePage.vue').then(module => module.default);
const MSSBusinessInfoPage = async() => await import(/* webpackChunkName: "mss" */'@/pages/mss/MSSBusinessInfoPage.vue').then(module => module.default);
const MSSBusinessInfoSfdcSignUpPage = async() => await import(/* webpackChunkName: "mss" */'@/pages/mss/MSSBusinessInfoSfdcSignUpPage.vue').then(module => module.default);
const MSSCompletedPage = async() => await import(/* webpackChunkName: "mss" */'@/pages/mss/MSSCompletedPage.vue').then(module => module.default);
const MSSConnectToStripePage = async() => await import(/* webpackChunkName: "mss" */'@/pages/mss/MSSConnectToStripePage.vue').then(module => module.default);
const MSSDeclinedPage = async() => await import(/* webpackChunkName: "mss" */'@/pages/mss/MSSDeclinedPage.vue').then(module => module.default);
const MSSIntegrationPage = async() => await import(/* webpackChunkName: "mss" */'@/pages/mss/MSSIntegrationPage.vue').then(module => module.default);
const MSSSalesRepPage = async() => await import(/* webpackChunkName: "mss" */'@/pages/mss/MSSSalesRepPage.vue').then(module => module.default);
const MSSTestingIntegrationPage = async() => await import(/* webpackChunkName: "mss" */'@/pages/mss/MSSTestingIntegrationPage.vue').then(module => module.default);
import OrdersPage from '@/pages/orders/OrdersPage.vue';
import OrdersMainPage from '@/pages/orders/OrdersMainPage.vue';
import OrderDetailsPage from '@/pages/orders/OrderDetailsPage.vue';
import ErrorPage from '@/pages/otherPages/ErrorPage.vue';
import NotFoundPage from '@/pages/otherPages/NotFoundPage.vue';
import SessionExpiredPage from '@/pages/otherPages/SessionExpiredPage.vue';
import UnauthorizedPage from '@/pages/otherPages/UnauthorizedPage.vue';
import NoMerchantPage from '@/pages/otherPages/NoMerchantPage.vue';
import PaymentsMainPage from '@/pages/payments/PaymentsMainPage.vue';
import PaymentsPage from '@/pages/payments/PaymentsPage.vue';
import PaymentDetailsPage from '@/pages/payments/PaymentDetailsPage.vue';
import SettingsPage from '@/pages/settings/SettingsPage.vue';
import UsersPage from '@/pages/users/UsersPage.vue';
import { UserPermissions } from '@/store/merchant/merchant-models';
import {
  applicationStore,
  authenticationStore,
  featureStore,
  merchantStore,
  onboardingStore,
  userStore,
} from '@/store/store';
import DisputesPage from '@/pages/disputes/DisputesPage.vue';
import DisputesMainPage from '@/pages/disputes/DisputesMainPage.vue';
import DisputeDetailsPage from '@/pages/disputes/DisputeDetailsPage.vue';
import StatementsPage from '@/pages/statements/StatementsPage.vue';
import StatementDetailsPage from '@/pages/statements/StatementDetailsPage.vue';
import StatementsMainPage from '@/pages/statements/StatementsMainPage.vue';
import UpdatesMainPage from '@/pages/updates/UpdatesMainPage.vue';
import UpdatesPage from '@/pages/updates/UpdatesPage.vue';
import SamplesPage from '@/pages/dev/SamplesPage.vue';
import Vue from 'vue';
import Router, { NavigationFailure, RawLocation, Route, RouteConfig } from 'vue-router';
import { hasAdminOrRole } from './store/user/user-store';
import AuthenticationStore from './store/authentication/authentication-store';

// If we don't block this, then we can't mock the router when this file gets imported for other components
if (!configuration.isUnitTestEnvironment) {
  Vue.use(Router);
}

// define a function used to capture errors from navigation errors
// eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
export const noop = (e: NavigationFailure): void => {};

// Helper functions to avoid triggering uncaught promise exceptions when programmatically navigating.
// This is because we can encounter errors when a navigation guard causes us to redirect to a different path than originally intended.
// Unfortunately Vue Router throws errors when this occurs instead of logging/warning instead.
// For more details see: https://stackoverflow.com/a/65326844
export const routerPush = (route: RawLocation): Promise<void | Route> => router.push(route).catch(noop);
export const routerReplace = (route: RawLocation): Promise<void | Route> => router.replace(route).catch(noop);

// Navigation Lifecycle - https://router.vuejs.org/guide/advanced/navigation-guards.html#the-full-navigation-resolution-flow

/**
 * Default path before enter guard.  Here is where we route to either the login/sign up page or the home page
 * @param to
 * @param from
 * @param next
 */
export const beforeDefaultPageEnter = LogGuard('beforeDefaultPageEnter', async (to: Route, from: Route, next: (param?: any) => void) => {
  // If not logged in and/or auth token has expired, must login
  if (!authenticationStore.authenticated || authenticationStore.isExpired) {
    // clean up any state left over from a previous session if it still remains
    await applicationStore.resetAllStores();

    let isSignUp = false;
    let setPlatform = false;
    if (to.name === signupPageName || /https:\/\/(www\.)?quadpay\.com/.test(document.referrer)) {
      // Prepare Auth0 hosted login to display sign up page
      isSignUp = true;

      // Sign-ups coming from quadpay.com will have a utm string indicating avg annual sales
      if (to.query?.utm) {
        logger.info('Utm found');
        // Set utm query string param for onboarding merchant creation
        merchantStore.setUtm(to.query.utm.toString());
      }

      // Sign-ups from ecomm sites will have a platform string
      if (to.query?.platform) {
        const platform = to.query.platform.toString().toLowerCase();
        if (isPlatformSupportedOnSignUp(platform)) {
          // Set the platform
          setPlatform = true;
          merchantStore.setPlatformOnSignUp(platform);
        }
      }
    }

    try {
      await authenticationStore.login({ isSignUp, setPlatform });
    } catch (e) {
      // Router doesn't handle objects thrown that aren't descendants of Error
      // Errors don't automatically bubble up to the error handlers so we have to force it
      if (!(e instanceof Error)) {
        e = new Error(JSON.stringify(e));
      }
      return next(e);
    }
  } else {
    await merchantStore.getOnboardingMerchant();
    await merchantStore.getMerchant();
    
    // If logged in, determine type of merchant (existing or onboarding)
    if (onboardingStore.isOnboarding) {
      return next({ name: onboardingPageName, replace: true });
    } else if (merchantStore.isOnboardedMerchant) {
      return next({ name: dashboardPageName, replace: true });
    } else {
      // If onboarding merchant, auth redirect
      return next({ name: (await AuthenticationStore.getPostAuthenticationRedirectPath()).name, replace: true });
    }
  }
});

//******************************************
// Before Check Email page enter
//******************************************
export const beforeEmailEnter = LogGuard('beforeEmailEnter', async (to: Route, from: Route, next: (param?: any) => void) => {
  if (merchantStore.isOnboardedMerchant) {
    return next({ name: defaultPageName });
  }
  // If no from name, get latest merchant info and redirect
  if (!from.name) {
    await merchantStore.getOnboardingMerchant();
    const emailVerified = merchantStore.onboardingMerchant.emailVerified;
    if (!emailVerified) {
      if (configuration.featureFlags.fullstory) {
        fullstory.load();
      }
      return next();
    } else {
      // Unfortunately this means getOnboardingMerchant may be called twice but we cannot use getPostAuthenticationRedirectPath earlier or we end up in an infinite loop
      return next(await AuthenticationStore.getPostAuthenticationRedirectPath());
    }
  } else {
    if (configuration.featureFlags.fullstory) {
      fullstory.load();
    }
    return next();
  }
});

//*********************************************
// Before invoice page enter (feature flagged)
//*********************************************
export const beforeInvoiceEnter = LogGuard('beforeInvoiceEnter', async (to: Route, from: Route, next: (param?: any) => void) => {
  if (merchantStore.isOnboardedMerchant) {
    if (!(userStore.canAccessStatements)) {
      return next({ name: defaultPageName });
    }
    return next();
  }
  return next(await AuthenticationStore.getPostAuthenticationRedirectPath());
});

//*********************************************
// Before payments page enter (feature flagged)
//*********************************************
export const beforePaymentsEnter = LogGuard('beforePaymentsEnter', async (to: Route, from: Route, next: (param?: any) => void) => {
  if (merchantStore.isOnboardedMerchant) {
    if (!(userStore.canAccessPayments)) {
      return next({ name: defaultPageName });
    }
    return next();
  }
  return next(await AuthenticationStore.getPostAuthenticationRedirectPath());
});

//*********************************************
// Before dispute page enter (feature flagged)
//*********************************************
export const beforeDisputeEnter = LogGuard('beforeDisputeEnter', async (to: Route, from: Route, next: (param?: any) => void) => {
  if (merchantStore.isOnboardedMerchant) {
    if (!(userStore.canAccessDisputes)) {
      return next({ name: defaultPageName });
    }
    return next();
  }
  return next(await AuthenticationStore.getPostAuthenticationRedirectPath());
});

//*********************************************
// Before users page enter (feature flagged)
//*********************************************
export const beforeUsersEnter = LogGuard('beforeUsersEnter', async (to: Route, from: Route, next: (param?: any) => void) => {
  if (merchantStore.isOnboardedMerchant) {
    if (!(userStore.canAccessUserPages)) {
      return next({ name: defaultPageName });
    }
    return next();
  }
  return next(await AuthenticationStore.getPostAuthenticationRedirectPath());
});

//******************************************
// Before any MP page enter
//******************************************
export const beforeMpEnter = LogGuard('beforeMpEnter', async (to: Route, from: Route, next: (param?: any) => void) => {
  if(to.meta.requiredRole as UserPermissions) {
    const role = to.meta.requiredRole as UserPermissions;

    if(!hasAdminOrRole(userStore.currentUserRoles, role)) {
      return next({ name: unauthorizedPageName });
    }
  }

  if(to.meta.bypassAuthentication) {
    // Hide endless loader on all non-authed pages
    userStore.setIsUserLoading(false);
  }

  if(!onboardingStore.isOnboarding && !merchantStore.isOnboardedMerchant) {
    return next(await AuthenticationStore.getPostAuthenticationRedirectPath());
  }

  return next();
});

//******************************************
// Before onboarding MP page enter - redirect to dashboard if merchant is onboarded
//******************************************
export const beforeOnboardingEnter = LogGuard('beforeOnboardingEnter', async (to: Route, from: Route, next: (param?: any) => void) => {
  await merchantStore.getOnboardingMerchant();
  if (!onboardingStore.isOnboarding) {
    return next({ name: defaultPageName });
  }

  return next();
});

//******************************************
// Before any MSS page enter (first)
//******************************************
export const beforeMssEnter = LogGuard('beforeMssEnter', async (to: Route, from: Route, next: (param?: any) => void) => {
  if (merchantStore.isOnboardedMerchant) {
    return next({ name: defaultPageName });
  }
  
  return next();
});

//******************************************
// Before specific MSS page enters (second)
//******************************************
export const beforeMssSfdcMerchantInfoEnter = LogGuard('beforeMssSfdcMerchantInfoEnter', async (to: Route, from: Route, next: (param?: any) => void) => {
  const emailVerified = !!merchantStore.onboardingMerchant.emailVerified;
  const businessInfoAdded = !!(merchantStore.onboardingMerchant.businessInfo?.businessAddress?.line1);
  if (emailVerified || businessInfoAdded) {
    if (configuration.featureFlags.fullstory) {
      fullstory.load();
    }
    return next();
  } else {
    return next(await AuthenticationStore.getPostAuthenticationRedirectPath());
  }
});
export const beforeMssBusinessInfoEnter = LogGuard('beforeMssBusinessInfoEnter', async (to: Route, from: Route, next: (param?: any) => void) => {
  if (merchantStore.onboardingMerchant.emailVerified) {
    if (configuration.featureFlags.fullstory) {
      fullstory.load();
    }
    return next();
  } else {
    return next(await AuthenticationStore.getPostAuthenticationRedirectPath());
  }
});
export const beforeMssSalesRepEnter = LogGuard('beforeMssSalesRepEnter', async (to: Route, from: Route, next: (param?: any) => void) => {
  const businessInfoAdded = !!(merchantStore.onboardingMerchant.businessInfo?.businessAddress?.line1);
  if (businessInfoAdded) {
    return next();
  } else {
    return next(await AuthenticationStore.getPostAuthenticationRedirectPath());
  }
});
export const beforeMssStripeConnectEnter = LogGuard('beforeMssStripeConnectEnter', async (to: Route, from: Route, next: (param?: any) => void) => {
  // Repeat GET onboarding call in case user creates Stripe account without returning to MP, then comes back
  if (!from.name) {
    await merchantStore.getOnboardingMerchant();
  }
  // Accept both stages before creating a Stripe account and after creating an unsuccessful Stripe account
  const shouldReachMssStripeConnectPage = !merchantStore.onboardingMerchant.stripeIntegrationBypassed
    && (merchantStore.onboardingMerchant.msaSigned || merchantStore.onboardingMerchant.stripeAccountCreated);

  if (shouldReachMssStripeConnectPage) {
    if (configuration.featureFlags.fullstory) {
      fullstory.load();
    }
    return next();
  } else {
    return next(await AuthenticationStore.getPostAuthenticationRedirectPath());
  }
});
export const beforeMssAfterStripeEnter = LogGuard('beforeMssAfterStripeEnter', async (to: Route, from: Route, next: (param?: any) => void) => {
  if (!from.name) {
    await merchantStore.getOnboardingMerchant();
  }
  // First call to prepareIntegration will advance stage to stripeAccountCreated, before then stage will remain msaSigned
  // Must accept both for (continuous flow || straight from login)
  const shouldReachMssStripeConnectCompletionPage = !merchantStore.onboardingMerchant.stripeIntegrationBypassed
    && (merchantStore.onboardingMerchant.msaSigned || merchantStore.onboardingMerchant.stripeAccountCreated);

  if (shouldReachMssStripeConnectCompletionPage) {
    if (configuration.featureFlags.fullstory) {
      fullstory.load();
    }
    return next();
  } else {
    return next(await AuthenticationStore.getPostAuthenticationRedirectPath());
  }
});
export const beforeMssIntegrationEnter = LogGuard('beforeMssIntegrationEnter', async (to: Route, from: Route, next: (param?: any) => void) => {
  // Whether in a continuous flow or if coming from nowhere, merchant should have keys before entering
  const hasKeys = !!(merchantStore.clientId && merchantStore.clientSecret);
  if (!hasKeys) {
    await merchantStore.getKeys();
  }
  if(!merchantStore.hasDueDiligenceApprovalValue) {
    await merchantStore.getDueDiligenceApprovalForOnboardingMerchant();
  }
  const stripeStepCompleted = merchantStore.onboardingMerchant.stripeIntegrationBypassed
    || merchantStore.onboardingMerchant.stripeAccountCompleted;
  if (stripeStepCompleted) {
    if (configuration.featureFlags.fullstory) {
      fullstory.load();
    }
    return next();
  } else {
    return next(await AuthenticationStore.getPostAuthenticationRedirectPath());
  }
});
export const beforeMssCompletedEnter = LogGuard('beforeMssCompletedEnter', async (to: Route, from: Route, next: (param?: any) => void) => {
  if (featureStore.isNewMerchantComplianceFlow || merchantStore.isOnboardingIntegrationCompleted) {
    return next();
  } else {
    return next(await AuthenticationStore.getPostAuthenticationRedirectPath());
  }
});

/**
 * Global before enter guard. This runs in between `beforeRouteLeave` and `beforeRouteUpdate`
 */
export const globalBeforeEach = LogGuard('globalBeforeEach', async (to: Route, from: Route, next: (param?: any) => void) => {
  /**
   * Redirect old url to new
   * 
   * merchant-portal-development.quadpay.xyz -> merchant.dev.us.zip.co
   * merchant-portal-sandbox.quadpay.com -> merchant.sand.us.zip.co
   * merchant-portal.quadpay.com -> merchant.us.zip.co
   */

   switch(window.location.host) {
    case 'merchant-portal-development.quadpay.xyz':
      window.location.replace(`https://merchant.dev.us.zip.co${window.location.pathname}${window.location.search}`);
      break;
    case 'merchant-portal-development.quadpay.ca':
      window.location.replace(`https://merchant.dev.ca.zip.co${window.location.pathname}${window.location.search}`);
      break;
    case 'merchant-portal-sandbox.quadpay.com':
      window.location.replace(`https://merchant.sand.us.zip.co${window.location.pathname}${window.location.search}`);
      break;
    case 'merchant-portal-sandbox.quadpay.ca':
      window.location.replace(`https://merchant.sand.ca.zip.co${window.location.pathname}${window.location.search}`);
      break;
    case 'merchant-portal.quadpay.com':
      window.location.replace(`https://merchant.us.zip.co${window.location.pathname}${window.location.search}`);
      break;
    default:
      return next();
  }
});

/**
 * Global before resolve guard.  This runs after the router-decorator and all the route-specific before guards:
 * https://router.vuejs.org/guide/advanced/navigation-guards.html#in-component-guards
 * This runs on every page transition to ensure the user is in a valid state to progress.
 * All users hitting this will be attempting to access a page requiring authentication
 * @param to
 * @param from
 * @param next
 */
export const globalBeforeResolve = LogGuard('globalBeforeResolve', async (to: Route, from: Route, next: (param?: any) => void) => {
  // Check authentication
  if (!authenticationStore.authenticated) {
    return next({ name: defaultPageName });
  }

  // Check expiration
  if (authenticationStore.isExpired) {
    logger.warn('Current session is expired');
    // Don't need to await because will be pushing to session expired page
    applicationStore.resetAllStores();

    if (to.name === sessionExpiredPageName) {
      return next();
    }

    return next({ name: sessionExpiredPageName });
  } else {
    // If the user has refreshed the page, must reset country/locale and timers!
    if (!from.name) {
      authenticationStore.setExp();
      applicationStore.switchTerritory(authenticationStore.currentIdentity);
      
      // Redirect from single order page to general orders page
      const currentTerritory = applicationStore.currentCountry.code;
      if (router.currentRoute.name === orderPageName && currentTerritory !== authenticationStore.currentIdentity.territory) {
        return next({ name: ordersPageName });
      }
    }
  }

  return next();
});

/**
 * Guard that runs after each route is processed for analytics.
 * @param to
 * @param from
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const globalAfterEach = (to: Route, from: Route) => {
  eventBus.publishPageViewEvent(to);
};

export const defaultPageName = 'default';
export const defaultPagePath = '/';
export const signupPageName = 'sign-up';

export const authCallbackPageName = 'auth-callback';
export const checkEmailPageName = 'check-email';
export const onboardingPageName = 'onboarding';
export const onboardingPagePath = '/onboarding';
export const dashboardPageName = 'dashboard';
export const dashboardPagePath = '/dashboard';
export const disputesPageName = 'disputes';
export const disputeDetailsPageName = 'dispute-details';
export const disputeEvidenceFormPageName = 'dispute-evidence-form';
export const statementsPageName = 'statements';
export const statementDetailsPageName = 'statement-details';
export const errorPageName = 'error';
export const helpPageName = 'help';
export const logoutPageName = 'logout';
export const mssAfterStripePageName = 'mss-after-stripe';
export const mssAfterStripePathName = 'finalize-stripe';
export const mssBusinessInfoPageName = 'mss-business-info';
export const mssCompletedPageName = 'mss-completed';
export const mssConnectToStripePageName = 'mss-stripe-connect';
export const mssDeclinedPageName = 'mss-declined';
export const mssIntegrationPageName = 'mss-integration';
export const mssTestingIntegrationPageName = 'mss-integration-test';
export const mssRootPathName = 'sign-up';
export const mssSalesRepPageName = 'mss-sales-rep';
export const mssSfdcSignUpPageName = 'mss-sfdc-sign-up';
export const ordersPageName = 'orders'
export const orderPageName = 'order';
export const notFoundPageName = 'not-found';
export const paymentsPageName = 'payments'
export const paymentDetailsPageName = 'payment-details'
export const sessionExpiredPageName = 'session-expired';
export const settingsPageName = 'settings'
export const transactionsPageName = 'transactions'
export const unauthorizedPageName = 'unauthorized';
export const verifyEmailPageName = 'verify-email';
export const updatesPageName = 'updates';
export const usersPageName = 'users';
export const noMerchantPageName = 'no-merchant';
export const samplesPageName = 'samples';

const routes: RouteConfig[] = [
  {
    path: '/',
    name: defaultPageName,
    beforeEnter: beforeDefaultPageEnter,
  },
  {
    path: '/callback',
    name: authCallbackPageName,
    component: AuthCallbackPage,
    meta: {
      bypassAuthentication: true,
    }
  },
  {
    path: '/check-email',
    name: checkEmailPageName,
    component: CheckEmailPage,
    beforeEnter: beforeEmailEnter,
  },
  {
    path: '/verify-email',
    name: verifyEmailPageName,
    component: VerifyEmailPage,
    beforeEnter: beforeEmailEnter,
  },
  {
    path: '/onboarding',
    name: onboardingPageName,
    components: { default: OnboardingPage },
    beforeEnter: beforeOnboardingEnter,
    meta: {
      showHeader: true,
      showSidebar: true,
    }
  },
  {
    path: '/dashboard',
    name: dashboardPageName,
    components: { default: DashboardPage },
    beforeEnter: beforeMpEnter,
    meta: {
      showHeader: true,
      showSidebar: true
    }
  },
  {
    path: `/${mssRootPathName}`,
    components: { default: MSSMainPage },
    beforeEnter: beforeMssEnter,
    children: [
      {
        path: '/',
        name: signupPageName,
        beforeEnter: beforeDefaultPageEnter,
      },
      {
        path: 'business-info',
        name: mssBusinessInfoPageName,
        component: MSSBusinessInfoPage,
        beforeEnter: beforeMssBusinessInfoEnter,
      },
      {
        path: 'business-info-confirmed',
        name: mssSalesRepPageName,
        component: MSSSalesRepPage,
        beforeEnter: beforeMssSalesRepEnter,
        meta: {
          bypassAuthentication: true,
        }
      },
      {
        path: 'merchant-info',
        name: mssSfdcSignUpPageName,
        component: MSSBusinessInfoSfdcSignUpPage,
        beforeEnter: beforeMssSfdcMerchantInfoEnter,
      },
      {
        path: 'stripe-connect',
        name: mssConnectToStripePageName,
        component: MSSConnectToStripePage,
        beforeEnter: beforeMssStripeConnectEnter,
      },
      {
        path: mssAfterStripePathName,
        name: mssAfterStripePageName,
        component: MSSAfterStripePage,
        beforeEnter: beforeMssAfterStripeEnter,
      },
      {
        path: 'integration',
        name: mssIntegrationPageName,
        component: MSSIntegrationPage,
        beforeEnter: beforeMssIntegrationEnter,
      },
      {
        path: 'integration-test',
        name: mssTestingIntegrationPageName,
        component: MSSTestingIntegrationPage,
        beforeEnter: beforeMssIntegrationEnter,
      },
      {
        path: 'completed',
        name: mssCompletedPageName,
        component: MSSCompletedPage,
        beforeEnter: beforeMssCompletedEnter,
      },
      {
        path: 'declined',
        name: mssDeclinedPageName,
        component: MSSDeclinedPage,
        meta: {
          bypassAuthentication: true,
        }
      },
    ]
  },
  {
    path: '/orders',
    components: { default: OrdersMainPage },
    beforeEnter: beforeMpEnter,
    children: [
      {
        path: '',
        name: ordersPageName,
        components: { default: OrdersPage },
        meta: {
          showHeader: true,
          showSidebar: true
        },
      },
      {
        path: ':orderId',
        name: orderPageName,
        components: { default: OrderDetailsPage },
        meta: {
          showHeader: true,
          showSidebar: true
        },
      }
    ]
  },
  {
    path: '/payments',
    components: { default: PaymentsMainPage },
    beforeEnter: beforePaymentsEnter,
    meta: {
      requiredRole: UserPermissions.paymentsRecon,
    },
    children: [
      {
        path: '',
        name: paymentsPageName,
        components: { default: PaymentsPage },
        meta: {
          showHeader: true,
          showSidebar: true,
          requiredRole: UserPermissions.paymentsRecon,
        },
      },
      {
        path: ':paymentId',
        name: paymentDetailsPageName,
        components: { default: PaymentDetailsPage },
        meta: {
          showHeader: true,
          showSidebar: true,
          requiredRole: UserPermissions.paymentsRecon,
        }
      },
    ]
  },
  {
    path: '/disputes',
    components: { default: DisputesMainPage },
    beforeEnter: beforeDisputeEnter,
    meta: {
      requiredRole: UserPermissions.disputes,
    },
    children: [
      {
        path: '',
        name: disputesPageName,
        components: { default: DisputesPage },
        meta: {
          showHeader: true,
          showSidebar: true,
          requiredRole: UserPermissions.disputes,
        },
      },
      {
        path: ':disputeId',
        name: disputeDetailsPageName,
        components: { default: DisputeDetailsPage },
        meta: {
          showHeader: true,
          showSidebar: true,
          requiredRole: UserPermissions.disputes,
        }
      },
    ]
  },
  {
    path: '/statements',
    components: { default: StatementsMainPage },
    beforeEnter: beforeInvoiceEnter,
    meta: {
      requiredRole: UserPermissions.transactionsRecon,
    },
    children: [
      {
        path: '',
        name: statementsPageName,
        components: { default: StatementsPage },
        meta: {
          showHeader: true,
          showSidebar: true,
          requiredRole: UserPermissions.transactionsRecon,
        },
      },
      {
        path: ':statementId',
        name: statementDetailsPageName,
        components: { default: StatementDetailsPage },
        meta: {
          showHeader: true,
          showSidebar: true,
          requiredRole: UserPermissions.transactionsRecon,
        },
      },
    ]
  },
  {
    path: '/updates',
    components: { default: UpdatesMainPage },
    children: [
      {
        path: '',
        name: updatesPageName,
        components: { default: UpdatesPage },
        meta: {
          showHeader: true,
          showSidebar: true,
        }
      }
    ]
  },
  {
    path: '/help',
    name: helpPageName,
    components: { default: HelpPage },
    beforeEnter: beforeMpEnter,
    meta: {
      showHeader: true,
      showSidebar: true
    },
  },
  {
    path: '/settings',
    name: settingsPageName,
    components: { default: SettingsPage },
    beforeEnter: beforeMpEnter,
    meta: {
      showHeader: true,
      showSidebar: true,
      requiredRole: UserPermissions.settings,
    },
  },
  {
    path: '/users',
    name: usersPageName,
    component: UsersPage,
    beforeEnter: beforeMpEnter,
    meta: {
      showHeader: true,
      showSidebar: true,
      requiredRole: UserPermissions.admin,
    },
  },
  {
    path: '/logout',
    name: logoutPageName,
    component: LogoutPage,
    meta: {
      bypassAuthentication: true,
    }
  },
  {
    path: '/unauthorized',
    name: unauthorizedPageName,
    component: UnauthorizedPage,
    meta: {
      bypassAuthentication: true,
    }
  },
  {
    path: '/no-merchant',
    name: noMerchantPageName,
    component: NoMerchantPage,
    meta: {
      bypassAuthentication: true,
    }
  },
  {
    path: '/error',
    name: errorPageName,
    component: ErrorPage,
    meta: {
      bypassAuthentication: true,
    }
  },
  {
    path: '/session-expired',
    name: sessionExpiredPageName,
    component: SessionExpiredPage,
    meta: {
      bypassAuthentication: true,
    },
  },
  {
    path: '*',
    component: NotFoundPage,
    meta: {
      bypassAuthentication: true,
    },
  },
];

// We only want certain pages to be accessible in non-PROD environments.
// NOTE: make sure these pages do not contain sensitive data!
// They will be still compiled into the PROD bundle.
if (configuration.environmentName !== 'Production') {
  routes.push({
    path: '/samples',
    name: samplesPageName,
    component: SamplesPage,
    beforeEnter: beforeMpEnter,
    meta: {
      showHeader: true,
      showSidebar: true,
      requiredRole: UserPermissions.admin,
    },
  });
}

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  /**
   * Reset page position to top of page after transition
   * @param to
   * @param from
   * @param savedPosition
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
});

router.beforeEach(globalBeforeEach);
router.beforeResolve(globalBeforeResolve);
router.afterEach(globalAfterEach);

router.onError((error: Error) => {
  logger.info('Router error handler');
  errorHandler(error);
});

export default router;
