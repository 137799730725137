import configuration from '@/configuration';
import Vue, { VueConstructor } from 'vue';
import VueI18n from 'vue-i18n';
import globalMessages from './locales/locales'

// Fix for using i18n in promises/async methods from:
// https://github.com/kazupon/vue-i18n/issues/184#issuecomment-545954961
const i18nAsyncWrapperPlugin = {
  install(vue: VueConstructor) {
    const localT = vue.prototype.$t;
    vue.prototype._$t = localT;

    vue.prototype.$t = function(...args) {
      if (this.$i18n) {
        return localT.apply(this, args);
      } else {
        return localT.apply(this.$root, args);
      }
    };
  },
};

Vue.use(VueI18n);
Vue.use(i18nAsyncWrapperPlugin);

const numberFormats: VueI18n.NumberFormats = {
  'en-US': {
    currency: {
      style: 'currency', currency: 'USD',
    },
    // This is needed for chart axis labels (ticks)
    currencyNoFraction: {
      style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0,
    },
  },
  'en-CA': {
    currency: {
      style: 'currency', currency: 'CAD', currencyDisplay: 'code',
    },
    // This is needed for chart axis labels (ticks)
    currencyNoFraction: {
      style: 'currency', currency: 'CAD', currencyDisplay: 'code', minimumFractionDigits: 0, maximumFractionDigits: 0,
    },
  },
  'fr-CA': {
    currency: {
      style: 'currency', currency: 'CAD', currencyDisplay: 'code',
    },
    // This is needed for chart axis labels (ticks)
    currencyNoFraction: {
      style: 'currency', currency: 'CAD', currencyDisplay: 'code', minimumFractionDigits: 0, maximumFractionDigits: 0,
    },
  },
};

export default new VueI18n({
  locale: { ...configuration.supportedCountries[0] }.supportedLocales[0].locale,
  fallbackLocale: { ...configuration.supportedCountries[0] }.supportedLocales[0].locale,
  silentTranslationWarn: true,
  numberFormats,
  messages: globalMessages,
});
