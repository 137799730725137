
import Vue from "vue";
import GooglePlacesAutocomplete, {
  AddressResult
} from "@/components/common/GooglePlacesAutocomplete.vue";
import { onboardingStore, applicationStore } from "@/store/store";
import {
  UpdateBusinessInfoCommand,
  Address,
  BusinessInfo as BusinessInfoModel
} from "@/store/onboarding/onboarding-models";
import { DefaultValidatorArray, RequiredFieldValidator } from '@/validators';

export default Vue.extend({
  name: "BusinessInformation",
  components: {
    "google-places-autocomplete": GooglePlacesAutocomplete
  },
  data() {
    return {
      businessAddress: {} as Address,
      isEditMode: false,
      requiredField: DefaultValidatorArray,
    };
  },
  computed: {
    businessInfo(): BusinessInfoModel {
      return onboardingStore.businessInfo;
    }
  },
  methods: {
    async submit() {
      const { form } = this.$refs;
      if (!this.isEditMode || !form.validate()) {
        return;
      }

      const body: UpdateBusinessInfoCommand = {
        businessName: this.businessInfo.businessName,
        annualSalesMax: this.businessInfo.annualSalesMax,
        businessAddress: this.businessAddress
      };
      await onboardingStore.updateOnboardingBusinessInfo(body);

      this.$emit("submit", form);
      this.isEditMode = false;
    },
    /**
     * Handles all address changes.  Additional validation takes places here in addition
     * to the rules defined on the component as to verify that the address is in a country we support
     * and that all address components are populated.
     */
    addressChanged(addressData: AddressResult) {
      const supportedCountries = applicationStore.supportedCountries.map(
        country => country.code
      );

      // Clear error state on any input change
      this.addressValidPlacesSelection = true;
      this.addressErrorState = false;
      this.addressErrorMessages = [];

      // We can ignore as the user might still be typing
      if (!addressData) {
        return;
      }

      // Google often returns addresses with no street number, so specifically set a message for that case
      if (!addressData.street_number || !addressData.route) {
        this.addressErrorState = true;
        this.addressErrorMessages.push(
          this.$t("businessInfo.validation-address-no-street-number").toString()
        );
      } else if (
        addressData.country &&
        !supportedCountries.includes(addressData.country)
      ) {
        this.addressErrorState = true;
        this.addressErrorMessages.push(
          this.$t("businessInfo.validation-address-invalid-country", {
            supportedCountries: supportedCountries.join(", "),
            companyName: this.companyName
          }).toString()
        );
      } else if (
        !addressData.route || // Street
        !(
          addressData.locality ||
          addressData.sublocality ||
          addressData.neighborhood ||
          addressData.postal_town
        ) || // City
        !addressData.administrative_area_level_1 || // State
        !addressData.postal_code ||
        !addressData.country
      ) {
        this.addressErrorState = true;
        this.addressErrorMessages.push(
          this.$t("businessInfo.validation-address-invalid").toString()
        );

        // Address is valid
      } else {
        if (!this.businessAddress) {
          this.businessAddress = {};
        }

        this.businessAddress.state = addressData.administrative_area_level_1;
        // Sometimes the city is only in the sublocality in metropolitan cities, among other variances by territory
        this.businessAddress.city =
          addressData.locality ||
          addressData.sublocality ||
          addressData.neighborhood ||
          addressData.postal_town;
        this.businessAddress.line1 =
          addressData.street_number + " " + addressData.route;
        this.businessAddress.postCode = addressData.postal_code;
        this.businessAddress.country = addressData.country;
      }
    }
  },
  async created() {
      this.requiredField = RequiredFieldValidator;
  }
});
