
import Vue from "vue";
import { TranslateResult } from "vue-i18n";
import { isLessThanMaxCharacters, isNotEmptyString } from "@/validators";
import { merchantStore } from "@/store/store";

import CardLayout from "@/components/layouts/CardLayout.vue";
import ActionButton from "@/components/controls/ActionButton.vue";
import TextBox from "@/components/controls/TextBox.vue";

export default Vue.extend({
  name: "UpdateWebsiteForm",
  components: {
    "action-button": ActionButton,
    "card-layout": CardLayout,
    textbox: TextBox,
  },
  data() {
    return {
      loading: false,
      valid: true,
      website: "",
      validStringInputRules: [] as Array<
        (str: string) => TranslateResult | boolean
      >,
    };
  },
  created() {
    this.initializeValidationRules();
  },
  methods: {
    initializeValidationRules() {
      this.validStringInputRules = [
        (str: string) =>
          isNotEmptyString(str) ||
          this.$t("updateWebsiteForm.validation-required"),
        (str: string) =>
          isLessThanMaxCharacters(str, 100) ||
          this.$t("updateWebsiteForm.validation-character-max", [100]),
      ];
    },
    async onSubmit() {
      if (this.loading) {
        return;
      }

      this.valid = this.$refs.form.validate();
      if (!this.valid) {
        return;
      }

      this.loading = true;
      // Gross, but making sure we're actually using the website value
      await this.$nextTick();
      await merchantStore.updateWebsiteUrl(this.website);
      this.loading = false;

      this.$emit("updated");
    },
    back() {
      this.$emit("back");
    },
    cancel() {
      this.$emit('cancel')
    }
  },
});
