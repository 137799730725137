
import Vue from "vue";
import configuration from "@/configuration";
import CardLayout from "@/components/layouts/CardLayout.vue";
import ActionButton from "@/components/controls/ActionButton.vue";

export default Vue.extend({
  name: "WebsiteUpdated",
  components: {
    "action-button": ActionButton,
    "card-layout": CardLayout,
  },
  computed: {
    companyName(): string {
      return configuration.company.name;
    },
  },
  methods: {
    back() {
      this.$emit('back');
    },
    cancel() {
      this.$emit("cancel");
    },
  },
});
