
import SemicolonIcon from '@/assets/images/icons/options/semicolon.svg?inline';
import CommaIcon from '@/assets/images/icons/options/comma.svg?inline';
import PipeIcon from '@/assets/images/icons/options/pipe.svg?inline';
import SingleQuoteIcon from '@/assets/images/icons/options/single-quote.svg?inline';
import DoubleQuoteIcon from '@/assets/images/icons/options/double-quote.svg?inline';
import DoubleDatePicker from '@/components/controls/DoubleDatePicker.vue';
import moment from 'moment-timezone';
import { mdiCheck } from '@mdi/js';
import configuration from '@/configuration';
import { applicationStore, authenticationStore } from '@/store/store';
import ActionButton from '../controls/ActionButton.vue';
import CheckBox from '../controls/CheckBox.vue';
import eventBus from '@/event-bus';
import { downloadFile, RequestUrl } from '@/store/store-requests';
import Vue, { PropType } from 'vue';
import { PagingInfo } from '@/utils';

const SegmentClickedEventName = 'CSV Export';
const SegmentAdvancedOptionsEventName = 'CSV Export Advanced Options';
export default Vue.extend({
  name: 'DownloadCsvDialog',
  components: {
    'checkbox': CheckBox,
    'action-button': ActionButton,
    'double-date-picker': DoubleDatePicker,
    'semicolon-icon': SemicolonIcon,
    'comma-icon': CommaIcon,
    'pipe-icon': PipeIcon,
    'single-quote-icon': SingleQuoteIcon,
    'double-quote-icon': DoubleQuoteIcon,
  },
  props: {
    getUrlFunc: {
      type: Function as PropType<(query: any) => RequestUrl | string>,
      required: true,
    },
    getPagingInfoFunc: {
      type: Function as PropType<(args: any) => PagingInfo>,
      required: false,
    },
    getFilenameFunc: {
      type: Function as PropType<(args: any) => string>,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      default: undefined,
    },
    dataType: {
      type: String,
      required: true,
    },
    startDate: {
      type: String,
      required: false,
    },
    endDate: {
      type: String,
      required: false,
    },
    mode: {
      type: String as PropType<undefined | 'normal' | 'compact' | 'embedded'>,
      required: false,
    },
  },
  data() {
    return {
      delimiters: [
        {
          text: `${this.$t('downloadCsvDialog.delimiter-comma')}`,
          value: ',',
        },
        {
          text: `${this.$t('downloadCsvDialog.delimiter-semicolon')}`,
          value: ';',
        },
        {
          text: `${this.$t('downloadCsvDialog.delimiter-pipe')}`,
          value: '|',
        },
      ],
      quoteStyles: [
        {
          text: `${this.$t('downloadCsvDialog.quote-style-none')}`,
          desc: 'none',
          value: 0,
        },
        {
          text: `${this.$t('downloadCsvDialog.quote-style-single')}`,
          desc: 'single',
          value: 1,
        },
        {
          text: `${this.$t('downloadCsvDialog.quote-style-double')}`,
          desc: 'double',
          value: 2,
        },
      ],
      removeDateSeparators: [
        {
          text: 'YYYY-MM-DD',
          value: false,
        },
        {
          text: 'YYYYMMDD',
          value: true,
        },
      ],
      success: false,
      successIcon: mdiCheck as string,
      selectedDelimiterIndex: 0,
      selectedQuoteStyleIndex: 0,
      selectedRemoveDateSeparatorsIndex: 0,
      includeHeaders: true,
      csvLoading: false,
      showDialog: false,
      expandedState: [],
      startFilterOff: false,
      endFilterOff: false,
      defaultDateFilter: true,
      startDateState: '',
      endDateState: '',
    };
  },
  computed: {
    companyName(): string {
      return configuration.company.name;
    },
    currentLocale(): string {
      return applicationStore.currentLocale.locale;
    },
    actionText(): string {
      return this.text ?? this.$t('downloadCsvDialog.download-csv').toString();
    },
  },
  watch: {
    'startDate': function(newVal) {
      this.onStartDatePropChanged(newVal);
    },
    'endDate': function(newVal) {
      this.onEndDatePropChanged(newVal);
    },
  },
  async mounted() {
    // Update the local states. Don't mutate the props directly
    this.startDateState = this.startDate;
    this.endDateState = this.endDate;
  },
  methods: {
    hasDetailsSlot() {
      return !!this.$slots.default;
    },
    async exportToCsv() {
      // Do not allow the user to click export while already exporting
      if (this.csvLoading) {
        return;
      }

      // Track event
      this.onSaveCsv();

      this.csvLoading = true;
      this.success = false;

      let query: any = {
        delimiter: this.delimiters[this.selectedDelimiterIndex].value,
        quoteStyle: this.quoteStyles[this.selectedQuoteStyleIndex].value,
        removeDateSeparators: this.removeDateSeparators[this.selectedRemoveDateSeparatorsIndex].value,
        includeHeadings: this.includeHeaders
      };

      if (!this.hasDetailsSlot()) {
        query = { ...query, from: this.startDateState, to: this.endDateState };
      }

      const pagingInfo = this.getPagingInfoFunc
        ? await this.getPagingInfoFunc(query)
        : { pageLimit: 0, total: 0 };
      const truncationOccurred = pagingInfo.pageLimit < pagingInfo.total;
      
      if (truncationOccurred) {
        applicationStore.triggerErrorModal(this.$t('downloadCsvDialog.csv-truncation-warning', { rowCount: pagingInfo.total.toLocaleString(), maxRowCount: pagingInfo.pageLimit.toLocaleString() }).toString());
      } else {
        const success = await downloadFile(this.getFilenameFunc({ startDate: this.startDateState, endDate: this.endDateState }), {
          url: this.getUrlFunc(query),
          options: {
            dataType: this.dataType,
            territory: authenticationStore.currentTerritory
          }
        });
        if (!success) {
          applicationStore.triggerErrorModal(this.$t('downloadCsvDialog.export-csv-error', { companyName: this.companyName }).toString());
        }
      }

      this.csvLoading = false;
      this.success = true;
      setTimeout(() => {
        this.showDialog = false;
        this.success = false;
      }, 500);
    },
    async startDateUpdated(newDate: string) {
      this.startDateState = moment.utc(newDate, "YYYY-MM-DD").startOf("day").toISOString();
    },
    async endDateUpdated(newDate: string) {
      this.endDateState = moment.utc(newDate, "YYYY-MM-DD").endOf("day").toISOString();
    },
    onCloseDialog() {
      this.showDialog = false;
    },
    onStartDatePropChanged(newValue) {
      this.startDateState = newValue;
    },
    onEndDatePropChanged(newValue) {
      this.endDateState = newValue;
    },
    // Tracking events
    onActivateDialog() {
      eventBus.publishTrackClickEvent(SegmentClickedEventName, { title: 'Activating Dialog' });
    },
    onSaveCsv() {
      eventBus.publishTrackClickEvent(SegmentClickedEventName, { title: 'Saving CSV' });
    },
    onToggleAdvancedOptions() {
      eventBus.publishTrackClickEvent(SegmentAdvancedOptionsEventName, { title: `${this.expandedState?.length ? 'Collapsing' : 'Expanding'} Panel` });
    },
    onToggleIncludeHeaders() {
      eventBus.publishTrackClickEvent(SegmentAdvancedOptionsEventName, { title: `Setting Include Headers to ${this.includeHeaders}` });
    },
    onChangeDelimiter(value) {
      eventBus.publishTrackClickEvent(SegmentAdvancedOptionsEventName, { title: `Setting Delimiter to ${value}` });
    },
    onChangeQuotation(value) {
      eventBus.publishTrackClickEvent(SegmentAdvancedOptionsEventName, { title: `Setting Quotation to ${value}` });
    },
    onChangeDateFormat(value) {
      eventBus.publishTrackClickEvent(SegmentAdvancedOptionsEventName, { title: `Setting Date Format to ${value}` });
    },
  },
});
