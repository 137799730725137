
import PageHeader from '@/components/common/PageHeader.vue';
import DialogBox from '@/components/common/DialogBox.vue';
import SessionExpiringModal from '@/components/common/SessionExpiringModal.vue';
import Sidebar from '@/components/common/Sidebar.vue';
import configuration from '@/configuration';
import { TranslateResult } from 'vue-i18n';
import logger from '@/logger';
import { applicationStore, authenticationStore, contentStore, userStore } from '@/store/store';
import LoadingSpinner from './components/common/LoadingSpinner.vue';
import Vue from 'vue';

export default Vue.extend({
  name: 'App',
  components: {
    'dialog-box': DialogBox,
    'page-header': PageHeader,
    Sidebar,
    'session-expiring-modal': SessionExpiringModal,
    'loading-spinner': LoadingSpinner,
  },
  data() {
    return {
      dialogTitle: '' as TranslateResult,
      dialogButtonText: '' as TranslateResult,
      isLoggingOut: false,
      windowWidth: window.innerWidth as number,
      drawerOpen: false,
    };
  },
  computed: {
    isUserLoading(): boolean {
      return userStore.isUserLoading;
    },
    isUserLoggingOut(): boolean {
      return this.isLoggingOut;
    },
    errorModalMessage(): string {
      return applicationStore.errorModalMessage;
    },
    errorToDisplay(): boolean {
      return applicationStore.showErrorModal;
    },
    sessionExpiring(): boolean {
      return authenticationStore.showExpModal;
    },
    isMobile(): boolean {
      // 812px is iPhoneX
      return this.windowWidth <= 812;
    },
  },
  /**
   * Set the page title on component creation
   */
  created() {
    document.title = configuration.environmentName === "Production" ?
      `${configuration.company.name} Merchant Portal` :
      `${configuration.company.name} ${configuration.environmentName} Merchant Portal`;

    logger.info(
      `Configuration loaded for node env '${process.env.NODE_ENV}' and hostname '${window.location.hostname}'`,
      configuration);

    this.dialogTitle = this.$t('app.dialog-title');
    this.dialogButtonText = this.$t('app.dialog-button-text');
  },
  async mounted() {
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth;
    });

    await userStore.getCurrentUser();
    await this.getNotifications();
  },
  methods: {
    closeErrorModal() {
      applicationStore.toggleShowErrorModal(false);
    },
    closeSessionExpiringModal() {
      authenticationStore.toggleShowExpModal(false);
    },
    toggleNavDrawerFn() {
      this.drawerOpen = !this.drawerOpen;
    },
    async getNotifications() {
      await contentStore.refreshNotifications();
    },
    async logout() {
      this.isLoggingOut = true;

      try {
        // Logout and redirect to logout page
        await authenticationStore.logout(false);
      } catch (e) {
        throw new Error(`Error logging out: ${JSON.stringify(e)}`);
      }
    },
  },
});
