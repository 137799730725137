
import NotFoundImage from '@/assets/images/404.svg?inline';
import SimpleCopyrightFooter from '@/components/common/SimpleCopyrightFooter.vue';
import SimpleLogoHeader from '@/components/common/SimpleLogoHeader.vue';
import Vue from 'vue';

/**
 * Component used for not-found items.
 */
export default Vue.extend({
  name: 'NotFound',
  components: {
    'not-found-image': NotFoundImage,
    'simple-footer': SimpleCopyrightFooter,
    'simple-header': SimpleLogoHeader,
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    navigateBack(event: Event) {
      event.preventDefault();

      // UI idempotency in case a user clicks more than once
      if (this.loading) {
        return;
      }

      this.loading = true;
      this.$router.go(-1);
    },
  },
});
