
import AddressDisplay from '@/components/orders/AddressDisplay.vue';
import LineItemsTable from '@/components/orders/LineItemsTable.vue';
import RefundDialog from '@/components/orders/RefundDialog.vue';
import RefundsTable from '@/components/orders/RefundsTable.vue';
import { Customer, Order } from '@/store/order/order-models';
import { applicationStore, featureStore, merchantStore, orderStore, userStore } from '@/store/store';
import TransactionTable from './TransactionTable.vue';
import SimpleView from '../layouts/SimpleView.vue';
import { Territory } from '@/store/application/application-models';
import { helpPageName } from '@/router';
import { toLocalDateTime } from '@/datetime';
import configuration from '@/configuration';
import Vue from 'vue';

export default Vue.extend({
  name: 'OrderDetails',
  components: {
    'simple-view': SimpleView,
    'address-display': AddressDisplay,
    'line-items-table': LineItemsTable,
    'refund-dialog': RefundDialog,
    'refunds-table': RefundsTable,
    'transaction-table': TransactionTable
  },
  computed: {
    showTax(): any {
      return featureStore.shouldDisplayTaxInfo;
    },
    canAccessStatements(): any {
      return userStore.canAccessStatements;
    },
    order(): Order {
      return orderStore.order;
    },
    customer(): Customer {
      return orderStore.order?.customer || {};
    },
    customerName(): string | undefined {
      if (this.customer.firstName && this.customer.lastName) {
        return `${this.customer.firstName} ${this.customer.lastName}`;
      }
      return undefined;
    },
    helpPageRouteName(): string {
      return helpPageName;
    },
    canRefund(): boolean {
      return merchantStore.merchant?.portalEnabledRefund || false;
    },
    refundDisabled(): boolean {
      return this.canRefund
        && userStore.canAccessDisputes
        && this.isUsTerritory()
        && !!this.order.hasDisputedPayment;
    },
    hasLineItems(): boolean {
      return !!orderStore?.order?.lineItems?.length;
    },
    currentLocale(): string {
      return applicationStore.currentLocale.locale;
    },
    disputesAndRefundsLink(): any {
      return configuration.links.disputesAndRefundsLink;
    },
  },
  methods: {
    /**
     * Display the date, in Utc using the configuration.dateFormat
     */
    displayDate(date: any) {
      return toLocalDateTime(date);
    },
    displayAmount(amount) {
      return this.$n(amount, 'currency');
    },
    isUsTerritory(): boolean {
      const territories = applicationStore.currentCountry?.territories || [];
      return territories.some(t => t === Territory.US);
    },
  },
});
