
import {
  mdiLockOutline,
  mdiLogout
} from '@mdi/js';
import { SupportedLocale } from '@/store/application/application-models';
import { applicationStore, authenticationStore, merchantStore, contentStore, userStore, onboardingStore } from '@/store/store';
import SidebarNotificationList from './SidebarNotificationList.vue'
import configuration from '@/configuration';
import { Notification } from '@/store/content/content-models';
import { MerchantIdentity } from '@/store/merchant/merchant-models';
import TickIcon from '@/assets/images/icons/tick.svg?inline';
import { TranslateResult } from 'vue-i18n';
import Vue, { PropType } from 'vue';
import Tooltip from "../controls/Tooltip.vue";

export type SidebarLink = {
  title: string | TranslateResult,
  icon: string,
  path: string,
  disabled?: string,
}

const territoryMap = {
  US: 'USA',
  GB: 'UK'
};
export default Vue.extend({
  name: 'Sidebar', // eslint-disable-line vue/multi-word-component-names
  components: {
    'sidebar-notification-list': SidebarNotificationList,
    'tick-icon': TickIcon,
    'tooltip': Tooltip,
  },
  props: {
    isMobile: {
      type: Boolean,
      default: false,
    },
    drawerOpen: {
      type: Boolean,
      default: true,
    },
    toggleNavDrawerFn: {
      type: Function as PropType<() => void>,
      required: true,
    },
  },
  data() {
    return {
      logoutIcon: mdiLogout as string,
      previewClickCounter: 0,
      isPreview: false,
    };
  },
  mounted() {
    this.isPreview = localStorage.getItem('PreviewEnabled') === 'true';
  },
  computed: {
    sidebarOpen: {
      get(): boolean {
        return this.drawerOpen;
      },
      set(value: boolean) {
        if (this.drawerOpen !== value) {
          this.toggleDrawer();
        }
      },
    },
    companyName(): string {
      return configuration.company.name;
    },
    userName(): string {
      return authenticationStore.userName;
    },
    merchantId(): string {
      return authenticationStore.merchantId;
    },
    canSwitchIdentities(): boolean {
      return this.hasMultipleMerchantIdentities || this.hasMultipleSupportedLocales;
    },
    hasMultipleMerchantIdentities(): boolean {
      return !!merchantStore.availableMerchantIdentities &&
        merchantStore.availableMerchantIdentities.length > 1;
    },
    merchantIdentitiesForUser(): MerchantIdentity[] {
      return merchantStore.availableMerchantIdentities;
    },
    hasMultipleSupportedLocales(): boolean {
      return applicationStore.supportedLocales.length > 1;
    },
    supportedLocales(): SupportedLocale[] {
      return applicationStore.supportedLocales;
    },
    currentLocale(): string {
      return applicationStore.currentLocale.locale;
    },
    csvV2Enabled(): boolean {
      return configuration.featureFlags.csvV2Enabled && applicationStore.currentCountry.code.toUpperCase() === 'US';
    },
    canDisplay(): boolean {
      // Defaults to false at beginning of page load when meta = {}
      return !!this.$route.meta?.showSidebar;
    },
    currentPlatform(): string {
      return merchantStore.platform;
    },
    contentNotifications(): Notification[] {
      return contentStore.sideBarContentNotifications;
    },
    isOnboarding(): boolean {
      return onboardingStore.isOnboarding;
    },
    lockIcon() {
      return mdiLockOutline;
    },
    pages(): any {
      const pages: SidebarLink[] = [];

      if(onboardingStore.isOnboarding) {
        pages.push({
          title: this.$t('sidebar.onboarding'),
          icon: '$onboarding',
          path: '/onboarding',
        });
      }

      pages.push({
        title: this.$t('sidebar.dashboard'),
        icon: '$dashboard',
        path: '/dashboard'
      });

      pages.push({
        title: this.$t('sidebar.orders'),
        icon: '$orders',
        path: '/orders'
      });

      if (userStore.canAccessPayments) {
        pages.push({
          title: this.$t('sidebar.payments'),
          icon: '$payments',
          path: '/payments'
        });
      }

      if (userStore.canAccessDisputes) {
        pages.push({
          title: this.$t('sidebar.disputes'),
          icon: '$disputes',
          path: '/disputes',
          disabled: this.isOnboarding ? this.$t('onboarding.locked-until-onboarding-completed') : undefined,
        });
      }

      if (userStore.canAccessStatements) {
        pages.push({
          title: this.$t('sidebar.statements'),
          icon: '$invoices',
          path: '/statements',
          disabled: this.isOnboarding ? this.$t('onboarding.locked-until-onboarding-completed') : undefined,
        });
      }

      return pages;
    },
    otherPages(): any {
      const pages: SidebarLink[] = [];

      if (userStore.canAccessUserPages) {
        pages.push({
          title: this.$t('sidebar.users'),
          icon: '$user',
          path: '/users'
        });
      }

      if (userStore.canAccessSettings) {
        pages.push({
          title: this.$t('sidebar.settings'),
          icon: '$settings',
          path: '/settings',
          disabled: this.isOnboarding ? this.$t('onboarding.locked-until-onboarding-completed') : undefined,
        });
      }

      pages.push({
        title: this.$t('sidebar.help'),
        icon: '$help',
        path: '/help'
      });

      return pages;
    },
  },
  methods: {
    getTerritoryName(territory: string) {
      return territoryMap[territory] || territory;
    },
    toggleDrawer() {
      this.toggleNavDrawerFn();
    },
    async switchMerchant(identity: MerchantIdentity) {
      await applicationStore.switchMerchant(identity);
      this.toggleDrawer();
    },
    async switchLanguage(locale: SupportedLocale) {
      await applicationStore.switchLanguage(locale);
      this.toggleDrawer();
    },
    async logout(event: Event) {
      event.preventDefault();

      // Toggle drawer closed (for mobile indication of "Logout" pressed)
      this.toggleDrawer();

      try {
        // Logout and redirect to logout page
        await authenticationStore.logout(false);
      } catch (e) {
        throw new Error(`Error logging out: ${JSON.stringify(e)}`);
      }
    },
    async togglePreview() {
      // For now it is using number of clicks on the zip logo to toggle preview mode
      // This feature is only available to Sandbox environment for now
      const BUTTON_CLICKS_TO_TOGGLE_PREVIEW_MODE = 6;
      const PREVIEW_ENABLED_KEY = 'PreviewEnabled';

      if (configuration.environmentName?.includes('Development') || configuration.environmentName?.includes('Sandbox') || configuration.environmentName?.includes('Production')) {
        this.previewClickCounter++;
        if (this.previewClickCounter % BUTTON_CLICKS_TO_TOGGLE_PREVIEW_MODE === 0) {
          try {
            if (!this.isPreview) {
              localStorage.setItem(PREVIEW_ENABLED_KEY, 'true');
            } else {
              localStorage.setItem(PREVIEW_ENABLED_KEY, 'false');
            }
            window.location.reload();
          } catch {
            // silently consumed the error as it is not critical. app can continue as usual.
          }
        }
      }
    },
  },
});
